<div>
  <div class="p-4">
      <canvas baseChart width="100%" height="100%"
        [data]="chartConfig"
        [labels]="chartConfig.labels"
        [type]="pieChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="pieChartLegend"
        (chartClick)="chartClicked($event)">
      </canvas>
  </div>
</div>