import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { S1ButtonType, S1UIService, S1Table, IS1InputSelectItem } from '@app/s1';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { IProfile, IProfileActivation } from '@app/shared/models/profile';
import { SettingsService } from '@app/core/settings/settings.service';
import { ProfileActivationDateModalComponent } from '../profile-activation-date-modal/profile-activation-date-modal.component';
import { IHierarcicalSelection, HierarchicalSelectionModalComponent } from '../hierarchical-selection-modal/hierarchical-selection-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'mdm-profile-activation-hierarchical',
  templateUrl: './profile-activation-hierarchical.component.html',
  styleUrls: ['./profile-activation-hierarchical.component.scss']
})
export class ProfileActivationHierarchicalComponent implements OnInit, AfterViewInit {

  s1ButtonType = S1ButtonType;

  searchForm: UntypedFormGroup;

  @Input() profile: IProfile;
  @Input() set profileActivations(newValue: IProfileActivation[]) {
    this._profileActivations = newValue
    this.updateTable()
  }

  @Output() profileActivationsChange = new EventEmitter<IProfileActivation[]>();

  @ViewChild('table') table: S1Table;
  @ViewChild('activationDateModal') activationDateModal: ProfileActivationDateModalComponent;
  @ViewChild('hierarchicalSelectionModal') hierarchicalSelectionModal: HierarchicalSelectionModalComponent;

  _profileActivations: IProfileActivation[] = []

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private ui: S1UIService, public settings: SettingsService) { 

    this.searchForm = this.formBuilder.group({
      code: null,
      company: null,
      region: null,
      site: null,
      brand: null,
      model: null,
      group: null
    });

  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.updateTable()
  }

  private removeSingle(activations: IProfileActivation[]): IProfileActivation[] {

    return activations.filter(activation => activation.idUnit == null)

  }

  private updateTable() {
    this.table?.updateData(this.removeSingle(this._profileActivations))
  }

  showDatePicker(activation: IProfileActivation) {

    this.activationDateModal.open(activation)

  }

  openSelection() {
    this.hierarchicalSelectionModal.open()
  }

  addRule(selection: IHierarcicalSelection) {

    if (this._profileActivations == null) {
      this._profileActivations = []
    }

    const company: IS1InputSelectItem = selection.company
    const region: IS1InputSelectItem = selection.region
    const site: IS1InputSelectItem = selection.site
    const group: IS1InputSelectItem = selection.group
    const brand: IS1InputSelectItem = selection.brand
    const model: IS1InputSelectItem = selection.model

    const profileActivation: IProfileActivation = {
      idProfile: this.profile?.id,
      idCompany: +company?.code,
      nameCompany: company?.label,
      idRegion: +region?.code,
      nameRegion: region?.label,
      idSite: +site?.code,
      nameSite: site?.label,
      idGroup: +group?.code,
      nameGroup: group?.label,
      idBrand: +brand?.code,
      nameBrand: brand?.label,
      idModel: +model?.code,
      nameModel: model?.label
    }

    this._profileActivations.push(profileActivation)
    this.updateTable()
    this.ui.showSuccessToast("profiles.assignment.sections.hierarchical.popup.created")
    this.profileActivationsChange.emit(this._profileActivations)

  }

  askDeleteRule(activation: IProfileActivation) {

    this.ui.showDialogPopup("profiles.activation.sections.hierarchical.popup.askDelete").then(result => {

      if (result.value) {
        this.deleteRule(activation)
      }

    })

  }

  deleteRule(activationToDelete: IProfileActivation) {

    this._profileActivations = this._profileActivations.filter(activation => activation.id != activationToDelete.id)
    this.updateTable()
    this.ui.showSuccessToast("profiles.activation.sections.hierarchical.popup.deleted")
    this.profileActivationsChange.emit(this._profileActivations)

  }

  updateProfile(profileUpdated: IProfileActivation) {

    this._profileActivations.map(activation => { 
      activation.id == profileUpdated.id
      activation = profileUpdated
    })

    this.updateTable()

  }

  showDetail(activationProfile: IProfileActivation) {
    this.router.navigate(["/profiles/" + this.profile?.id + "/activation/" + activationProfile?.id + "/detail"]);
  }

}