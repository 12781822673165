import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'mdm-dashboard-profile-card',
  templateUrl: './dashboard-profile-card.component.html',
  styleUrls: ['./dashboard-profile-card.component.scss']
})
export class DashboardProfileCardComponent {
  
  @Input() summary: any;
  @Output() onDetailPlotLink = new EventEmitter<any>();
  
  constructor() { }

  goToPage(idType, profileStatus) {
    let filters = {
      'idType': idType,
      'profileStatus': profileStatus
    }
    this.onDetailPlotLink.emit(filters);
  }
}
