import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1UIService, S1ButtonType, S1Table } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { IUnitsSearchParams } from '@app/shared/services/units.service';
import { IProfilesSelectionParams, ProfilesService } from '@app/shared/services/profiles.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AeService } from '@app/shared/services/ae.service';

@Component({
  selector: 'mdm-unit-selection-modal',
  templateUrl: './unit-selection-modal.component.html',
  styleUrls: ['./unit-selection-modal.component.scss']
})
export class UnitSelectionModalComponent implements IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;

  @Input() editMode: boolean = false;
  @Input() idProfile: number;
  @Input() idCompany: number;
  @Input() idAgent: number;
  @Input() canForceProfile: boolean = false;
  @Input() androidEnterpriseUnits: boolean = false;
  @Output() unitSelected = new EventEmitter<IProfilesSelectionParams>();
  @Output() unitAllSelected = new EventEmitter<IProfilesSelectionParams>();
  
  s1ButtonType = S1ButtonType;
  selection: number[] = [];
  searchForm: UntypedFormGroup;
  advancedSearchCollapsed: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private ui: S1UIService,
    public settings: SettingsService,
    private profilesService: ProfilesService,
    private aeService: AeService
  ) {
    this.searchForm = this.formBuilder.group({
      code: null,
      fingerprint: null,
      region: null,
      site: null,
      brand: null,
      model: null,
      group: null,
      company: this.settings.getCompany()
    });
    this.advancedSearchCollapsed = true;

  }

  open() {
    this.selection = [];

    this.searchForm.patchValue({
      code: null,
      fingerprint: null,
      region: this.settings.setPreselectedRegion(this.searchForm.controls.region, this.settings.getCompany()?.code, true),
      site: null,
      brand: null,
      model: null,
      group: null,
      company: this.settings.getCompany()
    });
    this.advancedSearchCollapsed = true;

    this.modal.open(S1ModalSizes.XL);
    this.getAvaiableUnitsDistribution();
  }

  close() {
    this.initializeResults(true);
    this.modal.close();
  }

  getAvaiableUnitsDistribution() {
    const params: IUnitsSearchParams = {
      codUnit: this.searchForm.controls.code.value,
      fingerprint: this.searchForm.controls.fingerprint.value,
      idCompany: this.searchForm.controls.company.value?.code,
      idAgent: this.idAgent,
      idRegion: !this.advancedSearchCollapsed ? this.searchForm.controls.region.value?.code : null,
      idSite: !this.advancedSearchCollapsed ? this.searchForm.controls.site.value?.code : null,
      idGroup: !this.advancedSearchCollapsed ? this.searchForm.controls.group.value?.code : null,
      idBrand: !this.advancedSearchCollapsed ? this.searchForm.controls.brand.value?.code : null,
      idModel: !this.advancedSearchCollapsed ? this.searchForm.controls.model.value?.code : null,
      paging: true,
      page: this.table?.config.pagination.page.page ?? 1,
      rows: this.table?.config.pagination.page.itemsPerPage ?? 10
    };
    if(!this.androidEnterpriseUnits) {
      this.profilesService.getAvailableUnitsDistribution(this.idProfile, params).subscribe(unitsPaginated => {
        this.table.updateOnlineData(unitsPaginated.units, unitsPaginated.total);
        this.initializeResults();
      });
    } else {
      this.aeService.getAvailableUnitsDistribution(this.idProfile, params).subscribe(unitsPaginated => {
        this.table.updateOnlineData(unitsPaginated.units, unitsPaginated.total);
        this.initializeResults();
      });
    }

  }

  askSaveAll() {
    const params: IUnitsSearchParams = {
      codUnit: this.searchForm.controls.code.value,
      fingerprint: this.searchForm.controls.fingerprint.value,
      idCompany: this.searchForm.controls.company.value?.code,
      idAgent: this.idAgent,
      idRegion: !this.advancedSearchCollapsed ? this.searchForm.controls.region.value?.code : null,
      idSite: !this.advancedSearchCollapsed ? this.searchForm.controls.site.value?.code : null,
      idGroup: !this.advancedSearchCollapsed ? this.searchForm.controls.group.value?.code : null,
      idBrand: !this.advancedSearchCollapsed ? this.searchForm.controls.brand.value?.code : null,
      idModel: !this.advancedSearchCollapsed ? this.searchForm.controls.model.value?.code : null
    };

    let text = this.translate.instant("profiles.distributions.popup.askSave", {total: this.table.config.totalData});
    let option = this.translate.instant("profiles.distributions.popup.askResendYes");
    let selectionParams: IProfilesSelectionParams  = {
      unitsSearchParams: params
    }
    if(this.canForceProfile) {
      this.ui.showDialogWithCheckBoxPopup(text, option).then(result => {
        if (!result.dismiss) {
          selectionParams.forceProfile = result.value == 1;
          this.unitAllSelected.emit(selectionParams);
          this.close();
        }
      });
    } else {
      this.ui.showDialogPopup(text).then(result => {
        if (result.value) {
          this.unitAllSelected.emit(selectionParams);
          this.close();
        }
      });
    }
  }

  askSaveSelection() {
    if(this.getTotalSelections()>0) {
      let text = this.translate.instant("profiles.distributions.popup.askSave", {total: this.getTotalSelections()});
      let option = this.translate.instant("profiles.distributions.popup.askResendYes");
      let selectionParams: IProfilesSelectionParams  = {
        units: [].concat.apply([], this.pagesSelectionId)
      }
      if(this.canForceProfile) {
        this.ui.showDialogWithCheckBoxPopup(text, option).then(result => {
          if (!result.dismiss) {
            selectionParams.forceProfile = result.value == 1;
            this.unitSelected.emit(selectionParams);
            this.close();
          }
        });
      } else {
        this.ui.showDialogPopup(text).then(result => {
          if (result.value) {
            this.unitSelected.emit(selectionParams);
            this.close();
          }
        });
      }
    }
  }


  pagesSelectionFlag: boolean[];
  pagesSelectionId: number[][] = [];
  morePages: boolean = true;
  numPages: number;
  currentPage: number;

  initializeResults(reset?: boolean) {
    setTimeout(() => {
      let oldNumPages = this.numPages;
      this.numPages = this.table.config.pagination?.numPages;
      let oldPage = this.currentPage;
      this.currentPage = this.table.config.pagination?.page.page;
      this.morePages = this.numPages > 1;
      if(reset || oldNumPages != this.numPages) { ////oldPage!=this.currentPage || 
        this.pagesSelectionFlag = new Array(this.numPages).fill(false);
        this.pagesSelectionId = new Array(this.numPages).fill([]);
      }
    }, 1);
  }
  
  selectAll() {
    let itemsPerPage: any  = this.table.config.pagination.page.itemsPerPage;
    this.pagesSelectionFlag = new Array(this.numPages).fill(true);
    this.pagesSelectionId = new Array(this.numPages).fill([]);

    for (let indexPage = 0; indexPage < this.numPages; indexPage++) {

      if(indexPage == this.currentPage-1) { //prima,centrale,ultima
        this.pagesSelectionId[indexPage] = this.table.config.rows.map(unit => unit.id);
      } else if(indexPage != this.currentPage-1 && indexPage != this.numPages-1) { //non la corrente, non l'ultima
        this.pagesSelectionId[indexPage] = new Array(parseInt(itemsPerPage)).fill(true);
      } else if(indexPage != this.currentPage-1 && indexPage == this.numPages-1) { //non la corrente, l'ultima
        let remains = this.table.config.totalData - this.getTotalSelections();
        this.pagesSelectionId[indexPage] = new Array(remains).fill(true);
      }
    }
  }

  getPageSelectionFlag() {
    return this.pagesSelectionFlag && this.pagesSelectionFlag[this.currentPage-1];
  }

  isSelected(unitId: number) {
    return this.pagesSelectionId && this.pagesSelectionId[this.currentPage-1]?.findIndex(id => id == unitId) >= 0;
  }

  toggleSelection() {
    this.pagesSelectionFlag[this.currentPage-1] = !this.pagesSelectionFlag[this.currentPage-1];
    this.pagesSelectionId[this.currentPage-1] = [];

    if(this.pagesSelectionFlag[this.currentPage-1] == true) {
      let units = this.table.config.rows;
      let unitIds = [];
      units.forEach(unit => {
        unitIds.push(unit.id);
      });
      this.pagesSelectionId[this.currentPage-1] = unitIds;
    }
  }

  existsSelection() {
    return this.getTotalSelections() > 0;
  }

  deselectSelection() {
    this.pagesSelectionFlag = new Array(this.numPages).fill(false);
    this.pagesSelectionId = new Array(this.numPages).fill([]);
  }


  getTotalSelections() {
    let total = 0;
    this.pagesSelectionId.forEach( selection => {
      total += selection.length;
    })

    return total;
  }

  updateSelection(checked: boolean, unitId: number) {
    let index = this.pagesSelectionId[this.currentPage-1].findIndex(id => id == unitId);
    let found = index > -1;
    if(!found && checked) {
      let unitIds = [... this.pagesSelectionId[this.currentPage-1]];
      unitIds.push(unitId);
      this.pagesSelectionId[this.currentPage-1] = unitIds;
    } else if(found && !checked) {
      this.pagesSelectionId[this.currentPage-1].splice(index, 1);
    }
    this.pagesSelectionFlag[this.currentPage-1] = false;
  }

}
