import { Component, OnInit, ViewChild } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { UnitsService } from '@app/shared/services/units.service';
import { IUnit } from '@app/shared/models/models';

@Component({
  selector: 'mdm-unit-failure-modal',
  templateUrl: './unit-failure-modal.component.html',
  styleUrls: ['./unit-failure-modal.component.scss']
})
export class UnitFailureModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;

  unit: IUnit;

  constructor(public settings: SettingsService, private unitsService: UnitsService, private ui: S1UIService) {
  }

  ngOnInit(): void { }

  open(unit: IUnit) {
    this.modal.open(S1ModalSizes.LG);
    this.unit = unit;
    this.table.updateData(unit.unitDisablements);
  }

  close() {
    this.modal.close();
  }

}
