import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoggingService } from '../services/log.service';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { RoleType } from '../services/auth.service';
import { IGroup, IRegion, ISite } from '@app/shared/models/models';
import {AbstractControl} from "@angular/forms";
import {IRegionsSearchParams, RegionsService} from "@app/shared/services/regions.service";
import {SitesService} from "@app/shared/services/sites.service";
import { IS1InputSelectItem } from '@app/s1';

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;
    private filterOption: any = new Map();

    sidebarChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private translate: TranslateService, private router: Router, private baseService: AuthService, private logService: LoggingService, private regionsService: RegionsService, private sitesService: SitesService) {

        this.errorTranslation = this.translate.get('error');

        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: 'Smartilio',
            year: ((new Date()).getFullYear()),
            version: environment.version,
            versionBE: environment.versionBE,
            releaseDate: environment.releaseDate
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    getInfoVersion() {
        this.baseService.getVersion(this.baseService.pathInfoVersion)
            .subscribe((response) => {
                this.setAppSetting('versionBE', response.version);
                this.setAppSetting('releaseDate', response.releaseDate);
                this.logService.log("Infos ", response, 300);
            },
                (error) => {
                    this.logService.log("Error", error, 200);
                }
            );

    }
    

    sessionExpired() {
        localStorage.clear();
    }

    manageErrorMsg(outcome) {

        this.translate.get('error').subscribe( (text) => {
            this.errorTranslation = text;
        });

        //this.errorTranslation = this.translate.get('error');
        let message = "";

        switch (outcome.code) {
            case '0001':
                message = this.errorTranslation.error_0001;
                break;
            case '0002':
                message = this.errorTranslation.error_0002;
                break;
            case '0003':
                message = this.errorTranslation.bad_credential;
                break;
            case '0004':
                message = this.errorTranslation.pwd_expired;
                break;
            case '0024':
                message = this.errorTranslation.same_pwd;
                break;
            case '0005':
                this.sessionExpired();
                this.router.navigate(["/login/0"]);
                break;
            case '0006':
                message = this.errorTranslation.account_disabled;
                break;
            case '0010':
                message = this.errorTranslation.link_expired;
                break;
            case '0007':
                this.sessionExpired();
                this.router.navigate(["/login/0"]);
                message = this.errorTranslation.auth_required;
                break;
            default:
                message = this.errorTranslation.generic_error;
                break;
        }
        return message;
    }

    setFilterFromSidebar( key: string , value: string ) {
        this.filterOption[key] = value;
        localStorage.setItem( 'filterOption' , JSON.stringify(this.filterOption) );
    }

    getActiveFiltersOfSidebar() {
        var result = JSON.parse(localStorage.getItem( 'filterOption' ));
        return result;
    }

    getCompany() {
        return this.isSuperAdmin() || this.isReseller() ? 
                    this.getFilterOfSidebarByName("company") :
                    {'code': localStorage.getItem("idCompany"), 'label': localStorage.getItem("nameCompany"), 'reseller': localStorage.getItem("resellerCompany")};
    }

    getRegions() {
        if(this.isOperatore() || this.isOperatoreRO()) {
            let _regions = localStorage.getItem('regions');
            if(_regions) {
                let regions: IRegion[] = JSON.parse(_regions);
                return regions?.map(region => {
                    return {'code': region.id, 'label': region.name}
                });
            } else {
                return [];
            }
        } else {
           return null;
        }
    }

    getSites() {
        if(this.isOperatore()) {
            let _sites = localStorage.getItem('sites');
            if(_sites) {
                let sites: ISite[] = JSON.parse(_sites);
                return sites?.map(site => {
                    return {'code': site.id, 'label': site.name}
                });
            } else {
                return [];
            }
        } else {
           return null;
        }
    }

    getGroups() {
        if(this.isOperatore()) {
            let _groups = localStorage.getItem('groups');
            if(_groups) {
                let groups: IGroup[] = JSON.parse(_groups);
                return groups?.map(group => {
                    return {'code': group.id, 'label': group.name}
                });
            } else {
                return [];
            }
        } else {
           return null;
        }
    }

    getFilterOfSidebarByName( key ) {
        if( this.getActiveFiltersOfSidebar() ) {
            return this.getActiveFiltersOfSidebar()[key];
        }
    }

    deleteFilterByName( key: string ) : void {
        if( this.getActiveFiltersOfSidebar() ) {
            var result = JSON.parse(localStorage.getItem( 'filterOption' ));
            delete result[key];
            this.filterOption = result;
            localStorage.setItem( 'filterOption' , JSON.stringify(this.filterOption) );
        }
        return;
    }

    onSidebarFilterChange() : void {
        this.sidebarChanged.next(true);
    }

    onSidebarFilterDelete() : void {
        this.sidebarChanged.next(true);
    }

    isSuperAdmin() : boolean {
        var _role = localStorage.getItem('role')
        if( _role && _role == RoleType[RoleType.SUPERADMIN] ) {
            return true;
        } else {
            return false;
        }
    }

    isAdmin() : boolean {
        var _role = localStorage.getItem('role')
        if( _role && _role == RoleType[RoleType.ADMIN] ) {
            return true;
        } else {
            return false;
        }
    }

    isOperatore(): boolean {
        const role = localStorage.getItem('role');
        return role && role === RoleType[RoleType.OPERATOR] ? true : false;
    }

    isOperatoreRO(): boolean {
        const role = localStorage.getItem('role');
        return role && role === RoleType[RoleType.OPERATOR_RO] ? true : false;
    }

    isReseller() : boolean {
        return localStorage.getItem('isReseller') == 'true';
    }
    
    getResellerCompanyId() {
        return localStorage.getItem('isReseller') == 'true' ? localStorage.getItem('idCompany') : null;
    }
    
    isCommandEnabled() : boolean {
        return localStorage.getItem('commandEnabled') == 'true';
    }

    isCobrowseEnabled() : boolean {
        return localStorage.getItem('cobrowseToken') != 'null' && localStorage.getItem('cobrowseToken') != "{\"tokens\":{}}";
    }
    
    getCobrowseToken(idCompany) : string {
        let cobrowseTokens = localStorage.getItem('cobrowseToken');
        return JSON.parse(cobrowseTokens)?.tokens[idCompany?.toString()]
    }

    getFlgSsoCompany(idCompany) : boolean {
        if(idCompany) {
            let flgSsoCompanyList = localStorage.getItem('flgSsoCompany');
            return JSON.parse(flgSsoCompanyList)?.ssoMap[idCompany?.toString()];
        } else return false;
    }

    getCompanyModules(idCompany) : string[] {
        let companyModules = localStorage.getItem('companyModules');
        if(companyModules) {
            return JSON.parse(companyModules)?.modulesMap[idCompany?.toString()];
        } else {
            return [];
        }
    }

    getCompanyUnitCustomFields(idCompany, idField = '') : string[] {
        let companyUnitCustomFields = localStorage.getItem('companyUnitCustomFields');
        if(companyUnitCustomFields) {
            if(idField !== '') return JSON.parse(companyUnitCustomFields)?.customFieldsMap[idCompany?.toString()][idField];
            return JSON.parse(companyUnitCustomFields)?.customFieldsMap[idCompany?.toString()];
        } else {
            return [];
        }
    }

    getUserRole() : void | string {
        var _role = localStorage.getItem('role');
        if( _role ) {
            return _role;
        }
    }


    setPreselectedRegion(formControl: AbstractControl, idCompany?: number, bindAllObject = true, regionList?: IS1InputSelectItem[]){
        const params: IRegionsSearchParams = {
            code: null,
            name: null,
            idCompany: idCompany
        }

        if (regionList) {
            this.setRegionList(regionList, formControl, bindAllObject);
        } else {
            this.regionsService.getRegions(params, false, false).subscribe(regions => {
                this.setRegionList(regions, formControl, bindAllObject);
            });
        }
    }

    // --------- PRIVATE METHODS --------- //

    /** Mehtod that sets a default region from a list in a formControl */
    private setRegionList(regions: IRegion[] | IS1InputSelectItem[], formControl: AbstractControl, bindAllObject: boolean): void {
        let regionsListDef = [];
        let regionsList = regions.map((region: IRegion | IS1InputSelectItem) => {
            return 'id' in region ? { code: region.id, label: region.name } : region
        });

        let userRegionIds = this.getRegions()?.map(region => region.code);
        if(userRegionIds?.length > 0) {
            regionsListDef = regionsList.filter(region => userRegionIds.includes(region.code as number));
        } else {
            regionsListDef = regionsList;
        }

        //autoselection when items == 1
        formControl.enable();
        if(this.isOperatore() || this.isOperatoreRO()){
            if (regionsListDef.length == 1) {
                if(bindAllObject) formControl.patchValue(regionsListDef[0]);
                else formControl.setValue(Number([regionsListDef[0].code]));

                formControl.disable();
            }
        }
    }

}
