import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdm-input-placheholder',
  templateUrl: './input-placheholder.component.html',
  styleUrls: ['./input-placheholder.component.scss']
})
export class InputPlacheholderComponent {

  @Input() label: string;

  constructor() { }

}
