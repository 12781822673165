<s1-card cardStyle="h-100">
  
  <div class="row justify-content-end" *ngIf="hasPages">
    <div class="col-auto d-flex align-items-center text-primary font-weight-bold pointer" (click)="followLink()">
      <span>
        {{ config?.info?.link | translate }}
      </span>
      <em class="ml-2 fa-2x fas fa-angle-right"></em>
    </div>
  </div>
  
  <div class="row mt-2">
    <div class="col-12 text-center">
      <h4 class="text-primary">{{ config?.info?.title | translate }}<small *ngIf="hasModal" class="ml-4 pointer"  (click)="openModal()">{{ 'dashboard.link_all_sites' | translate }}</small></h4>
      <h3 class="mb-1 text-primary" *ngIf="config?.info?.subtitle"><em class="{{ config?.info?.subtitleIcon }}"></em> {{ config?.info?.subtitle | translate }}</h3>
    </div>
  </div>

  <!-- DATE SELECTOR -->
  <div class="row mt-2 mb-4 px-4" *ngIf="hasDateManagement">
    <div class="col px-0">
      <div class="row text-primary">
        <div class="col-12 col-md-12 col-xl-12">
          <button class="btn btn-light" type="button" (click)="prevDay()">
            <i class="fas fa-caret-left"></i>
          </button>
          <button class="btn btn-light" type="button" (click)="prevDay()" disabled>
            <h5>{{ date | date:'dd/MM/yyyy' }}</h5>
          </button>
          <button class="btn btn-light" type="button" (click)="nextDay()">
            <i class="fas fa-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row mt-2">
    <div class="col-12 text-center">
      <div class="pointer" flot [dataset]="config?.data" [options]="plotOptions" [height]="180" (onPlotClick)="goToPage($event)"></div>
    </div>
  </div>

  <div class="row mt-2" [ngClass]="layoutLegend == 'vertical' ? '' : 'd-flex justify-content-center'">
    <ng-container *ngFor="let filter of config?.info?.filters">
      <div class="px-4" [ngClass]="layoutLegend == 'vertical' ? 'col-12' : 'col-auto'">
        <p class="d-flex justify-content-between align-items-center font-weight-bold">
          <span class="pl-4">
            <em class="mr-2 fas fa-square status text-{{ filter.style }}"></em>
            {{ config?.info?.filterLabel | translate}} {{ filter.type | translate}}
          </span>
          <span class="pr-4">
            {{ filter.count }}
          </span>
        </p>
      </div>
    </ng-container>
  </div>
</s1-card>
<dashboard-modal #dashboardModal (onSelected)="goToPageFromModal($event)"></dashboard-modal>
