import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 's1TableGeneralIndex'
})
export class S1TableGeneralIndexPipe implements PipeTransform {

  transform(index: number, ...args: any[]): number {

    if (args.length === 0) {
      return index;
    }

    const table = args[0];

    return table.config.pagination.page.itemsPerPage * (table.config.pagination.page.page - 1) + index + 1;
  }

}