import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
@Component({
  selector: 'mdm-dashboard-summary-card',
  templateUrl: './dashboard-summary-card.component.html',
  styleUrls: ['./dashboard-summary-card.component.scss']
})
export class DashboardSummaryCardComponent {

  layoutLegend: string; // horizontal,vertical
  @Input() hasPages = false;
  @Input() hasModal = false;
  @Input() hasDateManagement = false;
  @Input() config: any;
  @Input() plotOptions: any;
  @Input() set plotType(confPlotType: any) {
    if (confPlotType?.plotType === 'pie') {
      this.plotOptions = this.getPiePlotOptions();
    } else if (confPlotType?.plotType === 'bar') {
      this.plotOptions = this.getBarPlotOptions(confPlotType?.numCategories);
    }
  }

  @Output() onNextPlotLink = new EventEmitter<any>();
  @Output() onDetailPlotLink = new EventEmitter<any>();
  @Output() onModalLink = new EventEmitter<any>();
  @Output() onDateChange = new EventEmitter<number>();

  @ViewChild('dashboardModal') dashboardModal: DashboardModalComponent;

  private msInDay = 1000 * 60 * 60 * 24;

  date: number;
  now: number;

  constructor() {
    this.date = Date.now();
    this.now = Date.now();
  }

  private getPiePlotOptions() {
    this.layoutLegend = 'vertical';
    return {
      series: {
        pie: {
          show: true,
          label: {
            show: false
          },
          stroke: {
            width: 1,
            color: '#ccc'
          }
        }
      },
      legend: {
        show: false
      },
      grid: {
        borderColor: '#eee',
        borderWidth: 1,
        hoverable: false,
        clickable: true,
        backgroundColor: '#fff'
      },
    };
  }

  private getBarPlotOptions(numCategories: number) {
    this.layoutLegend = 'horizontal';
    return {
      series: {
          stack: true,
          lines: {show: false, steps: false },
          bars: {
              align: 'center',
              lineWidth: 1,
              show: true,
              barWidth: 0.3,
              fill: 0.9
          }
      },
      grid: {
          margin: {
            left: 25
          },
          borderColor: '#eee',
          borderWidth: 1,
          hoverable: true,
          clickable: true,
          backgroundColor: '#fff'
      },
      tooltip: true,
      tooltipOpts: {
        content: (label, x, y) => x + ' : ' + y
      },
      xaxis: {
          tickColor: '#fff',
          mode: 'categories',
          min: -0.5,
          max: numCategories - 0.5
      },
      yaxis: {
          min: 0,
          // max: 150, // optional: use it for a clear represetation
          // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
          tickColor: '#eee',
          //tickSize: 1, // Ensures that the ticks are integers
          tickFormatter: (val, axis) => {
            return val % 1 === 0 ? val.toString() : '';
          }
      },
      shadowSize: 0,
      legend: {
        show: false
      },
    };
  }

  followLink() {
    this.onNextPlotLink.emit();
  }

  openModal() {
    this.dashboardModal.open(this.config);
  }

  goToPage(event) {
    this.onDetailPlotLink.emit(event);
  }

  goToPageFromModal(event) {
    this.onModalLink.emit(event);
  }

  prevDay() {
    this.date -= this.msInDay;
    this.onDateChange.emit(this.date);
  }

  nextDay() {
    const tomorrow = this.now + this.msInDay;
    if ((this.date + this.msInDay) < tomorrow) {
      this.date += this.msInDay;
      this.onDateChange.emit(this.date);
    }
  }

}
