<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">SIGNUP TO GET INSTANT ACCESS.</p>
                <form [formGroup]="valForm" class="form-validate mb-3" role="form" name="registerForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                    <div class="form-group">
                        <label class="text-muted mb-1">Email address</label>
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" type="email" name="account_email" placeholder="Enter email" autocomplete="off" formControlName="email" required="" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field is required</div>
                        <div class="text-danger" *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field must be a valid email address</div>
                    </div>
                    <div formGroupName="passwordGroup">
                        <mdm-input-password [control]="valForm.get('passwordGroup.password')"></mdm-input-password>
                        <mdm-input-password [text]="'base.retype_pwd'" [control]="valForm.get('passwordGroup.confirmPassword')"></mdm-input-password>
                    </div>
                    <div class="clearfix">
                        <div class="checkbox c-checkbox float-left mt0">
                            <label>
                                <input type="checkbox" required="" name="account_agreed" formControlName="accountagreed" />
                                <span class="fa fa-check"></span>I agree with the
                                <a href="#">terms</a>
                            </label>
                        </div>
                    </div>
                    <span class="text-danger" *ngIf="valForm.controls['accountagreed'].hasError('required') && (valForm.controls['accountagreed'].dirty || valForm.controls['accountagreed'].touched)">You must agree the terms</span>
                    <button class="btn btn-block btn-primary mt-3" type="submit">Create account</button>
                </form>
                <!-- <div class="alert alert-danger text-center"></div> -->
                <p class="pt-3 text-center">Have an account?</p>
                <a class="btn btn-block btn-secondary" [routerLink]="'/login'">Signup</a>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>
