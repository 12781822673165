import { Component, OnInit, Input } from '@angular/core';
import { SummaryBadgeType, SummaryBadgeConfig } from '../summary-badge/summary-badge.component';
import { StatisticCardConfig } from '../statistic-card/statistic-card.component';
import { StatusLevel } from '@app/core/classes/status';
import { ChartColors, ChartPieConfig } from '../chart-pie/chart-pie.component';
import { IWall } from '@app/shared/models/models';

@Component({
    selector: 'wall-summary-card',
    templateUrl: './wall-summary-card.component.html',
    styleUrls: ['./wall-summary-card.component.scss']
})

export class WallSummaryCardComponent implements OnInit {

  @Input() wall: IWall;
  @Input() isCollapsed = false;

  statistics: any;
  cardsConfig: StatisticCardConfig[];

  constructor() {

    this.cardsConfig = [];

    this.statistics = {
      total: 0,
      status: {
        info: 0,
        warning: 0,
        error: 0
      },
      battery: {
        p60: 0,
        p20: 0,
        p0: 0
      },
      time: {
        s0: 0,
        s60: 0,
        s120: 0
      }
    };
  }

  ngOnInit(): void {
    this.updateStatistics();
  }

  updateStatistics() {
    const units = this.wall.units.reduce((flat, val) => flat.concat(val), []); // reduce list of units list in a flat list
    this.statistics.total = units.length;
    // Status
    this.statistics.status.info = units.filter( unit => unit.statusLevel === StatusLevel.OK).length;
    this.statistics.status.warning = units.filter( unit => unit.statusLevel === StatusLevel.WARNING).length;
    this.statistics.status.error = units.filter( unit => unit.statusLevel === StatusLevel.ERROR).length;
    // Battery
    this.statistics.battery.p60 = units.filter( unit => unit.batteryPerc > 60).length;
    this.statistics.battery.p20 = units.filter( unit => unit.batteryPerc > 20 && unit.batteryPerc <= 60).length;
    this.statistics.battery.p0 = units.filter( unit => unit.batteryPerc < 20).length;
    // Time
    this.statistics.time.s0 = units.filter(unit =>
      this.getSecondsFromTimestamp(unit.tsLastStatus) >= 0 && this.getSecondsFromTimestamp(unit.tsLastStatus) < 480).length;
    this.statistics.time.s60 = units.filter(unit =>
      this.getSecondsFromTimestamp(unit.tsLastStatus) >= 480 && this.getSecondsFromTimestamp(unit.tsLastStatus) < 720).length;
    this.statistics.time.s120 = units.filter(unit =>
      this.getSecondsFromTimestamp(unit.tsLastStatus) >= 720).length;
    this.updateCardsConfig();
  }

  private getSecondsFromTimestamp(timestamp: number){
    const now = new Date().getTime();
    let seconds = Math.floor(now - timestamp);
    return seconds /= 1000;
  }

  updateCardsConfig() {
    // Status
    const statusBadges: SummaryBadgeConfig[] = [
      { type: SummaryBadgeType.STATUS_OK, value: this.statistics.status.info, label: StatusLevel.OK},
      { type: SummaryBadgeType.STATUS_WARNING, value: this.statistics.status.warning, label: StatusLevel.WARNING},
      { type: SummaryBadgeType.STATUS_ERROR, value: this.statistics.status.error, label: StatusLevel.ERROR}
    ];
    const statusChart: ChartPieConfig = {
      datasets: [{
        data: [this.statistics.status.info, this.statistics.status.warning, this.statistics.status.error],
        backgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
        hoverBackgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
        hoverBorderColor: [ChartColors.success, ChartColors.warning, ChartColors.danger]
      }],
      labels: [StatusLevel.OK, StatusLevel.WARNING, StatusLevel.ERROR]
    };
    // Battery
    const batteryBadges: SummaryBadgeConfig[] = [
      { type: SummaryBadgeType.BATTERY_OK, value: this.statistics.battery.p60, label: '> 60%'},
      { type: SummaryBadgeType.BATTERY_WARNING, value: this.statistics.battery.p20, label: '20 - 60%'},
      { type: SummaryBadgeType.BATTERY_DANGER, value: this.statistics.battery.p0, label: '< 20%'}
    ];
    const batteryChart: ChartPieConfig = {
      datasets: [{
        data: [this.statistics.battery.p60, this.statistics.battery.p20, this.statistics.battery.p0],
        backgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
        hoverBackgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
        hoverBorderColor: [ChartColors.success, ChartColors.warning, ChartColors.danger]
      }],
      labels: ['> 60%', '20 - 60%', '< 20%']
    };
    // Battery
    const timeBadges: SummaryBadgeConfig[] = [
      { type: SummaryBadgeType.TIME_OK, value: this.statistics.time.s0, label: '< 8m'},
      { type: SummaryBadgeType.TIME_WARNING, value: this.statistics.time.s60, label: '8 - 12m'},
      { type: SummaryBadgeType.TIME_DANGER, value: this.statistics.time.s120, label: '> 12m'}
    ];
    const timeChart: ChartPieConfig = {
      datasets: [{
        data: [this.statistics.time.s0, this.statistics.time.s60, this.statistics.time.s120],
        backgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
        hoverBackgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
        hoverBorderColor: [ChartColors.success, ChartColors.warning, ChartColors.danger]
      }],
      labels: ['< 8m', '8 - 12m', '> 12m']
    };
    this.cardsConfig = [
      { title: 'dispensing.wall_summary.statistics.status', badges: statusBadges, chart: statusChart},
      { title: 'dispensing.wall_summary.statistics.battery', badges: batteryBadges, chart: batteryChart},
      { title: 'dispensing.wall_summary.statistics.time', badges: timeBadges, chart: timeChart}
    ];
  }
}
