import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1UIService } from '@app/s1';
import { IDashboardInfo } from '@app/shared/models/dashboard';
import { CompaniesService } from '@app/shared/services/companies.service';
import { DashboardService, IDashboardSearchParams } from '@app/shared/services/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dashboard-mdm',
  templateUrl: './dashboard-mdm.component.html',
  styleUrls: ['./dashboard-mdm.component.scss']
})
export class DashboardMdmComponent implements OnDestroy {
  
  sidebarEventSubscription: Subscription;
  s1ButtonType = S1ButtonType;
  refreshTimerStore = [];
  dashboardInfo: IDashboardInfo;

  idCompany: number;
  idRegion: any;
  dashboardSearchParams: IDashboardSearchParams;

  totalDevices: number;
  zeroAppliedProfiles: boolean;
  zeroOs: boolean;
  zeroLevelBattery: boolean;
  zeroHealthBattery: boolean;

  unitsConnectedConfig: any;
  unitsConnectedPlotType: any;

  profilesAppliedConfig: any;
  profilesAppliedPlotType: any;

  osAppliedConfig: any;
  osAppliedPlotType: any;

  batteryHealthConfig: any;
  batteryHealthPlotType: any;

  sitesUnitsConnectedConfig: any;
  sitesUnitsConnectedPlotType: any;
  sitesUnitsConnectedHasModal: any;

  sitesProfilesAppliedConfig: any;
  sitesProfilesAppliedPlotType: any;
  sitesProfilesAppliedHasModal: any;

  sitesOsAppliedConfig: any;
  sitesOsAppliedPlotType: any;
  sitesOsAppliedHasModal: any;

  sitesbatteryLevelConfig: any;
  sitesbatteryLevelPlotType: any;
  sitesbatteryLevelHasModal: any;

  sitesbatteryHealthConfig: any;
  sitesbatteryHealthPlotType: any;
  sitesbatteryHealthHasModal: any;

  profilesSummary: any;
  showBatteryHealthPlot: boolean = false;

  loading = false;
  maxSites = 10;
  showMore = false;

  constructor(
    private settings: SettingsService,
    private dashboardService: DashboardService,
    public companiesService: CompaniesService,
    public http: HttpClient,
    private router: Router,
    private ui: S1UIService
  ) {
    this.sidebarEventSubscription = this.settings.sidebarChanged.subscribe(() => {
      // Dashboard must be reloaded only when page is blank (undefined) or when the page is showing the same amount of tabs (rerender not required)
      if (this.sidebarEventSubscription === undefined || (this.dashboardService.currentModules === this.settings.getCompanyModules(this.settings.getCompany().code).length)) {
        this.getDashboardInfo();
      }
    });
  }

  /*setRefresh(milliseconds = 0) {
    this.getDashboardInfo();
    this.clearRefreshInterval();
    if(milliseconds > 0) {
      let refreshTimerId = setInterval(() => this.getDashboardInfo(), milliseconds);
      this.refreshTimerStore.push(refreshTimerId);
    }
  }*/

  refresh() {
    this.getDashboardInfo();
  }

  clearRefreshInterval() {
    this.refreshTimerStore.forEach(intervalId => {
      clearInterval(intervalId);
    });
    this.refreshTimerStore = [];
  }

  getDashboardInfo() {
    this.ui.showSpinner();
    this.loading = true;

    console.log('refreshTimerStore - dashboardInfo', this.refreshTimerStore)
    this.idCompany = this.settings.getCompany()?.code;
    this.idRegion = null;
    this.dashboardSearchParams = {
      idCompany: this.idCompany?.toString(),
      idRegion: this.idRegion
    }

    if(this.idCompany) {
      this.companiesService.getCompany(this.idCompany, false).subscribe(async _ => {
        /** To reduce loading times some basic calls are fired in parallel in two blocks, in order to avoid useless waits and load the page faster */
        await Promise.all([this.getUnitsConnected(), this.getProfilesApplied(), this.getOsApplied(), this.getBatteryHealth(), this.getProfilesSummary()]);
        
        this.dashboardService.currentModules = this.settings.getCompanyModules(this.settings.getCompany().code).length;
        this.loading = false;
        this.ui.closeSpinner();
      });
    }
  }

  async getSitesUnitsInfo() {
    this.showMore = !this.showMore;
    /** APIs are called only when not sitesunits are available, meaning the "show more" action was never fired and data has to be loaded */
    if (!this.sitesUnitsConnectedConfig) {
      this.ui.showSpinner();
      await Promise.all([this.getSitesUnitsConnected(), this.getSitesProfilesApplied(), this.getSitesOsApplied(), this.getSitesBatteryLevel(), this.getSitesBatteryHealth()]);
      this.ui.closeSpinner();
    }
  }

  getUnitsConnected(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getUnitsConnected(this.dashboardSearchParams).subscribe(unitsConnected => {
        const total = unitsConnected.ok + unitsConnected.ko;
        this.totalDevices = total;
        const percOk = this.percentage(unitsConnected.ok, total);
        const percKo = this.percentage(unitsConnected.ko, total);
        this.unitsConnectedPlotType = {
          plotType: 'pie'
        };
        this.unitsConnectedConfig = {
          data: [
            { color: '#39C558', filters: {unitStatus: 'success'}, data: percOk },
            { color: '#ff3e43', filters: {unitStatus: 'danger'}, data: percKo },
          ],
          info: {
            title: "dashboard.summary.units_connected",
            subtitle: percOk ? percOk +'%' : 'base.na',
            subtitleIcon: "fa fa-rss text-success",
            filterLabel: "units.status.shortName",
            filters: [
              { type: "units.status.ok", count: unitsConnected.ok, style: "success" },
              { type: "units.status.error", count: unitsConnected.ko, style: "danger" }
            ]
          }
        }
        resolve();
      });
    });
  }

  getProfilesApplied(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getProfilesApplied(this.dashboardSearchParams).subscribe(profilesApplied => {
        const total = profilesApplied.na + profilesApplied.success + profilesApplied.warning + profilesApplied.danger;
        const totalNA = total - profilesApplied.na == 0;
        const percNa = this.percentage(profilesApplied.na, total);
        const percSuccess = this.percentage(profilesApplied.success, total);
        const percWarning = this.percentage(profilesApplied.warning, total);
        const percDanger = this.percentage(profilesApplied.danger, total);

        this.profilesAppliedPlotType = {
          plotType: 'pie'
        };

        this.profilesAppliedConfig = {
          data: [
            { color: '#27c24c', filters: {profileStatus: 'success'}, data: percSuccess },
            { color: '#ff902b', filters: {profileStatus: 'warning'}, data: percWarning },
            { color: '#f05050', filters: {profileStatus: 'danger'}, data: percDanger },
            { color: '#e6e6e6', filters: {profileStatus: 'na'}, data: percNa }
          ],
          info: {
            title: "dashboard.summary.profiles_ok",
            subtitle: percSuccess && !totalNA ? percSuccess +'%' : 'base.na',
            subtitleIcon: "fa fa-laptop-code text-success",
            filterLabel: "profiles.status.shortName",
            filters: [
              { type: "profiles.status.success", count: profilesApplied.success, style: "success" },
              { type: "profiles.status.warning", count: profilesApplied.warning, style: "warning" },
              { type: "profiles.status.error", count: profilesApplied.danger, style: "danger" },
              { type: "profiles.status.na", count: profilesApplied.na, style: "light" }
            ]
          }
        }
        resolve();
      });
    });
  }

  getOsApplied(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getOsApplied(this.dashboardSearchParams).subscribe(osApplied => {
        const total = osApplied.na + osApplied.success + osApplied.danger;
        const totalNA = total - osApplied.na === 0;
        const percNa = this.percentage(osApplied.na, total);
        const percSuccess = this.percentage(osApplied.success, total);
        const percWarning = this.percentage(osApplied.warning, total);
        const percDanger = this.percentage(osApplied.danger, total);

        this.osAppliedPlotType = {
          plotType: 'pie'
        };

        this.osAppliedConfig = {
          data: [
            { 'color': '#27c24c', 'filters': {'osStatus': 'success'}, 'data': percSuccess },
            { 'color': '#ff902b', 'filters': {'osStatus': 'warning'}, 'data': percWarning },
            { 'color': '#f05050', 'filters': {'osStatus': 'danger'}, 'data': percDanger },
            { 'color': '#e6e6e6', 'filters': {'osStatus': 'na'}, 'data': percNa }
          ],
          info: {
            "title": "dashboard.summary.os_ok",
            "subtitle": percSuccess && !totalNA ? percSuccess +'%' : 'base.na',
            "subtitleIcon": "fa-android fab text-success",
            "filterLabel": "profiles.status.shortName",
            "filters": [
              { "type": "profiles.status.success", "count": osApplied.success, "style": "success" },
              { "type": "profiles.status.warning", "count": osApplied.warning, "style": "warning" },
              { "type": "profiles.status.ko", "count": osApplied.danger, "style": "danger" },
              { "type": "profiles.status.na", "count": osApplied.na, "style": "light" }
            ]
          }
        }
        resolve();
      });
    });
  }

  getBatteryHealth(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getBatteryHealth(this.dashboardSearchParams).subscribe(response => {
        let batteryHealth =
            {
              na: response.levels[0].value,
              low: response.levels[1].value,
              normal: response.levels[2].value,
              high: response.levels[3].value
            };
  
        let total = batteryHealth.na + batteryHealth.low + batteryHealth.normal + batteryHealth.high;
        let totalNA = total - batteryHealth.na == 0;
  
        let percNa = this.percentage(batteryHealth.na, total);
        let percLow = this.percentage(batteryHealth.low, total);
        let percNormal = this.percentage(batteryHealth.normal, total);
        let percHigh = this.percentage(batteryHealth.high, total);
        this.batteryHealthPlotType = {
          plotType: 'pie'
        };
        this.batteryHealthConfig = {
          "data": [
            { 'color': '#27c24c', 'filters': {'batteryHealthStatus': 'LEVEL_3'}, 'data': percHigh },
            { 'color': '#0383ba', 'filters': {'batteryHealthStatus': 'LEVEL_2'}, 'data': percNormal },
            { 'color': '#f05050', 'filters': {'batteryHealthStatus': 'LEVEL_1'}, 'data': percLow },
            { 'color': '#e6e6e6', 'filters': {'batteryHealthStatus': 'LEVEL_0'}, 'data': percNa }
          ],
          "info": {
            "title": "dashboard.summary.battery_health_ok",
            "subtitle": percHigh && !totalNA ? percHigh +'%' : 'base.na',
            "subtitleIcon": "fas fa-battery-full text-success",
            "filterLabel": "units.status.shortName",
            "filters": [
              { "type": "units.status.high", "count": batteryHealth.high, "style": "success" },
              { "type": "units.status.normal", "count": batteryHealth.normal, "style": "info" },
              { "type": "units.status.low", "count": batteryHealth.low, "style": "danger" },
              { "type": "units.status.na", "count": batteryHealth.na, "style": "light" }
            ]
          }
        };
        resolve();
      });
    });
  }

  getProfilesSummary(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getProfilesSummary(this.dashboardSearchParams).subscribe(response => {
        this.profilesSummary = response.results;
        resolve();
      });
    });
  }

  getSitesUnitsConnected(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getSitesUnitsConnected(this.dashboardSearchParams).subscribe(unitsConnected => {
        let dataOk = [];
        let dataKo = [];
        let sites = [];
        let modalDataOk = [];
        let modalDataKo = [];
        let modalSites = [];
  
        unitsConnected?.results.forEach(data => {
          //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
          let ok = [data.nameSite, data.ok];
          let ko = [data.nameSite, data.ko];
          let site = { 'idSite': data.idSite, 'nameSite': data.nameSite, 'idRegion': data.idRegion};
          if(sites.length<this.maxSites) {
            dataOk.push(ok);
            dataKo.push(ko);
            sites.push(site);
          } else {
            modalDataOk.push(ok);
            modalDataKo.push(ko);
            modalSites.push(site);
          }
        });
        modalSites = sites.concat(modalSites);
        modalDataOk = dataOk.concat(modalDataOk);
        modalDataKo = dataKo.concat(modalDataKo);
  
        //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
        for (let i = sites.length+1 ; i<=this.maxSites; i++) {
          dataKo.push(['#'+i, 0]);
          dataOk.push(['#'+i, -1]);
          sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
        }
  
        let barStackeData = [
          { "color": "#f05050", 'filters': {'unitStatus': 'danger'}, "sites": sites, "data": dataKo },
          { "color": "#39C558", 'filters': {'unitStatus': 'success'}, "sites": sites, "data": dataOk }
        ];
        this.sitesUnitsConnectedPlotType = {
          plotType: 'bar',
          numCategories: sites.length
        };
        this.sitesUnitsConnectedHasModal = modalSites.length > this.maxSites;
        this.sitesUnitsConnectedConfig = {
          "data": barStackeData,
          "info": {
            "title": "dashboard.summary.units_connected",
            "filterLabel": "",
            "filters": [
              { "type": "units.status.ok", "count": "", "style": "success" },
              { "type": "units.status.error", "count": "", "style": "danger" }
            ]
          },
          "modal":{
            "title": "dashboard.summary.units_connected",
            "sites": modalSites,
            "columns": [
              { "type": "units.status.ok", "style": "bg-success", "filter": {'unitStatus': 'success'}, "data": modalDataOk },
              { "type": "units.status.error", "style": "bg-danger", "filter": {'unitStatus': 'danger'}, "data": modalDataKo },
            ]
          }
        }
        resolve();
      });
    });
  }

  getSitesProfilesApplied(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getSitesProfilesApplied(this.dashboardSearchParams).subscribe(profilesApplied => {
        let dataSuccess = [];
        let dataWarning = [];
        let dataDanger = [];
        let dataNa = [];
        let sites = [];
        let total = 0;
        let modalDataSuccess = [];
        let modalDataWarning = [];
        let modalDataDanger = [];
        let modalDataNa = [];
        let modalSites = [];
  
        profilesApplied?.results.forEach(profilesApplied => {
          //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
          let success = [profilesApplied.nameSite, profilesApplied.success];
          let warning = [profilesApplied.nameSite, profilesApplied.warning];
          let danger = [profilesApplied.nameSite, profilesApplied.danger];
          let na = [profilesApplied.nameSite, profilesApplied.na];
          let site = { 'idSite': profilesApplied.idSite, 'nameSite': profilesApplied.nameSite, 'idRegion': profilesApplied.idRegion};
          if(sites.length<this.maxSites) {
            dataSuccess.push(success);
            dataWarning.push(warning);
            dataDanger.push(danger);
            dataNa.push(na);
            sites.push(site);
          } else {
            modalDataSuccess.push(success);
            modalDataWarning.push(warning);
            modalDataDanger.push(danger);
            modalDataNa.push(na);
            modalSites.push(site);
          }
          total =+ profilesApplied.na + profilesApplied.success + profilesApplied.warning + profilesApplied.danger;
        });
        this.zeroAppliedProfiles = total == 0;
        modalSites = sites.concat(modalSites);
        modalDataSuccess = dataSuccess.concat(modalDataSuccess);
        modalDataWarning = dataWarning.concat(modalDataWarning);
        modalDataDanger = dataDanger.concat(modalDataDanger);
        modalDataNa = dataNa.concat(modalDataNa);
  
        //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
        for (let i = sites.length+1 ; i<=this.maxSites; i++) {
          dataSuccess.push(['#'+i, -1]);
          dataWarning.push(['#'+i, -1]);
          dataDanger.push(['#'+i, 0]);
          dataNa.push(['#'+i, -1]);
          sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
        }
  
        let barStackeData = [
          { "color": "#f05050", 'filters': {'profileStatus': 'danger'}, "sites": sites, "data": dataDanger },
          { "color": "#ff902b", 'filters': {'profileStatus': 'warning'}, "sites": sites, "data": dataWarning },
          { "color": "#27c24c", 'filters': {'profileStatus': 'success'}, "sites": sites, "data": dataSuccess },
          { "color": "#e6e6e6", 'filters': {'profileStatus': 'na'}, "sites": sites, "data": dataNa },
        ];
        this.sitesProfilesAppliedPlotType = {
          plotType: 'bar',
          numCategories: sites.length
        };
        this.sitesProfilesAppliedHasModal = modalSites.length > this.maxSites;
        this.sitesProfilesAppliedConfig = {
          "data": barStackeData,
          "info": {
            "title": "dashboard.summary.profiles_ok",
            "filterLabel": "",
            "filters": [
              { "type": "profiles.status.success", "count": "", "style": "success" },
              { "type": "profiles.status.warning", "count": "", "style": "warning" },
              { "type": "profiles.status.error", "count": "", "style": "danger" },
              { "type": "profiles.status.na", "count": "", "style": "light" }
            ]
          },
          "modal":{
            "title": "dashboard.summary.profiles_ok",
            "sites": modalSites,
            "columns": [
              { "type": "profiles.status.success", "style": "bg-success", "filter": {'profileStatus': 'success'}, "data": modalDataSuccess },
              { "type": "profiles.status.warning", "style": "bg-warning", "filter": {'profileStatus': 'warning'}, "data": modalDataWarning },
              { "type": "profiles.status.error", "style": "bg-danger", "filter": {'profileStatus': 'danger'}, "data": modalDataDanger },
              { "type": "profiles.status.na", "style": "bg-light", "filter": {'profileStatus': 'na'}, "data": modalDataNa }
            ]
          }
        }
        resolve();
      });
    });
  }

  getSitesOsApplied(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getSitesOsApplied(this.dashboardSearchParams).subscribe(osApplied => {
        let dataSuccess = [];
        let dataWarning = [];
        let dataDanger = [];
        let dataNa = [];
        let sites = [];
        let total = 0;
        let modalDataSuccess = [];
        let modalDataWarning = [];
        let modalDataDanger = [];
        let modalDataNa = [];
        let modalSites = [];
  
        osApplied?.results.forEach(profilesApplied => {
          //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
          let success = [profilesApplied.nameSite, profilesApplied.success];
          let warning = [profilesApplied.nameSite, profilesApplied.warning];
          let danger = [profilesApplied.nameSite, profilesApplied.danger];
          let na = [profilesApplied.nameSite, profilesApplied.na];
          let site = { 'idSite': profilesApplied.idSite, 'nameSite': profilesApplied.nameSite, 'idRegion': profilesApplied.idRegion};
          if(sites.length<this.maxSites) {
            dataSuccess.push(success);
            dataWarning.push(warning);
            dataDanger.push(danger);
            dataNa.push(na);
            sites.push(site);
          } else {
            modalDataSuccess.push(success);
            modalDataWarning.push(warning);
            modalDataDanger.push(danger);
            modalDataNa.push(na);
            modalSites.push(site);
          }
          total =+ profilesApplied.na + profilesApplied.success + profilesApplied.warning + profilesApplied.danger;
        });
        this.zeroOs = total == 0;
        modalSites = sites.concat(modalSites);
        modalDataSuccess = dataSuccess.concat(modalDataSuccess);
        modalDataWarning = dataWarning.concat(modalDataWarning);
        modalDataDanger = dataDanger.concat(modalDataDanger);
        modalDataNa = dataNa.concat(modalDataNa);
  
        //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
        for (let i = sites.length+1 ; i<=this.maxSites; i++) {
          dataSuccess.push(['#'+i, -1]);
          dataWarning.push(['#'+i, -1]);
          dataDanger.push(['#'+i, 0]);
          dataNa.push(['#'+i, -1]);
          sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
        }
  
        let barStackeData = [
          { "color": "#f05050", 'filters': {'osStatus': 'danger'}, "sites": sites, "data": dataDanger },
          { "color": "#ff902b", 'filters': {'osStatus': 'warning'}, "sites": sites, "data": dataWarning },
          { "color": "#27c24c", 'filters': {'osStatus': 'success'}, "sites": sites, "data": dataSuccess },
          { "color": "#e6e6e6", 'filters': {'osStatus': 'na'}, "sites": sites, "data": dataNa },
        ];
        this.sitesOsAppliedPlotType = {
          plotType: 'bar',
          numCategories: sites.length
        };
        this.sitesOsAppliedHasModal = modalSites.length > this.maxSites;
        this.sitesOsAppliedConfig = {
          "data": barStackeData,
          "info": {
            "title": "dashboard.summary.os_ok",
            "filterLabel": "",
            "filters": [
              { "type": "profiles.status.success", "count": "", "style": "success" },
              { "type": "profiles.status.warning", "count": "", "style": "warning" },
              { "type": "profiles.status.error", "count": "", "style": "danger" },
              { "type": "profiles.status.na", "count": "", "style": "light" }
            ]
          },
          "modal":{
            "title": "dashboard.summary.os_ok",
            "sites": modalSites,
            "columns": [
              { "type": "profiles.status.success", "style": "bg-success", "filter": {'osStatus': 'success'}, "data": modalDataSuccess },
              { "type": "profiles.status.warning", "style": "bg-warning", "filter": {'osStatus': 'warning'}, "data": modalDataWarning },
              { "type": "profiles.status.error", "style": "bg-danger", "filter": {'osStatus': 'danger'}, "data": modalDataDanger },
              { "type": "profiles.status.na", "style": "bg-light", "filter": {'osStatus': 'na'}, "data": modalDataNa }
            ]
          }
        }
        resolve();
      });
    });
  }

  getSitesBatteryLevel(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getSitesBatteryLevel(this.dashboardSearchParams).subscribe(response => {
        let dataHigh = [];
        let dataNormal = [];
        let dataLow = [];
        let dataNa = [];
        let sites = [];
        let total = 0;
        let modalDataHigh = [];
        let modalDataNormal = [];
        let modalDataLow = [];
        let modalDataNa = [];
        let modalSites = [];
  
        response?.results.forEach(site => {
          //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
          let na = [site.nameSite, site.levels[0].value];
          let low = [site.nameSite, site.levels[1].value];
          let normal = [site.nameSite, site.levels[2].value];
          let high = [site.nameSite, site.levels[3].value];
          let asite = { 'idSite': site.idSite, 'nameSite': site.nameSite, 'idRegion': site.idRegion};
  
          if(sites.length<this.maxSites) {
            dataNa.push(na);
            dataLow.push(low);
            dataNormal.push(normal);
            dataHigh.push(high);
            sites.push(asite);
          } else {
            modalDataNa.push(na);
            modalDataLow.push(low);
            modalDataNormal.push(normal);
            modalDataHigh.push(high);
            modalSites.push(asite);
          }
          total += site.levels[0].value + site.levels[1].value + site.levels[2].value + site.levels[3].value;
        });
        this.zeroLevelBattery = total == 0;
        this.showBatteryHealthPlot = this.zeroLevelBattery; //se zero -> visualizza health
        modalSites = sites.concat(modalSites);
        modalDataNa = dataNa.concat(modalDataNa);
        modalDataLow = dataLow.concat(modalDataLow);
        modalDataNormal = dataNormal.concat(modalDataNormal);
        modalDataHigh = dataHigh.concat(modalDataHigh);
  
        //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
        for (let i = sites.length+1 ; i<=this.maxSites; i++) {
          dataNa.push(['#'+i, -1]);
          dataLow.push(['#'+i, 0]);
          dataNormal.push(['#'+i, -1]);
          dataHigh.push(['#'+i, -1]);
          sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
        }
  
        let barStackeData = [
          { "color": "#f05050", 'filters': {'batteryLevelStatus': 'LEVEL_1'}, "sites": sites, "data": dataLow },
          { "color": '#0383ba', 'filters': {'batteryLevelStatus': 'LEVEL_2'}, "sites": sites, "data": dataNormal },
          { "color": "#27c24c", 'filters': {'batteryLevelStatus': 'LEVEL_3'}, "sites": sites, "data": dataHigh },
          { "color": "#e6e6e6", 'filters': {'batteryLevelStatus': 'LEVEL_0'}, "sites": sites, "data": dataNa },
        ];

        this.sitesbatteryLevelPlotType = {
          plotType: 'bar',
          numCategories: sites.length
        };
        this.sitesbatteryLevelHasModal = modalSites.length > this.maxSites;
        this.sitesbatteryLevelConfig = {
          "data": barStackeData,
          "info": {
            "title": "dashboard.summary.battery_level_ok",
            "link": !this.zeroHealthBattery ? "dashboard.summary.battery_health_ok" : null,
            "filterLabel": "",
            "filters": [
              { "type": "units.status.highPerc", "count": "", "style": "success" },
              { "type": "units.status.normalPerc", "count": "", "style": "info" },
              { "type": "units.status.lowPerc", "count": "", "style": "danger" },
              { "type": "units.status.na", "count": "", "style": "light" },
            ]
          },
          "modal":{
            "title": "dashboard.summary.battery_level_ok",
            "sites": modalSites,
            "columns": [
              { "type": "units.status.highPerc", "style": "bg-success", "filter": {'batteryLevelStatus': 'LEVEL_3'}, "data": modalDataHigh },
              { "type": "units.status.normalPerc", "style": "bg-info", "filter": {'batteryLevelStatus': 'LEVEL_2'}, "data": modalDataNormal },
              { "type": "units.status.lowPerc", "style": "bg-danger", "filter": {'batteryLevelStatus': 'LEVEL_1'}, "data": modalDataLow },
              { "type": "units.status.na", "style": "bg-light", "filter": {'batteryLevelStatus': 'LEVEL_0'}, "data": modalDataNa },
            ]
          }
        }
        resolve();
      });
    });
  }

  getSitesBatteryHealth(): Promise<void> {
    return new Promise((resolve) => {
      this.dashboardService.getSitesBatteryHealth(this.dashboardSearchParams).subscribe(response => {
        let dataHigh = [];
        let dataNormal = [];
        let dataLow = [];
        let dataNa = [];
        let sites = [];
        let total = 0;
        let modalDataHigh = [];
        let modalDataNormal = [];
        let modalDataLow = [];
        let modalDataNa = [];
        let modalSites = [];
  
        response?.results.forEach(site => {
          //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
          let na = [site.nameSite, site.levels[0].value];
          let low = [site.nameSite, site.levels[1].value];
          let normal = [site.nameSite, site.levels[2].value];
          let high = [site.nameSite, site.levels[3].value];
          let asite = { 'idSite': site.idSite, 'nameSite': site.nameSite, 'idRegion': site.idRegion};
  
          if(sites.length<this.maxSites) {
            dataNa.push(na);
            dataLow.push(low);
            dataNormal.push(normal);
            dataHigh.push(high);
            sites.push(asite);
          } else {
            modalDataNa.push(na);
            modalDataLow.push(low);
            modalDataNormal.push(normal);
            modalDataHigh.push(high);
            modalSites.push(asite);
          }
          total += site.levels[0].value + site.levels[1].value + site.levels[2].value + site.levels[3].value;
        });
        this.zeroHealthBattery = total == 0;
        modalSites = sites.concat(modalSites);
        modalDataNa = dataNa.concat(modalDataNa);
        modalDataLow = dataLow.concat(modalDataLow);
        modalDataNormal = dataNormal.concat(modalDataNormal);
        modalDataHigh = dataHigh.concat(modalDataHigh);
  
        //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
        for (let i = sites.length+1 ; i<=this.maxSites; i++) {
          dataNa.push(['#'+i, -1]);
          dataLow.push(['#'+i, 0]);
          dataNormal.push(['#'+i, -1]);
          dataHigh.push(['#'+i, 0]);
          sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
        }
  
        let barStackeData = [
          { "color": "#f05050", 'filters': {'batteryHealthStatus': 'LEVEL_1'}, "sites": sites, "data": dataLow },
          { "color": '#0383ba', 'filters': {'batteryHealthStatus': 'LEVEL_2'}, "sites": sites, "data": dataNormal },
          { "color": "#27c24c", 'filters': {'batteryHealthStatus': 'LEVEL_3'}, "sites": sites, "data": dataHigh },
          { "color": "#e6e6e6", 'filters': {'batteryHealthStatus': 'LEVEL_0'}, "sites": sites, "data": dataNa }
        ];
        this.sitesbatteryHealthPlotType = {
          plotType: 'bar',
          numCategories: sites.length
        };
        this.sitesbatteryHealthHasModal = modalSites.length > this.maxSites;
        this.sitesbatteryHealthConfig = {
          "data": barStackeData,
          "info": {
            "title": "dashboard.summary.battery_health_ok",
            "link": !this.zeroLevelBattery ? "dashboard.summary.battery_level_ok" : null,
            "filterLabel": "",
            "filters": [
              { "type": "units.status.batteryHealth.highPerc", "count": "", "style": "success" },
              { "type": "units.status.batteryHealth.normalPerc", "count": "", "style": "info" },
              { "type": "units.status.batteryHealth.lowPerc", "count": "", "style": "danger" },
              { "type": "units.status.na", "count": "", "style": "light" },
            ]
          },
          "modal":{
            "title": "dashboard.summary.battery_health_ok",
            "sites": modalSites,
            "columns": [
              { "type": "units.status.batteryHealth.highPerc", "style": "bg-success", "filter": {'batteryHealthStatus': 'LEVEL_3'}, "data": modalDataHigh },
              { "type": "units.status.batteryHealth.normalPerc", "style": "bg-info", "filter": {'batteryHealthStatus': 'LEVEL_2'}, "data": modalDataNormal },
              { "type": "units.status.batteryHealth.lowPerc", "style": "bg-danger", "filter": {'batteryHealthStatus': 'LEVEL_1'}, "data": modalDataLow },
              { "type": "units.status.na", "style": "bg-light", "filter": {'batteryHealthStatus': 'LEVEL_0'}, "data": modalDataNa },
            ]
          }
        }
        resolve();
      });
    });
  }

  ngOnDestroy() {
    this.sidebarEventSubscription?.unsubscribe();
    this.clearRefreshInterval();
  }

  percentage(value, total) {
    if(total>0) {
      return (100 * value / total).toFixed(2);
    }
    return null;
  }

  goToUnits(event, filterBySite = false) {
    let filters = event?.series?.filters;
    let sites = event?.series?.sites;
    let dataIndex = event?.dataIndex;

    if(!filters) {
      filters = event || {};
    }

    if(filterBySite && sites) {
      filters['idSite'] = sites[dataIndex]?.idSite;
      filters['idRegion'] = sites[dataIndex]?.idRegion;
    }

    let params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    this.router.navigate(['/units'], {state: { filters:  params }});
  }

  goToUnitsFromModal(filters: any) {
    let params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    this.router.navigate(['/units'], {state: { filters:  params }});
  }

}

