<s1-modal #modal [title]="title">
  
  <s1-card>
    <s1-table #table [onlinePagination]="false">
      <thead header>
        <tr>
          <th></th>
          <th class="w-15" *ngIf="configColumn1?.label" [ngClass]="configColumn1?.style">{{ configColumn1.label | translate }}</th>
          <th class="w-15" *ngIf="configColumn2?.label" [ngClass]="configColumn2?.style">{{ configColumn2.label | translate }}</th>
          <th class="w-15" *ngIf="configColumn3?.label" [ngClass]="configColumn3?.style">{{ configColumn3.label | translate }}</th>
          <th class="w-15" *ngIf="configColumn4?.label" [ngClass]="configColumn4?.style">{{ configColumn4.label | translate }}</th>
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let detail of table.config.rows; let i = index">
          <td>{{ detail.site }}</td>
          <td class="w-15 pointer" *ngIf="detail.col1!=null" (click)="select(i, configColumn1?.filter, 0)">{{ detail.col1 }}</td>
          <td class="w-15 pointer" *ngIf="detail.col2!=null" (click)="select(i, configColumn2?.filter, 1)">{{ detail.col2 }}</td>
          <td class="w-15 pointer" *ngIf="detail.col3!=null" (click)="select(i, configColumn3?.filter, 2)">{{ detail.col3 }}</td>
          <td class="w-15 pointer" *ngIf="detail.col4!=null" (click)="select(i, configColumn4?.filter, 3)">{{ detail.col4 }}</td>
        </tr>
      </tbody>
    </s1-table>
    
  </s1-card>

</s1-modal>