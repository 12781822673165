import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { S1ButtonType, S1Modal, IS1Modal, S1ModalSizes } from '@app/s1';

@Component({
  selector: 'mdm-application-permission-policy-modal',
  templateUrl: './application-permission-policy-modal.component.html',
  styleUrls: ['./application-permission-policy-modal.component.scss']
})
export class AePermissionPolicyModalComponent implements OnInit, IS1Modal {

  @Output() onCreated = new EventEmitter<any>();
  @ViewChild('modal') modal: S1Modal;
  s1ButtonType = S1ButtonType;
  permissionForm: UntypedFormGroup;
  policyOptions = [];
  
  constructor(private formBuilder: UntypedFormBuilder) {
    this.permissionForm = this.formBuilder.group({
      index: null,
      permission: [null, Validators.required],
      policy: [null, Validators.required],
    });
    this.permissionForm.get('permission').markAsTouched();
    this.policyOptions = [
      "PERMISSION_POLICY_UNSPECIFIED",
      "PROMPT",
      "GRANT",
      "DENY",
    ];
  }

  ngOnInit(): void { }

  open(permission: any, index: number) {
    this.permissionForm.patchValue({
      index: index,
      permission: permission?.permission,
      policy: permission?.policy,
    });
    this.modal.open(S1ModalSizes.LG);
  }

  save() {
    const permission: any = {
      index: this.permissionForm?.controls.index.value,
      permission: this.permissionForm?.controls.permission.value,
      policy: this.permissionForm?.controls.policy.value
    };
    this.onCreated.emit(permission);
    this.close();
  }

  close() {
    this.modal.close();
  }

}
