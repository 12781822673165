<s1-modal #modal [title]="'aeapplications.list.title_configuration'">

  <s1-card>
    <form [formGroup]="configurationForm">
      <div class="row text-left">
        <s1-input-text class="col-10 mb-2" [readonly]="applicationPolicy?.packageName" 
            [control]="configurationForm.controls.packageName" [label]="'aeapplications.list.table.columns.application'" [labelGrouped]="false"></s1-input-text>
        <s1-button class="mt-center" [type]="s1ButtonType.Refresh" [disabled]="applicationPolicy?.packageName || !configurationForm.controls.packageName.value || configurationForm.controls.packageName.value.trim().length === 0" (onClick)="loadApplication(configurationForm.controls.packageName.value)"></s1-button>
        <div class="text-danger ml-4" *ngIf="packageNameAlreadyUsed">{{'base.ae_package_name_already_used' | translate}}</div>
        <div class="text-danger ml-4" *ngIf="packageNameNotFound">{{'base.ae_package_name_not_found' | translate}}</div>

        <ng-container *ngIf="application">
          
          <label class="h3 font-weight-bold text-center col-12 mt-2">{{application?.title}}</label>
  
          <mdm-input-options-select class="col-12 mb-2"
              [control]="configurationForm.controls.installType" [label]="'aeapplications.list.table.columns.installType'" [options]="installTypeOptions" [bindAllObject]="false"></mdm-input-options-select>
              
          <mdm-input-options-select class="col-6 mb-2"
              [control]="configurationForm.controls.autoUpdateMode" [label]="'aeapplications.list.table.columns.autoUpdateMode'" [options]="autoUpdateModeOptions" [bindAllObject]="false"></mdm-input-options-select>
          <s1-input-text class="col-6 mb-2" type="number" [control]="configurationForm.controls.minimunVersionCode" [label]="'aeapplications.list.table.columns.minimunVersionCode'" [labelGrouped]="false"></s1-input-text>

          <mdm-input-options-select class="col-12 mb-2"
              [control]="configurationForm.controls.defaultPermissionPolicy" [label]="'aeapplications.list.table.columns.defaultPermissionPolicy'" [options]="permissionPolicyOptions" [bindAllObject]="false"></mdm-input-options-select>
  
          <ng-container *ngFor="let applicationPermissionControl of applicationPermissionsFormArray?.controls; let i = index">
            <mdm-input-options-select *ngIf="applicationPermissionControl.value" class="col-12" [control]="applicationPermissionControl" label="{{'base.override' | translate }} {{applicationPolicy.permissions[i].permission}}" [options]="permissionPolicyOptions" [bindAllObject]="false"></mdm-input-options-select>
          </ng-container>
          
          <mdm-input-options-select class="col-12" [control]="configurationForm.controls.newPermissionKey" label="{{'base.override' | translate }} {{'aeapplications.list.table.columns.permission' | translate}}" [options]="applicationPermissionsOptions" [bindAllObject]="false"></mdm-input-options-select>
          <mdm-input-options-select class="col-10" [control]="configurationForm.controls.newPermissionPolicy" [label]="'aeapplications.list.table.columns.newPermissionPolicy'" [options]="permissionPolicyOptions" [bindAllObject]="false"></mdm-input-options-select>
          <s1-button class="col-2 mt-center" [type]="s1ButtonType.Add" [disabled]="!configurationForm.controls.newPermissionPolicy.value || !configurationForm.controls.newPermissionKey.value" (onClick)="addPermission()"></s1-button>
  


          <ng-container *ngFor="let managedConfigurationControl of managedConfigurationsFormArray?.controls; let i = index">
  
            <ng-container *ngIf="['STRING','HIDDEN'].includes(application.managedProperties[i].type)">
              <s1-input-textarea class="col-12 mb-4" [control]="managedConfigurationControl" [label]="application.managedProperties[i].key"></s1-input-textarea>
            </ng-container>
            
            <ng-container *ngIf="['INTEGER'].includes(application.managedProperties[i].type)">
              <s1-input-text class="col-12 mb-4" type="number" [control]="managedConfigurationControl" [label]="application.managedProperties[i].key" [labelGrouped]="false"></s1-input-text>
            </ng-container>
  
            <ng-container *ngIf="['BOOL'].includes(application.managedProperties[i].type)">
              <s1-input-switch class="col-auto mb-4" [control]="managedConfigurationControl" [label]="application.managedProperties[i].key" [grouped]="false"></s1-input-switch>
            </ng-container>
  
            <ng-container *ngIf="['CHOICE'].includes(application.managedProperties[i].type)">
              <mdm-input-options-select class="col-12 mb-4" [control]="managedConfigurationControl" [label]="application.managedProperties[i].key" 
                [options]="managedConfigurationsArrayOptions[i]" [bindAllObject]="false"></mdm-input-options-select>
            </ng-container>
  
            <ng-container *ngIf="['MULTISELECT'].includes(application.managedProperties[i].type)">
              <s1-input-multiselect class="col-12 mb-4" [control]="managedConfigurationControl" [label]="application.managedProperties[i].key"
              [options]="managedConfigurationsArrayOptions[i]"></s1-input-multiselect>
            </ng-container>
  
          </ng-container>
          
          <div class="col-12 mb-4 text-center">
            <s1-button [type]="s1ButtonType.Save" [disabled]="!application || !configurationForm.valid" (onClick)="save()"></s1-button>
          </div>
        </ng-container>
                                
      </div>
    </form>
  </s1-card>

  

</s1-modal>