<div class="row mt-2" [ngClass]="'d-flex justify-content-center'">
  <ng-container>
    <div class="px-4" [ngClass]="'col-12'">
      <p class="d-flex justify-content-between align-items-center font-weight-bold">
          <span class="pl-4">
            <em class="mr-2 fas fa-square status text-{{ style[type].textColor }}"></em>
            {{ label }}
          </span>
        <span class="pr-4">
           {{ value }}
          </span>
      </p>
    </div>
  </ng-container>
</div>