<div class="form-group">
    <label for="password">{{ label | translate }}</label>
    <div class="input-group with-focus">
        <input class="form-control border-right-0" id="password" type="password" name="password"
            placeholder="{{(text ? text : (editable && !editEnabled) ? '******' : 'base.pwd') | translate}}"
            autocomplete="new-password"
            [readonly]="readonly || (editable && !editEnabled)"
            [formControl]="control" #password/>
        <ng-container *ngIf="!editable || (editable && editEnabled); else editPwdTemplate">
            <div class="input-group-append" [ngClass]="{'readonly': readonly}" style="cursor: pointer;"
                (click)="togglePassword()">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <em class="fa" [ngClass]="displayedPwd ? 'fa-eye-slash' : 'fa-eye'"></em>
                </span>
            </div>
        </ng-container>
    </div>
    <div class="text-danger" *ngIf="control.hasError('required') && (control.dirty || control.touched)">{{ 'base.field_required' | translate }}</div>
    <div class="text-danger" *ngIf="control.hasError('pattern') && (control.dirty || control.touched)">{{'base.input_required' | translate}}</div>
    <div class="text-danger" *ngIf="control.hasError('equalTo')">{{'base.pwd_not_match' | translate}}</div>
</div>

<ng-template #editPwdTemplate>
    <div class="input-group-append" style="cursor: pointer;"
        (click)="toggleEdit()">
        <span class="input-group-text text-muted bg-transparent border-left-0">
            <em class="fa fa-pencil"></em>
        </span>
    </div>
</ng-template>