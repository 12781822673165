import { Injectable } from '@angular/core';
import { IS1SearchParams, S1HttpClientService } from '@app/s1';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAsset, IUnit } from '../models/models';
import { IProfile, IProfileType, IProfileSetting, IProfileAsset, IProfileNetwork, IProfileOS, IProfileDistribution } from '../models/profile';
import { IAssetsSearchParams } from './assets.service';
import { IUnitsPaginated, IUnitsSearchParams } from './units.service';

export interface IProfilesSearchParams  extends IS1SearchParams {
  code?: string, //TODO capire se serve
  description?: string,
  daysFromDate?: number,
  status?: string,
  flgDisabled?: boolean,
  semaphore?: boolean,
  idType?: number,
  idProfile?: number,
  hasAssignments?: boolean,
  tsStart?: number,
  idRegion?: string,
  idSite?: string,
  idGroup?: string,
  idBrand?: string,
  idModel?: string,
  groupByRegion?: boolean,
  groupBySite?: boolean,
  groupByGroup?: boolean,
  groupByBrand?: boolean,
  groupByModel?: boolean,
  codeUnit?: string,
  idCompany: number,
  withCheck?: boolean
}
export interface IProfilesSelectionParams {
  unitsSearchParams?: IUnitsSearchParams,
  idProfileDistribution?: number,
  units?: number[],
  forceProfile?: boolean
}

export interface IProfilesPaginated {
  profiles: IProfile[],
  total: number
}

export interface IProfileTypesParams {
  code: string,
  name: string
}

enum ProfileAPI {
  asset = "/asset",
  setting = "/setting",
  network = "/network",
  os = "/os",
  agent = "/agent",
  ae = "/ae",
  profileDistribution = "/profile-distribution/",
  osDistribution = "/os-distribution/",
  agentDistribution = "/agent-distribution/",
  forceStatusError = "/force-status-error/",
  massiveForceStatusError = "/massive-force-status-error"
}

export interface IAssetTypes {
  id: string,
  code: string,
  description: string,
}

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  private path = "/profiles"
  private pathType = "/profileTypes"
  private pathAssetType = "/profiles/asset-types"
  private pathAssetList = "/profiles/assets"
  private pathAssetAgentList = "/profiles/assets-agent"
  private pathAssetOsList = "/profiles/assets-os"
  private pathAssignmentList = "/profiles/with-conditions"
  private pathGroupByList = "/profiles/with-group-by"
  private pathEnrollmentList = "/enroll-simulation"
  private pathClone = "/clone"
  private pathEnable = "/enable"
  private pathInvalidate = "/end";

  private pathListUnit = "/units";
  private pathAddUnits = "add-units";
  private pathAddAllUnit = "/add-all-available-unit";
  private pathRemoveUnit = "/remove-unit";
  private pathRemoveUnitList = "remove-units";
  private pathRedistribution = "redistribution";

  private pathCreate = "/create-asset-file";
  private pathUpdate = "/update-asset-file";

  constructor(private s1HttpClient: S1HttpClientService) { }

  getProfiles(parameters?: IProfilesSearchParams, ui: boolean = true, minimal: boolean = false): Observable<IProfilesPaginated> {
    return this.s1HttpClient.get(minimal ? this.path + '/minimal' : this.path, parameters, ui).pipe(
      map(response => { return { profiles: response.data.results, total: response.data.total } })
    );
  }

  getProfileList(searchParams: IS1SearchParams, parameters: IProfilesSearchParams,  isNotGrouped: boolean, ui: boolean = true): Observable<IProfilesPaginated> {
    return this.s1HttpClient.get(this.pathGroupByList,  {...searchParams, ...parameters}, ui).pipe(
      map(response => { 
        let results =  isNotGrouped ? response.data.results[0]?.profiles : response.data.results;
        return { profiles: results, total: response.data.total } 
      })
    );
  }

  invalidateProfile(profile: IProfile, ui: boolean = true): Observable<any> {
    return this.s1HttpClient.put(this.path + this.pathInvalidate + "/" + profile.id , {}, ui).pipe(
      map(response => response.outcome.success)
    );
  }

  getProfileAssignments(parameters?: IProfilesSearchParams, ui: boolean = true): Observable<IProfilesPaginated> {
    return this.s1HttpClient.get(this.pathAssignmentList, parameters, ui).pipe(
      map(response => { return { profiles: response.data.results, total: response.data.total } })
    );
  }

  getEnrollmentProfiles(parameters?: IProfilesSearchParams, ui: boolean = true): Observable<IProfile[]> {
    return this.s1HttpClient.get(this.pathEnrollmentList, parameters, ui).pipe(
      map(response => response.data.results)
    );
  }

  getOsAssets(parameters?: IAssetsSearchParams, ui: boolean = true): Observable<IAsset[]> {
    return this.s1HttpClient.get(this.pathAssetOsList, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  getOss(parameters?: IProfilesSearchParams, ui: boolean = true): Observable<IProfile[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.os, parameters, ui).pipe(
      map(response => response.data.results)
    );
  }

  getAgents(parameters?: IProfilesSearchParams, ui: boolean = true): Observable<IProfile[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.agent, parameters, ui).pipe(
      map(response => response.data.results)
    );
  }

  getAes(parameters?: IProfilesSearchParams, ui: boolean = true): Observable<IProfile[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.ae, parameters, ui).pipe(
      map(response => response.data.results)
    );
  }

  getAgentAssets(parameters?: IAssetsSearchParams, ui: boolean = true): Observable<IAsset[]> {
    return this.s1HttpClient.get(this.pathAssetAgentList, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  getTypes(parameters?: IProfileTypesParams): Observable<IProfileType[]> {
    return this.s1HttpClient.get(this.pathType, {}, false).pipe(
      map(response => response.data.results)
    );
  }

  getProfileAsset(id: number, showLoading: boolean = true ): Observable<IProfileAsset> {
    return this.s1HttpClient.get(this.path + "/" + (id) + ProfileAPI.asset, {}, showLoading).pipe(
      map(response => response.data)
    );
  }

  getProfileSetting(id: number): Observable<IProfileSetting> {
    return this.s1HttpClient.get(this.path + "/" + (id) + ProfileAPI.setting , {}).pipe(
      map(response => response.data)
    );
  }

  getProfileNetwork(id: number): Observable<IProfileNetwork> {
    return this.s1HttpClient.get(this.path + "/" + (id) + ProfileAPI.network, {}).pipe(
      map(response => response.data)
    );
  }

  getProfileOS(id: number): Observable<IProfileOS> {
    return this.s1HttpClient.get(this.path + "/" + (id) + ProfileAPI.os, {}).pipe(
      map(response => response.data)
    );
  }

  createProfileSetting(profile: IProfileSetting): Observable<IProfileSetting> {
    return this.s1HttpClient.post(this.path + ProfileAPI.setting, profile).pipe(
      map(response => response.data)
    );
  }

  updateProfileSetting(profile: IProfileSetting ): Observable<IProfileSetting> {
    return this.s1HttpClient.put(this.path + "/" + profile.idProfile + ProfileAPI.setting , profile).pipe(
      map(response => response.data)
    );
  }

  cloneProfileSetting(id: number): Observable<IProfileSetting> {
    return this.s1HttpClient.post(this.path + "/" + id + ProfileAPI.setting + this.pathClone, {}).pipe(
      map(response => response.data)
    );
  }
  
  enableProfileSetting(id: number, enable: boolean): Observable<IProfileSetting> {
    return this.s1HttpClient.post(this.path + "/" + id + ProfileAPI.setting + this.pathEnable + "/" + enable, {}).pipe(
      map(response => response.data)
    );
  }
  
  deleteProfileSetting(id: number): Observable<IProfileSetting> {
    return this.s1HttpClient.delete(this.path + "/" + id + ProfileAPI.setting).pipe(
      map(response => response.data)
    );
  }

  createProfileNetwork(profile: IProfileNetwork): Observable<IProfileNetwork> {
    return this.s1HttpClient.post(this.path + ProfileAPI.network, profile).pipe(
      map(response => response.data)
    );
  }

  updateProfileNetwork(profile: IProfileNetwork): Observable<IProfileNetwork> {
    return this.s1HttpClient.put(this.path + "/" + profile.idProfile + ProfileAPI.network , profile).pipe(
      map(response => response.data)
    );
  }

  cloneProfileNetwork(id: number): Observable<IProfileNetwork> {
    return this.s1HttpClient.post(this.path + "/" + id + ProfileAPI.network + this.pathClone, {}).pipe(
      map(response => response.data)
    );
  }

  enableProfileNetwork(id: number, enable: boolean): Observable<IProfileNetwork> {
    return this.s1HttpClient.post(this.path + "/" + id + ProfileAPI.network + this.pathEnable + "/" + enable, {}).pipe(
      map(response => response.data)
    );
  }

  deleteProfileNetwork(id: number): Observable<IProfileNetwork> {
    return this.s1HttpClient.delete(this.path + "/" + id + ProfileAPI.network).pipe(
      map(response => response.data)
    );
  }

  createProfileAsset(profile: IProfileAsset): Observable<IProfileAsset> {
    return this.s1HttpClient.post(this.path + ProfileAPI.asset, profile).pipe(
      map(response => response.data)
    );
  }

  updateProfileAsset(profile: IProfileAsset, showLoading: boolean = true): Observable<IProfileAsset> {
    return this.s1HttpClient.put(this.path + "/" + profile.idProfile + ProfileAPI.asset , profile, showLoading).pipe(
      map(response => response.data)
    );
  }

  createAsset(asset: IAsset, showLoading: boolean = true): Observable<any> {
    return this.s1HttpClient.postFormData(this.path + this.pathCreate, asset).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  updateAsset(asset: IAsset, showLoading: boolean = true): Observable<any> {
    return this.s1HttpClient.postFormData(this.path + this.pathUpdate, asset).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }


  moveAssetOfProfileAsset(profile: IProfileAsset, asset: IAsset, direction: boolean): Observable<IProfileAsset> {
    return this.s1HttpClient.post(this.path + "/" + profile.idProfile + ProfileAPI.asset + "/move-order?idAsset=" + asset.id + "&up=" + direction, {}).pipe(
      map(response => response.data)
    );
  }
  
  cloneProfileAsset(id: number): Observable<IProfileAsset> {
    return this.s1HttpClient.post(this.path + "/" + id + ProfileAPI.asset + this.pathClone, {}).pipe(
      map(response => response.data)
    );
  }

  enableProfileAsset(id: number, enable: boolean): Observable<IProfileAsset> {
    return this.s1HttpClient.post(this.path + "/" + id + ProfileAPI.asset + this.pathEnable + "/" + enable, {}).pipe(
      map(response => response.data)
    );
  }

  deleteProfileAsset(id: number): Observable<IProfileAsset> {
    return this.s1HttpClient.delete(this.path + "/" + id + ProfileAPI.asset).pipe(
      map(response => response.data)
    );
  }

  deleteAssetOfProfileAsset(profile: IProfileAsset, asset: IAsset): Observable<boolean> {
    return this.s1HttpClient.delete(this.path + "/" + profile.idProfile + ProfileAPI.asset + "/delete-asset?idAsset=" + asset.id + "&deleteAssetToo=" + asset.confirmRemotion).pipe(
      map(response => response.outcome.success)
    );
  }

  createProfileOS(profile: IProfileOS): Observable<IProfileOS> {
    return this.s1HttpClient.post(this.path + ProfileAPI.os, profile).pipe(
      map(response => response.data)
    );
  }

  updateProfileOS(profile: IProfileOS ): Observable<IProfileOS> {
    return this.s1HttpClient.put(this.path + "/" + profile.idProfile + ProfileAPI.os , profile).pipe(
      map(response => response.data)
    );
  }

  getAssestTypes(includeOs: boolean): Observable<IAssetTypes[]> {
    return this.s1HttpClient.get(this.pathAssetType, {'includeOs': includeOs}, false).pipe(
      map(response => response.data.results)
    );
  }

  getAssestList(parameters: IAssetsSearchParams): Observable<IAsset[]> {
    return this.s1HttpClient.get(this.pathAssetList, parameters).pipe(
      map(response => response.data.results)
    );
  }

  getAvailableUnitsDistribution(id: number, parameters: IUnitsSearchParams): Observable<IUnitsPaginated> {
    return this.s1HttpClient.get(this.path + ProfileAPI.profileDistribution + id + '/available-units', parameters).pipe(
      map(response => { return { units: response.data.results, total: response.data.total } })
    );
  }

  getProfilesDistribution(parameters: IProfilesSearchParams): Observable<IProfileDistribution[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.profileDistribution, parameters).pipe(
      map(response => response.data.results)
    );
  }
  createProfileDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.post(this.path + ProfileAPI.profileDistribution, profile).pipe(
      map(response => response.data)
    );
  }
  updateProfileDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.put(this.path + ProfileAPI.profileDistribution + profile.id, profile).pipe(
      map(response => response.data)
    );
  }
  deleteProfileDistribution(id: number): Observable<any> {
    return this.s1HttpClient.delete(this.path + ProfileAPI.profileDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getProfileDistributionDetail(id: number): Observable<IProfileDistribution> {
    return this.s1HttpClient.get(this.path + ProfileAPI.profileDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getProfileUnitsDistribution(id: number): Observable<IUnit[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.profileDistribution + id + this.pathListUnit).pipe(
      map(response => response.data.results)
    );
  }
  setProfileUnitsDistribution(parameters:any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.profileDistribution + this.pathAddUnits, parameters).pipe(
      map(response => response.data)
    );
  }
  unsetProfileUnitDistribution(id: number, idUnit: number): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.profileDistribution + id + this.pathRemoveUnit + '?idUnit='+idUnit, {}).pipe(
      map(response => response.data)
    );
  }
  unsetProfileUnitListDistribution(parameters: any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.profileDistribution + this.pathRemoveUnitList, parameters).pipe(
      map(response => response.data)
    );
  }
  setProfileAllUnitsDistribution(id: number, parameters: IUnitsSearchParams, forceProfile: boolean): Observable<boolean> {
    let params = this.s1HttpClient.mapParameters(parameters);
    params += "&forceProfile="+forceProfile;
    return this.s1HttpClient.post(this.path + ProfileAPI.profileDistribution + id + this.pathAddAllUnit + params, {}).pipe(
      map(response => response.data)
    );
  }
  sendProfileUnitsDistribution(parameters: any): Observable<IUnit[]> {
    return this.s1HttpClient.post(this.path + ProfileAPI.profileDistribution + this.pathRedistribution, parameters).pipe(
      map(response => response.data)
    );
  }
  
  forceProfileStatusError(idUnitProfile: any): Observable<any> {
    return this.s1HttpClient.put(this.path + ProfileAPI.forceStatusError + idUnitProfile, {}).pipe(
      map(response => response.data)
    );
  }
  massiveForceProfileStatusError(parameters: any): Observable<boolean> {
    return this.s1HttpClient.put(this.path + ProfileAPI.massiveForceStatusError, parameters).pipe(
      map(response => response.data)
    );
  }

  
  //OS
  getOsDistribution(parameters?: IProfilesSearchParams): Observable<IProfileDistribution[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.osDistribution, parameters).pipe(
      map(response => response.data.results)
      );
  }
  createOsDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.post(this.path + ProfileAPI.osDistribution, profile).pipe(
      map(response => response.data)
    );
  }
  updateOsDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.put(this.path + ProfileAPI.osDistribution + profile.id, profile).pipe(
      map(response => response.data)
    );
  }
  deleteOsDistribution(id: number): Observable<any> {
    return this.s1HttpClient.delete(this.path + ProfileAPI.osDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getOsDistributionDetail(id: number): Observable<IProfileDistribution> {
    return this.s1HttpClient.get(this.path + ProfileAPI.osDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getOsUnitsDistribution(id: number): Observable<IUnit[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.osDistribution + id + this.pathListUnit).pipe(
      map(response => response.data.results)
    );
  }
  setOsUnitsDistribution(parameters:any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.osDistribution + this.pathAddUnits, parameters).pipe(
      map(response => response.data)
    );
  }
  unsetOsUnitDistribution(id: number, idUnit: number): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.osDistribution + id + this.pathRemoveUnit + '?idUnit='+idUnit, {}).pipe(
      map(response => response.data)
    );
  }
  unsetOsUnitListDistribution(parameters: any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.osDistribution + this.pathRemoveUnitList, parameters).pipe(
      map(response => response.data)
    );
  }
  setOsAllUnitsDistribution(id: number, parameters: IUnitsSearchParams): Observable<boolean> {
    let params = this.s1HttpClient.mapParameters(parameters);
    return this.s1HttpClient.post(this.path + ProfileAPI.osDistribution + id + this.pathAddAllUnit + params, {}).pipe(
      map(response => response.data)
    );
  }
  sendOsUnitsDistribution(parameters: any): Observable<IUnit[]> {
    return this.s1HttpClient.post(this.path + ProfileAPI.osDistribution + this.pathRedistribution, parameters).pipe(
      map(response => response.data)
    );
  }

  //Agent
  getAgentDistribution(parameters?: IProfilesSearchParams): Observable<IProfileDistribution[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.agentDistribution, parameters).pipe(
      map(response => response.data.results)
      );
  }
  createAgentDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.post(this.path + ProfileAPI.agentDistribution, profile).pipe(
      map(response => response.data)
    );
  }
  updateAgentDistribution(profile: IProfileDistribution): Observable<number> {
    return this.s1HttpClient.put(this.path + ProfileAPI.agentDistribution + profile.id, profile).pipe(
      map(response => response.data)
    );
  }
  deleteAgentDistribution(id: number): Observable<any> {
    return this.s1HttpClient.delete(this.path + ProfileAPI.agentDistribution + id).pipe(
      map(response => response.data)
    );
  }
  getAgentDistributionDetail(id: number, ui?: boolean): Observable<IProfileDistribution> {
    return this.s1HttpClient.get(this.path + ProfileAPI.agentDistribution + id, null, ui).pipe(
      map(response => response.data)
    );
  }
  getAgentUnitsDistribution(id: number): Observable<IUnit[]> {
    return this.s1HttpClient.get(this.path + ProfileAPI.agentDistribution + id + this.pathListUnit).pipe(
      map(response => response.data.results)
    );
  }
  setAgentUnitsDistribution(parameters:any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.agentDistribution + this.pathAddUnits, parameters).pipe(
      map(response => response.data)
    );
  }
  unsetAgentUnitDistribution(id: number, idUnit: number): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.agentDistribution + id + this.pathRemoveUnit + '?idUnit='+idUnit, {}).pipe(
      map(response => response.data)
    );
  }
  unsetAgentUnitListDistribution(parameters: any): Observable<boolean> {
    return this.s1HttpClient.post(this.path + ProfileAPI.agentDistribution + this.pathRemoveUnitList, parameters).pipe(
      map(response => response.data)
    );
  }
  setAgentAllUnitsDistribution(id: number, parameters: IUnitsSearchParams): Observable<boolean> {
    let params = this.s1HttpClient.mapParameters(parameters);
    return this.s1HttpClient.post(this.path + ProfileAPI.agentDistribution + id + this.pathAddAllUnit + params, {}).pipe(
      map(response => response.data)
    );
  }
  sendAgentUnitsDistribution(parameters: any): Observable<IUnit[]> {
    return this.s1HttpClient.post(this.path + ProfileAPI.agentDistribution + this.pathRedistribution, parameters).pipe(
      map(response => response.data)
    );
  }
}
