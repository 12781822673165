import { Component, OnInit, Input } from '@angular/core';
import { IDispensingUnit, IUnit, IWall } from '@app/shared/models/models';
import {SettingsService} from "@app/core/settings/settings.service";
import {DispensingService} from "@app/shared/services/dispensing.service";

@Component({
    selector: 'wall-card',
    templateUrl: './wall-card.component.html',
    styleUrls: ['./wall-card.component.scss']
})

export class WallCardComponent implements OnInit {

  @Input() set wall(wall: IWall) {
    this.currentWall = wall;
    this.flattenedList = wall.units.reduce((flat, val) => flat.concat(val), []); //reduce list of units list in a flat list
    this.unitCounter = this.flattenedList.length;
  };
  @Input() isCollapsed: boolean = false;

  limitFullMode: number = 30; // Numero massimo di unit visualizzabili su una riga in modalità FULL con tutte le info
  gridLevels = [];

  unitCounter: number;
  currentWall: IWall;
  flattenedList: IDispensingUnit[];

  constructor(public settings: SettingsService, private dispensingService: DispensingService) {
    this.gridLevels = [
      { lowerBound: 12, upperBound: 24, elements: 2, width: 6 }, // 2 units in 2 cells large 6 = 24 units max
      { lowerBound: 24, upperBound: 36, elements: 3, width: 4 }, // 3 units in 3 cells large 4 = 36 units max
      { lowerBound: 36, upperBound: 48, elements: 4, width: 3 }, // 4 units in 4 cells large 3 = 48 units max
      { lowerBound: 48, upperBound: 60, elements: 5, width: 2 }, // 5 units in 6 cells large 2 = 60 units max
      { lowerBound: 60, upperBound: 72, elements: 6, width: 1 }  // 6 units in 12 cells large 1 = 72 units max
    ];
  }
  ngOnInit(): void {}

  getUnits(list: IDispensingUnit[][], row, coll) {
    //find a match for each sublist
    let match = null;
    list.forEach(units => {
      //check just the first element
      if(units.length>0 && units[0].row == row && units[0].col == coll) {
        match = units;
      }
    });
    return match;
  }

  showTab(wallToShow: any) {
    const params: any = {
      idCompany:  this.settings.getCompany().code,
      idSite: wallToShow.idSite,
      idRegion: wallToShow.idRegion,
      codWall: wallToShow.code
    };

    this.dispensingService.getWall(params).subscribe(wall => {
      wall.show = true;
      this.currentWall = wall;
      //aggiorno il count
      this.flattenedList = wall.units.reduce((flat, val) => flat.concat(val), []); //reduce list of units list in a flat list
      this.unitCounter = this.flattenedList.length;
    });
  }

}
