import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import swal, { SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Question = "question"
}

export interface SelectItem {
  code: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class S1UIService {

  private visibleLoader: boolean = false;

  constructor(private translate: TranslateService, private toasterService: ToastrService) { }

  showBlockingSpinner() {
    if (!swal.isVisible()) {
      swal.fire({
        title: this.translate.instant('s1.swal.loading'),
        allowOutsideClick: false,
        html: '<strong class="text-primary"></strong>',
        showCancelButton: false,
        showConfirmButton: false,
      })
      swal.showLoading();
    }
  }
  
  updateFooter(text) {
    if(swal.isVisible()) {
      swal.update({
        html: '<strong class="text-primary">'+text+'</strong>'
      });
    }
  }
  
  // Spinner
  showSpinner() {
    if (!this.visibleLoader) {
      this.visibleLoader = true;
      swal.fire({
        title: this.translate.instant('s1.swal.loading')
      });
      swal.showLoading();
    }
  }

  closeSpinner() {
    this.visibleLoader = false;
    swal.close();
  }

  // Popup
  showPopup(type: S1PopUpType, title?: string, text?: string) {

    swal.fire(this.translate.instant(title), this.translate.instant(text), type);

  }

  showErrorPopup(errorMessage: string) {
    this.showPopup(S1PopUpType.Error, this.translate.instant('s1.swal.error'), this.translate.instant(errorMessage))
  }

  showPopupNoPermission() {
    this.showPopup(S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission')
  }

  showHTTPErrorPopup(error: HttpErrorResponse) {
    let errorTitle = 's1.swal.error';
    let errorMessage = 'genericError';
    
    if (error.statusText) {
      errorMessage = error.statusText;
    }

    if (error.status) {
      errorTitle = '' + error.status;
      switch (error.status) {
        case 13: // duplicated
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.code_already_used');
          break;
        case 23: // max file size
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_not_coherent');
          break;
        case 25: // ipaddress not coherent
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_not_coherent');
          break;
        case 26: // ipaddress to > from
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_address_from_to_error');
          break;
        case 27: // ipaddress or subnet mask format not valid
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.ip_wrong_format');
          break;
        case 30:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_fields_row_file_error')}`;
          break;
        case 31:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_unit_code_file_error')}`;
          break;
        case 32:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_timezone_file_error')}`;
          break;
        case 33:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_timezone_file_error')}`;
          break;
        case 37:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.missing_from_date_file_error')}`;
          break;
        case 38:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_from_date_file_error')}`;
          break;
        case 39:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_from_time_file_error')}`;
          break;
        case 40:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.generic_from_datetime_file_error')}`;
          break;
        case 41:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_end_date_file_error')}`;
          break;
        case 42:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.invalid_end_time_file_error')}`;
          break;
        case 43:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.generic_end_datetime_file_error')}`;
          break;
        case 44:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.endtime_higher_fromtime_file_error')}`;
          break;
        case 45:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.unit_code_existing_file_error')}`;
          break;
        case 46:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.unit_code_db_existing_file_error')}`;
          break;
        case 47:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.exceeding_file_error')}`;
          break;
        case 48:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.exceeding_row_file_error')}`;
          break;
        case 49:
          errorTitle = 's1.swal.error';
          errorMessage = `(${this.translate.instant('base.row')}${error.error}): ${this.translate.instant('error.exceeding_unit_code_file_error')}`;
          break;
        case 53: // duplicated
          errorTitle = 's1.swal.error';
          errorMessage = this.translate.instant('error.shop_setting_already_present');
          break;
        case 401: // Unauthorized
          errorMessage = this.translate.instant('APIErrors.' + error.status);
          break;
        case 403: // Forbidden
          swal.close();
          return;
      }
    }

    this.showPopup(S1PopUpType.Error, errorTitle, errorMessage);
  }

  showDialogPopup(title: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
    })

  }

  showDialogWithInputTextPopup(inputMessage: string, params?: Object): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      icon: S1PopUpType.Question,
      html: `<label>`+ this.translate.instant(inputMessage, params) +`</label><input type="text" id="content" class="swal2-input" maxlength="255">`,
      preConfirm: () => {
        const content: any = swal.getPopup().querySelector('#content');
        return { 'text': content.value };
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
      
    })

  }

  _showDialogWithInputTextAndCheckPopup(inputMessage: string, flagMessage: string, failureMessage: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      icon: S1PopUpType.Question,
      html: `<label class="mr-3">
                <input type="checkbox" id="flagFailure" class="mr-2 mb-4">`+ this.translate.instant(failureMessage)
          +`</label>
            <label>
                <input type="checkbox" id="flag" class="mr-2 mb-4">`+ this.translate.instant(flagMessage)
          +`</label>
             <label>`+ this.translate.instant(inputMessage) +`</label><input type="text" id="content" class="swal2-input" maxlength="255">`,
      preConfirm: () => {
        const content: any = swal.getPopup().querySelector('#content');
        const flag: any = swal.getPopup().querySelector('#flag');
        const flagFailure: any = swal.getPopup().querySelector('#flagFailure');
        return { 'text': content.value, 'flag': flag.checked, 'flagFailure': flagFailure.checked };
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }

    })

  }

  showDialogWithCheckPopup(title: string, options: any = {}): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      inputValue: 'no',
      input: 'radio',
      inputOptions: options,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!'
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
    })

  }

  showDialogWithCheckBoxPopup(title: string, option: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      inputValue: null,
      input: 'checkbox',
      inputPlaceholder: option,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-xs btn-danger'
      }
    })

  }

  /*showSelectionPopup(title: string, elementList: any): Promise<any> {

    return swal({
      title: this.translate.instant(title),
      type: "question",
      input: 'radio',
      inputOptions: elementList
    });

  }

  showInputPopup(title: string, text: string, fieldText: string, initialValue: string = null): Promise<any> {

    return swal({
      title: this.translate.instant(title),
      text: text,
      input: 'number',
      inputValue: initialValue,
      inputPlaceholder: this.translate.instant(fieldText),
    });

  }*/

  // TOAST
  showSuccessToast(text: string) {
    this.toasterService.success(this.translate.instant(text), this.translate.instant("s1.toast.success"));
  }

  showErrorToast(text: string) {
    this.toasterService.error(this.translate.instant(text), this.translate.instant("s1.toast.error"));
  }

}
