import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

import { AppService } from '@app/core/services/base.service';
import { ColorsService } from '@app/shared/colors/colors.service';
import { LoggingService } from '@app/core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { IUnit } from '@app/shared/models/models';
import { UnitsService } from '@app/shared/services/units.service';
import { DispensingService } from '@app/shared/services/dispensing.service';
import {SettingsService} from "@app/core/settings/settings.service";


@Component({
    selector: 'unit-commands',
    templateUrl: './unit-commands.component.html',
    styleUrls: ['./unit-commands.component.scss']
})

export class UnitCommandsComponent implements OnInit {

  @Input() mdmCommands: boolean = false;
  @Input() aeCommands: boolean = false;
  @Input() dispensingCommands: boolean = false;
  @Input() exclusions: string[];
  @Input() unit: IUnit;
  @Input() unitList: string[];
  @Output() onCommandSent = new EventEmitter<boolean>();
  unitCommands: Array<any>;
  selectedCommand: any;
  styles: any;

  constructor(public appService: AppService, private router: Router, private logService: LoggingService, private translate: TranslateService, public colors: ColorsService, 
    private unitsService: UnitsService, private dispensingService: DispensingService, private settingsService: SettingsService) {
    this.styles = {
      "REBOOT" : { icon: "mr-2 fas fa-sync-alt", color: "bg-success"},
      "PLAY_SOUND" : { icon: "mr-2 fa fa-volume-up", color: "bg-info"},
      "VIBRATE" : { icon: "mr-2 fa fa-water", color: "bg-info"},
      "FLASH_LED" : { icon: "mr-2 fa fa-lightbulb", color: "bg-info"},
      "LOCK" : { icon: "mr-2 fa fa-lock", color: "bg-info"},
      "WIPE" : { icon: "mr-2 fas fa-undo-alt", color: "bg-danger"},
      "SET_ADB_ON" : { icon: "mr-1 fab fa-usb", color: "bg-info"},
      "SET_ADB_OFF" : { icon: "mr-1 fab fa-usb", color: "bg-info"},
      "DISABLE_KIOSK" : { icon: "fas fa-store", color: "bg-danger"},
      "ENABLE_KIOSK" : { icon: "fas fa-store", color: "bg-success"},
      //"LOAD_CONFIG" : { icon: "fas fa-cog", color: "bg-info"},
      "PLAYSOUND" : { icon: "fa fa-volume-up", color: "bg-info"},
      //"CLOSE_APP" : { icon: "fa fa-power-off", color: "bg-danger"},
      "UNLOCK" : { icon: "fa fa-unlock", color: "bg-success"},
      "SET_BARS_VISIBILITY_ON" : { icon: "mr-1 fa fa-window-maximize\n", color: "bg-info"},
      "SET_BARS_VISIBILITY_OFF" : { icon: "mr-1 fa fa-window-maximize\n", color: "bg-info"}
    };
  }

  ngOnInit(): void {
    if(this.mdmCommands) {
      this.loadMdmCommands();
    }
    if(this.aeCommands) {
      this.loadAECommands();
    }
    if(this.dispensingCommands) {
      this.loadDispensingCommands();
    }
    //se mdm e ae, carico quelli in comune
    if(this.mdmCommands && this.aeCommands){
      this.loadCommonCommands();
    }
  }

  private loadMdmCommands() {
    this.unitCommands = [];
    //Reboot -> vince Android Enterprise, poi MDM
    this.unitCommands.push({id: 7, command: "REBOOT", description: "Reboot", enable: true, type: "MOBILE"});
    //M+D Playsound -> vince MDM
    this.unitCommands.push({id: 9, command: "PLAY_SOUND", description: "Playsound", enable: true, type: "MOBILE"});
    //Vibrate -> vince MDM
    this.unitCommands.push({id: 10, command: "VIBRATE", description: "Vibrate", enable: true, type: "MOBILE"});
    //Flash Led [Flash Unit Led] -> MDM
    this.unitCommands.push({id: 11, command: "FLASH_LED", description: "Flash Unit Led", enable: true, type: "MOBILE"});
    //ADB ON -> MDM
    this.unitCommands.push({id: 14, command: "SET_ADB_ON", description: "ADB On", enable: true, type: "MOBILE"});
    //ADB OFF -> MDM
    this.unitCommands.push({id: 15, command: "SET_ADB_OFF", description: "ADB Off", enable: true, type: "MOBILE"});
    //NUOVE
    //Enable bars (SET_BARS_VISIBILITY con top e bottom a true)
    this.unitCommands.push({id: 13, command: "SET_BARS_VISIBILITY_ON", description: "Enable bars", enable: true, type: "MOBILE"});
    //Disable bars (SET_BARS_VISIBILITY con top e bottom a false)
    this.unitCommands.push({id: 13, command: "SET_BARS_VISIBILITY_OFF", description: "Disable bars", enable: true, type: "MOBILE"});
    //FINE NUOVE
    //Kiosk OFF (MDM: MODIFY_KIOSK con enable a false)
    this.unitCommands.push({id: 17, command: "DISABLE_KIOSK", description: "Kiosk Off", enable: true, type: "MOBILE"});
    //Kiosk ON (MDM: MODIFY_KIOSK con enable a true)
    this.unitCommands.push({id: 18, command: "ENABLE_KIOSK", description: "Kiosk On", enable: true, type: "MOBILE"});
    //Factory Reset -> vince Android Enterprise, poi MDM
    this.unitCommands.push({id: 13, command: "WIPE", description: "Factory reset", enable: true, type: "MOBILE"});

    this.unitCommands = this.unitCommands.filter(unitCommand => !this.exclusions?.includes(unitCommand.command));
  }

  private loadAECommands() {
    this.unitCommands = [];
    //Reboot -> vince Android Enterprise, poi MDM
    this.unitCommands.push({id: 7, command: "REBOOT", description: "Reboot", enable: true, type: "MOBILE"});
    //Lock
    this.unitCommands.push({id: 12, command: "LOCK", description: "Lock", enable: true, type: "MOBILE"});
    //Factory Reset -> vince Android Enterprise, poi MDM
    this.unitCommands.push({id: 13, command: "WIPE", description: "Factory reset", enable: true, type: "MOBILE"});
    this.unitCommands = this.unitCommands.filter(unitCommand => !this.exclusions?.includes(unitCommand.command));
  }

  private loadDispensingCommands() {
    //TODO capire i campi di configurazione id e type
    this.unitCommands = [];
    //Reboot -> vince Android Enterprise, poi MDM
    this.unitCommands.push({id: 1, command: "REBOOT", description: "Reboot", enable: true, type: "MOBILE"});
    //Playsound -> vince MDM
    this.unitCommands.push({id: 3, command: "PLAY_SOUND", description: "Playsound", enable: true, type: "MOBILE"});
    //Vibrate -> vince MDM
    this.unitCommands.push({id: 4, command: "VIBRATE", description: "Vibrate", enable: true, type: "MOBILE"});
    //Flash Led [Flash Cradle Led] -> Dispensing
    this.unitCommands.push({id: 5, command: "FLASH_LED", description: "Flash Cradle Led", enable: true, type: "MOBILE"});
    //NO    Load Configuration (Dispensing) -> Dispensing
    //this.unitCommands.push({id: 2, command: "LOAD_CONFIG", description: "Load config", enable: true, type: "MOBILE"});
    //NO    Close App (Dispensing)
    //this.unitCommands.push({id: 8, command: "CLOSE_APP", description: "Close app", enable: true, type: "MOBILE"});
    //Unlock (Unlock Cradle) -> Dispensing
    //NUOVE
    //Enable bars (SET_BARS_VISIBILITY con top e bottom a true)
    this.unitCommands.push({id: 13, command: "SET_BARS_VISIBILITY_ON", description: "Enable bars", enable: true, type: "MOBILE"});
    //Disable bars (SET_BARS_VISIBILITY con top e bottom a false)
    this.unitCommands.push({id: 13, command: "SET_BARS_VISIBILITY_OFF", description: "Disable bars", enable: true, type: "MOBILE"});
    //FINE NUOVE

    this.unitCommands.push({id: 12, command: "UNLOCK", description: "Unlock Cradle", enable: true, type: "MOBILE"});
    this.unitCommands = this.unitCommands.filter(unitCommand => !this.exclusions?.includes(unitCommand.command));
  }

  private loadCommonCommands() {
    this.unitCommands = [];

    //Reboot -> vince Android Enterprise, poi MDM
    this.unitCommands.push({id: 7, command: "REBOOT", description: "Reboot", enable: true, type: "MOBILE"});
    //M+D Playsound -> vince MDM
    this.unitCommands.push({id: 9, command: "PLAY_SOUND", description: "Playsound", enable: true, type: "MOBILE"});
    //Vibrate -> vince MDM
    this.unitCommands.push({id: 10, command: "VIBRATE", description: "Vibrate", enable: true, type: "MOBILE"});
    //Flash Led [Flash Unit Led] -> MDM
    this.unitCommands.push({id: 11, command: "FLASH_LED", description: "Flash Unit Led", enable: true, type: "MOBILE"});
    //ADB ON -> MDM
    this.unitCommands.push({id: 14, command: "SET_ADB_ON", description: "ADB On", enable: true, type: "MOBILE"});
    //ADB OFF -> MDM
    this.unitCommands.push({id: 15, command: "SET_ADB_OFF", description: "ADB Off", enable: true, type: "MOBILE"});
    //NUOVE
    //Enable bars (SET_BARS_VISIBILITY con top e bottom a true)
    this.unitCommands.push({id: 13, command: "SET_BARS_VISIBILITY_ON", description: "Enable bars", enable: true, type: "MOBILE"});
    //Disable bars (SET_BARS_VISIBILITY con top e bottom a false)
    this.unitCommands.push({id: 13, command: "SET_BARS_VISIBILITY_OFF", description: "Disable bars", enable: true, type: "MOBILE"});
    //FINE NUOVE
    //Kiosk OFF (MDM: MODIFY_KIOSK con enable a false)
    this.unitCommands.push({id: 17, command: "DISABLE_KIOSK", description: "Kiosk Off", enable: true, type: "MOBILE"});
    //Kiosk ON (MDM: MODIFY_KIOSK con enable a true)
    this.unitCommands.push({id: 18, command: "ENABLE_KIOSK", description: "Kiosk On", enable: true, type: "MOBILE"});
    //Lock
    this.unitCommands.push({id: 12, command: "LOCK", description: "Lock", enable: true, type: "MOBILE"});

    this.unitCommands = this.unitCommands.filter(unitCommand => !this.exclusions?.includes(unitCommand.command));
  }

  selectCommand(command) {
    this.selectedCommand = command;
  }

  launchCommand(command) {

    if(this.unit) {
      this.unitsService.sendCommand(this.settingsService.getCompany().code, this.unit.code, command.command).subscribe( response => {
        this.onCommandSent.emit(response);
      });
    }
    if(this.unitList?.length>0) {
      this.unitsService.sendCommandAll(this.settingsService.getCompany().code, this.unitList, command.command).subscribe( response => {
        this.onCommandSent.emit(response);
      });
    }
  }

}
