<ng-template #toolTipProfileSuccess>
  {{ 'fields.input.profileStatus' | translate }} {{ 'profiles.status.success' | translate }}
</ng-template>
<ng-template #toolTipProfileWarning>
  {{ 'fields.input.profileStatus' | translate }} {{ 'profiles.status.warning' | translate }}
</ng-template>
<ng-template #toolTipProfileDanger>
  {{ 'fields.input.profileStatus' | translate }} {{ 'profiles.status.error' | translate }}
</ng-template>
<ng-template #toolTipProfileNa>
  {{ 'fields.input.profileStatus' | translate }} {{ 'profiles.status.na' | translate }}
</ng-template>

<s1-card cardStyle="card-no-padding">
  <div class="table-responsive">
    <table class="table table-bordered table-hover profilesSummary">
      <tbody>
        <tr *ngFor="let profile of summary; let i = index">
          <td>
            <div class="row">
              <div class="col-3">
                <div class="badge-profile text-primary">
                  <em class="mr-1 icon-smartilio icon-{{profile.descriptionType | lowercase}}"></em>
                  <strong class="">{{ 'profiles.profile.title' | translate }} {{profile.descriptionType}}</strong>
                </div>  
              </div>
              <div class="col">
                <div class="row text-center">
                  <div class="col pointer" (click)="goToPage(profile.idType, 'success')">
                    <p class="status">
                      <i class="fa fa-check-circle text-success mr-1"></i>
                      {{ 'profiles.status.shortName' | translate }} {{ 'profiles.status.success' | translate }}
                      <span class="counter ml-1">
                        {{profile.success || '0'}}
                      </span>
                    </p>
                  </div>
                  <div class="col pointer" (click)="goToPage(profile.idType, 'warning')">
                    <p class="status">
                      <i class="far fa-clock text-warning mr-2"></i>
                      {{ 'profiles.status.shortName' | translate }} {{ 'profiles.status.warning' | translate }}
                      <span class="counter ml-1">
                        {{profile.warning || '0'}}
                      </span>
                    </p>
                  </div>
                  <div class="col pointer" (click)="goToPage(profile.idType, 'danger')">
                    <p class="status">
                      <i class="fa fa-exclamation-triangle text-danger mr-2"></i>
                      {{ 'profiles.status.shortName' | translate }} {{ 'profiles.status.error' | translate }}
                      <span class="counter ml-1">
                        {{profile.danger || '0'}}
                      </span>
                    </p>
                  </div>
                  <div class="col pointer" (click)="goToPage(profile.idType, 'na')">
                    <p class="status">
                      <i class="fa fa-power-off light-gray mr-2"></i>
                      {{ 'profiles.status.shortName' | translate }} {{ 'profiles.status.na' | translate }}
                      <span class="counter ml-1">
                        {{profile.na || '0'}}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</s1-card>