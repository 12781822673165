<div class="wrapper">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
    <!-- sidebar-->
    <app-sidebar class="aside-container"></app-sidebar>
    <!-- offsidebar-->
    <!-- <app-offsidebar class="offsidebar"></app-offsidebar> -->
    <!-- Main section-->
    <section class="section-container">
        <!-- Page content-->
        <div class="content-wrapper">
            <!-- subheader-->
            <app-subheader></app-subheader>
            <router-outlet></router-outlet>
        </div>
    </section>
    <!-- rightsidebar-->
    <app-rightsidebar *ngIf="isSuperAdminOrReseller" class="offsidebar"></app-rightsidebar>
    <!-- Page footer-->
    <footer class="footer-container" app-footer></footer>
</div>