import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGroup } from '../models/models';

export interface IGroupsSearchParams {
  idCompany: number,
  code: string,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  private path = "/groups"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getGroups(parameters?: IGroupsSearchParams, ui: boolean = true): Observable<IGroup[]> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createGroup(group: IGroup): Observable<IGroup> {
    return this.s1HttpClient.post(this.path, group, false).pipe(
      map(response => response.data)
    )
  }

  updateGroup(group: IGroup): Observable<IGroup> {
    return this.s1HttpClient.put(this.elementPath(group), group, false).pipe(
      map(response => response.data)
    )
  }

  deleteGroup(group: IGroup): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(group), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(group: IGroup): string {
    return this.path + "/" + group.id
  }

}
