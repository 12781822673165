<ng-container *ngIf="isHomeVisible">

    <div class="row mt-1 px-4">
        <div class="col px-0">
            <s1-card>
                <div class="row text-primary">
                    <div class="offset-8 col-4 text-right">
                        <s1-button class="mr-2" [type]="s1ButtonType.Refresh" [onlyIcon]="false" [outline]="true" (onClick)="refresh()" [loading]="loading" [disabled]="loading"></s1-button>
                    </div>
                </div>
            </s1-card>
        </div>
    </div>
    <div class="row">
        <div class="col-auto mx-auto px-0 text-center bg-white">
            <button class="btn text-primary" type="button" (click)="prevWeek(0)">
                <h4><i class="fas fa-caret-left"></i></h4>
            </button>
            <button class="btn text-primary" type="button" disabled>
                <h4>{{ mondays[0] | date:'dd/MM/yyyy' }} - {{ sundays[0] | date:'dd/MM/yyyy' }}</h4>
            </button>
            <button class="btn text-primary" type="button" (click)="nextWeek(0)">
                <h4><i class="fas fa-caret-right"></i></h4>
            </button>
        </div>
    </div>
    <div class="row px-4">
        <div class="col px-0 mr-2">
            <mdm-dashboard-summary-card [config]="cartsConfig" [plotType]="piePlotType"></mdm-dashboard-summary-card>
        </div>
        <div class="col px-0 mr-2">
            <mdm-dashboard-summary-card [config]="partialRescansConfig" [plotType]="piePlotType"></mdm-dashboard-summary-card>
        </div>
        <div class="col px-0 mr-2">
            <mdm-dashboard-summary-card [config]="totalRescansConfig" [plotType]="piePlotType"></mdm-dashboard-summary-card>
        </div>
    </div>


    <div class="row mt-3 mb-0">
        <div class="col-auto mx-auto px-0 text-center bg-white">
            <button class="btn text-primary" type="button" (click)="prevWeek(1)">
                <h4><i class="fas fa-caret-left"></i></h4>
            </button>
            <button class="btn text-primary" type="button" disabled>
                <h4>{{ mondays[1] | date:'dd/MM/yyyy' }} - {{ sundays[1] | date:'dd/MM/yyyy' }}</h4>
            </button>
            <button class="btn text-primary" type="button" (click)="nextWeek(1)">
                <h4><i class="fas fa-caret-right"></i></h4>
            </button>
        </div>
    </div>
    <div class="row px-4 mb-0">
        <div class="col px-0">
            <s1-card [cardStyle]="'mb-0'">
                <div class="row">
                    <mdm-input-options-select class="ml-4 col-5" [control]="searchForm.controls.graphsTypeBlocco1" [label]="'shop.dashboard_graph_type'" [options]="optionsGraphsTypesBlocco1" [bindAllObject]="false" (onLoadList)="getGraphsTypeBlocco1($event)" [clearable]="false"></mdm-input-options-select>
                    <s1-button class="col-auto mt-search" [type]="s1ButtonType.View" (onClick)="getGraficoBlocco1()"></s1-button>
                </div>
                <div class="col-12 m-2 text-danger">
                    <ng-container *ngIf="hasErrorGraphsTypeBlocco1">{{'base.fields_required' | translate }}</ng-container>
                </div>
            </s1-card>
        </div>
    </div>
    <div class="row px-4" *ngIf="showSelectorCartsBlocco1 == 'SITES_CARTS_STATUS'">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesCartsStatusConfig" [plotType]="sitesCartsStatusPlotType" [hasPages]="false" [hasModal]="false"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row px-4" *ngIf="showSelectorCartsBlocco1 == 'SITES_CARTS_REVENUE'">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesCartsRevenueConfig" [plotType]="sitesCartsRevenuePlotType" [hasPages]="false" [hasModal]="false"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row px-4" *ngIf="showSelectorCartsBlocco1 == 'SITES_RESCANS'">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesRescansConfig" [plotType]="sitesRescansPlotType" [hasPages]="false" [hasModal]="false"></mdm-dashboard-summary-card>
        </div>
    </div>

    <div class="row mt-3 mb-0">
        <div class="col-auto mx-auto px-0 text-center bg-white">
            <button class="btn text-primary" type="button" (click)="prevWeek(2)">
                <h4><i class="fas fa-caret-left"></i></h4>
            </button>
            <button class="btn text-primary" type="button" disabled>
                <h4>{{ mondays[2] | date:'dd/MM/yyyy' }} - {{ sundays[2] | date:'dd/MM/yyyy' }}</h4>
            </button>
            <button class="btn text-primary" type="button" (click)="nextWeek(2)">
                <h4><i class="fas fa-caret-right"></i></h4>
            </button>
        </div>
    </div>
    <div class="row px-4 mb-0">
        <div class="col px-0">
            <s1-card [cardStyle]="'mb-0'">
                <div class="row">
                    <mdm-input-region-select class="ml-4 col-4" [control]="searchForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false" [siteControl]="searchForm.controls.site" (onLoadList)="getRegion($event)" [clearable]="false"></mdm-input-region-select>
                    <mdm-input-site-select class="col-3" [control]="searchForm.controls.site" [idRegion]="searchForm.controls.region.value" [bindAllObject]="false" (onLoadList)="getSite($event)" [clearable]="false"></mdm-input-site-select>
                </div>
                <div class="row">
                    <mdm-input-options-select class="ml-4 col-5" [control]="searchForm.controls.graphsTypeBlocco2" [label]="'shop.dashboard_graph_type'" [options]="optionsGraphsTypesBlocco2" [bindAllObject]="false" (onLoadList)="getGraphsTypeBlocco2($event)" [clearable]="false"></mdm-input-options-select>
                    <s1-button class="col-auto mt-search" [type]="s1ButtonType.View" (onClick)="getGraficoBlocco2()"></s1-button>
                </div>
                <div class="col-12 m-2 text-danger">
                    <ng-container *ngIf="hasErrorGraphsTypeBlocco2">{{'base.fields_required' | translate }}</ng-container>
                </div>
            </s1-card>
        </div>
    </div>
    <div class="row px-4" *ngIf="searchForm.controls.region.value && showSelectorCartsBlocco2 == 'SPESE_GIORNALIERE_NEGOZIO'">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="daysCartsCounterConfig" [plotType]="daysCartsCounterPlotType" [hasPages]="false" [hasModal]="false"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row px-4" *ngIf="searchForm.controls.region.value && showSelectorCartsBlocco2 == 'FATTURATO_NEGOZIO'">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="daysCartsRevenueConfig" [plotType]="daysCartsRevenuePlotType" [hasPages]="false" [hasModal]="false"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row px-4" *ngIf="searchForm.controls.region.value  && showSelectorCartsBlocco2 == 'RILETTURE_NEGOZIO'">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="daysRescansConfig" [plotType]="daysRescansPlotType" [hasPages]="false" [hasModal]="false"></mdm-dashboard-summary-card>
        </div>
    </div>
