import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { BrandsService } from '@app/shared/services/brands.service';

@Component({
  selector: 'mdm-input-brand-select',
  templateUrl: './input-brand-select.component.html',
  styleUrls: ['./input-brand-select.component.scss']
})
export class InputBrandSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() placeholder: string = '';
  @Input() basedOnAgent: boolean = false;
  @Input() set idAgent(newValue: number) {
    this.agentSelected = newValue != null;
    if (this.basedOnAgent && this.agentSelected) {
      this.getBrands(newValue);
    }
  }
  @Output() onSelectChange = new EventEmitter();

  agentSelected = false;
  brandsList: IS1InputSelectItem[];

  constructor(private brandsService: BrandsService) { }

  ngOnInit(): void {
    if (!this.basedOnAgent) {
      this.getBrands(); 
    }
  }

  private getBrands(idAgent?: number) {
    const params = {
      ...(idAgent) && { idAgent }
    }

    this.brandsService.getBrands(params, false).subscribe(brands => {
      this.brandsList = brands.map(brand => { 
        return { code: brand.id, label: brand.name } 
      })
    })

  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}