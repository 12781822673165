import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompaniesService } from '@app/shared/services/companies.service';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';

@Component({
  selector: 'mdm-input-company-select',
  templateUrl: './input-company-select.component.html',
  styleUrls: ['./input-company-select.component.scss']
})
export class InputCompanySelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Output() onSelectChange: EventEmitter<void> = new EventEmitter();

  companiesList: IS1InputSelectItem[];

  constructor(private companiesService: CompaniesService) { }

  ngOnInit(): void {
    this.getCompanies()
  }

  changed() {
    this.onSelectChange.emit();
  }

  private getCompanies() {
    this.companiesService.getCompanies(null, true, false).subscribe(companies => {
      this.companiesList = companies.map(company => { 
        return { code: company.id, label: company.name } 
      })
    })
  }

}
