import { Component, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { CompaniesService } from '@app/shared/services/companies.service';
import { IS1InputSelectItem  } from '@app/s1';
import { Router } from '@angular/router';

@Component({
    selector: 'app-rightsidebar',
    templateUrl: './rightsidebar.component.html',
    styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit, OnDestroy {
    
    currentTheme: any;
    selectedLanguage: string;
    companiesList: IS1InputSelectItem[];

    constructor(
        public settings: SettingsService,
        public themes: ThemesService,
        public translator: TranslatorService,
        public elem: ElementRef,
        public companiesService: CompaniesService,
        public router: Router
    ) {
        this.currentTheme = themes.getDefaultTheme();
        this.selectedLanguage = this.getLangs()[0].code;
    }

    ngOnInit() {
        this.anyClickClose();

        if ( this.settings.isSuperAdmin() || this.settings.isReseller() ) {
            this.getCompanies();
        }
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    anyClickClose() {
        document.addEventListener('click', this.checkCloseOffsidebar, false);
    }

    checkCloseOffsidebar = (e: PointerEvent) => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        /** The menu has to be closed only if the click event was fired outside an element in the menu-toggle */
        if (!contains && !e.target['id'].includes('menu-toggle')) {
            this.settings.setLayoutSetting('offsidebarOpen', false);
        }
    }

    selectedFilter( key: string , value: any, preventToggle: boolean = false) {
        this.settings.setFilterFromSidebar( key , value );

        if ( this.settings.getFilterOfSidebarByName('company')  && !preventToggle ) {
            this.settings.setLayoutSetting('offsidebarOpen', false);
        }

        this.settings.onSidebarFilterChange();
        
        if (this.router.url !== '/home') {
            this.router.navigate(['home']);
        }
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.checkCloseOffsidebar);
    }

    // ---------- PRIVATE METHODS ---------- //

    private getCompanies() {
        this.companiesService.getCompanies(null, true, false).subscribe(companies => {
            this.companiesList = companies.map(company => {
                return {
                    code: company.id ? company.id : null,
                    label: company.name ? company.name : null,
                    reseller: company.reseller ? company.reseller : null
                };
            });
            if (this.settings.isReseller()) {
                const resellerCompanyId = this.settings.getResellerCompanyId();
                const index = this.companiesList.findIndex(company => company.code ? company.code  === +resellerCompanyId : null);
                if (index) {
                    const resellerCompany = this.companiesList[index];
                    this.companiesList.splice(index, 1);
                    this.companiesList.unshift(resellerCompany);
                }
            }
            if ( !this.settings.getActiveFiltersOfSidebar() ) {
                this.selectedFilter( 'company' , this.companiesList[0], true );
            }
        });
    }

}
