import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1UIService } from '@app/s1';
import { IProfileDistribution, ProfileDistributionAction, ProfileDistributionStatus } from '@app/shared/models/profile';

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};

@Component({
  selector: 'mdm-profile-distribution-cell',
  templateUrl: './profile-distribution-cell.component.html',
  styleUrls: ['./profile-distribution-cell.component.scss']
})
export class ProfileDistributionCellComponent implements OnChanges {

  s1ButtonType = S1ButtonType;
  @Input() profileType: string;
  @Input() profile: IProfileDistribution;
  @Output() onLinkDetail = new EventEmitter<IProfileDistribution>();
  @Output() onDelete = new EventEmitter<IProfileDistribution>();
  tsNow: number;

  constructor(
    public settings: SettingsService,
    private ui: S1UIService
  ) {
    this.tsNow = Date.now();
  }
  
  ngOnChanges(changes: ComponentChanges<ProfileDistributionCellComponent>): void {
    this.profile = changes.profile.currentValue;
  }

  ngOnInit(): void {}

  goToDetail() {
    this.onLinkDetail.emit(this.profile);
  }

  askToDelete(profile: IProfileDistribution) {
    this.ui.showDialogPopup("profiles.distributions.popup.askDelete").then(result => {
      if (result.value) {
        this.onDelete.emit(profile);
      }
    });
  }

  canEdit(status: string, tsStart: number) {
    if (
      //status === ProfileDistributionStatus.WAITING || status === ProfileDistributionStatus.NOT_SCHEDULATED || 
      !tsStart || tsStart > this.tsNow) {
        return true;
    } else {
      return false;
    }
  }

  stylizeProgress(status: string) {
    let classes = "";
    if (status === ProfileDistributionStatus.DONE) {
      classes += 'text-success';
    } else if (status === ProfileDistributionStatus.ERROR) {
      classes += 'text-danger';
    } else if (status === ProfileDistributionStatus.RUNNING) {
      classes += 'text-primary';
    } else if (status === ProfileDistributionStatus.WAITING) {
      classes += 'text-primary';
    } else if (status === ProfileDistributionStatus.NOT_SCHEDULATED) {
      classes += 'text-muted';
    } else {
      classes += 'text-dark';
    }
    return classes;
  }

  iconProgress(status: string) {
    let classes = "";
    if (status === ProfileDistributionStatus.DONE) {
      classes += 'fa-2x fas fa-check';
    } else if (status === ProfileDistributionStatus.ERROR) {
      classes += 'fa-2x fas fa-exclamation-triangle';
    } else if (status === ProfileDistributionStatus.RUNNING) {
      classes += 'fa-2x fas fa-walking';
    } else if (status === ProfileDistributionStatus.WAITING) {
      classes += 'fa-2x far fa-clock';
    } else if (status === ProfileDistributionStatus.NOT_SCHEDULATED) {
      classes += 'fa-2x fas fa-stopwatch';
    } else {
      classes += 'text-dark';
    }
    return classes;
  }

  iconAction(action: string) {
    let classes = '';
    if (action === ProfileDistributionAction.UPGRADE) {
      classes += 'fa-2x fas fa-long-arrow-alt-up';
    } else if (action === ProfileDistributionAction.DOWNGRADE) {
      classes += 'fa-2x fas fa-long-arrow-alt-down';
    }
    return classes;
  }

}
