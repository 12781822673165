<s1-card [title]="'profiles.activation.sections.hierarchical.title'" [alignTitleCenter]="false">
  <ng-container header-right >
    <s1-button (onClick)="openSelection()" [type]="s1ButtonType.Add"></s1-button>
  </ng-container>
 
  <s1-table class="pt-1" #table [onlinePagination]="false">
    <thead header>
      <tr>
        <th>{{'profiles.activation.sections.hierarchical.table.columns.region' | translate}}</th>
        <th>{{'profiles.activation.sections.hierarchical.table.columns.site' | translate}}</th>
        <th>{{'profiles.activation.sections.hierarchical.table.columns.group' | translate}}</th>
        <th>{{'profiles.activation.sections.hierarchical.table.columns.brand' | translate}}</th>
        <th>{{'profiles.activation.sections.hierarchical.table.columns.model' | translate}}</th>
        <th>{{'profiles.activation.sections.hierarchical.table.columns.activationDate' | translate}}</th>
        <th></th>                                   
      </tr>
    </thead>
    <tbody body>
      <ng-container *ngFor="let activation of table.config.rows; let i = index">
        <tr>
          <td>{{ activation.nameRegion }}</td>
          <td>{{ activation.nameSite }}</td>
          <td>{{ activation.nameGroup }}</td>
          <td>{{ activation.nameBrand }}</td>
          <td>{{ activation.nameModel }}</td>
          <td>{{ activation.tsActivation | s1ReadableDate : true }}</td>
          <td class="text-left fit">
            <s1-button class="pr-1" (onClick)="showDetail(activation)" [type]="s1ButtonType.View"></s1-button>
            <s1-button class="pr-1" (onClick)="showDatePicker(activation)" [type]="s1ButtonType.Normal" [icon]="'fas fa-calendar-alt'" [onlyIcon]="true"></s1-button>
            <s1-button (onClick)="askDeleteRule(activation)" [type]="s1ButtonType.Delete"></s1-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </s1-table>
</s1-card>

<mdm-hierarchical-selection-modal #hierarchicalSelectionModal (hierarchicalSelected)="addRule($event)"></mdm-hierarchical-selection-modal>

<mdm-profile-activation-date-modal #activationDateModal (profileUpdated)="updateProfile($event)"></mdm-profile-activation-date-modal>
