import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 's1ServerDate'
})
export class S1ServerDatePipe implements PipeTransform  {

  constructor(private datePipe: DatePipe) { }

  transform(date: Date, ...args: unknown[]): string {

    if (!date) {
      return null;
    }

    let onlyDayDateString = this.datePipe.transform(date, 'yyyy-MM-dd');

    onlyDayDateString += 'T00:00:00.000Z';

    return onlyDayDateString;

  }

}
