import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ChartGaugeConfig } from 'src/app/shared/components/chart-gauge/chart-gauge.component';
import { ChartColors } from 'src/app/shared/components/chart-bar/chart-bar.component';
import { SummaryBadgeType, SummaryBadgeConfig } from 'src/app/shared/components/summary-badge/summary-badge.component';
import { ChartPieConfig } from 'src/app/shared/components/chart-pie/chart-pie.component';
import { DatePipe } from '@angular/common';
import { DispensingService } from '@app/shared/services/dispensing.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { UnitType } from '@app/shared/models/models';
import { S1UIService } from '@app/s1';

@Component({
  selector: 'dashboard-dispansing',
  templateUrl: './dashboard-dispansing.component.html',
  styleUrls: ['./dashboard-dispansing.component.scss']
})

export class DashboardDispansingComponent implements OnInit {

  date: any;
  now: any;

  badgesKiosk: SummaryBadgeConfig[];
  pieKiosk: ChartPieConfig;

  badgesUnit: SummaryBadgeConfig[];
  pieUnit: ChartPieConfig;

  gaugeKiosk: ChartGaugeConfig;
  gaugeUnit: ChartGaugeConfig;

  chartUnitsConfig: any;
  chartUnitsPlotType: any;

  chartPrelieviConfig: any;
  chartPrelieviPlotType: any;

  prelieviList: any[] = [];
  unitsList: any[] = [];

  summaryBadgeType = SummaryBadgeType;
  unitType = UnitType;

  showMore = false;

  constructor(
    private dispensingService: DispensingService,
    public settings: SettingsService,
    public route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ui: S1UIService
  ) {
    this.now = Date.now();
    this.date = Date.now();

    this.gaugeKiosk = { total: 0, value: 0, color: ChartColors.info, label: 'Kiosk' };
    this.gaugeUnit = { total: 0, value: 0, color: ChartColors.info, label: 'Unit' };
    this.badgesKiosk = [];
    this.pieKiosk = { datasets: [], labels: [] };
    this.badgesUnit = [];
    this.pieUnit = { datasets: [], labels: [] };
  }

  async ngOnInit(): Promise<void> {
    await this.loadChart();
  }

  async loadChart(date?: number) {
    this.ui.showSpinner();

    const params: any = {
      idCompany: this.settings.getCompany().code,
      idRegion: null,
      idSite: null,
    };

    let dateFrom = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');

    if (!date) {
      this.dispensingService.getDashboardCharts(params, false).subscribe(charts => {
        this.mapGaugeCharts(charts);
      });
    } else {
      dateFrom = this.datePipe.transform(date, 'yyyy-MM-dd');
      params.dateFrom = dateFrom;
      params.delta = 0; // Numero di elementi per Prelievi
      await this.dispensingService.getDashboardPrelievi(params, false).toPromise().then(prelievi => {
        this.mapBarChartPrelievi(prelievi);
      });
    }

    this.ui.closeSpinner();
  }

  async loadSummaryCharts(date?: number) {
    this.ui.showSpinner();

    let dateFrom = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    if (date > 0) {
      dateFrom = this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    const params: any = {
      idCompany: this.settings.getCompany().code,
      idRegion: null,
      idSite: null,
    };

    if (!date) {
      params.delta = 0; // Numero di elementi di untis
      await this.dispensingService.getDashboardUnits(params, false).toPromise().then(units => {
        this.mapBarChartUnits(units);
      });
    }

    params.dateFrom = dateFrom;
    params.delta = 0; // Numero di elementi per Prelievi
    await this.dispensingService.getDashboardPrelievi(params, false).toPromise().then(prelievi => {
      this.mapBarChartPrelievi(prelievi);
    });

    this.ui.closeSpinner();
  }

  async getSitesUnitsInfo(date?: number) {
    this.showMore = !this.showMore;
    if (!this.chartUnitsConfig && !this.chartPrelieviConfig) {
      await this.loadSummaryCharts(date);
    }
  }

  mapGaugeCharts(data: any[]) {
    data?.forEach(result => {
      if (result.type === 'ENTRANCE_UNIT') { // Kiosk

        this.gaugeKiosk = { total: result.countTotal, value: result.countOk, color: ChartColors.info, label: 'Kiosk' };
        this.pieKiosk = {
          datasets: [{
            data: [result.countOk, result.countWarn, result.countKo],
            backgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
            hoverBackgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
            hoverBorderColor: [ChartColors.success, ChartColors.warning, ChartColors.danger]
          }],
          labels: ['OK', 'WARNING', 'ERROR']
        };
        this.badgesKiosk = [
          { type: SummaryBadgeType.STATUS_OK, value: result.countOk, label: 'OK' },
          { type: SummaryBadgeType.STATUS_WARNING, value: result.countWarn, label: 'WARNING' },
          { type: SummaryBadgeType.STATUS_ERROR, value: result.countKo, label: 'ERROR' }
        ];

      } else if (result.type === 'MOBILE') { // Unit

        this.gaugeUnit = { total: result.countTotal, value: result.countOk, color: ChartColors.info, label: 'Unit' };
        this.pieUnit = {
          datasets: [{
            data: [result.countOk, result.countWarn, result.countKo],
            backgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
            hoverBackgroundColor: [ChartColors.success, ChartColors.warning, ChartColors.danger],
            hoverBorderColor: [ChartColors.success, ChartColors.warning, ChartColors.danger]
          }],
          labels: ['OK', 'WARNING', 'ERROR']
        };
        this.badgesUnit = [
          { type: SummaryBadgeType.OK, value: result.countOk, label: 'OK' },
          { type: SummaryBadgeType.STATUS_WARNING, value: result.countWarn, label: 'WARNING' },
          { type: SummaryBadgeType.ERROR, value: result.countKo, label: 'ERROR' }
        ];

      }
    });
  }

  mapBarChartUnits(data: any[]) {
    const labelXAxis: any[] = [];
    const okList: number[] = [];
    const warningList: number[] = [];
    const koList: number[] = [];
    this.unitsList = data;

    this.unitsList?.forEach(site => {
      // spezzo la stringa label in righi da 3 parole
      const labelArray: string[] = [];
      let labelArraytemp: string[] = [];

      labelArraytemp = site.name.split(' ');
      for (let i = 0; i < labelArraytemp.length; ) {
        let label = '';
        if (i < labelArraytemp.length) { label += labelArraytemp[i]; }
        i++;
        if (i < labelArraytemp.length) { label += ' ' + labelArraytemp[i]; }
        i++;
        if (i < labelArraytemp.length) { label += ' ' + labelArraytemp[i]; }
        i++;
        labelArray.push(label);
      }

      labelXAxis.push(labelArray);
      okList.push(site.countUnitOk);
      warningList.push(site.countUnitWarn);
      koList.push(site.countUnitKo);
    });

    for (let i = this.unitsList?.length; i < 10; i++) {
      labelXAxis.push('');
      okList.push(0);
      koList.push(0);
    }

    const formattedSites = this.unitsList?.map(u => {
      return { idSite: u.id, nameSite: u.name, idRegion: u.idRegion };
    });

    const formattedUnitList = [
      { color: '#27c24c', filters: {}, sites: formattedSites, data: this.unitsList?.map((u, i) => [ u.name, okList[i] ]) },
      { color: '#0383ba', filters: {}, sites: formattedSites, data: this.unitsList?.map((u, i) => [ u.name, warningList[i] ]) },
      { color: '#f05050', filters: {}, sites: formattedSites, data: this.unitsList?.map((u, i) => [ u.name, koList[i] ]) },
    ];

    this.chartUnitsPlotType = {
      plotType: 'bar',
      numCategories: 10
    };

    this.chartUnitsConfig = {
      data: formattedUnitList,
      info: {
        title: 'dispensing.home.charts.units',
        link: null,
        filterLabel: '',
        filters: [
          { type: 'Ok', count: '', style: 'success' },
          { type: 'Warning', count: '', style: 'warning' },
          { type: 'Error', count: '', style: 'danger' }
        ]
      },
      modal: {
        title: 'dispensing.home.charts.units',
        sites: formattedSites,
        columns: [
          { type: 'Ok', style: 'bg-success', filter: {}, data: this.unitsList?.map((u, i) => [ u.name, okList[i] ]) },
          { type: 'Warning', style: 'bg-warning', filter: {}, data: this.unitsList?.map((u, i) => [ u.name, warningList[i] ]) },
          { type: 'Error', style: 'bg-danger', filter: {}, data: this.unitsList?.map((u, i) => [ u.name, koList[i] ]) }
        ]
      }
    };
  }

  mapBarChartPrelievi(data: any[]) {
    const labelXAxis: string[] = [];
    const pickedList: number[] = [];
    const missingList: number[] = [];
    this.prelieviList = data;

    this.prelieviList?.forEach(site => {
      labelXAxis.push(site.name);
      pickedList.push(site.countPrelievi);
      missingList.push(site.countMissingPrelievi);
    });

    const formattedPrelievi = this.prelieviList.map(p => {
      return { idSite: p.id, nameSite: p.name, idRegion: p.idRegion };
    });

    const formattedPrelieviList = [
      { color: '#0383ba', filters: {}, sites: formattedPrelievi, data: this.prelieviList?.map((u, i) => [ u.name, pickedList[i] ]) },
      { color: '#f05050', filters: {}, sites: formattedPrelievi, data: this.prelieviList?.map((u, i) => [ u.name, missingList[i] ]) }
    ];

    this.chartPrelieviPlotType = {
      plotType: 'bar',
      numCategories: 10
    };

    this.chartPrelieviConfig = {
      data: formattedPrelieviList,
      info: {
        title: 'dispensing.home.charts.pickup',
        link: null,
        filterLabel: '',
        filters: [
          { type: 'Picked', count: '', style: 'info' },
          { type: 'Error', count: '', style: 'danger' }
        ]
      },
      modal: {
        title: 'dispensing.home.charts.pickup',
        sites: formattedPrelievi,
        columns: [
          { type: 'Picked', style: 'bg-info', filter: {}, data: this.prelieviList?.map((u, i) => [ u.name, pickedList[i] ]) },
          { type: 'Error', style: 'bg-danger', filter: {}, data: this.prelieviList?.map((u, i) => [ u.name, missingList[i] ]) }
        ]
      }
    };
  }

  pieClicked(type: UnitType, statusIndex: number) {
    const filters = {
      statusLevel: this.pieKiosk.labels[statusIndex],
      unitType: type
    };
    const params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    this.router.navigate(['/dispensing/units'], {state: { filters:  params }});
  }

  unitAction(event, fromModal: boolean) {
    const filters = {
      idRegion: fromModal ? event?.idRegion : event?.series?.sites[event.dataIndex].idRegion,
      idSite: fromModal ? event?.idSite : event?.series?.sites[event.dataIndex].idSite,
      statusLevel: this.pieKiosk.labels[event.seriesIndex],
      unitType: UnitType.UNIT
    };
    const params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    this.router.navigate(['/dispensing/units'], {state: { filters:  params }});
  }

  prelieviAction(event) {
    const selected = this.prelieviList[event.seriesIndex];
    const filters = {
      idRegion: selected.idRegion,
      idSite: selected.id,
    };
    const params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    this.router.navigate(['/dispensing/dashboard'], {state: { filters:  params }});
  }

}
