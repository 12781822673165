<s1-modal #modal [title]="'profiles.activation.sections.date.title'">
  <span class="h4 text-warning">Dati mockati</span>
  <s1-card>
    <form [formGroup]="dateForm">
      <div class="row">
        <s1-input-datepicker class="col-6 pt-4" [control]="dateForm.controls.date"></s1-input-datepicker>
        <timepicker class="col-6" [formControl]="dateForm.controls.time" [showMeridian]="false"></timepicker>
      </div>
    </form>
    <div class="col-12 text-center mt-1">
      <s1-button [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
    </div>
  </s1-card>
</s1-modal>
