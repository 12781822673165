import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
    selector: 'time-icon',
    templateUrl: './time-icon.component.html',
    styleUrls: ['./time-icon.component.scss']
})

export class TimeIconComponent implements OnChanges {

  @Input() timestamp: number;
  style: string = 'far fa-hourglass-end fa-2x text-dark'
  
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.style = this.getTimeStyle();
  }

  getTimeStyle():string {
    let now = new Date().getTime();
    let seconds = Math.floor(now - this.timestamp);
    seconds /= 1000;


    if (seconds < 0) {
      return 'far fa-hourglass-end text-dark';
    }
    if (seconds < 480) {
      return 'fas fa-hourglass-start text-success'; //fa-2x
    }
    if (seconds < 720) {
      return 'fas fa-hourglass-half text-warning';
    }
    return 'fas fa-hourglass-end text-danger';
  }

}
