<s1-modal #modal [title]="'aeapplications.list.title_permissions'">
  
  <s1-card>
    <form [formGroup]="permissionForm">
      <div class="row text-left">
        <mdm-input-options-select class="col-12 mb-2" [control]="permissionForm.controls.policy" [label]="'aeapplications.list.table.columns.policy'" [options]="policyOptions" [bindAllObject]="false"></mdm-input-options-select>
        <s1-input-text class="col-12 mb-2" [control]="permissionForm.controls.permission" [label]="'aeapplications.list.table.columns.permission'" [labelGrouped]="false"></s1-input-text>
      
        <div class="col-12 mb-2 text-center">
          <s1-button [type]="s1ButtonType.Save" [disabled]="!permissionForm.valid" (onClick)="save()"></s1-button>
        </div>
      </div>
    </form>
  </s1-card>

</s1-modal>