<s1-card [cardStyle]="(level % 2 == 0) ? 'assets-card' : ''">
  <div class="row p-1">
    <div class="col-auto text-center" *ngIf="editMode">
      <em *ngIf="count!==1 && asset.installationOrder===1" class="ml-2 mt-2 fa fas fa-minus"></em>
      <em *ngIf="count!==1 && asset.installationOrder!==1" class="ml-2 mt-2 fa-2x fas fa-angle-up text-primary pointer" (click)="upAsset(asset)"></em>
      <span class="ml-2 d-block"></span>
      <em *ngIf="count!==1 && asset.installationOrder===count" class="ml-2 fa fas fa-minus" ></em>
      <em *ngIf="count!==1 && asset.installationOrder!==count" class="ml-2 fa-2x fas fa-angle-down text-danger pointer" (click)="downAsset(asset)"></em>
    </div>
    <div class="col-1 text-center">
      <i class="pt-3 fas fa-file fa-2x" *ngIf="isType('File')"></i>
      <i class="pt-3 fas fa-mobile fa-2x" *ngIf="isType('App')"></i>
      <i class="pt-3 fas fa-toolbox fa-2x" *ngIf="isType('Tool')"></i>
      <i class="pt-3 fa-google-play fab fa-2x" *ngIf="isType('Os')"></i>
      <span class="d-block">{{ asset.type }}</span>
    </div>
    <div class="text-left" [ngClass]="(editMode) ? 'col-3' : 'col-4'">
      <p class="mb-0 font-weight-bold">{{ 'assets.list.table.columns.name' | translate }}</p>
      <p class="mb-1">{{ asset.name }}</p>
      <p class="mb-0 font-weight-bold">{{ 'profiles.asset.table.columns.description' | translate }}</p>
      <p class="mb-0">{{ asset.description }}</p>
    </div>
    <div class="text-left" [ngClass]="(editMode) ? 'col-5' : 'col-6'">
      <p class="mb-0 font-weight-bold">{{ 'profiles.asset.table.columns.source' | translate }}</p>
      <p class="mb-1"><em class="fa mr-2 fas fa-link"></em>{{ asset.source || '---' }}</p>
      <p class="mb-0 font-weight-bold" [style.display]="isType('File') ? 'block' : 'none' ">{{ 'profiles.asset.table.columns.target' | translate }}</p>
      <p class="mb-0 " [style.display]="isType('File') ? 'block' : 'none' "><em class="fa mr-2 fas fa-folder"></em>{{ asset.target || '---' }}</p>
      <p class="mb-0 font-weight-bold" [style.display]="isType('App') ? 'block' : 'none' ">{{ 'profiles.asset.table.columns.package_name' | translate }}</p>
      <p class="mb-0 " [style.display]="isType('App') ? 'block' : 'none' "><em class="fa mr-2 fas fa-folder"></em>{{ asset.packageName || '---' }}</p>
      <p class="mb-0 font-weight-bold" [style.display]="isType('Tool') ? 'block' : 'none' ">{{ 'profiles.asset.table.columns.execute_with' | translate }}</p>
      <p class="mb-0 " [style.display]="isType('Tool') ? 'block' : 'none' "><em class="fa mr-2 fas fa-user"></em>{{ asset.executeWith || '---'}}</p>
    </div>
    <div class="col-2 mt-2 text-right" *ngIf="editMode">
      <div class="btn-group pr-2" dropdown>
        <button class="btn px-2 shadow-sm btn-outline-primary dropdown-toggle" type="button" dropdownToggle>
          <i class="fa fas fa-exchange-alt"></i> 
        </button>
        <div *dropdownMenu class="p-0 dropdown-menu dropdown-menu-right">
            <a class="dropdown-item pointer bg-primary" (click)="replaceAssetFromSearch(asset)">
              <em class="fa fas fa-exchange-alt mr-1"></em>{{ 'base.replace_from_available_assets' | translate}}
            </a>
            <a class="dropdown-item pointer bg-success" (click)="replaceAssetFromNew(asset)">
              <em class="fa fas fa-exchange-alt mr-1"></em>{{ 'base.replace_with_new_asset' | translate}}
            </a>
        </div>
      </div>
      <s1-button [type]="s1ButtonType.Delete" [onlyIcon]="true" [outline]="true" (onClick)="askDelete(asset)"></s1-button>
    </div>
  </div>

</s1-card>

<mdm-asset-modal #assetModal (assetUpdated)="updateAsset($event)"></mdm-asset-modal>
