<s1-modal #modal [title]="">
  <span class="h4 text-warning">Dati mockati</span>
  <s1-card>
    <form [formGroup]="searchForm">
      <div class="row text-left pl-2">
        <mdm-input-region-select class="col-3 mb-0" [control]="searchForm.controls.region" [idCompany]="settings.getCompany()?.code"
          [siteControl]="searchForm.controls.site"></mdm-input-region-select>
        <mdm-input-site-select class="col-3 mb-0" [control]="searchForm.controls.site" [idRegion]="searchForm.controls.region.value?.code"></mdm-input-site-select>
        <mdm-input-group-select class="col-3 mb-0" [control]="searchForm.controls.group" [idCompany]="settings.getCompany()?.code"></mdm-input-group-select>
        <div class="w-100"></div>
        <mdm-input-brand-select class="col-3 mb-0" [control]="searchForm.controls.brand"></mdm-input-brand-select>
        <mdm-input-model-select class="col-3 mb-0" [control]="searchForm.controls.model" [idBrand]="searchForm.controls.brand.value?.code"></mdm-input-model-select>
      </div>
      <div class="col-12 text-center mt-1 mb-2">
        <s1-button [type]="s1ButtonType.Add" (onClick)="add()"></s1-button>
      </div>
    </form>
  </s1-card>

</s1-modal>