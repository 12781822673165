import { Component, OnInit, ViewChild, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    selectedLanguage = "";

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    isSuperAdmin : boolean = false;
    sidebarEventSubscription : Subscription;
    flagSsoCompany = false;

    constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, public injector: Injector, public translator: TranslatorService) {

        // show only a few items on demo
        // this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.selectedLanguage = this.translator.getLanguageInUse()

        this.isSuperAdmin = this.settings.isSuperAdmin();
        console.log('this.menuItems' , this.menuItems)
    }

    ngOnInit() {
        this.isNavSearchVisible = false;

        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        /*const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el)
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        });*/

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

        this.sidebarEventSubscription = this.settings.sidebarChanged.subscribe(() => {
            this.flagSsoCompany = this.settings.getFlgSsoCompany(this.settings.getCompany()?.code);
        });
    }

    ngOnDestroy(): void {
        this.sidebarEventSubscription?.unsubscribe();
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    // Language
    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
        this.selectedLanguage = value;
    }

    // Logout
    logout() {
        // Salvo le informazioni relative allo stato del filtro della sidebar
        var _c = localStorage.getItem('filterOption');
        this.settings.sessionExpired();
        localStorage.setItem('filterOption' , _c);
        this.router.navigate(["/login/0"]);
    }

    changePassword() {
        this.router.navigate(["/userprofile/change-password"]);
    }
}
