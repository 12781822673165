import { Component } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginData } from '../../../../app/core/classes/loginData.module';
import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '@app/core/translator/translator.service';
import swal from 'sweetalert2';
import { S1UIService } from '@app/s1';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent  {

  loginForm: UntypedFormGroup;

  // params from path
  paramId;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;

  constructor(
    public settings: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private translator: TranslatorService,
    private ui: S1UIService
  ) {

      // get text message
      //this.errorTranslation =  this.translate.get('error');
      this.translate.get('error').subscribe( (text) => {
          this.errorTranslation = text;
      });

      //this.msgTranslation =  this.translate.get('msg');
      this.translate.get('msg').subscribe( (text) => {
          this.msgTranslation = text;
      });

      this.paramId = this.route.params.subscribe(params => {
          const id = params['id']; // (+) converts string 'id' to a number
          if (id != null ) { this.errorMessage = this.msgTranslation?.logout_ok; }
      });

      this.loginForm = this.formBuilder.group({
          'username': [null, Validators.required],
          'password': [null, Validators.required]
      });

  }

  /** Spinner is closed in different situations to make it always available when needed */
  onSubmit(): void {
      this.ui.closeSpinner();
      const lData = new LoginData(this.loginForm.value.username, this.loginForm.value.password);
      this.ui.showSpinner();
      this.authService.login(lData).subscribe(
        (response) => {
          /** In the response the alert must not be closed, in order to be managed only after dashboard loading */
          if (response.outcome.success === true) {
            this.translator.useLanguage(this.translator.defaultLanguage)
            this.router.navigate(['/home']);
          } else {
            this.ui.closeSpinner();
            swal.fire(this.msgTranslation.error, this.settings.manageErrorMsg(response.outcome), "error");
            switch (response.outcome.code) {
              case '0004': {
                this.router.navigate(["/renew/" + response.data?.passwordExpiredToken]);
              }
            }
          }
        },
        (error) => {
          this.ui.closeSpinner();
          this.logger.log("Error", error, 200);
          this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
          swal.fire(this.msgTranslation.error, this.errorMessage, "error");
        }
      );
  }

}
