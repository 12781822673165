import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1UIService, S1ButtonType, S1Table } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { IGroupsSearchParams } from '@app/shared/services/groups.service';
import { ICompany } from '@app/shared/models/models';
import { CompaniesService } from '@app/shared/services/companies.service';

@Component({
  selector: 'mdm-reseller-company-selection',
  templateUrl: './reseller-company-selection.component.html',
  styleUrls: ['./reseller-company-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResellerCompanySelectionComponent implements OnInit { //, IS1Modal {

  @Input() company: ICompany;
  @Output() onCompanySelected = new EventEmitter<ICompany>();
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;
  
  constructor(public settings: SettingsService, private companiesService: CompaniesService) {}

  ngOnInit(): void {
    this.getCompanies();
  }

  refresh() {
    this.getCompanies();
  }

  getCompanies() {
    this.companiesService.getResellerCompanies().subscribe(resellers => {
      this.table.config.pagination.page.itemsPerPage = 5;
      resellers = resellers.filter(reseller => reseller.id != this.company.id);
      this.table.updateData(resellers);

      let selected = resellers.find(reseller => reseller.id == this.company.idResellerCompany);
      if(selected) {
        this.updateCompany(selected, false);
      }
    });
  }

  updateCompany(reseller: ICompany, remove: boolean) {
    this.company.idResellerCompany = remove ? 0 : reseller.id;
    this.company.codeResellerCompany = remove ? '' : reseller.code;
    this.onCompanySelected.emit(this.company);
  }

}
