import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { S1ButtonType, S1UIService } from '@app/s1';
import { IAsset } from '@app/shared/models/models';
import { TranslateService } from '@ngx-translate/core';
import { AssetModalComponent } from '../asset-modal/asset-modal.component';

@Component({
  selector: 'mdm-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssetCardComponent implements OnInit {

  @Input() asset: IAsset;
  @Input() level: number = 0;
  @Input() count: number = 0;
  @Input() editMode: boolean = true;
  @Output() assetUpdated = new EventEmitter<IAsset>();
  @Output() assetMovedUp = new EventEmitter<IAsset>();
  @Output() assetMovedDown = new EventEmitter<IAsset>();
  @Output() assetDeleted = new EventEmitter<IAsset>();
  @Output() assetReplacedFromSearch = new EventEmitter<IAsset>();
  @Output() assetReplacedFromNew = new EventEmitter<IAsset>();
  @ViewChild('assetModal') assetModal: AssetModalComponent;
  s1ButtonType = S1ButtonType;

  constructor(private translate: TranslateService, private ui: S1UIService) { }

  ngOnInit(): void { }

  isType(type: string) {
    return this.asset?.type?.toUpperCase() == type.toUpperCase();
  }

  openModal(asset?: IAsset) {
    this.assetModal.open(asset);
  }

  updateAsset(assetToUpdate: IAsset) {
    this.assetUpdated.emit(assetToUpdate);
  }
  
  upAsset(assetToUpdate: IAsset) { 
    this.assetMovedUp.emit(assetToUpdate);
  }
  
  downAsset(assetToUpdate: IAsset) { 
    this.assetMovedDown.emit(assetToUpdate);
  }
  
  replaceAssetFromSearch(assetToReplace: IAsset) {
    this.assetReplacedFromSearch.emit(assetToReplace);
  }
  
  replaceAssetFromNew(assetToReplace: IAsset) {
    this.assetReplacedFromNew.emit(assetToReplace);
  }

  askDelete(asset?: IAsset) {
    let options = {
      'no': this.translate.instant("profiles.asset.popup.askDeleteAssetNo"),
      'yes': this.translate.instant("profiles.asset.popup.askDeleteAssetYes")
    };

    let dialog;
    if(asset.isRemovable) {
      dialog = this.ui.showDialogWithCheckPopup("profiles.asset.popup.askDelete", options);
    } else {
      dialog = this.ui.showDialogPopup("profiles.asset.popup.askDelete");
    }
    dialog.then(result => {
      if (result.value) {
        console.log(result);
        asset.confirmRemotion = result.value === "yes";
        this.deleteAsset(asset);
      }
    });
  }

  private deleteAsset(assetToDelete: IAsset) {
    this.assetDeleted.emit(assetToDelete);
  }

}
