import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { S1Modal, IS1Modal, S1ModalSizes, S1ButtonType, S1ReadableDatePipe } from '@app/s1';
import { IUnit } from '@app/shared/models/models';
import { IProfileSetting } from '@app/shared/models/profile';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { UnitsService } from '@app/shared/services/units.service';

@Component({
  selector: 'mdm-profile-settings-modal',
  templateUrl: './profile-settings-modal.component.html',
  styleUrls: ['./profile-settings-modal.component.scss']
})
export class ProfileSettingsModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  s1ButtonType = S1ButtonType;
  unit: IUnit;
  settingProfile: IProfileSetting;
  activeProfile: IProfileSetting;
  profileForm: UntypedFormGroup;
  nameType = 'Settings';

  constructor(private readableDatePipe: S1ReadableDatePipe, private formBuilder: UntypedFormBuilder, private unitsService: UnitsService, private profilesService: ProfilesService) {
    this.profileForm = this.formBuilder.group({
      region: null,
      site: null,
      group: null,
      type: [null, Validators.required],
      code: null,
      description: null,
      startdate: null,
      starttime: null,
      enddate: null,
      endtime: null,
      company: null
    });
  }

  ngOnInit(): void { }

  getSite(sites) {
    if(this.profileForm.controls.region.value) {
      let site = sites.find(site => site.code == this.settingProfile?.idSite,);
      if(site) {
         this.profileForm.patchValue({
          site: this.settingProfile?.idSite,
        });
      }
    }
  }

  open(settingProfile?: IProfileSetting, unit?: IUnit) {
    this.settingProfile = settingProfile;
    this.activeProfile = settingProfile;
    this.unit = unit;
    this.updateForm();
    this.modal.open(S1ModalSizes.XL);
  }
  
  detail(settingProfile?: IProfileSetting) {
    setTimeout(() => {
      if(settingProfile) {
        let history = this.settingProfile.history;
        let assigned = this.settingProfile.assigned;
        let canceled = this.settingProfile.canceled;
        this.settingProfile = settingProfile;
        this.settingProfile.history = history;
        this.settingProfile.assigned = assigned;
        this.settingProfile.canceled = canceled;
      } else {
        this.settingProfile = this.activeProfile;
      }
      this.updateForm();
    }, 0);
  }  

  refresh(viewPendingProfile) {
    if(this.unit.idSettingsProfile || this.unit.idSettingsProfilePending) {
      this.unitsService.getUnitProfileSetting(this.unit).subscribe(setting => {
        this.settingProfile = setting;
        this.activeProfile = setting;
        this.settingProfile.viewPendingProfile = viewPendingProfile;
        this.updateForm();
      });
    }
  }
  
  changeProfileStatusInError(idUnitProfile: number) {
    this.profilesService.forceProfileStatusError(idUnitProfile).subscribe(result => {
      this.refresh(this.settingProfile.viewPendingProfile);
    });
  }

  private updateForm() {
    this.profileForm.patchValue({
      region: this.settingProfile?.idRegion,
      site: this.settingProfile?.idSite,
      group: this.settingProfile?.idGroup,
      type: this.nameType,
      code: this.settingProfile?.codeUnit,
      company: this.settingProfile?.idCompany,
      description: this.settingProfile?.description,
      startdate: this.settingProfile?.tsStart && this.readableDatePipe.transform(new Date(this.settingProfile?.tsStart), true),
      starttime: null,
      enddate: this.settingProfile?.tsEnd && this.readableDatePipe.transform(new Date(this.settingProfile?.tsEnd), true),
      endtime: null
    })
  }

  close() {
    this.settingProfile = null;
    this.modal.close();
  }

}
