import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';
import { SitesService, ISitesSearchParams } from '@app/shared/services/sites.service';

@Component({
  selector: 'mdm-input-site-select',
  templateUrl: './input-site-select.component.html',
  styleUrls: ['./input-site-select.component.scss']
})
export class InputSiteSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() set idRegion(newValue: number) {
    this.regionSelected = newValue != null;
    this.getSites(newValue);
  }
  @Input() bindAllObject = true;
  @Input() readonly = false;
  @Input() showAll = false;
  @Input() placeholder = '';
  @Input() disableSelected = false;
  @Input() clearable: boolean = true;
  @Input() selectedSites: number[] = [];
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Output() onSelectChange = new EventEmitter();

  regionSelected = false;
  sitesList: IS1InputSelectItem[];
  isAdminOrSuper: boolean;

  constructor(private sitesService: SitesService, public settingsService: SettingsService) {
    this.isAdminOrSuper = this.settingsService.isSuperAdmin() || this.settingsService.isReseller() || this.settingsService.isAdmin();
  }

  ngOnInit(): void {
    if (!this.regionSelected) {
      this.getSites();
    }
  }

  ngOnChanges(): void {
    if (this.sitesList) {
      const list = this.sitesList.map(s => { s.disabled = this.selectedSites.includes(s.code as number); return s });
      this.sitesList = [...list];
    }
  }

  private getSites(idRegion?: number) {
    if (idRegion || this.showAll) {
      const params: ISitesSearchParams = {
        code: null,
        name: null,
        idCompany: this.settingsService.getCompany().code,
        idRegion
      };

      this.sitesService.getSites(params, true, false).subscribe(sites => {
        this.sitesList = sites.map(site => {
          return { code: site.id, label: site.name, disabled: (this.disableSelected && this.selectedSites.includes(site.id)) };
        });
  
        // autoselection when items == 1
        this.control.enable();
        if (this.settingsService.isOperatore() || this.settingsService.isOperatoreRO()) {
          if (this.sitesList.length === 1 && this.regionSelected) {
            this.bindAllObject ? this.control.patchValue(this.sitesList[0]) : this.control.setValue(Number([this.sitesList[0].code]));
            this.control.disable();
          }
        }

        this.onLoadList.emit(this.sitesList);
      });
    } else {
      this.onLoadList.emit();
    }
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
