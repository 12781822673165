<s1-modal #modal [title]="'units.list.title'" (onClose)="close()">

  <s1-card class="text-left">
    <s1-table #table [onlinePagination]="false">
      <tbody body>
        <tr *ngFor="let unit of table.config.rows; let i = index">
          <td>
            <div class="row">
              <div class="col py-2">
                <mdm-unit-cell [modal]="true" [unit]="unit" template="assets" [showButtons]="false"></mdm-unit-cell>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>

</s1-modal>