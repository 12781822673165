import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem, S1ButtonType, S1UIService } from '@app/s1';
import { IAsset } from '@app/shared/models/models';
import { ProfileTypeName } from '@app/shared/models/profile';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { Observable } from 'rxjs';
import { AssetModalComponent } from '../asset-modal/asset-modal.component';
@Component({
  selector: 'mdm-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent {

  @Input() editMode: boolean = true;
  @Input() newMode: boolean = false;
  @Input() distributionMode: string; //all, os, agent, ae, null(off)
  @Input() readonlyMode: boolean = false;
  @Input() profileForm: UntypedFormGroup;
  @Input() idProfile: number;
  @Output() onSave = new EventEmitter<UntypedFormGroup>();
  @Output() onLoadSiteList = new EventEmitter<IS1InputSelectItem[]>();

  idCompany: number;
  idType: number;
  s1ButtonType = S1ButtonType;
  @ViewChild('addAssetModal') addAssetModal: AssetModalComponent;
  minDate: Date = new Date();

  isSuperAdminOrReseller : boolean = false;

  constructor(
    public settings: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private profilesService: ProfilesService,
    private ui: S1UIService
  ) {
    this.idCompany = settings.getCompany().code;
    this.profileForm = this.formBuilder.group({
      region: this.isCompanyUser() ? null : [null, Validators.required],
      site: this.isRegionUser() ? null : [null, Validators.required],
      group: this.isGroupUser() ? null : [null, Validators.required],
      type: [null, Validators.required],
      profile: [null, Validators.required],
      action: 'UPGRADE',
      code: null,
      description: null,
      reboot: false,
      startdate: null,
      starttime: null,
      enddate: null,
      endtime: null,
      company: this.settings.getCompany()
    });
    this.isSuperAdminOrReseller = this.settings.isSuperAdmin() || this.settings.isReseller();
  }

  isCompanyUser() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0;
  }

  isRegionUser() {
    return !this.settings.isOperatore() || this.settings.getSites()?.length==0;
  }

  isGroupUser() {
    return !this.settings.isOperatore() || this.settings.getGroups()?.length==0;
  }

  isAllowedRegion() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0 || this.settings.getRegions()?.filter(region => region.code == this.profileForm.controls.region.value).length > 0;
  }

  isVisibleSave(){
    switch(this.profileForm.controls.type.value?.toLowerCase()) {
      case (!this.readonlyMode && ProfileTypeName.androidEnterprise.toLowerCase()): {
        return false;
        break;
      }
      case (!this.readonlyMode && ProfileTypeName.fileManager.toLowerCase()): {
        return true;
        break;
      }
      case (!this.readonlyMode && ProfileTypeName.settings.toLowerCase()): {
        return !this.editMode;
        break;
      }
      case (!this.readonlyMode && ProfileTypeName.network.toLowerCase()): {
        return !this.editMode;
        break;
      }
      default: {
        return false;
        break;
      }
    }

  }
  isVisibleFiledsFirstStep():boolean{
    switch(this.profileForm.controls.type.value?.toLowerCase()) {
      case (ProfileTypeName.androidEnterprise.toLowerCase()): {
        return false;
        break;
      }
      case (ProfileTypeName.fileManager.toLowerCase()): {
        return true;
        break;
      }
      case (ProfileTypeName.settings.toLowerCase()): {
        return true;
        break;
      }
      case (ProfileTypeName.network.toLowerCase()): {
        return true;
        break;
      }
      default: {
        return false;
        break;
      }
    }

  }

  getSite(sites) {
    this.onLoadSiteList.emit(sites);
  }

  typeLoad(list: IS1InputSelectItem[]) {
    if (this.profileForm.controls.type.value) {
      this.idType = list.find(el => el.code === this.profileForm.controls.type.value)['id'];
    }
  }

  typeChange(idType?: number) {
    if (idType) {
      this.idType = idType;
    }
    this.profileForm.controls.profile.reset();
  }

  selectCurrentProfile(profiles) {
    if(this.profileForm.controls.type.value) {
      let profile = profiles.find(profile => profile.code == this.idProfile);
      if(profile) {
        this.profileForm.patchValue({
          profile: this.idProfile
        });
      } else {
        this.idProfile = null;
      }
    } else {
      this.idProfile = null;
    }
  }

  save() {
    this.onSave.emit(this.profileForm);
  }

  setNow() {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 5);
    now.setSeconds(0);
    this.profileForm.patchValue({
      startdate: now,
      starttime: now,
    });
  }
  
  IsValidStart() {
    let valid = true;
    let startDate: Date = this.profileForm?.controls.startdate.value;
    let startTime = this.profileForm?.controls.starttime.value;
    if(startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
    }
    if(startDate && !startTime) {
      startDate.setHours(0,0,0);
    }
    if(startDate) {
      valid = startDate.getTime() > Date.now();
    }
    return valid;
  }

  goToPage() {
    if(this.distributionMode=='all') {
      this.router.navigate(['/profiles']);
    } else if(this.distributionMode=='os') {
      this.addAssetModal.open();
    } else if(this.distributionMode=='agent') {
      this.router.navigate(['/settings/agents']);
    } else if(this.distributionMode=='ae') {
      this.router.navigate(['/ae/policies']);
    }
  }

  addAsset(asset: IAsset) {

    this.ui.showBlockingSpinner();
    let saved = false;
    this.saveAsset(asset).subscribe(
      response => {
        if(response && typeof response === 'number') {
          this.ui.updateFooter(response+'%');
          saved = response == 100;
        }
        if(response && typeof response !== 'number') {
          console.log(response);
          if(saved) {
            this.ui.showSuccessToast("assets.asset.popup.created");
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([this.router.url]);
          }
        }
      },
      error => {console.log(error);},
      () => {this.ui.closeSpinner();}
    );
  }

  saveAsset(asset: IAsset): Observable<any> {
    var formData: any = new FormData();
    formData.append("description", asset.description);
    formData.append("name", asset.name);
    formData.append("file", asset.file);
    formData.append("localFile", asset.localFile);
		formData.append("source", asset.source);
    if(asset.target) {
      formData.append("target", asset.target);
    }
    if(asset.executeWith) {
      formData.append("executeWith", asset.executeWith);
    }
    if(asset.packageName) {
      formData.append("packageName", asset.packageName);
    }
    if(asset.flgExtract != null) {
      formData.append("flgExtract", asset.flgExtract);
    }
		formData.append("idAssetType", asset.idAssetType);
		formData.append("idCompany", asset.idCompany);
    return this.profilesService.createAsset(formData);
  }

}
