export class UnitStatus {

    public static readonly DEAD = 'DEAD';
    public static readonly DISCONNECTED = 'DISCONNECTED';
    public static readonly LOCKED = 'LOCKED';
    public static readonly UNLOCKED = 'UNLOCKED';
    public static readonly ALIVE = 'ALIVE';
    public static readonly DISABLED = 'DISABLED';
    public static readonly ENABLED = 'ENABLED';
    
}
export class ProfileStatus {

    public static readonly ASSIGNED = 'ASSIGNED';
    public static readonly DOWNLOAD = 'DOWNLOAD';
    public static readonly APPLIED = 'APPLIED';
    public static readonly SENT = 'SENT';
    public static readonly ERROR = 'ERROR';
    public static readonly CANCELED = 'CANCELED';
    public static readonly INTERRUPTED = 'INTERRUPTED';
    
}

export class CartStatus {

    public static readonly OPEN = 'OPEN';
    public static readonly PAYMENT = 'PAYMENT';
    public static readonly PARTIAL_RESCAN = 'PARTIAL_RESCAN';
    public static readonly TOTAL_RESCAN = 'TOTAL_RESCAN';
    public static readonly CLOSED = 'CLOSED';
    public static readonly CANCELED = 'CANCELED';
    public static readonly CHANGE_DEVICE = 'CHANGE_DEVICE';
    public static readonly RESCAN_FAILED = 'RESCAN_FAILED';
    public static readonly WAITING_TOTAL_RESCAN = 'WAITING_TOTAL_RESCAN';

}

export enum StatusLevel {
    OK = "OK",
    WARNING = "WARNING",
    ERROR = "ERROR"
}
