import { Component, OnInit, Input, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { IProfileAssignment, IProfile } from '@app/shared/models/profile';
import { S1Table, S1ButtonType, S1UIService } from '@app/s1';
import { UnitSelectionModalComponent } from '../unit-selection-modal/unit-selection-modal.component';
import { IUnit } from '@app/shared/models/models';
import { SettingsService } from '@app/core/settings/settings.service';

@Component({
  selector: 'mdm-profile-assignment-single',
  templateUrl: './profile-assignment-single.component.html',
  styleUrls: ['./profile-assignment-single.component.scss']
})
export class ProfileAssignmentSingleComponent implements OnInit, AfterViewInit {

  @Input() profile: IProfile;
  @Input() set profileAssignments(newValue: IProfileAssignment[]) {
    this._profileAssignments = newValue
    this.updateTable()
  }

  @Output() profileAssignmentsChange = new EventEmitter<IProfileAssignment[]>();

  @ViewChild('table') table: S1Table;
  @ViewChild('unitSelectionModal') unitSelectionModal: UnitSelectionModalComponent;

  s1ButtonType = S1ButtonType;

  _profileAssignments: IProfileAssignment[] = []

  constructor(public settings: SettingsService, private ui:S1UIService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateTable()
  }

  private removeHierarchical(assignments: IProfileAssignment[]): IProfileAssignment[] {

    return assignments.filter(assignment => assignment.idUnit != null)

  }

  private updateTable() {
    this.table?.updateData(this.removeHierarchical(this._profileAssignments))
  }

  add() {

    this.unitSelectionModal.open()

  }

  askRemoveDevice(assignment: IProfileAssignment) {

    this.ui.showDialogPopup("profiles.assignment.sections.single.popup.askRemove").then(result => {

      if (result.value) {
        this.removeDevice(assignment)
      }

    })

  }

  removeDevice(assignmentToRemove: IProfileAssignment) {

    this._profileAssignments = this._profileAssignments.filter(assignment => assignment.id != assignmentToRemove.id)
    this.updateTable()
    this.ui.showSuccessToast("profiles.assignment.sections.single.popup.removed")
    this.profileAssignmentsChange.emit(this._profileAssignments)

  }

  addDevice(unit: IUnit) {

    const newProfileAssignment: IProfileAssignment = {
      idProfile: this.profile?.id,
      idCompany: unit?.idCompany,
      nameCompany: unit?.nameCompany,
      idRegion: unit?.idRegion,
      nameRegion: unit?.nameRegion,
      idSite: unit?.idSite,
      nameSite: unit?.nameSite,
      idGroup: unit?.idGroup,
      nameGroup: unit?.nameGroup,
      idBrand: unit?.idBrand,
      nameBrand: unit?.nameBrand,
      idModel: unit?.idModel,
      nameModel: unit?.nameModel,
      idUnit: unit?.id,
      codUnit: unit?.code,
      ipaddress: unit?.ipaddress,
      btmac: unit?.btmac,
      wifimac: unit?.wifimac,
      batterySerialNumber: unit?.batterySerialNumber,
      batteryPerc: unit?.batteryPerc,
      appRelease: unit?.appRelease,
      osRelease: unit?.osRelease,
      tsLastStatus: unit?.tsLastStatus,
      tsLastEvent: unit?.tsLastEvent
    }

    this._profileAssignments.push(newProfileAssignment)
    this.updateTable()
    this.ui.showSuccessToast("profiles.assignment.sections.single.popup.added")
    this.profileAssignmentsChange.emit(this._profileAssignments)

  }

}
