<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-secondary">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo-big.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2 font-weight-bold text-primary">{{'base.pws_set' | translate}}</p>
                <form [formGroup]="valForm" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                    <div class="form-group">
                        <div formGroupName="passwordGroup">
                            <mdm-input-password [control]="valForm.get('passwordGroup.password')"></mdm-input-password>
                            <mdm-input-password [text]="'base.retype_pwd'" [control]="valForm.get('passwordGroup.confirmPassword')"></mdm-input-password>
                        </div>
                    </div>
                    <button class="btn btn-danger btn-block" type="submit">{{'recover.confirm' | translate}}</button>
                </form>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>
