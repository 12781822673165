import { Injectable } from '@angular/core';
import { Form } from '@angular/forms';
import { IS1SearchParams, S1HttpClientService } from '@app/s1';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAsset} from '../models/models';

export interface IAssetsSearchParams {
  idAssetType?: number,
  codeAssetType?: string,
  description?: string,
  source?: string,
  target?: string,
  executeWith?: string,
  packageName?: string,
  name?: string,
  includeOs?: boolean,
  idCompany?: number,
  
  idRegion?: string,
  idSite?: string,
  idGroup?: string,
  idBrand?: number,
  idModel?: string,
  groupByRegion?: boolean,
  groupBySite?: boolean,
  groupByGroup?: boolean,
  groupByBrand?: boolean,
  groupByModel?: boolean,
  codUnit?: string
}

export interface IAssetGroupedBy {
  id: number,
  type: string,
  description: string,
  items?: IAssetGroupedBy,
  unitAssets?: IAsset[]
}

export interface IAssetsPaginated {
  assets: IAsset[],
  total: number
}

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  private path = "/assets"
  private pathAgents = "/agents"
  private pathCreate = "/create-asset-file"
  private pathUpdate = "/update-asset-file"
  private pathAgentsCreate = "/create-agent-file"
  private pathAgentsUpdate = "/update-agent-file"
  
  constructor(private s1HttpClient: S1HttpClientService) { }

  getAssets(parameters?: IAssetsSearchParams, ui: boolean = true): Observable<IAsset[]> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  getAgents(parameters?: IAssetsSearchParams, ui: boolean = true): Observable<IAsset[]> {
    return this.s1HttpClient.get(this.pathAgents, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createAsset(asset: FormData): Observable<any> {
    return this.s1HttpClient.postFormData(this.path + this.pathCreate, asset).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  createAgent(asset: FormData): Observable<any> {
    return this.s1HttpClient.postFormData(this.pathAgents + this.pathAgentsCreate, asset).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  updateAsset(asset: FormData): Observable<any> {
    return this.s1HttpClient.postFormData(this.path + this.pathUpdate, asset).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  updateAgent(asset: FormData): Observable<any> {
    return this.s1HttpClient.postFormData(this.pathAgents + this.pathAgentsUpdate, asset).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  deleteAsset(asset: IAsset): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(asset)).pipe(
      map(response => response.outcome.success)
    )
  }

  deleteAgent(asset: IAsset): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPathAgent(asset), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(asset: IAsset): string {
    return this.path + "/" + asset.id
  }

  private elementPathAgent(asset: IAsset): string {
    return this.pathAgents + "/" + asset.id
  }

}
