import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';
import { ISite } from '@app/shared/models/models';
import { SitesService, ISitesSearchParams } from '@app/shared/services/sites.service';

@Component({
  selector: 'mdm-input-site-multiselect',
  templateUrl: './input-site-multiselect.component.html',
  styleUrls: ['./input-site-multiselect.component.scss']
})
export class InputSiteMultiSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() set regions(list: IS1InputSelectItem[]) {
    if(list) {
      this.getSites(list);
    }
  }
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Output() onLoadList = new EventEmitter<ISite[]>();
  @Output() onSelectChange = new EventEmitter();
  @Output() onSelectAllChange = new EventEmitter();

  sitesList: IS1InputSelectItem[];
  isAdminOrSuper: boolean;

  constructor(private sitesService: SitesService, public settingsService: SettingsService) { 
    this.isAdminOrSuper = this.settingsService.isSuperAdmin() || this.settingsService.isReseller() || this.settingsService.isAdmin();
  }
  
  ngOnInit(): void {}

  private getSites(regions?: IS1InputSelectItem[], ui: boolean = true) {
    this.sitesList = [];
    if(!regions) {
      this.onLoadList.emit([]);
      return;
    }
    
    const params: ISitesSearchParams = {
      code: null,
      name: null,
      idCompany: this.settingsService.getCompany().code,
      idRegions: regions.map(region => region.code )
    }
    this.sitesService.getSites(params, true, false).subscribe(sites => {
      this.onLoadList.emit(sites);
      this.sitesList = sites.map(site => { 
        return { code: site.id, label: regions.length>1 ? "(" + site.nameRegion + ") "+site.name : site.name } 
      })
    });
    
  }

  select(event) {
    this.onSelectChange.emit(event);
  }
  selectAll(event) {
    this.onSelectAllChange.emit(event);
  }

}
