import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {IS1Modal, S1Modal, S1ModalSizes, S1ButtonType, S1Table, S1UIService} from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { UnitsService } from '@app/shared/services/units.service';
import { IUnit } from '@app/shared/models/models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'mdm-unit-askDisable-modal',
  templateUrl: './unit-askDisable-modal.component.html',
  styleUrls: ['./unit-askDisable-modal.component.scss']
})
export class UnitAskDisableModalComponent implements OnInit, IS1Modal {

  disableUnitForm: UntypedFormGroup;
  unit: IUnit;

  @Output() onCloseAskDisable = new EventEmitter<IUnit>();
  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;

  constructor(public settings: SettingsService, private unitsService: UnitsService, private formBuilder: UntypedFormBuilder, private ui: S1UIService) {
    this.disableUnitForm = this.formBuilder.group({
      reason: null,
      failureFlg: false
    });
  }

  ngOnInit(): void {
  }

  open(unit: IUnit) {
    this.disableUnitForm.patchValue({
      reason: null,
      failureFlg: false
    });

    this.unit = unit;
    this.modal.open(S1ModalSizes.MD);
  }

  disableUnit(withResetIp: boolean) {
    const reason = this.disableUnitForm.controls.reason.value;
    const failureFlg = this.disableUnitForm.controls.failureFlg.value;
    const resetIpFlg = withResetIp;

    this.unitsService.disableUnit(this.unit.id, {'reason': reason, 'resetIp': resetIpFlg, 'failure': failureFlg}).subscribe(response => {
      this.ui.showSuccessToast('units.popup.disable');
      this.onCloseAskDisable.emit(this.unit);
      this.modal.close();
    });
  }

  close() {
    this.modal.close();
  }

}
