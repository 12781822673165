import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1ButtonType, S1UIService } from '@app/s1';
import { IProfileActivation } from '@app/shared/models/profile';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProfileActivationService } from '@app/shared/services/profile-activation.service';

@Component({
  selector: 'mdm-profile-activation-date-modal',
  templateUrl: './profile-activation-date-modal.component.html',
  styleUrls: ['./profile-activation-date-modal.component.scss']
})
export class ProfileActivationDateModalComponent implements OnInit, IS1Modal {

  @Output() profileUpdated = new EventEmitter<IProfileActivation>()

  @ViewChild('modal') modal: S1Modal;
  s1ButtonType = S1ButtonType;

  dateForm: UntypedFormGroup;
  profileActivation: IProfileActivation;

  constructor(private formBuilder: UntypedFormBuilder, private profileActivationService: ProfileActivationService, private ui: S1UIService) { 

    this.dateForm = this.formBuilder.group({
      date: null,
      time: null
    })

  }

  ngOnInit(): void { }

  open(profile: IProfileActivation) {

    this.profileActivation = profile
    const date = profile?.tsActivation ? new Date(profile?.tsActivation) : null
    this.dateForm.controls.date.patchValue(date)
    this.dateForm.controls.time.patchValue(date)

    this.modal.open(S1ModalSizes.MD)

  }

  close() {
    this.modal.close()
  }

  save() {

    const date = this.dateForm.controls.date.value
    const time = this.dateForm.controls.time.value

    if (date == null || time == null) {
      this.ui.showErrorPopup("profiles.activation.sections.date.popup.errorDate")
      return;
    }

    date.setHours(time.getHours(), time.getMinutes(), 0);

    this.profileActivation.tsActivation = date.getTime();

    //TODO: remove service and translations if is not yet necessary
    /*this.profileActivationService.setActivationDate(this.profileActivation).subscribe(profileUpdated => {

      this.ui.showSuccessToast("profiles.activation.sections.date.popup.updated")
      this.profileUpdated.emit(profileUpdated)

    })*/

    this.profileUpdated.emit(this.profileActivation)
    this.modal.close()
  }

}
