import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDashboardInfo } from '../models/dashboard';

export interface IDashboardSearchParams {
  idCompany: string,
  idRegion: string
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private path = "/dashboard";
  
  private pathUnitsConnected = "/connected-devices-stat";
  private pathSitesUnitsConnected = "/connected-devices-sites-stat";
  
  private pathProfilesApplied = "/assigned-profiles-stat";
  private pathSitesProfilesApplied = "/assigned-profiles-sites-stat";

  private pathOsApplied = "/assigned-os-stat";
  private pathSitesOsApplied = "/assigned-os-sites-stat";

  private pathBatteryHealth = "/batteries-health-stat";
  private pathSitesBatteryHealth = "/batteries-health-sites-stat";

  private pathBatteryLevel = "/batteries-level-stat";
  private pathSitesBatteryLevel = "/batteries-level-sites-stat";

  private pathProfilesSummary = "/assigned-profile-types-stat";

  private _currentModules = 0;
  get currentModules(): number {
    return this._currentModules;
  }
  set currentModules(tabs: number) {
    this._currentModules = tabs;
  }

  constructor(private s1HttpClient: S1HttpClientService) { }

  getDashboardInfo(idCompany: number, ui: boolean = true): Observable<IDashboardInfo> {
    return this.s1HttpClient.get(this.path + "/" + idCompany, {}, false).pipe(
      map(response => response.data)
    )
  }

  getUnitsConnected(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathUnitsConnected, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getSitesUnitsConnected(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathSitesUnitsConnected, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getProfilesApplied(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathProfilesApplied, parameters, false).pipe(
      map(response => response.data)
      )
    }
    
  getSitesProfilesApplied(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathSitesProfilesApplied, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getOsApplied(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathOsApplied, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getSitesOsApplied(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathSitesOsApplied, parameters, false).pipe(
      map(response => response.data)
    )
  }
  
  getBatteryHealth(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathBatteryHealth, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getSitesBatteryHealth(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathSitesBatteryHealth, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getBatteryLevel(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathBatteryLevel, parameters, false).pipe(
      map(response => response.data)
    )
  }

  getSitesBatteryLevel(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathSitesBatteryLevel, parameters, false).pipe(
      map(response => response.data)
    )
  }
    
  getProfilesSummary(parameters: IDashboardSearchParams): Observable<any> {
    return this.s1HttpClient.get(this.path + this.pathProfilesSummary, parameters, false).pipe(
      map(response => response.data)
    )
  }

}
