import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder , Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1UIService } from '@app/s1';
import { IProfileNetwork } from '@app/shared/models/profile';
import { ProfilesService } from '../../../shared/services/profiles.service';
import { IpAssignmentsService } from '@app/shared/services/ipassignments.service';

@Component({
  selector: 'profile-network-form',
  templateUrl: './profile-network-form.component.html',
  styleUrls: ['./profile-network-form.component.scss']
})
export class ProfileNetworkFormComponent implements OnInit {

  @Input() profileId: number;

  profileNetworkForm: UntypedFormGroup;
  idCompany: number;
  profile: any; // IProfileNetwork;
  ipStrategies: any;
  dhcpStrategy = 'DHCP';
  hasError: boolean;
  notValid: boolean;
  s1ButtonType = S1ButtonType;
  insertMode: boolean;
  readOnlyMode: boolean;
  changePassword: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private ui: S1UIService,
    private profilesService: ProfilesService,
    private settings: SettingsService,
    private assignmentsService: IpAssignmentsService
  ) {
    this.idCompany = settings.getCompany().code;
    this.insertMode = true;
    this.readOnlyMode = false;

    this.profileNetworkForm = this.formBuilder.group({
      description: null,
      region: this.isCompanyUser() ? null : [null, Validators.required],
      site: this.isRegionUser() ? null : [null, Validators.required],
      group: this.isGroupUser() ? null : [null, Validators.required],
      connectImmediately: false,
      ssid: null,
      hidden: false,
      encryption: null,
      password: null,
      ipstrategy: null
    });

    this.assignmentsService.getProfileNetworkIpAssignmentStrategies().subscribe(strategies => {
      this.ipStrategies = strategies.map(strategy => ({ code: strategy.code, label: strategy.description }));
    });
  }

  ngOnInit(): void {
    if (this.profileId) {
      this.profilesService.getProfileNetwork(this.profileId).subscribe(networkProfile => {
        this.profile = networkProfile;
        this.insertMode = false;
        this.readOnlyMode = !(!this.profile.hasChildren && this.isAllowedRegion());

        this.profileNetworkForm.patchValue({
          description: this.profile.description,
          region: this.profile.idRegion,
          site: this.profile.idSite,
          group: this.profile.idGroup,
          connectImmediately: this.profile?.connectImmediately,
          hidden: this.profile?.hidden,
          ssid: this.profile?.ssid,
          encryption: this.profile?.encryption,
          password: this.profile?.password,
          ipstrategy: this.profile?.codeIpAssignmentStrategy
        });
        // SOLUZIONE_1
        this.profileNetworkForm.get('region').valueChanges.subscribe(() => {
          this.profileNetworkForm.patchValue({
            site: this.profile.idSite
          });
        });
      });
    }
  }

  isCompanyUser() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length === 0;
  }

  isRegionUser() {
    return !this.settings.isOperatore() || this.settings.getSites()?.length === 0;
  }

  isGroupUser() {
    return !this.settings.isOperatore() || this.settings.getGroups()?.length === 0;
  }

  encryptionChange(type) {
    this.profileNetworkForm.patchValue({
      encryption: type ? type.code : null
    });
  }

  validateIPaddress(ipaddress) {
    return ipaddress ? /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress) : true;
  }

  isAllowedRegion() {
    return !this.settings.isOperatore()
      || this.settings.getRegions()?.length === 0
      || this.settings.getRegions()?.filter(region => region.code === this.profile?.idRegion).length > 0;
  }

  validateForm() {
    this.hasError = false;
    this.notValid = false;
  }

  save(){
    this.validateForm();

    if (this.hasError || this.notValid){
      return;
    }

    const description = this.profileNetworkForm.controls.description.value;
    const connectImmediately = this.profileNetworkForm?.controls.connectImmediately.value;
    const hidden = this.profileNetworkForm?.controls.hidden.value;
    const ssid = this.profileNetworkForm?.controls.ssid.value;
    const encryption = this.profileNetworkForm?.controls.encryption.value;
    const password = this.profileNetworkForm?.controls.password.value;
    const ipstrategy = this.profileNetworkForm?.controls.ipstrategy.value;
    const region = this.profileNetworkForm?.controls.region.value;
    const site = this.profileNetworkForm?.controls.site.value;
    const group = this.profileNetworkForm?.controls.group.value;
    if (this.profile) { // modifica
      this.profile.description = description;
      this.profile.connectImmediately = connectImmediately;
      this.profile.hidden = hidden;
      this.profile.ssid = ssid;
      this.profile.encryption = encryption;
      this.profile.password = password;
      this.profile.codeIpAssignmentStrategy = ipstrategy;
      this.profile.idRegion = region;
      this.profile.idSite = site;
      this.profile.idGroup = group;
      this.profile.changePassword = this.changePassword;
      this.updateNetworkProfile(this.profile);
    } else {
      const newNetworkProfile: IProfileNetwork = {
        idCompany: this.settings.getCompany().code,
        idRegion: region,
        idSite: site,
        idGroup: group,
        description,
        connectImmediately,
        hidden,
        ssid,
        encryption,
        password,
        codeIpAssignmentStrategy: ipstrategy
      };
      this.createNetworkProfile(newNetworkProfile);
    }
  }

  private createNetworkProfile(profile: IProfileNetwork) {
    this.profilesService.createProfileNetwork(profile).subscribe(newProfile => {
      if (newProfile) {
        this.ui.showSuccessToast('msg.operation_ok');
        this.router.navigate(['/profiles', newProfile.idProfile, newProfile.nameType]);
      }
    });
  }

  private updateNetworkProfile(profile: IProfileNetwork) {
    this.profilesService.updateProfileNetwork(profile).subscribe(profileUpdated => {
      if (profileUpdated) {
        this.ui.showSuccessToast('msg.operation_ok');
      }
    });
  }

}
