<s1-input-select class="col-6 mb-2 fix-margin" 
    (onSelectChange)="changed($event)"
    [readonly]="readonly || !(showAll || regionSelected)"
    [control]="control"
    [bindAllObject]="bindAllObject"
    [itemsList]="sitesList"
    [clearable]="clearable"
    [label]="'fields.input.site'"
    [labelGrouped]="false"
    [placeholder]="placeholder">
</s1-input-select>
