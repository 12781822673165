import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1UIService, S1ButtonType, S1Table } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';

import { IAsset } from '@app/shared/models/models';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IAssetsSearchParams } from '@app/shared/services/assets.service';

@Component({
  selector: 'mdm-asset-selection-modal',
  templateUrl: './asset-selection-modal.component.html',
  styleUrls: ['./asset-selection-modal.component.scss']
})
export class AssetSelectionModalComponent implements OnInit, IS1Modal {

  @Input() idProfile: number;
  @Input() idCompany: number;
  @Input() includeOs: boolean = true;
  @Output() assetSelected = new EventEmitter<IAsset[]>();
  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;
  selection: IAsset[] = [];
  singleSelection: boolean = false
  searchForm: UntypedFormGroup;
  installationOrder: number;

  constructor(private formBuilder: UntypedFormBuilder, private ui: S1UIService, public settings: SettingsService, private profilesService: ProfilesService) {
    this.initialize();
  }

  ngOnInit(): void {}

  open(installationOrder?: number) {
    if(installationOrder) {
      this.installationOrder = installationOrder;
      this.singleSelection = true;
    } else {
      this.singleSelection = false;
    }
    this.selection = [];
    this.modal.open(S1ModalSizes.XL);
    this.getAssets();
  }

  close() {
    this.initialize();
    this.modal.close();
  }

  initialize() {
    this.installationOrder = null;
    this.searchForm = this.formBuilder.group({
      assetType: null,
      description: null,
      name: null,
      source: null,
      target: null,
      executeWith: null,
      company: this.settings.getCompany()
    });
  }

  getAssets() {
    const parameters: IAssetsSearchParams = {
      idAssetType: this.searchForm.controls.assetType.value?.code,
      description: this.searchForm.controls.description.value,
      source: this.searchForm.controls.source.value,
      target: this.searchForm.controls.target.value,
      executeWith: this.searchForm.controls.executeWith.value,
      name: this.searchForm.controls.name.value,
      includeOs: this.includeOs,
      idCompany: this.settings.getCompany().code
    };
    this.profilesService.getAssestList(parameters).subscribe(assets => {
      this.table.updateData(assets);
    });
  }

  updateSelection(checked: boolean, asset: IAsset) {
    let index = this.selection.findIndex(item => item.id == asset.id);
    let found = index > -1;
    if(checked && !found) {
      asset.installationOrder = this.installationOrder;
      asset.replacer = this.installationOrder ? true : false;
      this.selection.push(asset);
    }
    if(!checked && found) {
      this.selection.splice(index, 1);
    }
  }

  saveSingleSelection(asset: IAsset) {
    asset.installationOrder = this.installationOrder;
    asset.replacer = this.installationOrder ? true : false;
    this.selection = [ asset ];
    this.save();
  }

  save() {
    this.assetSelected.emit(this.selection);
    this.close();
  }

}
