import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mdm-input-locale-type-select',
  templateUrl: './input-locale-type-select.component.html',
  styleUrls: ['./input-locale-type-select.component.scss']
})
export class InputLocaleTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  types: Array<any> = new Array();

  constructor() { }

  ngOnInit(): void {
    this.types = this.getTypes();
  }

  private getTypes() {
    return [
      { code: 'it-IT', label: 'it-IT' },
      { code: 'es-ES', label: 'es-ES' },
      { code: 'fr-FR', label: 'fr-FR' },
      { code: 'de-DE', label: 'de-DE' },
      { code: 'sl-SL', label: 'sl-SL' },
      { code: 'en-GB', label: 'en-GB' },
      { code: 'en-US', label: 'en-US' }
    ];
  }
  
  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
