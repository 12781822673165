import { Component, Input } from '@angular/core';
import { FileItemStatus } from '@app/shared/models/file';

@Component({
  selector: 'mdm-file-status-badge',
  templateUrl: './file-status-badge.component.html',
  styleUrls: ['./file-status-badge.component.scss']
})
export class FileStatusBadgeComponent {

  @Input() status: FileItemStatus;
  @Input() label: string;

  colors: { [key in FileItemStatus] : string } = {
    NONE: "badge-primary",
    UPLOADED: "badge-success",
    REQUESTED: "badge-warning",
    ERROR: "badge-danger"
  }

  constructor() { }

}
