import { Injectable } from '@angular/core';
import { IRegion, ISite } from '@app/shared/models/models';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        this.menuItems = [];
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {

        let menuLoaded : Array<{
            text:string,
            link:string,
            icon: string,
            roles: Array<string>
        }> = new Array;
        
        let roleUser = localStorage.getItem("role");
        let idUserCompany = localStorage.getItem("idCompany");
        let scopeUser = !idUserCompany ? null : 'COMPANY';

        let _regions = localStorage.getItem('regions');
        let regions: IRegion[] = [];
        if(_regions) {
            regions = JSON.parse(_regions);
        }
        scopeUser = regions.length==0 ? scopeUser : 'REGION'; //0->company,else->region

        let _sites = localStorage.getItem('sites');
        let sites: ISite[] = [];
        if(_sites) {
            sites = JSON.parse(_sites);
        }
        scopeUser = sites.length==0 ? scopeUser : 'SITE'; //0->region,else->site

        this.menuItems.forEach(menuLink => {
            var _elem = this.checkMenuRoleLevel(menuLink, roleUser, scopeUser);
            if( _elem ) {
                menuLoaded.push(menuLink);  
            }
        });
        return menuLoaded;
    }

    checkMenuRoleLevel(menuLink, roleUser, scopeUser) {
        let resultArr : Array<{
            text:string,
            link:string,
            icon: string,
            roles: Array<string>
        }> = new Array;

        let result = this.filterMenuRole(menuLink, roleUser, scopeUser);
        if( result?.submenu ) {
            var _subMenu = result.submenu;
            result.submenu = [];

            for(var i = 0; i < _subMenu.length; i++ ) {
                var _item = this.filterMenuRole(_subMenu[i], roleUser, scopeUser); 
                if( _item ) {
                    result.submenu.push( _item )
                }
            }
            
        }
        return result;
    }

    filterMenuRole(menuLink, roleUser, scopeUser) {
        if(
            (menuLink.roles == null || menuLink.roles.length == 0 || menuLink.roles.some(role => role === roleUser)) &&
            (menuLink.scopes == null || menuLink.scopes.length == 0 || menuLink.scopes.some(scope => scope === scopeUser))
        ) {    
            return menuLink;
        }
    }

}
