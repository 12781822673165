import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICompany } from '../models/models';

export interface ICompaniesSearchParams {
  code: string,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private path = "/companies"
  private pathResellerCompanies = "/companies/resellers"
  private pathCompaniesCreate = "/create-company-file"
  private pathCompaniesUpdate = "/update-company-file"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getCompany(companyId: number, ui: boolean = false): Observable<ICompany> {
    return this.s1HttpClient.get(this.path + "/" + companyId, {}, ui).pipe(
      map(response => response.data)
    )
  }

  getCompanies(parameters?: ICompaniesSearchParams, minimal: boolean = false, ui: boolean = true): Observable<ICompany[]> {
    return this.s1HttpClient.get(minimal ? this.path + '/minimal' : this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  getResellerCompanies(parameters?: ICompaniesSearchParams, ui: boolean = true): Observable<ICompany[]> {
    return this.s1HttpClient.get(this.pathResellerCompanies, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createCompany(company: FormData): Observable<any> {
    return this.s1HttpClient.postFormData(this.path + this.pathCompaniesCreate, company).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  updateCompany(company: FormData): Observable<any> {
    return this.s1HttpClient.postFormData(this.path + this.pathCompaniesUpdate, company).pipe(
      map(response => response?.data ? response?.data : response)
    )
  }

  deleteCompany(company: ICompany): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(company), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(company: ICompany): string {
    return this.path + "/" + company.id
  }

}
