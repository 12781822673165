<h2 *ngIf="profileId" class="col-6 mt-4 mb-4 badge-profile">
    <em class="mr-1 icon-smartilio icon-{{profile?.nameType | lowercase}}" style="font-size:36px;"></em>
    <span>{{profile?.nameType}}</span>
</h2>

<form [formGroup]="profileAssetForm">
    <div class="row text-left">
        <s1-input-text class="col-12" [control]="profileAssetForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
        <mdm-input-region-select class="col-4 mt-1" [control]="profileAssetForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
            [siteControl]="profileAssetForm.controls.site"></mdm-input-region-select>
        <mdm-input-site-select class="col-4 mt-1" [control]="profileAssetForm.controls.site" [idRegion]="profileAssetForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
        <mdm-input-group-select class="col-4 mt-1" [control]="profileAssetForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
    </div>
    <div class="row text-center">
        <s1-button [disabled]="!profileAssetForm.valid" class="col mt-0 mb-1" [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
    </div>
</form>

<ng-container>
    <div class="d-flex justify-content-end mb-2 pt-3 mt-4" style="border-top: 1px solid #e7e7e7;" *ngIf="!readOnlyMode && !insertMode">
        <s1-button class="mr-2" [type]="s1ButtonType.Add" [onlyIcon]="false" (onClick)="openModalSearchAsset()" [label]="'base.add_from_available_assets'"></s1-button>
        <s1-button [type]="s1ButtonType.NewHighlight" (onClick)="openModalAddAsset()"></s1-button>
    </div>
    <mdm-asset-card *ngFor="let asset of assets"
        [count]="assets.length"
        [asset]="asset"
        [editMode]="!readOnlyMode"
        (assetUpdated)="updateAsset($event)"
        (assetMovedUp)="moveAsset($event, true)"
        (assetMovedDown)="moveAsset($event, false)"
        (assetReplacedFromSearch)="openModalSearchAsset($event)"
        (assetReplacedFromNew)="openModalAddAsset($event)"
        (assetDeleted)="deleteAsset($event)">
    </mdm-asset-card>
</ng-container>


<mdm-asset-modal #addAssetModal (assetAdded)="addAsset($event)"></mdm-asset-modal>
<mdm-asset-selection-modal #selectAssetModal [includeOs]="false" (assetSelected)="addAssets($event)"></mdm-asset-selection-modal>