<s1-input-select class="col-6 mb-2 fix-margin"
    (onSelectChange)="changed($event)"
    [readonly]="readonly || !(showAll || companySelected)"
    [control]="control"
    [bindAllObject]="bindAllObject"
    [itemsList]="regionsList"
    [clearable]="clearable"
    [label]="'fields.input.region'"
    [labelGrouped]="false"
    [placeholder]="placeholder"
></s1-input-select>
