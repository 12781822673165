import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

import { S1Modal, S1ModalSizes, S1Table, S1UIService } from '@app/s1';
import { ISite } from '@app/shared/models/models';
import { SettingsService } from '@app/core/settings/settings.service';

@Component({
  selector: 'dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss']
})
export class DashboardModalComponent implements OnInit {

  @Output() onSelected = new EventEmitter<ISite>();
  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  title: string;
  sites: any;
  configColumn1: any;
  configColumn2: any;
  configColumn3: any;
  configColumn4: any;

  constructor(private ui: S1UIService, public settings: SettingsService) {}

  ngOnInit(): void {}

  open(dashboardConfig: any) {
    const dashboardData = [];

    this.sites = dashboardConfig.modal.sites;
    this.title = dashboardConfig.modal.title;
    const columns = dashboardConfig.modal.columns;

    this.configColumn1 = { label: columns[0]?.type, style: columns[0]?.style, filter: columns[0]?.filter, data: columns[0]?.data };
    this.configColumn2 = { label: columns[1]?.type, style: columns[1]?.style, filter: columns[1]?.filter, data: columns[1]?.data };
    this.configColumn3 = { label: columns[2]?.type, style: columns[2]?.style, filter: columns[2]?.filter, data: columns[2]?.data };
    this.configColumn4 = { label: columns[3]?.type, style: columns[3]?.style, filter: columns[3]?.filter, data: columns[3]?.data };

    for (let i = 0; i < this.sites.length; i++) {
      dashboardData.push({
        site: this.sites[i]?.nameSite,
        col1: this.configColumn1.data ? this.configColumn1.data[i][1] : null,
        col2: this.configColumn2.data ? this.configColumn2.data[i][1] : null,
        col3: this.configColumn3.data ? this.configColumn3.data[i][1] : null,
        col4: this.configColumn4.data ? this.configColumn4.data[i][1] : null,
      });
    }

    this.table.updateData(dashboardData);
    this.modal.open(S1ModalSizes.LG);
  }

  select(index: number, filter: any, seriesIndex: number) {
    const location = this.sites[index];
    const filters = { seriesIndex, ...location, ...filter };
    this.onSelected.emit(filters);
    this.close();
  }

  close() {
    this.modal.close();
  }
}
