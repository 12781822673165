import {Component, OnInit, Input, EventEmitter, ViewChild, Output, OnChanges} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder , Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileStatus } from '@app/core/classes/status';
import { SettingsService } from '@app/core/settings/settings.service';
import {IS1InputSelectItem, S1ButtonType, S1Table, S1UIService} from '@app/s1';
import {IProfile, IProfileAsset, IProfileSetting} from '@app/shared/models/profile';
import { ProfilesService } from '../../../shared/services/profiles.service';
import {IAsset} from "@app/shared/models/models";
import {AssetModalComponent} from "@app/shared/components/asset-modal/asset-modal.component";
import {
  AssetSelectionModalComponent
} from "@app/shared/components/asset-selection-modal/asset-selection-modal.component";
import {Observable} from "rxjs";

@Component({
  selector: 'profile-asset-form',
  templateUrl: './profile-asset-form.component.html',
  styleUrls: ['./profile-asset-form.component.scss']
})
export class ProfileAssetFormComponent implements OnInit, OnChanges {
  profileAssetForm: UntypedFormGroup;
  idCompany: number;
  profile: any
  s1ButtonType = S1ButtonType;
  assets: IAsset[] = [];
  insertMode: boolean;
  readOnlyMode: boolean;

  @Input() profileId: number;
  @Input() refreshButton: boolean = true;

  @ViewChild('addAssetModal') addAssetModal: AssetModalComponent;
  @ViewChild('selectAssetModal') selectAssetModal: AssetSelectionModalComponent;

  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private ui: S1UIService, private profilesService: ProfilesService, private settings: SettingsService) {
    this.idCompany = settings.getCompany().code;
    this.insertMode = true;
    this.readOnlyMode = false;

    this.profileAssetForm = this.formBuilder.group({
      description: null,
      region: this.isCompanyUser() ? null : [null, Validators.required],
      site: this.isRegionUser() ? null : [null, Validators.required],
      group: this.isGroupUser() ? null : [null, Validators.required],
    });

  }

  ngOnInit(): void {
    if(this.profileId){
      this.profilesService.getProfileAsset(this.profileId).subscribe(assetProfile => {
        this.profile = assetProfile;
        this.assets = this.profile.assets ?? [];
        this.insertMode = false;
        this.readOnlyMode = !(!this.profile.hasChildren && this.isAllowedRegion());


        this.profileAssetForm.patchValue({
          description: this.profile.description,
          region: this.profile.idRegion,
          site: this.profile.idSite,
          group: this.profile.idGroup
        });
        //seleziona site con id dell'oggetto parto
        //SOLUZIONE_1
        /*
        setTimeout(() => {
          this.profileSettingsForm.patchValue({
            site: this.profileNetwork.idSite
          });
        });*/
        //SOLUZIONE_1
        this.profileAssetForm.get('region').valueChanges.subscribe(() => {
          this.profileAssetForm.patchValue({
            site: this.profile.idSite
          });
        });
      });
    }

  }

  ngOnChanges() {}

  isCompanyUser() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0;
  }

  isRegionUser() {
    return !this.settings.isOperatore() || this.settings.getSites()?.length==0;
  }

  isGroupUser() {
    return !this.settings.isOperatore() || this.settings.getGroups()?.length==0;
  }

  isAllowedRegion() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0 || this.settings.getRegions()?.filter(region => region.code == this.profile?.idRegion).length > 0;
  }

  save(){
    if (this.profile) {
      this.profile.description = this.profileAssetForm.controls.description.value;
      this.profile.idRegion = this.profileAssetForm?.controls.region.value;
      this.profile.idSite = this.profileAssetForm?.controls.site.value;
      this.profile.idGroup = this.profileAssetForm?.controls.group.value;
      this.profile.assets = this.assets;
      this.updateAssetProfile(this.profile);
    } else {
      const newAssetProfile: IProfileAsset = {
        idCompany: this.idCompany,
        idRegion: this.profileAssetForm?.controls.region.value,
        idSite: this.profileAssetForm?.controls.site.value,
        idGroup: this.profileAssetForm?.controls.group.value,
        description: this.profileAssetForm?.controls.description.value,
        assets: this.assets
      };
      this.createAssetProfile(newAssetProfile);
    }
  }


  createAssetProfile(profile: IProfileAsset ) {
    this.profilesService.createProfileAsset(profile).subscribe(newProfile => {
      if(newProfile) {
        this.ui.showSuccessToast('msg.operation_ok');
        this.router.navigate(['/profiles', newProfile.idProfile, newProfile.nameType]);
      }
    });
  }

  updateAssetProfile(profile: IProfileAsset) {
    this.profilesService.updateProfileAsset(profile).subscribe(assetProfileUpdated => {
      if (assetProfileUpdated) {
        this.profilesService.getProfileAsset(this.profile.idProfile, false).subscribe(assetProfile => {
          this.profile = assetProfile;
          this.assets = this.profile?.assets ?? [];
        });
        this.ui.showSuccessToast('msg.operation_ok');
      }
    });
  }

  openModalAddAsset(asset?: IAsset) {
    let fromAsset: IAsset = {
      description: null,
      source: null,
      installationOrder: asset?.installationOrder
    }
    this.addAssetModal.open(fromAsset);
  }

  openModalSearchAsset(asset?: IAsset) {
    this.selectAssetModal.open(asset?.installationOrder);
  }

  addAssets(assetsSelection: IAsset[]) {

    assetsSelection = assetsSelection.filter(selected => {
      return !this.assets.find(asset => asset.id === selected.id);
    });
    this.assets = this.assets.concat(assetsSelection);
    this.profile.assets = this.assets;
    this.updateAssetProfile(this.profile);
  }

  addAsset(asset: IAsset) {
    this.ui.showBlockingSpinner();
    let saved = false;
    this.saveAsset(asset).subscribe(
        response => {
          if(response && typeof response === 'number') {
            this.ui.updateFooter(response+'%');
            saved = response == 100;
          }
          if(response && typeof response !== 'number') {
            if(saved) {
              asset.id = response.id;
              asset.source = response.source;
              delete asset.file;
              this.assets.push(asset);
              this.profile.assets = this.assets;
              this.updateAssetProfile(this.profile);
            }
          }
        },
        error => {console.log(error);},
        () => {this.ui.closeSpinner();}
    );
  }


  updateAsset(assetUpdated: IAsset) {
    this.ui.showBlockingSpinner();
    let saved = false;
    this.saveAsset(assetUpdated).subscribe(
        response => {
          if(response && typeof response === 'number') {
            this.ui.updateFooter(response+'%');
            saved = response == 100;
          }
          if(response && typeof response !== 'number') {
            console.log(response);
            if(saved) {
              assetUpdated.source = response.source;
              delete assetUpdated.file;
              this.assets[this.assets.findIndex(asset => asset.id === assetUpdated.id)] = assetUpdated;
              this.profile.assets = this.assets;
              this.updateAssetProfile(this.profile);
            }
          }
        },
        error => {console.log(error);},
        () => {this.ui.closeSpinner();}
    );
  }

  saveAsset(asset: IAsset): Observable<any> {
    var formData: any = new FormData();
    if(asset.id) {
      formData.append("id", asset.id);
    }

    formData.append("description", asset.description);
    formData.append("name", asset.name);
    formData.append("file", asset.file);
    formData.append("localFile", asset.localFile);
    formData.append("source", asset.source);
    formData.append("stringaEsec", asset.stringaEsec);
    formData.append("flgEsecViaStringa", asset.flgEsecViaStringa);

    if(asset.target) {
      formData.append("target", asset.target);
    }
    if(asset.executeWith) {
      formData.append("executeWith", asset.executeWith);
    }
    if(asset.packageName) {
      formData.append("packageName", asset.packageName);
    }
    if(asset.flgExtract != null) {
      formData.append("flgExtract", asset.flgExtract);
    }
    formData.append("idAssetType", asset.idAssetType);
    formData.append("idCompany", asset.idCompany);
    return !asset.id ? this.profilesService.createAsset(formData) :  this.profilesService.updateAsset(formData);
  }

  moveAsset(assetUpdated: IAsset, direction: boolean) {
    this.profilesService.moveAssetOfProfileAsset(this.profile,assetUpdated,direction).subscribe(assetProfile => {
      this.profile = assetProfile;
      this.assets = this.profile?.assets ?? [];
    });
  }

  deleteAsset(assetDeleted: IAsset) {
    this.profilesService.deleteAssetOfProfileAsset(this.profile, assetDeleted).subscribe(profile => {
      if (profile) {
        this.profilesService.getProfileAsset(this.profile.idProfile, true).subscribe(assetProfile => {
          this.profile = assetProfile;
          this.assets = this.profile?.assets ?? [];
        });
        this.ui.showSuccessToast('msg.operation_ok');
      }
    });
  }


}
