import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numericList'
})
export class NumericListPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {

    let res = [];
    let start = args.length > 0  ? args[0] : 1
    for (let i = start; i <= value; i++) {
      res.push(i);
    }
    return res;

  }

}