import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { GroupsService, IGroupsSearchParams } from '@app/shared/services/groups.service';

@Component({
  selector: 'mdm-input-group-multiselect',
  templateUrl: './input-group-multiselect.component.html',
  styleUrls: ['./input-group-multiselect.component.scss']
})
export class InputGroupMultiSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() set idCompany(newValue: number) {
    this.companySelected = newValue != null
    this.getGroups(newValue)  
  }

  @Input() readonly: boolean = false;
  @Input() showAll: boolean = false
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Output() onSelectChange = new EventEmitter();
  @Output() onSelectAllChange = new EventEmitter();
  
  companySelected: boolean = false
  groupsList: IS1InputSelectItem[];

  constructor(private groupsService: GroupsService) { }

  ngOnInit(): void {}

  private getGroups(idCompany?: number) {

    const params: IGroupsSearchParams = {
      code: null,
      name: null,
      idCompany: idCompany
    }

    this.groupsService.getGroups(params, false).subscribe(groups => {
      this.groupsList = groups.map(group => { 
        return { code: group.id, label: group.name } 
      })
      this.onLoadList.emit(this.groupsList);
    })

  }

  select(event) {
    this.onSelectChange.emit(event);
  }
  selectAll(event) {
    this.onSelectAllChange.emit(event);
  }

}
