<ng-container *ngIf="template == 'units'">

  <div class="row">
    <div class="col-4">
      <p class="mb-1">
        <em class="fa mr-1 fa-tablet-alt"></em>
        <strong class="mr-1" *ngIf="!(unit?.type == 'ENTRANCE_UNIT' && unit?.model == 'WEB')">{{ unit?.code }}</strong>
        <strong class="mr-1" *ngIf="(unit?.type == 'ENTRANCE_UNIT' && unit?.model == 'WEB')">CHIOSCO {{ unit?.codWall }}</strong>
      </p>
      <p class="mb-1">
        <span><em class="fas fa-globe"></em> {{ unit?.ipaddress || 'no ip' }} - <em class="fab fa-android"></em> {{ unit?.osrelease || "base.notassigned" | translate }} - <em class="fas fa-code-branch"></em> {{ unit?.appVersion || "base.notassigned" | translate }}</span>
      </p>
      <p class="mb-1">
        <span>{{ unit?.nameRegion || 'no region' }} - {{ unit?.nameSite || 'no site'}}</span>
      </p>
    </div>

    <div class="col-3">
      <div class="mb-1">
        <b [ngClass]="stylizeStatus(unit?.unitStatus)">{{unit?.unitStatus}}</b>
      </div>
      <div class="mb-1" *ngIf="unit?.type != 'ENTRANCE_UNIT'">
        <battery-icon [percentage]="unit?.batteryPerc"></battery-icon>
      </div>
      <div class="mb-1" *ngIf="unit?.idWall">
        <span *ngIf="unit?.codWall == '65535' || unit?.row == 65535 || unit?.col == 65535; else elseBlock;">N/D</span>
        <ng-template #elseBlock>
          <span><em class="fas fa-th"></em> {{'dispensing.units.table_wall' | translate}}: {{unit?.codWall}} </span>
          <span *ngIf="unit?.type != 'ENTRANCE_UNIT'">- {{'dispensing.units.table_row' | translate}}: {{unit?.row}} - {{'dispensing.units.table_coll' | translate}}: {{unit?.col}} </span>
        </ng-template>
      </div>
    </div>

    <div class="col-3">
      <div class="mb-1">
        <div *ngIf="unit?.type != 'ENTRANCE_UNIT'">
          <em title="{{'units.list.table.columns.tsLastEvent' | translate}}" class="icon-bell mr-2"></em>
          {{ unit?.tsLastEvent | s1ReadableDate: true  }}
          <span *ngIf="!unit?.tsLastEvent">no event</span>
        </div>
        <div class="ml-4 text-left" *ngIf="unit?.lastTimeEvent">
          <span *ngIf="unit?.lastTimeEvent" class="small">{{ unit?.lastTimeEvent | timestampToTime}} {{'base.ago' | translate}}</span>
        </div>
      </div>
      <div>
        <div>
          <em title="{{'units.list.table.columns.tsLastStatus' | translate}}" class="icon-feed mr-2"></em>
          {{ unit?.tsLastStatus | s1ReadableDate: true}}
          <span class="small ml-4" *ngIf="!unit?.tsLastStatus">no status</span>
        </div>
        <div class="ml-4 text-left" *ngIf="unit?.tsLastStatus">
          <span class="small" [ngClass]="stylizeSinceLastStatus(unit?.tsLastStatus)">{{ unit?.tsLastStatus / 1000 | secondsToTime: '48' }} {{'base.ago' | translate}}</span>
        </div>
      </div>
    </div>
    
    <div class="col-auto">
      <div class="mb-2 pointer text-primary text-left" (click)="showDetail()">
        <em class="mr-1 fas fa-info-circle"></em>
        <span>{{'units.list.table.columns.moreinfo' | translate}}</span>
      </div>
      <div class="mb-2 pointer text-primary text-left" (click)="goToWall(unit)">
        <em class="mr-1 fas fa-th"></em>
        <span>{{'dispensing.units.wall' | translate}}</span>
      </div>

      
    </div>
  </div>

</ng-container>