import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 's1-input-multiselect',
  templateUrl: './s1-input-multiselect.component.html',
  styleUrls: ['./s1-input-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S1InputMultiselect implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() placeholder: string = 'base.all';
  @Input() options: any;
  @Input() textField: string = 'code';
  @Input() readonly: boolean = false;
  @Input() enableCheckAll: boolean = true;
  @Output() onSelect = new EventEmitter();
  @Output() onSelectAll = new EventEmitter();
  @Output() onSelectClear = new EventEmitter();
  
  dropdownSettings: IDropdownSettings = {};

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: this.textField,
      enableCheckAll: this.enableCheckAll,
      searchPlaceholderText: 'Digita per ricercare...',
      allowSearchFilter: true,
      selectAllText: this.translate.instant('base.all_selection'),
      unSelectAllText: this.translate.instant('base.empty_selection')
    };
  }

  checkIsValid(): boolean {
    return this.control.valid || !this.control.touched;
  }

  onItemSelect(item: any) {
    this.onSelect.emit(item);
  }

  onAllItemsSelect(items: any) {
    this.onSelectAll.emit(items);
  }

  onAllItemsDeselect() {
    this.onSelectClear.emit();
  }
}
