import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { IS1Modal, S1ButtonType, S1Modal, S1UIService, S1ModalSizes, IS1InputSelectItem } from '@app/s1';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';

export interface IHierarcicalSelection {
  company: IS1InputSelectItem,
  region: IS1InputSelectItem,
  site: IS1InputSelectItem,
  group: IS1InputSelectItem,
  brand: IS1InputSelectItem,
  model: IS1InputSelectItem,
}

@Component({
  selector: 'mdm-hierarchical-selection-modal',
  templateUrl: './hierarchical-selection-modal.component.html',
  styleUrls: ['./hierarchical-selection-modal.component.scss']
})
export class HierarchicalSelectionModalComponent implements OnInit , IS1Modal {

  s1ButtonType = S1ButtonType;

  @Output() hierarchicalSelected = new EventEmitter<IHierarcicalSelection>();

  @ViewChild('modal') modal: S1Modal;

  searchForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private ui: S1UIService, public settings: SettingsService) {
    this.searchForm = this.formBuilder.group({
      region: null,
      site: null,
      brand: null,
      model: null,
      group: null
    });

  }

  ngOnInit(): void { }

  open() {

    this.searchForm.patchValue({
      region: this.settings.setPreselectedRegion(this.searchForm.controls.region, this.settings.getCompany()?.code, true),
      site: null,
      brand: null,
      model: null,
      group: null
    })

    this.modal.open(S1ModalSizes.XL)

  }

  close() {
    this.modal.close()
  }

  add() {

    const params: IHierarcicalSelection = {
      company: this.settings.getCompany()?.value,
      region: this.searchForm.controls.region.value,
      site: this.searchForm.controls.site.value,
      group: this.searchForm.controls.group.value,
      brand: this.searchForm.controls.brand.value,
      model: this.searchForm.controls.model.value
    }

    this.hierarchicalSelected.emit(params)
    this.close()

  }

}