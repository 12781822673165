<s1-modal #modal [title]="'unitsfailures.unitfailures.list.title'" (onClose)="close()">

  <s1-card class="assets">
    <s1-table #table [withPagination]="false">
        <thead header>
            <tr>
                <td class="py-1 text-left">
                    <p class="mb-0 font-weight-bold">{{ 'unitsfailures.unitfailures.list.table.columns.codUnit' | translate }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-0 font-weight-bold">{{ 'unitsfailures.unitfailures.list.table.columns.tsCreated' | translate }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-0 font-weight-bold">{{ 'unitsfailures.unitfailures.list.table.columns.disablingReason' | translate }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-0 font-weight-bold">{{ 'unitsfailures.unitfailures.list.table.columns.tsEnabling' | translate }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-0 font-weight-bold">{{ 'unitsfailures.unitfailures.list.table.columns.failure' | translate }}</p>
                </td>
            </tr>
        </thead>
        <tbody body>
            <tr *ngFor="let unitFailure of table.config.rows; let i = index">
                <td class="py-1 text-left">
                    <p class="mb-1">{{ unitFailure.codUnit }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-1">{{ unitFailure.tsCreated | s1ReadableDate: true }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-1">{{ unitFailure.disablingReason }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-1">{{ unitFailure.tsEnabling | s1ReadableDate: true }}</p>
                </td>
                <td class="py-2 text-left">
                    <p class="mb-1" *ngIf="unitFailure.failure">
                        <i class="fas fa-check fa-xl"></i>
                    </p>
                </td>
            </tr>
        </tbody>
    </s1-table>

    <div class="col-12 m-3 text-center">
      <s1-button [type]="s1ButtonType.Back" (onClick)="close()"></s1-button>
    </div>

  </s1-card>

</s1-modal>