import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';

import { IS1InputSelectItem } from '@app/s1';
import { RegionsService, IRegionsSearchParams } from '@app/shared/services/regions.service';

@Component({
  selector: 'mdm-input-region-multiselect',
  templateUrl: './input-region-multiselect.component.html',
  styleUrls: ['./input-region-multiselect.component.scss']
})
export class InputRegionMultiSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() set idCompany(newValue: number) {
    this.companySelected = newValue != null
    this.getRegions(newValue)  
  }
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Input() showAll: boolean = false;
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Output() onSelectChange = new EventEmitter();
  @Output() onSelectAllChange = new EventEmitter();

  companySelected: boolean = false;
  regionsList: IS1InputSelectItem[];
  isAdminOrSuper: boolean;
  
  constructor(private regionsService: RegionsService, public settingsService: SettingsService) { 
    this.isAdminOrSuper = this.settingsService.isSuperAdmin() || this.settingsService.isReseller() || this.settingsService.isAdmin();
   }

  ngOnInit(): void {}

  private getRegions(idCompany?: number, ui: boolean = true) {

    const params: IRegionsSearchParams = {
      code: null,
      name: null,
      idCompany: idCompany
    }

    this.regionsService.getRegions(params, true, false).subscribe(regions => {

      this.regionsList = regions.map(region => { 
        return { code: region.id, label: region.name } 
      });

      let userRegionIds = this.settingsService.getRegions()?.map(region => region.code);
      if(userRegionIds) {
        this.regionsList = this.regionsList.filter(region => userRegionIds.includes(region.code as number));
      }

      this.onLoadList.emit(this.regionsList);
    })

  }

  select(event) {
    this.onSelectChange.emit(event);
  }
  selectAll(event) {
    this.onSelectAllChange.emit(event);
  }

}
