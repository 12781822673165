import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1UIService, S1ButtonType, S1Table } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IGroupsSearchParams } from '@app/shared/services/groups.service';
import { UnitsService } from '@app/shared/services/units.service';
import { IGroup, IUnit } from '@app/shared/models/models';

@Component({
  selector: 'mdm-group-selection-modal',
  templateUrl: './group-selection-modal.component.html',
  styleUrls: ['./group-selection-modal.component.scss']
})
export class GroupSelectionModalComponent implements OnInit, IS1Modal {

  @Input() unit: IUnit;
  @Output() onGroupSelected = new EventEmitter<IGroup>();
  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;
  searchForm: UntypedFormGroup;
  
  constructor(private formBuilder: UntypedFormBuilder, private ui: S1UIService, public settings: SettingsService, private unitsService: UnitsService) {
    this.initSearchForm();
  }

  ngOnInit(): void { }

  open() {
    this.modal.open(S1ModalSizes.XL);
    this.getGroups();
  }

  close() {
    this.initSearchForm();
    this.modal.close();
  }

  initSearchForm() {
    this.searchForm = this.formBuilder.group({
      code: null,
      description: null,
      company: this.settings.getCompany()
    });
  }

  getGroups() {
    const parameters: IGroupsSearchParams = {
      idCompany: this.searchForm.controls.company.value?.code,
      code: this.searchForm.controls.code.value,
      name: this.searchForm.controls.description.value
    };

    this.unitsService.getUnitGroups(parameters).subscribe(groups => {
      this.table.updateData(groups);
    });
  }

  updateGroup(group: IGroup, remove: boolean) {
    if(remove) {
      group.id = 0;
    }
    this.onGroupSelected.emit(group);
    this.close();
  }

}
