<ng-container *ngIf="walls?.length > 0">
  <ul class="nav nav-tabs">
    <li class="nav-item" >
      <a class="nav-link bg-info" [ngClass]="{active: summaryWall.show}" href="#" (click)="showTab(summaryWall)">
        {{ summaryWall.description }}
      </a>
    </li>
    <li class="nav-item pointer" *ngFor="let wall of walls">
      <a class="nav-link" [ngClass]="{active: wall.show, 'bg-info': (wall.code == 'SUMMARY'), 'bg-infoprimary': (wall.code == 'NO_WALL')}" (click)="showTab(wall)">
        {{ wall.description }}
      </a>
    </li>
  </ul>
  <div class="tab-content" id="nav-tabContent">
   <div class="tab-pane fade show" [ngClass]="{'active': summaryWall.show}" role="tabpanel">
      <ng-container *ngFor="let wall of walls">
        <wall-summary-card [wall]="wall" (click)="showTab(wall)"></wall-summary-card>
      </ng-container>
    </div>
    <div *ngFor="let wall of walls" class="tab-pane fade show" [ngClass]="{'active': wall.show}" role="tabpanel">
      <wall-card [wall]="wall"></wall-card>
    </div>
  </div>
</ng-container>
