import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UnitCellComponent } from '../unit-cell/unit-cell.component';
import { IUnit } from '@app/shared/models/models';
import { SettingsService } from '@app/core/settings/settings.service';
import { Router } from '@angular/router';
import { S1UIService } from '@app/s1';
import { UnitsService } from '@app/shared/services/units.service';
import { UnitStatus } from '@app/core/classes/status';

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};

@Component({
  selector: 'mdm-unit-cell-min',
  templateUrl: './unit-cell-min.component.html',
  styleUrls: ['./unit-cell-min.component.scss']
})
export class UnitCellMinComponent implements OnChanges {

  @Input() unit: IUnit;
  @Input() template: string = 'units';
  @Input() ae: boolean = false;
  @Input() searchFilters: any;
  @Output() unitDisabled = new EventEmitter<any>();

  isDisabled: boolean = false;

  constructor(
    public settings: SettingsService,
    private router: Router,
    private ui: S1UIService,
    private unitsService: UnitsService
  ) { }

  ngOnChanges(changes: ComponentChanges<UnitCellComponent>): void {
    this.unit = changes.unit?.currentValue;
    this.isDisabled = this.unit?.status == UnitStatus.DISABLED;
  }

}
