import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ProfilesService } from '@app/shared/services/profiles.service';
import { IProfile, IProfileOS } from '@app/shared/models/profile';
import { IUnit } from '@app/shared/models/models';

import { S1ButtonType, S1UIService } from '@app/s1';

@Component({
  selector: 'mdm-profile-os-content',
  templateUrl: './profile-os-content.component.html',
  styleUrls: ['./profile-os-content.component.scss']
})
export class ProfileOsContentComponent implements OnInit {

  @Input() set profileOs(newValue: IProfileOS) {

    if (newValue) {
      this.OSProfile = newValue;
      this.updateForm();
    }

  }
  @Input() profileForm: UntypedFormGroup;
  @Input() editMode: boolean = true;
  @Input() set profile(newValue: IProfile) {

    if (newValue) {
      this.getOsProfile(newValue)
    }

  }

  @Input() profileData: any;

  OSProfile: IProfileOS;
  OSForm: UntypedFormGroup;

  s1ButtonType = S1ButtonType;

  constructor( private formBuilder: UntypedFormBuilder, private router: Router, private profilesService: ProfilesService , private ui: S1UIService ) { 
    this.OSForm = this.formBuilder.group({
      type: null,
      description: null,
      source: null,
      target: null
    })
  }

  ngOnInit(): void { }

  private getOsProfile(profile: IProfile) {

    this.profilesService.getProfileOS(profile.id).subscribe(osProfile => {

      this.OSProfile = osProfile;
      this.updateForm();

    })

  }

  private updateForm() {

    this.OSForm.patchValue({
      type: this.OSProfile?.os?.type,
      description: this.OSProfile?.os?.description,
      source: this.OSProfile?.os?.source,
      target: this.OSProfile?.os?.target
    })

  }

  save() {
  
    if (this.OSProfile) {

      this.OSProfile.description = this.profileForm.controls.description.value

      this.OSProfile.os.type = this.OSForm.controls.type.value
      this.OSProfile.os.description = this.OSForm.controls.description.value
      this.OSProfile.os.source = this.OSForm.controls.source.value
      this.OSProfile.os.target = this.OSForm.controls.target.value

      this.updateOSProfile(this.OSProfile);

    } else {

      const newOsProfile: IProfileOS = {
        idType: this.profileForm?.controls.type.value,
        code: this.profileForm?.controls.code.value,
        versionProfile: this.profileForm?.controls.version.value,
        nameProfile: null, // TODO capire che valore è
        description: this.profileForm?.controls.description.value,
        idCompany: this.profileForm?.controls.company.value?.code,
        os: {
          type: this.OSForm.controls.type.value,
          description: this.OSForm.controls.description.value,
          source: this.OSForm.controls.source.value,
          target: this.OSForm.controls.target.value
        }
      }

      this.createOSProfile(newOsProfile);

    }

  }

  private createOSProfile( profile: IProfileOS ) {

    this.profilesService.createProfileOS(profile).subscribe(newProfile => {
      if (newProfile) {
        this.ui.showSuccessToast("profiles.os.popup.created");
        this.router.navigate(["profiles"]);
      }
    })

  }

  private updateOSProfile( profile: IProfileOS ) {

    this.profilesService.updateProfileOS(profile).subscribe(profileUpdated => {
      if (profileUpdated) {
        this.ui.showSuccessToast("profiles.os.popup.updated");
        this.router.navigate(["profiles"]);
      }

    })

  }

}
