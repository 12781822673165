<s1-card [title]="'profiles.activation.sections.single.title'" [alignTitleCenter]="false">
  <ng-container header-right >
    <s1-button (onClick)="add()" [type]="s1ButtonType.Add"></s1-button>
  </ng-container>
  <s1-table class="pt-1" #table [onlinePagination]="false">
    <tbody body>
      <ng-container *ngFor="let activation of table.config.rows; let i = index">
        <tr>
          <td>{{ activation.codUnit }}</td>
          <td>{{ activation.nameRegion + " - " + activation.nameSite }}</td>
          <td>{{ activation.nameBrand + " - " + activation.nameModel }}</td>
          <td>{{ activation.appRelease }}</td>
          <td>{{ activation.batteryPerc }}</td>
          <td>{{ activation.profileStatus }}</td>
          <td>{{ activation.tsActivation | s1ReadableDate: true }}</td>
          <td class="text-left fit">
            <s1-button class="pr-1" (onClick)="showDatePicker(activation)" [type]="s1ButtonType.Normal" [icon]="'fas fa-calendar-alt'" [onlyIcon]="true"></s1-button>
            <s1-button (onClick)="askRemoveDevice(activation)" [type]="s1ButtonType.Delete"></s1-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </s1-table>
</s1-card>

<mdm-unit-selection-modal #unitSelectionModal (unitSelected)="addDevice($event)"></mdm-unit-selection-modal>
<mdm-profile-activation-date-modal #activationDateModal (profileUpdated)="updateProfile($event)"></mdm-profile-activation-date-modal>
