import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { GroupsService, IGroupsSearchParams } from '@app/shared/services/groups.service';

@Component({
  selector: 'mdm-input-group-select',
  templateUrl: './input-group-select.component.html',
  styleUrls: ['./input-group-select.component.scss']
})
export class InputGroupSelectComponent implements OnInit {

  @Input() bindAllObject: boolean = true;
  @Input() control: UntypedFormControl;
  @Input() set idCompany(newValue: number) {
    this.companySelected = newValue != null
    this.getGroups(newValue)  
  }
  @Input() readonly: boolean = false;
  @Input() showAll: boolean = false
  @Input() placeholder: string = '';
  
  companySelected: boolean = false

  groupsList: IS1InputSelectItem[];

  constructor(private groupsService: GroupsService) { }

  ngOnInit(): void {
    //this.getGroups()
  }

  private getGroups(idCompany?: number) {

    const params: IGroupsSearchParams = {
      code: null,
      name: null,
      idCompany: idCompany
    }

    this.groupsService.getGroups(params, false).subscribe(groups => {
      this.groupsList = groups.map(group => { 
        return { code: group.id, label: group.name } 
      })
    })

  }

}
