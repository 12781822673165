import { Injectable } from '@angular/core';

import { S1HttpClientService } from '@app/s1';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDispensingUnit, IDispensingUnitsPaginated, IDispensingUnitsSearchParams, ISite, IWall } from '../models/models';

export interface IWallsSearchParams {
  code?: string,
  name?: string,
  idCompany: number,
  idRegion?: number,
  idSite: number,
  idWall?: number,
}

@Injectable({
  providedIn: 'root'
})
export class DispensingService {
  private pathDashboard = "/dispensing/dashboard/";
  private pathUnits = "/dispensing/units";
  private pathWalls = "/dispensing/dashboard/walls";
  private pathWall = "/dispensing/dashboard/wall";
  private pathDashboardWalls = "/dispensing/dashboard/dashboard-walls";
  private pathGraph = "/graph/";
  private pathHomeChart = "/dispensing/dashboard/home/chart";
  private pathHomePrelievi = "/dispensing/dashboard/home/prelievi";
  private pathHomeUnits = "/dispensing/dashboard/home/units";
  private pathExlcudeUnit = "/exclude/";

  constructor(private s1HttpClient: S1HttpClientService) { }

  getUnits(parameters: IDispensingUnitsSearchParams, ui: boolean = true): Observable<IDispensingUnitsPaginated> {
    return this.s1HttpClient.get(this.pathUnits, parameters, ui)
    .pipe(
      map(response => { return { units: response.data.results, total: response.data.total } })
    )
  }

  getWalls(parameters: IWallsSearchParams, ui: boolean = true): Observable<IWall[]> {
    return this.s1HttpClient.get(this.pathWalls, parameters, ui)
    .pipe(
      map(response => response.data.results)
    )
  }

  getWall(parameters: IWallsSearchParams, ui: boolean = true): Observable<IWall> {
    return this.s1HttpClient.get(this.pathWall, parameters, ui)
    .pipe(
      map(response => response.data)
    )
  }

  getDashboardWalls(parameters: IWallsSearchParams, ui: boolean = true): Observable<IWall[]> {
    return this.s1HttpClient.get(this.pathDashboardWalls, parameters, ui)
        .pipe(
            map(response => response.data.results)
        )
  }

  getGraph(unitId: number, parameters: any, ui: boolean = true): Observable<any[]> {
    return this.s1HttpClient.get(this.pathDashboard + unitId + this.pathGraph, parameters, ui)
    .pipe(
      map(response => response.data.results)
    )
  }

  getDashboardCharts(parameters: any, ui: boolean = true): Observable<any[]> {
    return this.s1HttpClient.get(this.pathHomeChart, parameters, ui)
    .pipe(
      map(response => response.data.results)
    )
  }

  getDashboardPrelievi(parameters: any, ui: boolean = true): Observable<any[]> {
    return this.s1HttpClient.get(this.pathHomePrelievi, parameters, ui)
    .pipe(
      map(response => response.data.results)
    )
  }

  getDashboardUnits(parameters: any, ui: boolean = true): Observable<any[]> {
    return this.s1HttpClient.get(this.pathHomeUnits, parameters, ui)
    .pipe(
      map(response => response.data.results)
    )
  }

  excludeUnit(unitId: any, exclude: boolean) {
    return this.s1HttpClient.post(this.pathUnits + "/" + unitId + this.pathExlcudeUnit + exclude, {}).pipe(
      map(response => response.outcome.success)
    );
  }

}
