<h2 *ngIf="profileId" class="col-6 mt-4 mb-4 badge-profile">
    <em class="mr-1 icon-smartilio icon-{{profile?.nameType | lowercase}}" style="font-size:36px;"></em>
    <span>{{profile?.nameType}}</span>
</h2>

<form [formGroup]="profileSettingsForm">
    <div class="row text-left pl-2 mt-2">
        <s1-input-text class="col-12 pl-2 mt-2" [control]="profileSettingsForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
        <mdm-input-region-select class="col-4 pl-2 mt-2" [control]="profileSettingsForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
            [siteControl]="profileSettingsForm.controls.site"></mdm-input-region-select>
        <mdm-input-site-select class="col-4 pl-2 mt-2" [control]="profileSettingsForm.controls.site" [idRegion]="profileSettingsForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
        <mdm-input-group-select class="col-4 pl-2 mt-2" [control]="profileSettingsForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
    </div>

    <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="col-2" [control]="profileSettingsForm.controls.barsVisibility" [readonly]="readOnlyMode"  [label]="'profiles.settings.fields.barsVisibility'"></s1-input-switch>
        <s1-input-switch class="col-auto" [control]="profileSettingsForm.controls.barVisibilityTop" [readonly]="readOnlyMode || !profileSettingsForm.controls.barsVisibility.value"  [label]="'profiles.settings.fields.barVisibilityTop'"></s1-input-switch>
        <s1-input-switch class="col-auto" [control]="profileSettingsForm.controls.barVisibilityBottom" [readonly]="readOnlyMode || !profileSettingsForm.controls.barsVisibility.value"  [label]="'profiles.settings.fields.barVisibilityBottom'"></s1-input-switch>
    </div>
    <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="col-2" [control]="profileSettingsForm.controls.brightnessRadio" [readonly]="readOnlyMode" [label]="'profiles.settings.fields.brightness'"></s1-input-switch>
        <s1-input-range class="col-3 pointer" [control]="profileSettingsForm.controls.brightness" [readonly]="readOnlyMode || !profileSettingsForm.controls.brightnessRadio.value" [label]="'profiles.settings.fields.brightness'"></s1-input-range>
        <label class="mt-3">{{profileSettingsForm.controls.brightness.value}}</label>
    </div>
    <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="col-2" [control]="profileSettingsForm.controls.screentimeoutRadio" [readonly]="readOnlyMode" [label]="'profiles.settings.fields.screentimeout'"></s1-input-switch>
        <s1-input-text class="col-3"[control]="profileSettingsForm.controls.screentimeout" [readonly]="readOnlyMode || !profileSettingsForm.controls.screentimeoutRadio.value" [labelGrouped]="false" [label]="'profiles.settings.fields.screentimeoutTime'"></s1-input-text>
    </div>
    <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="col-2" [control]="profileSettingsForm.controls.dailyReboot" [readonly]="readOnlyMode" [label]="'profiles.settings.fields.reboot'"></s1-input-switch>
        <div class="col-auto">
            <label>{{ 'profiles.settings.fields.rebootTime' | translate }}</label>
            <timepicker [formControl]="profileSettingsForm.controls.dailyRebootTime" [showMeridian]="false" [mousewheel]="false" [disabled]="readOnlyMode || !profileSettingsForm.controls.dailyReboot.value"></timepicker>
        </div>
        <s1-input-switch class="col-auto" [control]="profileSettingsForm.controls.dailyRebootWhenCharging" [readonly]="readOnlyMode || !profileSettingsForm.controls.dailyReboot.value" [label]="'profiles.settings.fields.rebootWhenCharging'"></s1-input-switch>
    </div>
    <div class="row text-left pl-2 mt-2">
        <mdm-input-locale-type-select class="col-2" [control]="profileSettingsForm.controls.locale" [readonly]="readOnlyMode" [bindAllObject]="false" (onSelectClear)="localeChange($event)" (onSelectChange)="localeChange($event)"></mdm-input-locale-type-select>
        <mdm-input-timezone-type-select class="col-3" [control]="profileSettingsForm.controls.timezone" [readonly]="readOnlyMode" [bindAllObject]="false" (onSelectClear)="timezoneChange($event)" (onSelectChange)="timezoneChange($event)"></mdm-input-timezone-type-select>
    </div>

    <div class="row text-center my-1">
        <div class="col-12">
            <s1-button [disabled]="!profileSettingsForm.valid" [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
        </div>
    </div>

</form>