<ng-container>
    <div class="row justify-content-center align-items-center">
        <div class="col-1">
            <i class="m-0 fas fa-paper-plane fa-lg " [title]="getTitleStatus(commands.statuscode)" [ngClass]="getColorStatus(commands.statuscode)" *ngIf="commands.comando.tipo=='diretto'"></i>
            <i class="m-0 fas fa-tower-broadcast fa-lg " [title]="getTitleStatus(commands.statuscode)" [ngClass]="getColorStatus(commands.statuscode)" *ngIf="commands.comando.tipo=='broadcast'"></i>
        </div>
        <div class="col-2">
            {{commands.comando.name}} <i title="visualizza parametri" class="fas ml-2  fa-lg colore_detail_open {{commands.isExpanded ? 'fa-circle-chevron-up ' : 'fa-circle-chevron-down'}} " (click)="commands.isExpanded = !commands.isExpanded" ></i>
            <!--<button class="bottone-selezione col-auto mt-0"  (click)="commands.isExpanded = !commands.isExpanded" ><span>{{commands.isExpanded ? 'Nascondi' : 'Dettaglio'}}</span></button>-->
        </div>
        <div class="col-2">{{commands.comando.tipo}}</div>
        <div class="col-2">{{commands.ts_invio}}</div>
        <div class="col-1">{{commands.feedback}}</div>
        <div class="col-1">{{commands.errors}}</div>
        <div class="col-2"><i *ngIf="commands.broadcast !=''" class="fas fa-industry fa-lg "></i><i *ngIf="commands.broadcast !=''" class="fas fa-caret-right ml-2"></i><i *ngIf="commands.broadcast !=''" class="fa-solid fa-map fa-lg ml-2 mr-2"></i>{{commands.broadcast}}</div>
        <div class="col-1">
            <i style="cursor: pointer;" class="fa-solid fa-table-list fa-lg mr-3 color_blue" (click)="apriDettaglio(commands.id)"></i>
            <i style="cursor: pointer;" class="fa-solid fa-clone fa-lg color_blue" (click)="apriDuple(commands.comando.tipo,commands.id)"></i>
        </div>
    </div>
    
    <div class="row justify-content-center align-items-center expanded-panel parametri" style="height: 50px;" *ngIf="commands.isExpanded">
        <div class="col elenco_parametri">Parametri:</div>
        <div class="col" *ngFor="let parameter of commands.comando.parameter; let i = index">
            <span class="nome_parametro">{{parameter.name}}</span>
            <span class="valore_parametro">{{parameter.code}}</span>
        </div>
    </div>
</ng-container>
