import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBrand } from '../models/models';

export interface IBrandsSearchParams {
  code?: string,
  name?: string,
  idAgent?: number
}

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  private path = "/brands"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getBrands(parameters?: IBrandsSearchParams, ui: boolean = true): Observable<IBrand[]> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createBrand(brand: IBrand): Observable<IBrand> {
    return this.s1HttpClient.post(this.path, brand, false).pipe(
      map(response => response.data)
    )
  }

  updateBrand(brand: IBrand): Observable<IBrand> {
    return this.s1HttpClient.put(this.elementPath(brand), brand, false).pipe(
      map(response => response.data)
    )
  }

  deleteBrand(brand: IBrand): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(brand), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(brand: IBrand): string {
    return this.path + "/" + brand.id
  }

}
