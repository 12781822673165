import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { IProfileNetwork } from '@app/shared/models/profile';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { ProfileStatus } from '@app/core/classes/status';
import { SettingsService } from '@app/core/settings/settings.service';
import { IpAssignmentsService } from '@app/shared/services/ipassignments.service';

@Component({
  selector: 'mdm-profile-network-content',
  templateUrl: './profile-network-content.component.html',
  styleUrls: ['./profile-network-content.component.scss']
})
export class ProfileNetworkContentComponent {

  @Input() set profileNetwork(newValue: IProfileNetwork) {
    if (newValue) {
      this.networkProfile = newValue;

      if(!this.networkProfile.id) {
        this.showOnlyTableOthers = true;
        this.networkProfile.viewCanceledProfile = true;
      }

      if(this.networkProfile.viewCanceledProfile) {
        this.showTableHistory = false;
        this.showTableAssigned = false;
        this.showTableOthers = true;
        //detail not viewable
      }
      if(this.networkProfile.viewPendingProfile) {
        this.showTableHistory = false;
        this.showTableAssigned = true;
        this.showTableOthers = false;
        //update detail if not already selected
        if(this.hasProfileHistory && !this.selected && this.networkProfile.assigned?.length > 0) {
          this.viewAssigned(this.networkProfile.assigned[0]);
        } 
      }
      if(!this.networkProfile.viewCanceledProfile && !this.networkProfile.viewPendingProfile) {
        this.showTableHistory = true;
        this.showTableAssigned = false;
        this.showTableOthers = false;
        //update detail if not already selected
        if(this.hasProfileHistory && !this.selected && this.networkProfile.history?.length > 0) {
          this.selected =  this.networkProfile.history[0];
        }
      }
      
      this.tableHistory.updateData(this.networkProfile.history);
      this.tableAssigned.updateData(this.networkProfile.assigned);
      this.tableOthers.updateData(this.networkProfile.canceled);
      this.updateForm();
    } else {
      this.selected = null;
    }
  }
  @Input() profileForm: UntypedFormGroup;
  @Input() hasProfileHistory: boolean = false;
  @Input() editMode: boolean = true;
  @Input() refreshButton: boolean = true;
  @Input() set profile(newValue: IProfileNetwork) {
    if (newValue) {
      this.networkProfile = newValue;
      this.updateForm();
    }
  }

  @Output() onDetail = new EventEmitter<IProfileNetwork>();
  @Output() onRefresh = new EventEmitter<any>();
  @Output() onChangeUnitProfileStatusInError = new EventEmitter<number>();
  
  @ViewChild('tableHistory') tableHistory: S1Table;
  @ViewChild('tableAssigned') tableAssigned: S1Table;
  @ViewChild('tableOthers') tableOthers: S1Table;
  networkProfile: IProfileNetwork;
  selected: IProfileNetwork;
  showTableHistory: boolean = true;
  showTableAssigned: boolean = false;
  showTableOthers: boolean = false;
  showOnlyTableOthers: boolean = false;
  networkForm: UntypedFormGroup;
  s1ButtonType = S1ButtonType;
  profileStatus = ProfileStatus;
  parentPath: string;
  hasError: boolean;
  notValid: boolean;
  ipStrategies: any;
  dhcpStrategy = 'DHCP';

  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private profilesService: ProfilesService, private assignmentsService: IpAssignmentsService, private ui: S1UIService, private settings: SettingsService) { 
    this.networkForm = this.formBuilder.group({
      connectImmediately: false,
      hidden: false,
      ssid: null,
      encryption: null,
      password: null,
      ipstrategy: null
    });
    this.route.parent.url.subscribe((urlPath) => {
      this.parentPath = "/" + urlPath[urlPath.length - 1].path;
    });
    this.hasError = false;

    this.assignmentsService.getProfileNetworkIpAssignmentStrategies().subscribe(strategies => {
       this.ipStrategies = strategies.map(strategy => { return {'code': strategy.code, "label": strategy.description}});
    });
  }

  refresh() {
    this.selected = null;
    this.onRefresh.emit(this.networkProfile.viewPendingProfile);
  }

  private updateForm() {
    this.networkForm.patchValue({
      connectImmediately: this.networkProfile?.connectImmediately,
      hidden: this.networkProfile?.hidden,
      ssid: this.networkProfile?.ssid,
      encryption: this.networkProfile?.encryption,
      password: this.networkProfile?.password,
      ipstrategy: this.networkProfile?.codeIpAssignmentStrategy
    });
  }

  encryptionChange(type) {
    this.networkForm.patchValue({
      encryption: type ? type.code: null
    });
  }

  validateForm() {
    this.hasError = false;
    this.notValid = false;
  }

  validateIPaddress(ipaddress) {
    return ipaddress ? /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress) : true;
  }

  save() {
    this.validateForm();

    if(this.hasError || this.notValid){
      return;
    }

    const description = this.profileForm.controls.description.value;
    const connectImmediately = this.networkForm?.controls.connectImmediately.value;
    const hidden = this.networkForm?.controls.hidden.value;
    const ssid = this.networkForm?.controls.ssid.value;
    const encryption = this.networkForm?.controls.encryption.value;
    const password = this.networkForm?.controls.password.value;
    const ipstrategy = this.networkForm?.controls.ipstrategy.value;
    const region = this.profileForm?.controls.region.value;
    const site = this.profileForm?.controls.site.value;
    const group = this.profileForm?.controls.group.value;
    if (this.networkProfile) {
      this.networkProfile.description = description;
      this.networkProfile.connectImmediately = connectImmediately;
      this.networkProfile.hidden = hidden;
      this.networkProfile.ssid = ssid;
      this.networkProfile.encryption = encryption;
      this.networkProfile.password = password;
      this.networkProfile.codeIpAssignmentStrategy = ipstrategy;
      this.networkProfile.idRegion = region;
      this.networkProfile.idSite = site;
      this.networkProfile.idGroup = group;
      this.updateNetworkProfile(this.networkProfile);
    } else {
      const newNetworkProfile: IProfileNetwork = {
        idCompany: this.settings.getCompany().code,
        idRegion: region,
        idSite: site,
        idGroup: group,
        description: description,
        connectImmediately: connectImmediately,
        hidden: hidden,
        ssid: ssid,
        encryption: encryption,
        password: password,
        codeIpAssignmentStrategy: ipstrategy
      }
      this.createNetworkProfile(newNetworkProfile);
    }
  }

  private createNetworkProfile(profile: IProfileNetwork) {
    this.profilesService.createProfileNetwork(profile).subscribe(newProfile => {
      if (newProfile) {
        this.ui.showSuccessToast('msg.operation_ok');
        this.router.navigate(['/profiles', newProfile.idProfile, newProfile.nameType]);
      }
    });
  }

  private updateNetworkProfile(profile: IProfileNetwork) {
    this.profilesService.updateProfileNetwork(profile).subscribe(profileUpdated => {
      if (profileUpdated) {
        this.ui.showSuccessToast('msg.operation_ok');
      }
    });
  }

  stylizeStatus(status: string) {
    if (status === ProfileStatus.APPLIED) {
      return 'text-success';
    } else if (status === ProfileStatus.DOWNLOAD || status === ProfileStatus.ASSIGNED || status === ProfileStatus.SENT) {
      return 'text-warning';
    } else if (status === ProfileStatus.ERROR) {
      return 'text-danger';
    } else {
      return 'text-muted';
    }
  }
  
  askChangeProfileStatus(profile: IProfileNetwork) {
    if(profile?.status ===  ProfileStatus.ASSIGNED || profile?.status ===  ProfileStatus.DOWNLOAD || profile?.status ===  ProfileStatus.SENT) {
      let text = 'profiles.distributions.popup.askChangeProfileStatusToCanceled'; //ProfileStatus.ASSIGNED
      if(profile?.status ===  ProfileStatus.DOWNLOAD) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToInterrupted';
      } else if(profile?.status ===  ProfileStatus.SENT) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToAborted';
      }
      this.ui.showDialogPopup(text).then(result => {
        if (result.value) {
          this.onChangeUnitProfileStatusInError.emit(profile.idUnitProfile);
        }
      });
    }
  }

  viewHistory(networkProfile?: IProfileNetwork) {
    if(networkProfile) {
      this.selected = networkProfile;
    } else if(this.networkProfile.history.length > 0) {
      this.selected =  this.networkProfile.history[0];
    }
    this.selected.viewPendingProfile = this.networkProfile.viewPendingProfile;
    this.selected.viewCanceledProfile = false;
    this.onDetail.emit(this.selected);
  }

  viewAssigned(networkProfile?: IProfileNetwork) {
    if(networkProfile) {
      this.selected = networkProfile;
    } else if(this.networkProfile.assigned.length > 0) {
      this.selected =  this.networkProfile.assigned[0];
    }
    this.selected.viewPendingProfile = this.networkProfile.viewPendingProfile;
    this.selected.viewCanceledProfile = false;
    this.onDetail.emit(this.selected);
  }

  viewOthers(networkProfile?: IProfileNetwork) {
    if(networkProfile) {
      this.selected = networkProfile;
    } else if(this.networkProfile.canceled.length > 0) {
      this.selected =  this.networkProfile.canceled[0];
    }
    this.selected.viewPendingProfile = false;
    this.selected.viewCanceledProfile = this.networkProfile.viewCanceledProfile;
    this.onDetail.emit(this.selected);
  }

  viewLastApplied() {
    if(this.networkProfile.history?.length > 0) {
      this.selected =  this.networkProfile.history[0];
      this.selected.viewPendingProfile = this.networkProfile.viewPendingProfile;
      this.selected.viewCanceledProfile = false;
    }
    this.tableHistory.config.pagination.page.page=1;
    this.tableHistory.pagination.changePage();
    this.onDetail.emit(this.selected);
  }

  openHistoryTable() {
    this.showTableOthers = false;
    this.showTableHistory = true;
    this.showTableAssigned = false;
    this.networkProfile.viewPendingProfile = false;
    this.viewHistory();
  }

  openAssignedTable() {
    if(this.networkProfile.assigned?.length > 0) {
      this.showTableOthers=false;
      this.showTableHistory=false;
      this.showTableAssigned=true;
      this.networkProfile.viewPendingProfile = true;
      this.selected.viewCanceledProfile = false;
      this.viewAssigned();
    }
  }

  openOthersTable() {
    if(this.tableOthers.config.rows.length > 0) {
      this.showTableOthers = true;
      this.showTableHistory = false;
      this.showTableAssigned = false;
      this.networkProfile.viewPendingProfile = false;
      this.networkProfile.viewCanceledProfile = true;
      this.viewOthers();
    }
  }
}
