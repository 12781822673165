<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START Left navbar-->
    <ul class="navbar-nav flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
    </ul>
    <ul class="navbar-nav flex-row">
        <!-- START Language button-->
        <li class="nav-item dropdown">
            <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false" style=" text-decoration: none;"><img id="imgNavSel" src="assets/img/lang/{{selectedLanguage}}.png" class="flagIcon"><!--<span id="lanNav{{lang.code}}"></span> <span class="caret"></span>--></a>
            <ul class="dropdown-menu" role="menu" style="min-width:auto !important;">
                <li *ngFor="let lang of getLangs()"><a href="javascript:" id="nav{{lang.code}}"  style=" text-decoration: none;" class="language" (click)="setLang(lang.code)"> <img id="imgNav{{lang.code}}" src="assets/img/lang/{{lang.code}}.png" class="flagIcon">  <!--<span id="lanNav{{lang.code}}"></span>--></a></li>
            </ul>
        </li>
        <!-- END Language menu-->


        <!-- START Profile/Logout menu-->
        <li class="nav-item dropdown">
            <a href="#" class="dropdown-toggle nav-link pt-1" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration: none;">
                <em class="icon-user"></em>
            </a>
            <ul class="dropdown-menu profile-submenu pl-2" role="menu">
                <li class="nav-item">
                    <a *ngIf="!flagSsoCompany" class="nav-link pointer text-primary" (click)="changePassword()">
                        <em class="icon-key"></em> {{'menu.changepassword' | translate}}
                    </a>
                    <a class="nav-link pointer text-danger" (click)="logout()">
                        <em class="icon-logout"></em> {{'menu.logout' | translate}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <!-- END Right Navbar-->
</nav>
<!-- END Top Navbar-->
