<s1-modal #modal [title]="'aeapplications.list.title_configurations'">
  
  <s1-card>
    <form [formGroup]="configurationForm">
      <div class="row text-left">
        <s1-input-text class="col-12 mb-2" [control]="configurationForm.controls.key" [label]="'aeapplications.list.table.columns.key'" [labelGrouped]="false"></s1-input-text>
      
        <div class="col-12 mb-4 text-center">
          <s1-button [type]="s1ButtonType.Save" [disabled]="!configurationForm.valid" (onClick)="save()"></s1-button>
        </div>
      </div>
    </form>
  </s1-card>

  

</s1-modal>