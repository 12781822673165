import {Component, Input, ViewChild} from '@angular/core';
import { IUnit } from '@app/shared/models/models';
import { S1ButtonType } from '@app/s1';
import { ProfileStatus, UnitStatus } from '@app/core/classes/status';
import { NavigationExtras, Router } from '@angular/router';
import { SettingsService } from '@app/core/settings/settings.service';
import {UnitFailureModalComponent} from '@app/shared/components/unit-failure-modal/unit-failure-modal.component';

@Component({
  selector: 'mdm-unit-failure-cell',
  templateUrl: './unit-failure-cell.component.html',
  styleUrls: ['./unit-failure-cell.component.scss']
})
export class UnitFailureCellComponent {
  s1ButtonType = S1ButtonType;
  profileStatus = ProfileStatus;
  
  @Input() unit: IUnit;
  @Input() modal: boolean = false;
  @ViewChild('unitFailureModal') unitFailureModal: UnitFailureModalComponent;
  isDisabled: boolean = false;

  constructor(
    public settings: SettingsService,
    private router: Router,
  ) { }


  ngOnInit(): void {}

  goToUnits(unit: IUnit) {
    let filters = {
      code: unit.code,
      unitEnabled: unit.status !== 'DISABLED' ? 'success' : 'danger'
    };
    let params: NavigationExtras = {
      queryParams: filters,
      skipLocationChange: false,
      fragment: 'top'
    };
    let baseRoute = '/units';
    this.router.navigate([baseRoute],  {state: { filters:  params }});
  }

  openUnitFailureDetails(unit) {
    this.unitFailureModal.open(unit);
    return false;
  }

  stylizeStatus(status: string) {
    if (status === UnitStatus.DEAD || status === UnitStatus.DISCONNECTED) {
      return  'text-danger';
    } if (status === UnitStatus.DISABLED) {
      return  '';
    } else {
      return 'text-success';
    }
  }

}
