import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IProfileAsset } from '@app/shared/models/profile';
import { IUnit } from '@app/shared/models/models';
import { S1Modal, IS1Modal, S1ModalSizes, S1ButtonType, S1ReadableDatePipe } from '@app/s1';
import { UnitsService } from '@app/shared/services/units.service';
import { ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-profile-file-manager-modal',
  templateUrl: './profile-file-manager-modal.component.html',
  styleUrls: ['./profile-file-manager-modal.component.scss']
})
export class ProfileFileManagerModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  s1ButtonType = S1ButtonType;
  unit: IUnit;
  assetProfile: IProfileAsset;
  activeProfile: IProfileAsset;
  profileForm: UntypedFormGroup;
  nameType = 'Asset';

  constructor(private readableDatePipe: S1ReadableDatePipe, private formBuilder: UntypedFormBuilder, private unitsService: UnitsService, private profilesService: ProfilesService) {
    this.profileForm = this.formBuilder.group({
      region: null,
      site: null,
      group: null,
      type: [null, Validators.required],
      code: null,
      description: null,
      startdate: null,
      starttime: null,
      enddate: null,
      endtime: null,
      company: null
    });
  }

  ngOnInit(): void { }

  refresh(viewPendingProfile) {
    if(this.unit.idAssetsProfile || this.unit.idAssetsProfilePending) {
      this.unitsService.getUnitProfileAsset(this.unit).subscribe(asset => {
        this.assetProfile = asset;
        this.activeProfile = asset;
        this.assetProfile.viewPendingProfile = viewPendingProfile;
        this.updateForm();
      });
    }
  }

  getSite(sites) {
    if(this.profileForm.controls.region.value) {
      let site = sites.find(site => site.code == this.assetProfile?.idSite,);
      if(site) {
         this.profileForm.patchValue({
          site: this.assetProfile?.idSite,
        });
      }
    }
  }

  open(assetProfile?: IProfileAsset, unit?: IUnit) {
    this.assetProfile = assetProfile;
    this.assetProfile.nameType = 'Asset';
    this.activeProfile = assetProfile;
    this.unit = unit;
    this.updateForm();
    this.modal.open(S1ModalSizes.XL);
  }

  detail(assetProfile?: IProfileAsset) {
    setTimeout(() => {
      if(assetProfile) {
        let history = this.assetProfile.history;
        let assigned = this.assetProfile.assigned;
        let canceled = this.assetProfile.canceled;
        this.assetProfile = assetProfile;
        this.assetProfile.history = history;
        this.assetProfile.assigned = assigned;
        this.assetProfile.canceled = canceled;
      } else {
        this.assetProfile = this.activeProfile;
      }
      this.updateForm();
    }, 0);
  }

  changeProfileStatusInError(idUnitProfile: number) {
    this.profilesService.forceProfileStatusError(idUnitProfile).subscribe(result => {
      this.refresh(this.assetProfile.viewPendingProfile);
    });
  }

  private updateForm() {
    this.profileForm.patchValue({
      region: this.assetProfile?.idRegion,
      site: this.assetProfile?.idSite,
      group: this.assetProfile?.idGroup,
      type: this.nameType,
      code: this.assetProfile?.codeUnit,
      company: this.assetProfile?.idCompany,
      description: this.assetProfile?.description,
      startdate: this.assetProfile?.tsStart && this.readableDatePipe.transform(new Date(this.assetProfile?.tsStart), true),
      starttime: null,
      enddate: this.assetProfile?.tsEnd && this.readableDatePipe.transform(new Date(this.assetProfile?.tsEnd), true),
      endtime: null
    });
  }

  close() {
    this.assetProfile = null;
    this.modal.close();
  }

}
