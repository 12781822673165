import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { IProfileType } from '@app/shared/models/profile';
import { ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-input-profile-type-select',
  templateUrl: './input-profile-type-select.component.html',
  styleUrls: ['./input-profile-type-select.component.scss']
})
export class InputProfileTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean = false;
  @Input() bindAllObject: boolean = true;
  @Input() unrequiredTypeCode: string;

  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  typesList: IS1InputSelectItem[];

  constructor(private profilesService: ProfilesService) { }
  
  ngOnInit(): void {
    this.getTypes();
  }

  private getTypes() {
    this.profilesService.getTypes().subscribe(types => {
      if (this.unrequiredTypeCode) {
        types = types.filter(type => type.code !== this.unrequiredTypeCode.toUpperCase());
      }
      this.typesList = types.map(type => { 
        return { id: type.id, code: type.code, label: type.name }
      });
      this.onLoadList.emit(this.typesList);
    });
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event: IProfileType) {
    this.onSelectChange.emit(event.id);
  }

}
