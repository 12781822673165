
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'fa fa-home',
    roles: []
};

const Settings = {
  translate: 'menu.settings',
  text: 'Settings',
  link: '/settings',
  icon: 'fa fa-cogs',
  roles: ['SUPERADMIN','ADMIN','OPERATOR', 'OPERATOR_RO'],
  scopes: ['COMPANY','REGION'],
  submenu: [
    {
      translate: 'menu.companies',
      text: 'Companies',
      link: '/settings/companies',
      icon: 'fa fa-industry',
      roles: ['SUPERADMIN']
    },
    {
      translate: 'menu.aeconfigure',
      text: 'Configure',
      link: '/ae/configure',
      icon: 'fab fa-wpforms',
      roles: ['SUPERADMIN','ADMIN']
    },
    {
      translate: 'menu.regions',
      text: 'Regions',
      link: '/settings/regions',
      icon: 'fa fa-map',
      roles: ['SUPERADMIN','ADMIN']
    },
    {
      translate: 'menu.sites',
      text: 'Sites',
      link: '/settings/sites',
      icon: 'fa fa-building',
      roles: ['SUPERADMIN','ADMIN', 'OPERATOR', 'OPERATOR_RO'],
      scopes: ['COMPANY', 'REGION'],
    },
    {
      translate: 'menu.groups',
      text: 'Groups',
      link: '/settings/groups',
      icon: 'fas fa-coins',
      roles: ['SUPERADMIN','ADMIN']
    },
    {
      translate: 'menu.brands',
      text: 'Brands',
      link: '/settings/brands',
      icon: 'far fa-copyright',
      roles: ['SUPERADMIN']
    },
    {
      translate: 'menu.models',
      text: 'Models',
      link: '/settings/models',
      icon: 'fa fa-mobile-alt',
      roles: ['SUPERADMIN']
    },
    {
      translate: 'menu.assets',
      text: 'Assets',
      link: '/settings/assets',
      icon: 'fas fa-puzzle-piece',
      roles: ['SUPERADMIN','ADMIN']
    },
    {
      translate: 'menu.agents',
      text: 'Agents',
      link: '/settings/agents',
      icon: 'fas fa-robot',
      roles: ['SUPERADMIN']
    },
    {
      translate: 'menu.placeholder',
      text: 'Placeholder',
      link: '/settings/placeholders',
      icon: 'fa fa-magnet',
      roles: ['SUPERADMIN', 'ADMIN']
    }
  ]
};

export const HeadingSmartilioMDM = {
  heading: true,
  translate: ' MDM',
  text: ' MDM'
};

const Staging = {
  translate: 'menu.staging',
  text: 'Staging',
  link: '/staging',
  icon: 'fas fa-barcode',
}

const IpAssignments = {
  translate: 'menu.ipAssignments',
  text: 'IP Assignments',
  link: '/ipassignments',
  icon: 'fas fa-globe',
  submenu: [
    {
      translate: 'menu.ipRangeStrategy',
      text: 'IP Range Strategy',
      link: '/settings/rangeip',
      icon: 'fas fa-arrows-alt-h'
    },
    {
      translate: 'menu.fixedIpStrategy',
      text: 'Fixed IP Strategy',
      link: '/settings/fixedip',
      icon: 'fas fa-map-pin',
    }
  ]
}

const Units = {
  translate: 'menu.units',
  text: 'Unit',
  link: '/units',
  icon: 'fas fa-mobile',
  submenu: [
    // {
    //   translate: 'menu.unitsCommand',
    //   text: 'unitsCommand',
    //   link: '/units/command',
    //   icon: 'fas fa-list'
    // },
    {
      translate: 'menu.unitsList',
      text: 'Units',
      link: '/units/list',
      icon: 'fas fa-mobile-alt'
    },
    {
      translate: 'menu.unitsfailures',
      text: 'Units Guasti',
      link: '/units/failure',
      icon: 'fas fa-exclamation-triangle',
    }
  ]
}

const Assets = {
  translate: 'menu.assetsearch',
  text: 'Asset Search',
  link: '/settings/assetssearch',
  icon: 'fas fa-puzzle-piece'
};

const Broadcast = {
  translate: 'menu.broadcast',
  text: 'Broadcast',
  link: '/units',
  icon: 'fas fa-broadcast-tower',
}

const Profiles = {
  translate: 'menu.profiles',
  text: 'Profiles',
  link: '/profiles',
  icon: 'fa fa-laptop-code',
  submenu: [
    {
      translate: 'menu.profilelist',
      text: 'Profile List',
      link: '/profiles',
      icon: 'fas fa-list-ul'
    },
    {
      translate: 'menu.profilesearch',
      text: 'Profile Search',
      link: '/profilessearch',
      icon: 'fa fa-search'
    }
  ]
};

const Enroll = {
  translate: 'menu.enroll',
  text: 'Enroll',
  link: '/assignments',
  icon: 'fas fa-sitemap',
  submenu: [
    {
      translate: 'menu.profilesassignments',
      text: 'Enroll Assignment',
      link: '/assignments',
      icon: 'fas fa-list-ul'
    },
    {
      translate: 'menu.profilesenroll',
      text: 'Enroll Simulation',
      link: '/enrollment',
      icon: 'far fa-play-circle'
    }
  ]
};

const Distribution = {
  translate: 'menu.distributions',
  text: 'Distributions',
  link: '/distributions',
  icon: 'fas fa-upload',
  submenu: [
    {
      translate: 'menu.profiledistributions',
      text: 'Profiles Distribution',
      link: '/distributions',
      icon: 'fa fa-laptop-code'
    },
    {
      translate: 'menu.osdistributions',
      text: 'OS Distribution',
      link: '/os/distributions',
      icon: 'fab fa-google-play'
    },
    {
      translate: 'menu.agentdistributions',
      text: 'Agents Distribution',
      link: '/agent/distributions',
      icon: 'fas fa-robot'
    }
  ]
};

const Users = {
  translate: 'menu.users',
  text: 'Users',
  link: '/user',
  icon: 'fa fa-user',
  roles: ['ADMIN','SUPERADMIN']
};

const AeManagedGooglePlay = {
  translate: 'menu.aemanagedgoogleplay',
  text: 'Google Play Gestito',
  link: '/ae/googleplay',
  icon: 'fab fa-google-play',
  roles: ['ADMIN','SUPERADMIN']
}

// const AE = {
//   translate: 'menu.ae',
//   text: 'Android Enterprise',
//   link: '/ae',
//   icon: 'fab fa-google',
//   roles: ['SUPERADMIN'],
//   submenu: [
//     {
//       translate: 'menu.aedistributions',
//       text: 'Policies Distribution',
//       link: '/ae/distribution',
//       icon: 'fas fa-upload'
//     }
//   ]
// };

export const HeadingSmartilioDispensing = {
  heading: true,
  translate: ' Dispensing',
  text: ' Dispensing',
};

export const HeadingSmartilioShop = {
  heading: true,
  translate: ' Shop',
  text: ' Shop'
};

export const menu = [
  Home,
  Settings,
  Users,
];

export const menuSmartilioMDM = [
  Staging,
  IpAssignments,
  Units,
  Assets,
  Profiles,
  Enroll,
  Distribution,
  AeManagedGooglePlay,
  // AE
];

const DispensingUnits = {
  translate: 'menu.units',
  text: 'Units',
  link: '/dispensing/units',
  icon: 'ml-1 fas fa-mobile',
}

const DispensingDashboard = {
  text: 'Dashboard',
  translate: 'menu.dashboard',
  link: '/dispensing/dashboard',
  icon: 'fas fa-tachometer-alt',
}

export const menuSmartilioDispensing = [
  DispensingUnits,
  DispensingDashboard
];

const ShopCarts = {
  translate: 'menu.carts',
  text: 'Carts',
  link: '/carts/list',
  icon: 'fa fa-shopping-cart'
};
const ShopSettings = {
  translate: 'menu.cartsettings',
  text: 'Settings',
  link: '/carts/settings',
  icon: 'fa fa-wrench'
};

export const menuSmartilioShop = [
  ShopCarts,
  ShopSettings
];

export const resellerCompanyMenu = [
  Users
];
