import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatorService } from '@app/core/translator/translator.service';
import { S1HttpClientService, S1UIService } from '@app/s1';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../../../core/settings/settings.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html'
})
export class SSOComponent {
  
  sso_paramters: any;
  sso_response: any;

  constructor(public settings: SettingsService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private translator: TranslatorService, private ui: S1UIService) {

    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.sso_paramters = params.sso_uuid;

        this.getSession(params.sso_uuid).subscribe(response => {
          this.sso_response = response;
          if(response.token) {
            this.router.navigate(["/home"]);
          }
        });
      }
    );
  }

  getSession(uuid: any, ui: boolean = true): Observable<any> {
    return this.get('/sso/session/' + uuid, ui).pipe(
      map(response => response.data)
    );
  }

  get(path: string, showUI: boolean = true): Observable<any> {
    if (showUI) {
      this.ui.showSpinner()
    }
    return this.http.get<any>(environment.restBaseUrl.replace('/bo',path), this.getRequestOptionArgs())
      .pipe(
        map((response: any) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      )
  }

  private getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
      })
    };
    return httpOptions;
  }

  private handleResponse(response: any, showUI: boolean = true) {

    if (response.outcome.success === true) {
      const dataResponse = response.data;
      localStorage.setItem("name", dataResponse.name);
      localStorage.setItem("surname", dataResponse.surname);
      localStorage.setItem("role", dataResponse.role);
      localStorage.setItem("token", dataResponse.token);
      localStorage.setItem("idCompany", dataResponse.idCompany);
      localStorage.setItem("nameCompany", dataResponse.nameCompany);
      localStorage.setItem("isReseller", dataResponse.resellerCompany);
      if(dataResponse.resellerCompany) {
        localStorage.removeItem("filterOption");
      }
      localStorage.setItem("flgSsoCompany", dataResponse.flgSsoCompany);
      localStorage.setItem("regions", JSON.stringify(dataResponse.regions));
      if(dataResponse.idRegion) {
        localStorage.setItem("idRegion", dataResponse.idRegion);
        localStorage.setItem("nameRegion", dataResponse.nameRegion);
      }
      localStorage.setItem("sites", JSON.stringify(dataResponse.sites));
      if(dataResponse.idSite) {
        localStorage.setItem("idSite", dataResponse.idSite);
        localStorage.setItem("nameSite", dataResponse.nameSite);
      }
      localStorage.setItem("groups", JSON.stringify(dataResponse.groups));
      localStorage.setItem("enabled", dataResponse.enabled);
      localStorage.setItem("commandEnabled", dataResponse.commandEnabled);
      localStorage.setItem("cobrowseToken", dataResponse.cobrowseToken);
      localStorage.setItem("companyModules", dataResponse.companyModules);
    } else {
      localStorage.clear();
    }
    
    if (showUI) {
      this.ui.closeSpinner()
    }
    return response;
  }

  private handleError(error: HttpErrorResponse, showUI: boolean = true) {
    switch(error.status) {
      case 401: // Unauthorized
      case 403: // Forbidden
      localStorage.clear();
      break;
    }
    if (showUI) {
      this.ui.showHTTPErrorPopup(error)
    }
    return throwError(error)
  }
  
}
