import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { StatusLevel } from '@app/core/classes/status';
import { IDispensingUnit } from '@app/shared/models/models';
import { UnitDetailModal } from '../unit-detail-modal/unit-detail-modal.component';

@Component({
    selector: 'unit-card',
    templateUrl: './unit-card.component.html',
    styleUrls: ['./unit-card.component.scss']
})

export class UnitCardComponent implements OnInit {

  @Input() set units (units: IDispensingUnit[]) {
    this.overlappedUnits = units;
    this.isOverlappedUnit = units?.length>1;
    this.unit = units[0];
  };
  @Input() fullMode: boolean = true;
  @ViewChild('detailModal') public detailModal: UnitDetailModal;
  background: string = "bg-light"

  unit: IDispensingUnit;
  overlappedUnits: IDispensingUnit[];
  isOverlappedUnit: boolean = false;
  
  constructor() {}

  public ngOnInit(): void {
    this.background = this.setBackground(this.unit?.statusLevel)
  }

  setBackground(statusLevel): string {
    /*switch (unit.status) {
      case "UNLOCKED":
        return "bg-success-light"
      case "LOCKED":
        return "bg-info-light"
      case "REBOOT":
        return "bg-warning-light"
      case "DEAD":
        return "bg-danger-light"
      case "DISCONNECTED":
        return "bg-dark"
      default:
        return "bg-light"
    }*/
    /*
    1	LOCKED	Locked	INFO
2	UNLOCKED	Unlocked	INFO
3	DISCONNECTED	Disconnected	WARNING
4	REBOOT	Reboot	INFO
5	DEAD	Dead	ERROR
6	AWAKENED	Awakened	INFO
7	ALIVE	Alive	INFO
8	PICKED_UP	Pick Up	INFO
     */
    switch (statusLevel) {
      case StatusLevel.OK:
        return "bg-success-light"
      case StatusLevel.WARNING:
        return "bg-warning-light"
      case StatusLevel.ERROR:
        return "bg-danger-light"
      default:
        return "bg-light"
    }
  }

  goToDetails() {
    this.detailModal.load(this.overlappedUnits)
  }
  
}
