<s1-modal #modal (onClose)="close()">

    <s1-card>
        <form [formGroup]="disableUnitForm">
            <div class="row text-center mb-2">
                <div class="mb-2 d-flex justify-content-center swal2-icon swal2-question swal2-icon-show">
                    <div class="swal2-icon-content">?</div>
                </div>
                <h3 class="col-12 card-title">
                    {{'s1.swal.attention'|translate}}
                </h3>
                <h6 class="col-12 card-title mb-4">
                    {{'units.popup.askFailureAlertMessage'|translate}}
                </h6>

                <div class="col-12 mb-1 d-flex justify-content-center">
                    <s1-input-switch class="col-auto" [control]="disableUnitForm.controls.failureFlg" [label]="'units.popup.askFailure'" [styleClass]="'no-background'" [grouped]="true"></s1-input-switch>
                </div>

                <s1-input-text class="col-12 mb-3" maxlength="255" [control]="disableUnitForm.controls.reason" [label]="'units.popup.askDisableWithReason'" [labelGrouped]="false"></s1-input-text>

                <div class="col-12 mt-4 text-center">
                    <s1-button [type]="s1ButtonType.Save" label="{{'units.popup.askDisableBtn' | translate}}" class="mr-3" (onClick)="disableUnit(false)"></s1-button>
                    <s1-button [type]="s1ButtonType.Save" label="{{'units.popup.askDisableWithResetIpBtn' | translate}}" class="mr-3" (onClick)="disableUnit(true)"></s1-button>
                    <s1-button [type]="s1ButtonType.Back" (onClick)="close()"></s1-button>
                </div>

            </div>
        </form>
    </s1-card>

</s1-modal>