<div class="wrapper">
    <div class="abs-center wd-xl">
        <!-- START card-->
        <div class="text-center mb-4">
            <div class="text-lg mb-3">ERROR</div>
            <p class="lead m0">Oh! Something went wrong :(</p>
        </div>
        
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>