import { Component, Input } from '@angular/core';
import { IDashboarOSItem } from '@app/shared/models/dashboard';

@Component({
  selector: 'mdm-dashboard-os-card',
  templateUrl: './dashboard-os-card.component.html',
  styleUrls: ['./dashboard-os-card.component.scss']
})
export class DashboardOsCardComponent {

  @Input() item: IDashboarOSItem;

  constructor() { }

}
