import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IUnit } from '@app/shared/models/models';
import { IProfileOS } from '@app/shared/models/profile';
import { IS1Modal, S1ButtonType, S1Modal, S1ModalSizes } from '@app/s1';

@Component({
  selector: 'mdm-profile-os-modal',
  templateUrl: './profile-os-modal.component.html',
  styleUrls: ['./profile-os-modal.component.scss']
})
export class ProfileOsModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;

  unit: IUnit;
  osProfile: IProfileOS;

  profileForm: UntypedFormGroup;

  s1ButtonType = S1ButtonType;

  constructor( private formBuilder: UntypedFormBuilder) {

    this.profileForm = this.formBuilder.group({
      type: [null, Validators.required],
      code: null,
      version: null,
      description: null,
      company: null
    });

  }

  ngOnInit(): void { }

  open(osProfile?: IProfileOS, unit?: IUnit) {

    this.osProfile = osProfile
    this.unit = unit
    this.updateForm()

    this.modal.open(S1ModalSizes.XL)

  }

  private updateForm() {

    this.profileForm.patchValue({
      type: this.osProfile?.idType,
      code: this.osProfile?.code,
      company: this.osProfile?.idCompany,
      description: this.osProfile?.description,
      version: this.osProfile?.versionProfile,
    })

  }

  close() {
    this.modal.close()
  }

}
