import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { ProfileDistributionStatus } from '@app/shared/models/profile';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mdm-input-distribution-status-select',
  templateUrl: './input-distribution-status-select.component.html',
  styleUrls: ['./input-distribution-status-select.component.scss']
})
export class InputDistributionStatusSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() placeholder: string = '';
  itemList: IS1InputSelectItem[];
  @Output() onSelectChange = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.getItems()
  }

  private getItems() {
    this.itemList = [
      { code: ProfileDistributionStatus.DONE, label: this.translate.instant("profiles.distributions.all.status_option_DONE")},
      { code: ProfileDistributionStatus.ERROR, label: this.translate.instant("profiles.distributions.all.status_option_ERROR")},
      { code: ProfileDistributionStatus.RUNNING, label: this.translate.instant("profiles.distributions.all.status_option_RUNNING")},
      { code: ProfileDistributionStatus.WAITING, label: this.translate.instant("profiles.distributions.all.status_option_WAITING")},
      { code: ProfileDistributionStatus.NOT_SCHEDULATED, label: this.translate.instant("profiles.distributions.all.status_option_NOT_SCHEDULATED")}
    ];
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}