<s1-modal #modal [title]="'units.list.table.columns.assets'" (onClose)="close()">

  <s1-card class="assets">
    <s1-table #table [onlinePagination]="false">
      <tbody body>
        <tr *ngFor="let asset of table.config.rows; let i = index">
          <td class="py-1 text-center">
              <i class="ml-4 fas fa-file fa-2x" *ngIf="asset.type == 'FILE'"></i>
              <i class="ml-4 fas fa-mobile fa-2x" *ngIf="asset.type == 'APP'"></i>
              <i class="ml-4 fas fa-toolbox fa-2x" *ngIf="asset.type == 'TOOL'"></i>
              <i class="ml-4 fa-google-play fab fa-2x" *ngIf="asset.type == 'OS'"></i>
              <span class="ml-4 d-block">{{ asset.type }}</span>
          </td>
          <td class="py-1 text-left">
              <p class="mb-0 font-weight-bold">{{ 'assets.list.table.columns.name' | translate }}</p>
              <p class="mb-1">{{ asset.name }}</p>
              <p class="mb-0 font-weight-bold">{{ 'profiles.asset.table.columns.description' | translate }}</p>
              <p class="mb-0">{{ asset.description }}</p>
          </td>
          <td class="py-1 text-left">
            <p class="mb-0 font-weight-bold">{{ 'profiles.asset.table.columns.source' | translate }}</p>
            <p class="mb-1"><em class="fa mr-2 fas fa-link"></em>{{ asset.source || '---' }}</p>
            <p class="mb-0 font-weight-bold" [style.display]="isType(asset.type, 'App') ? 'block' : 'none' ">{{ 'profiles.asset.table.columns.package_name' | translate }}</p>
            <p class="mb-0" [style.display]="isType(asset.type, 'App') ? 'block' : 'none' "><em class="fa mr-2 fas fa-folder"></em>{{ asset.packageName || '---' }}</p>
            <p class="mb-0 font-weight-bold" [style.display]="isType(asset.type, 'File') ? 'block' : 'none' ">{{ 'profiles.asset.table.columns.target' | translate }}</p>
            <p class="mb-0" [style.display]="isType(asset.type, 'File') ? 'block' : 'none' "><em class="fa mr-2 fas fa-folder"></em>{{ asset.target || '---' }}</p>
            <p class="mb-0  font-weight-bold" [style.display]="isType(asset.type, 'Tool') ? 'block' : 'none' ">{{ 'profiles.asset.table.columns.execute_with' | translate }}</p>
            <p class="mb-0" [style.display]="isType(asset.type, 'Tool') ? 'block' : 'none' "><em class="fa mr-2 fas fa-user"></em>{{ asset.executeWith || '---'}}</p>
          </td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>

</s1-modal>