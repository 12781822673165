import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartType, ChartDataset } from 'chart.js';

export interface ChartBarConfig {
  data: ChartDataset[];
  colors: any[];
  dataLabels: string[];
  axisLabels: ChartAxisLabels;
}

export interface ChartAxisLabels {
  x: string;
  y: string;
}

export enum ChartColors {
  primary = '#5d9cec',
  secondary = '#fff',
  success = '#27c24c',
  info = '#0371a1',
  warning = '#ff902b',
  danger = '#f05050',
  dark = '#3a3f51',
  darkerInfo = '#01577d'
}

@Component({
  selector: 'chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit {

  @Input() set config(newValue: ChartBarConfig) {
    this.chartConfig = newValue;

    this.barChartOptions.scales.x.title.text = this.chartConfig.axisLabels.x;
    this.barChartOptions.scales.x.title.display = (this.chartConfig.axisLabels.x !== '');

    this.barChartOptions.scales.y.title.text = this.chartConfig.axisLabels.y;
    this.barChartOptions.scales.y.title.display = (this.chartConfig.axisLabels.y !== '');
  }

  @Input() set isStacked(newValue: boolean) {
    this.barChartOptions.scales.x.stacked = newValue;
    this.barChartOptions.scales.y.stacked = newValue;
  }

  @Input() chartHeight = '300px';

  @Output() barClicked = new EventEmitter<{index: number, datasetIndex: number}>();

  chartConfig: ChartBarConfig;

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        grid: {
          display: false
        },
        stacked: false,
        title: {
          display: false,
          text: ''
        },
        ticks: {
          autoSkip: false // Per evitare che in modalità smartphone scompaiano alcune label
        }
      },
      y: {
        grid: {
          borderDash: [8, 4]
        },
        stacked: false,
        title: {
          display: false,
          text: '',
        },
        ticks: {
          callback: (value) => {if (Number(value) % 1 === 0) { return value; }}
        },
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];

  constructor() {}

  ngOnInit() {}

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: any[] }): void {
    console.log(event, active);

    if (active && active.length) {
      const clicked = { index: active[0].index, datasetIndex: active[0].datasetIndex };
      this.barClicked.emit(clicked);
    }

  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
