<s1-card>
  <ng-container *ngIf="showOnlyTableOthers && !selected">
    <div class="text-center my-4">
        <em class="fa-2x mr-2 fas fa-exclamation-circle"></em>
        <span class="h3">{{ 'base.no_profiles' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!showOnlyTableOthers || selected">
    <div class="col fit text-right text-primary" *ngIf="!showTableOthers && refreshButton">
      <s1-button right class="mr-2" [type]="s1ButtonType.Refresh" [onlyIcon]="false" [outline]="true" (onClick)="refresh()"></s1-button>
    </div>
    <div class="d-flex justify-content-end mb-2" *ngIf="!newMode && editMode">
      <s1-button class="mr-2" [type]="s1ButtonType.Add" [onlyIcon]="false" (onClick)="openModalSearchAsset()" [label]="'base.add_from_available_assets'"></s1-button>
      <s1-button [type]="s1ButtonType.NewHighlight" (onClick)="openModalAddAsset()"></s1-button>
    </div>
    <mdm-asset-card *ngFor="let asset of assets" [count]="assets.length" [asset]="asset" 
      (assetUpdated)="updateAsset($event)" (assetMovedUp)="moveAsset($event, true)" (assetMovedDown)="moveAsset($event, false)" 
      (assetReplacedFromSearch)="openModalSearchAsset($event)" (assetReplacedFromNew)="openModalAddAsset($event)" 
      (assetDeleted)="deleteAsset($event)" [editMode]="editMode">
    </mdm-asset-card>
  </ng-container>

  <s1-card class="bg-light" [ngClass]="hasProfileHistory ? '' : 'd-none'">
    <div class="row justify-content-end mt-2 mb-0" *ngIf="!showOnlyTableOthers && (showTableAssigned || showTableOthers)">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="showTableAssigned || (showTableHistory && tableAssigned.config.rows.length > 0) || showTableOthers ? 'text-primary' : 'text-muted'" (click)="openHistoryTable()">
        <span>
          {{ 'base.history' | translate }}
        </span>
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row justify-content-end mt-2 mb-0" *ngIf="!showTableAssigned">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="tableAssigned.config.rows.length > 0 ? 'text-primary' : 'text-muted'" (click)="openAssignedTable()">
        <span>
          {{ 'base.waiting_activation' | translate }} ({{tableAssigned.config.rows.length}})
        </span>
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row justify-content-end mt-0 mb-2" *ngIf="!showTableOthers">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="tableOthers.config.rows.length > 0 ? 'text-primary' : 'text-muted'" (click)="openOthersTable()">
        {{ 'base.canceled_interrupted' | translate }} ({{tableOthers.config.rows.length}})
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row text-left mx-2">
      <div class="col-auto text-primary">
          <h4 *ngIf="showTableHistory">
            {{ 'base.history' | translate }}
            <button type="button" class="col-auto mx-4 my-2 btn-xs btn btn-outline-primary" (click)="viewLastApplied()">
            {{ 'base.show_current' | translate }}
            </button>
          </h4>
          <h4 *ngIf="showTableAssigned">
            {{ 'base.waiting_activation' | translate }}
          </h4>
          <h4 *ngIf="showTableOthers">
            {{ 'base.canceled_interrupted' | translate }}
          </h4>
      </div>
    </div>
    <s1-table #tableHistory [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableHistory ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.start' | translate }}</th>     
          <th class="">{{ 'profiles.list.table.columns.end' | translate }}</th>                                    
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let asset of tableHistory.config.rows; let i = index" class="pointer" [ngClass]="asset == selected ? 'row-selected' : ''" (click)="viewHistory(asset)">
          <td>
            <b [ngClass]="stylizeStatus(asset?.status)">{{asset?.status}}</b>
            
            <ng-container *ngIf="asset?.status ===  profileStatus.ASSIGNED">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(asset)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="asset?.status ===  profileStatus.DOWNLOAD">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(asset)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="asset?.status ===  profileStatus.SENT">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(asset)"></s1-button>  
            </ng-container>

          </td>
          <td>{{ asset.description }}</td>
          <td>{{ asset.tsStart | s1ReadableDate: true }}</td>
          <td>{{ asset.tsEnd | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
    <s1-table #tableAssigned [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableAssigned ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.activation' | translate }}</th>     
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let asset of tableAssigned.config.rows; let i = index;" class="pointer" [ngClass]="asset == selected ? 'row-selected' : ''" (click)="viewAssigned(asset)">
          <td>
            <b [ngClass]="stylizeStatus(asset?.status)">{{asset?.status}}</b>
            
            <ng-container *ngIf="asset?.status ===  profileStatus.ASSIGNED">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(asset)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="asset?.status ===  profileStatus.DOWNLOAD">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(asset)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="asset?.status ===  profileStatus.SENT">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(asset)"></s1-button>  
            </ng-container>

          </td>
          <td>{{ asset.description }}</td>
          <td>{{ asset.tsActivation | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
    <s1-table #tableOthers [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableOthers ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.start' | translate }}</th>     
          <th class="">{{ 'profiles.list.table.columns.end' | translate }}</th>                                      
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let asset of tableOthers.config.rows; let i = index;" class="pointer" [ngClass]="asset == selected ? 'row-selected' : ''" (click)="viewOthers(asset)">
          <td>
            <b [ngClass]="stylizeStatus(asset?.status)">{{asset?.status}}</b>
          </td>
          <td>{{ asset.description }}</td>
          <td>{{ asset.tsStart | s1ReadableDate: true }}</td>
          <td>{{ asset.tsEnd | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>

</s1-card>

<mdm-asset-modal #addAssetModal (assetAdded)="addAsset($event)"></mdm-asset-modal>
<mdm-asset-selection-modal #selectAssetModal [includeOs]="false" (assetSelected)="addAssets($event)"></mdm-asset-selection-modal>
