import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mdm-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent {

  @Input() text: string;
  @Input() label: string;
  @Input() readonly: boolean;
  @Input() editable: boolean = false;
  @Input() control: UntypedFormControl;
  @Output() onPwdEdit = new EventEmitter<void>();

  // Password management values
  @ViewChild('password') password: ElementRef;
  
  displayedPwd: boolean = false;
  editEnabled: boolean = false;

  constructor() { }

  togglePassword(): void {
    this.password.nativeElement.type = this.password.nativeElement.type === 'password' ? 'text' : 'password';
    this.displayedPwd = !this.displayedPwd;
  }

  toggleEdit(): void {
    this.editEnabled = true;
    this.onPwdEdit.emit();
  }

}
