<s1-card>
  <form [formGroup]="OSForm">
    <div class="row text-left pl-2">
      <s1-input-text class="col-5" [control]="OSForm.controls.source" [label]="'profiles.os.fields.source'" [labelGrouped]="false" [readonly]="!editMode"></s1-input-text>
      <s1-input-text class="col-5" [control]="OSForm.controls.target" [label]="'profiles.os.fields.target'" [labelGrouped]="false" [readonly]="!editMode"></s1-input-text>
    </div>
    <div class="col-12 text-center mt-2" *ngIf="editMode">
      <s1-button [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
    </div>
  </form>
</s1-card>