import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { IProfileAssignmentsParams } from './profile-assignment.service';
import { IProfileActivation, IProfileActivationStatistic, IProfileActivationDetail } from '../models/profile';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const fakeProfileActivations = require('../mocks/fake-get-profileActivations.json');
const fakeProfileActivationStatistic = require('../mocks/fake-get-profileActivation-statistic.json');
const fakeProfileActivationDetail = require('../mocks/fake-get-profileActivation-detail.json');
const fakeProfileActivationUnits = require('../mocks/fake-get-profileActivation-units.json');
const fakeProfileActivationUnitsStatistic = require('../mocks/fake-get-profileActivation-units-statistic.json');

enum ProfileActivationAPI {
  apply = "/apply",
  statistic = "/statistic",
  dateActivation = "/tsActivation",
  detail = "detail"
}

export interface IProfileActivationsParams extends IProfileAssignmentsParams { }

export interface IProfileActivationUnitsStatisticParams {
  idProfile: number,
  codStatistic: string
} 

@Injectable({
  providedIn: 'root'
})
export class ProfileActivationService {

  private path = "/profileActivations"

  constructor(private s1HttpClient: S1HttpClientService) {

  }

  getActivations(parameters: IProfileActivationsParams, ui: boolean = true): Observable<IProfileActivation[]> {

    return of(fakeProfileActivations.data.results); // TODO: remove mocks

    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  saveActivations(activations: IProfileActivation[]): Observable<IProfileActivation[]> {

    return of(activations); // TODO: remove mocks

    return this.s1HttpClient.post(this.path, activations).pipe(
      map(response => response.data.results)
    )

  }

  applyActivations(parameters: IProfileActivationsParams): Observable<boolean> {

    return of(true); // TODO: remove mocks

    return this.s1HttpClient.post(this.path + ProfileActivationAPI.apply, parameters).pipe(
      map(response => response.data.results)
    )

  }

  getStatistic(parameters: IProfileActivationsParams): Observable<IProfileActivationStatistic[]> {

    return of(fakeProfileActivationStatistic.data.results); // TODO: remove mocks

    return this.s1HttpClient.get(this.path, parameters).pipe(
      map(response => response.data.results)
    )

  }

  setActivationDate(profile: IProfileActivation): Observable<IProfileActivation> {

    return of(profile); // TODO: remove mocks

    const parameters = { tsActivation: profile.tsActivation }

    return this.s1HttpClient.get(this.elementPath(profile) + ProfileActivationAPI.dateActivation, parameters).pipe(
      map(response => response.data)
    )

  }

  getDetails(parameters: IProfileActivationsParams): Observable<IProfileActivationDetail[]> {

    return of(fakeProfileActivationDetail.data.results); // TODO: remove mocks

    return this.s1HttpClient.get(this.path + ProfileActivationAPI.detail, parameters).pipe(
      map(response => response.data.results)
    )

  }

  getActivationUnits(activationId: number): Observable<IProfileActivation[]> {

    return of(fakeProfileActivationUnits.data.results); // TODO: remove mocks

    return this.s1HttpClient.get(this.path + "/" + activationId).pipe(
      map(response => response.data.results)
    )

  }

  getStatisticUnits(parameters: IProfileActivationUnitsStatisticParams): Observable<IProfileActivation[]> {

    return of(fakeProfileActivationUnitsStatistic.data.results); // TODO: remove mocks

    return this.s1HttpClient.get(this.path, parameters).pipe(
      map(response => response.data.results)
    )

  }

  private elementPath(profile: IProfileActivation): string {
    return this.path + "/" + profile.id
  }
  
}
