import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { IProfilesSearchParams, ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-input-profile-list-select',
  templateUrl: './input-profile-list-select.component.html',
  styleUrls: ['./input-profile-list-select.component.scss']
})
export class InputProfileListSelectComponent implements OnInit {

  @Input() idCompany: number;
  @Input() control: UntypedFormControl;
  @Input() basedOnType: boolean = false;
  @Input() set idType(newValue: number) {
    this.typeSelected = newValue != null;
    if (this.basedOnType && newValue) {
      this.getProfiles(true, newValue);
    }
  }
  @Input() isOss: boolean = false;
  @Input() isAgent: boolean = false;
  @Input() isAe: boolean = false;
  @Input() filterNoAssignments: boolean = false;
  @Input() filterDisabled: boolean = null;
  @Input() readonly: boolean = false;
  @Input() bindAllObject: boolean = true;
  @Input() label: string = 'fields.input.profile';
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  typeSelected = false;
  profileList: IS1InputSelectItem[];

  constructor(private profilesService: ProfilesService) { }

  ngOnInit(): void {
    if (!this.basedOnType) {
      this.getProfiles(false);
    } else {
      this.onLoadList.emit();
    }
  }

  loadListByType(profiles, idType) {
    let list: any;
    if(this.filterNoAssignments) {
      list = profiles.filter(profile => !profile.hasAssignments && (idType ? profile.idType === idType : true));
    } else {
      list = profiles.filter(profile => idType ? profile.idType === idType : true);
    }
    if(list) {
      this.profileList = list.map(profile => { 
        return { code: profile.id, label: profile.description || '-' } 
      });
      this.onLoadList.emit(this.profileList);
    }
  }

  loadListByCode(profiles, code) {
    let list: any;
    if(this.filterNoAssignments) {
      list = profiles.filter(profile => !profile.hasAssignments && (code ? profile.codeType === code : true));
    } else {
      list = profiles.filter(profile => code ? profile.codeType === code : true);
    }
    if(list) {
      this.profileList = list.map(profile => { 
        return { code: profile.id, label: profile.description } 
      });
      this.onLoadList.emit(this.profileList);
    }
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

  // --------- PRIVATE METHODS --------- //

  /** Method that gets the list of profiles, cheching whether it has to be filtered by type (byType true) or code (byType false) */
  private getProfiles(byType: boolean, propertyId = null) {
    const params: IProfilesSearchParams = {
      code: null,
      description: null,
      idType: byType ? propertyId : null,
      idCompany: this.idCompany,
      flgDisabled: this.filterDisabled === null ? null : !this.filterDisabled
    };

    if(this.isOss) {
      this.profilesService.getOss(params).subscribe(profiles => {
        this[byType ? 'loadListByType' : 'loadListByCode'](profiles, propertyId);
      });
    } else if(this.isAgent) {
      this.profilesService.getAgents(params).subscribe(profiles => {
        this[byType ? 'loadListByType' : 'loadListByCode'](profiles, propertyId);
      });
    } else if(this.isAe) {
      this.profilesService.getAes(params).subscribe(profiles => {
        this[byType ? 'loadListByType' : 'loadListByCode'](profiles, propertyId);
      });
    } else {
      params.semaphore = false;
      params.withCheck = false;
      this.profilesService.getProfiles(params, false, true).subscribe(profiles => {
        this[byType ? 'loadListByType' : 'loadListByCode'](profiles.profiles, propertyId);
      });
    } 
  }

}
