import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-input-asset-type-select',
  templateUrl: './input-asset-type-select.component.html',
  styleUrls: ['./input-asset-type-select.component.scss']
})
export class InputAssetTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Input() includeOs: boolean = false;

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  types: Array<any> = new Array();

  constructor( private profiles: ProfilesService ) { }

  ngOnInit(): void {
    this.getAssetTypes();
  }

  getAssetTypes() {
    if(localStorage.getItem("assetTypes_includeOs_" + this.includeOs)) {
      this.types = JSON.parse(localStorage.getItem("assetTypes_includeOs_" + this.includeOs));
    } else {
      this.profiles.getAssestTypes(this.includeOs).subscribe( typesList => {
        this.types = typesList.map( type => {
          return { code: type.id, label: type.description };
        })
        localStorage.setItem("assetTypes_includeOs_" + this.includeOs, JSON.stringify(this.types));
      });
    }
  }
  
  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
