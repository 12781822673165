import { Component, OnInit, Input } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';

export interface ChartPieConfig {
  data: number[],
  colors: string[],
  labels: string | string[]
}

export enum ChartColors {
  primary = "#5d9cec",
  secondary = "#fff",
  success = "#27c24c",
  info = "#0371a1", //"#23b7e5",
  warning = "#ff902b",
  danger = "#f05050",
  dark = "#3a3f51"
}

@Component({
  selector: 'chart-doughnut',
  templateUrl: './chart-doughnut.component.html',
  styleUrls: ['./chart-doughnut.component.scss']
})
export class ChartDoughnutComponent implements OnInit {

  @Input() set pieConfig(newValue: ChartPieConfig) {
    this.chartConfig = newValue;
  }

  chartConfig: ChartPieConfig;

  // Doughnut
  public doughnutChartLabels = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  public doughnutChartData = [40, 60];
  public doughnutChartType: ChartType = 'doughnut';

  constructor() {

    //this.pieChartData = this.chartConfig.data
    
    
   }

  ngOnInit() {
    
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}