<s1-card>
  <ng-container *ngIf="showOnlyTableOthers && !selected">
    <div class="text-center my-4">
        <em class="fa-2x mr-2 fas fa-exclamation-circle"></em>
        <span class="h3">{{ 'base.no_profiles' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!showOnlyTableOthers || selected">
    <div class="col fit text-right text-primary" *ngIf="!showTableOthers && refreshButton">
      <s1-button right class="mr-2" [type]="s1ButtonType.Refresh" [onlyIcon]="false" [outline]="true" (onClick)="refresh()"></s1-button>
    </div>
    <form [formGroup]="networkForm">
      <div class="row text-left pl-2 mt-2">
        <s1-input-text class="offset-md-3 col-3" [control]="networkForm.controls.ssid" [label]="'profiles.network.fields.ssid'" [labelGrouped]="false" [readonly]="!editMode"></s1-input-text>
        <s1-input-switch class="col-auto" [control]="networkForm.controls.connectImmediately" [label]="'profiles.network.fields.connectImmediately'" [readonly]="!editMode"></s1-input-switch>
      </div>
      <div class="row text-left pl-2 mt-2">
        <s1-input-text class="offset-md-3 col-3" [control]="networkForm.controls.encryption" *ngIf="!editMode" [readonly]="true" [label]="'profiles.network.fields.encryption'" [labelGrouped]="false"></s1-input-text>
        <mdm-input-security-network-type-select class="offset-md-3 col-3" [control]="networkForm.controls.encryption" *ngIf="editMode" [bindAllObject]="false" (onSelectClear)="encryptionChange($event)" (onSelectChange)="encryptionChange($event)"></mdm-input-security-network-type-select>
        <ng-container *ngIf="editMode; else pwdPlaceholder">
          <mdm-input-password class="col-3"
            [control]="networkForm.controls.password"
            [label]="'profiles.network.fields.password'"></mdm-input-password>
        </ng-container>
      </div>
      <div class="row text-left pl-2 mt-2">
        <mdm-input-options-select class="offset-md-3 col-3" *ngIf="editMode" [control]="networkForm.controls.ipstrategy" [label]="'profiles.network.fields.ipStrategy'" [options]="ipStrategies" [bindAllObject]="false"></mdm-input-options-select>
        <s1-input-text class="offset-md-3 col-3" *ngIf="!editMode" [control]="networkForm.controls.ipstrategy" [label]="'profiles.network.fields.ipStrategy'" [labelGrouped]="false" [readonly]="!editMode"></s1-input-text>
        <s1-input-switch class="col-auto" [control]="networkForm.controls.hidden" [label]="'profiles.network.fields.hidden'" [readonly]="!editMode"></s1-input-switch>
      </div>
      
      <div class="row text-center mt-2 mb-1">
        <div class="col-12" *ngIf="editMode">
          <s1-button [disabled]="!profileForm.valid" [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
        </div>
      </div>
    </form>
  </ng-container>


  <s1-card class="bg-light" [ngClass]="hasProfileHistory ? '' : 'd-none'">
    <div class="row justify-content-end mt-2 mb-0" *ngIf="!showOnlyTableOthers && (showTableAssigned || showTableOthers)">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="showTableAssigned || (showTableHistory && tableAssigned.config.rows.length > 0) || showTableOthers ? 'text-primary' : 'text-muted'" (click)="openHistoryTable()">
        <span>
          {{ 'base.history' | translate }}
        </span>
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row justify-content-end mt-2 mb-0" *ngIf="!showTableAssigned">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="tableAssigned.config.rows.length > 0 ? 'text-primary' : 'text-muted'" (click)="openAssignedTable()">
        <span>
          {{ 'base.waiting_activation' | translate }} ({{tableAssigned.config.rows.length}})
        </span>
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row justify-content-end mt-0 mb-2" *ngIf="!showTableOthers">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="tableOthers.config.rows.length > 0 ? 'text-primary' : 'text-muted'" (click)="openOthersTable()">
        {{ 'base.canceled_interrupted' | translate }} ({{tableOthers.config.rows.length}})
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row text-left mx-2">
      <div class="col-auto text-primary">
          <h4 *ngIf="showTableHistory">
            {{ 'base.history' | translate }}
            <button type="button" class="col-auto mx-4 my-2 btn-xs btn btn-outline-primary" (click)="viewLastApplied()">
            {{ 'base.show_current' | translate }}
            </button>
          </h4>
          <h4 *ngIf="showTableAssigned">
            {{ 'base.waiting_activation' | translate }}
          </h4>
          <h4 *ngIf="showTableOthers">
            {{ 'base.canceled_interrupted' | translate }}
          </h4>
      </div>
    </div>
    <s1-table #tableHistory [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableHistory ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.start' | translate }}</th>     
          <th class="">{{ 'profiles.list.table.columns.end' | translate }}</th>                                    
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let network of tableHistory.config.rows; let i = index" class="pointer" [ngClass]="network == selected ? 'row-selected' : ''" (click)="viewHistory(network)">
          <td>
            <b [ngClass]="stylizeStatus(network?.status)">{{network?.status}}</b>

            <ng-container *ngIf="network?.status ===  profileStatus.ASSIGNED">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(network)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="network?.status ===  profileStatus.DOWNLOAD">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(network)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="network?.status ===  profileStatus.SENT">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(network)"></s1-button>  
            </ng-container>

          </td>
          <td>{{ network.description }}</td>
          <td>{{ network.tsStart | s1ReadableDate: true }}</td>
          <td>{{ network.tsEnd | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
    <s1-table #tableAssigned [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableAssigned ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.activation' | translate }}</th>     
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let network of tableAssigned.config.rows; let i = index;" class="pointer" [ngClass]="network == selected ? 'row-selected' : ''" (click)="viewAssigned(network)">
          <td>
            <b [ngClass]="stylizeStatus(network?.status)">{{network?.status}}</b>

            <ng-container *ngIf="network?.status ===  profileStatus.ASSIGNED">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(network)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="network?.status ===  profileStatus.DOWNLOAD">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(network)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="network?.status ===  profileStatus.SENT">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(network)"></s1-button>  
            </ng-container>

          </td>
          <td>{{ network.description }}</td>
          <td>{{ network.tsActivation | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
    <s1-table #tableOthers [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableOthers ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.start' | translate }}</th>     
          <th class="">{{ 'profiles.list.table.columns.end' | translate }}</th>                                      
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let network of tableOthers.config.rows; let i = index;" class="pointer" [ngClass]="network == selected ? 'row-selected' : ''" (click)="viewOthers(network)">
          <td>
            <b [ngClass]="stylizeStatus(network?.status)">{{network?.status}}</b>
          </td>
          <td>{{ network.description }}</td>
          <td>{{ network.tsStart | s1ReadableDate: true }}</td>
          <td>{{ network.tsEnd | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>

</s1-card>

<ng-template #pwdPlaceholder>
  <mdm-input-placheholder class="col-3" [label]="'users.user.fields.password'"></mdm-input-placheholder>
</ng-template>