import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mdm-input-executewith-type-select',
  templateUrl: './input-executewith-type-select.component.html',
  styleUrls: ['./input-executewith-type-select.component.scss']
})
export class InputExecuteWithTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Input() set additionalTypes(additionalTypes: string[]) {
    this.types = this.getTypes();
    if (additionalTypes) { // If a new types are added to the select
      additionalTypes.forEach(at => {
        if (!this.types.find(t => t.code === at)) { // The value has to be added in the list only when not available
          this.types = [...this.types, { code: at, label: at }]
        }
      });
    }
  }

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  types: Array<any> = new Array();

  constructor() { }

  ngOnInit(): void {
    this.types = this.getTypes();
  }
  
  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

  // --------- PRIVATE METHODS ---------- //

  private getTypes() {
    return [
      { code: 'MX', label: 'MX' },
      { code: 'DXU', label: 'DXU' },
      { code: 'SURELOCK', label: 'SURELOCK' },
      { code: 'DLSTAGER', label: 'DLSTAGER' },
      { code: 'EZCONFIG', label: 'EZCONFIG' },
      { code: 'SMARTILIO', label: 'SMARTILIO' },
      { code: 'EMKITAGENT', label: 'EMKITAGENT' },
      { code: 'EMINSTALLER', label: 'EMINSTALLER' }
    ].sort((a, b) => (a.code > b.code) ? 1 : -1);
  }

}
