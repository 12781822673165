<s1-card>
  <form [formGroup]="profileForm">

    <!-------------------START-------------------->
    <!----  profile (no distribution) layout ----->
    <!-------------------------------------------->
    <ng-container *ngIf="!distributionMode">

      <!---------START---------->
      <!----  profile EDIT ----->
      <!------------------------>
      <div *ngIf="!editMode || !newMode" class="row text-left ml-2 mb-1"> <!-- unit list view, view profile(!editMode && !newMode), edit profile(editMode && !newMode)-->
        <div class="col-2 mt-4 badge-profile">
          <em class="mr-1 icon-smartilio icon-{{profileForm.controls.type.value | lowercase}}"></em>
          <span class="">{{profileForm.controls.type.value}}</span>
        </div>
        
        <!-- region modificabile in EDIT da admin-->
        <!-- region NON modificabile in VIEW da admin--> 
        <!-- region NON modificabile in UNIT VIEW da admin-->
        <!-- region NON modificabile in EDIT da operatore-->
        <!-- region NON modificabile in VIEW da operatore-->
        <!-- region NON modificabile in UNIT VIEW da operatore-->
        <!-- {{ (readonlyMode || (!editMode && !newMode)) }} -->

        <ng-container *ngIf="isCompanyUser()">
          <!-- *Admin oppure Operatore senza region: può modificare la descrizione del profilo-->
          <s1-input-text class="col-5 mt-1" [readonly]="readonlyMode" [control]="profileForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
          <!-- *Admin oppure Operatore senza region: può modificare la region del profilo ...ho tolto || (!editMode && !newMode)-->
          <div class="col-12"></div>
          <mdm-input-region-select class="offset-2 col-3 mt-1" [readonly]="readonlyMode" [control]="profileForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
            [siteControl]="profileForm.controls.site"></mdm-input-region-select>
          <mdm-input-site-select class="col-3 mt-1" [readonly]="readonlyMode" [control]="profileForm.controls.site"  (onLoadList)="getSite($event)" [idRegion]="profileForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
          <mdm-input-group-select class="col-3 mt-1" [readonly]="readonlyMode" [control]="profileForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
        </ng-container>

        <ng-container *ngIf="!isCompanyUser()">
          <!-- Operatore con region: può modificare la descrizione del profilo quando la sua region è uguale alla region del profilo-->
          <s1-input-text class="col-5 mt-1" [readonly]="readonlyMode || !isAllowedRegion()" [control]="profileForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
          <!-- Operatore con region: non può modificare la region del profilo-->
          <div class="col-12"></div>
          <mdm-input-region-select class="offset-2 col-3 mt-1" [readonly]="true" [control]="profileForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
            [siteControl]="profileForm.controls.site"></mdm-input-region-select>
          <mdm-input-site-select class="col-3 mt-1" [readonly]="true" [control]="profileForm.controls.site"  (onLoadList)="getSite($event)" [idRegion]="profileForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
          <mdm-input-group-select class="col-3 mt-1" [readonly]="true" [control]="profileForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
        </ng-container>

      </div>

      <!-- profile EDIT e devo mostrare il pulsante -->
      <div *ngIf="!settings.isOperatoreRO() && (!editMode || !newMode) && isVisibleSave()" class="row text-center">
        <s1-button [disabled]="!profileForm.valid" class="col mt-0 mb-1" [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
      </div>
      <!---------END---------->
      <!------ profile EDIT -->
      <!---------------------->


      <!---------START---------->
      <!----  profile NEW  ----->
      <!------------------------>
      <div *ngIf="editMode && newMode"  class="row text-left ml-2 mb-1">
        <div class="col-2 pt-3 badge-profile">
          <mdm-input-profile-type-select [control]="profileForm.controls.type" (onSelectClear)="typeChange()" (onSelectChange)="typeChange()" [bindAllObject]="false" [readonly]="settings.isOperatoreRO() || !editMode"></mdm-input-profile-type-select>
        </div>
        <ng-container *ngIf="isVisibleFiledsFirstStep()">
          <s1-input-text class="col-5 pt-4" [readonly]="readonlyMode" [control]="profileForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
          <div class="col-12"></div>
          <mdm-input-region-select class="offset-2 col-3 mt-1" [control]="profileForm.controls.region" [idCompany]="idCompany" [bindAllObject]="false"
            [siteControl]="profileForm.controls.site"></mdm-input-region-select>
          <mdm-input-site-select class="col-3 mt-1" [control]="profileForm.controls.site"  (onLoadList)="getSite($event)" [idRegion]="profileForm.controls.region.value" [bindAllObject]="false"></mdm-input-site-select>
          <mdm-input-group-select class="col-3 mt-1" [control]="profileForm.controls.group" [idCompany]="idCompany" [bindAllObject]="false"></mdm-input-group-select>
        </ng-container>

      </div>
      <!-- NEW profile e devo mostrare il pulsante -->
      <div *ngIf="editMode && newMode && isVisibleSave()" class="row text-center"> <!-- new profile -->
        <s1-button [disabled]="!profileForm.valid" class="col mt-0 mb-1" [type]="s1ButtonType.Next" (onClick)="save()"></s1-button>
      </div>
      <!--------- END ---------->
      <!----  profile NEW  ----->
      <!------------------------>

    </ng-container>
    <!-------------------END---------------------->
    <!----  profile (no distribution) layout ----->
    <!-------------------------------------------->



    <!-------------------START-------------------->
    <!-------  profile distribution layout ------->
    <!-------------------------------------------->
    <ng-container *ngIf="distributionMode">

      <div class="row text-left pl-2 mb-0" *ngIf="!settings.isOperatoreRO()">
        <div class="text-right offset-6 col-6 mt-btn" *ngIf="editMode && (distributionMode != 'agent' || isSuperAdminOrReseller)">
          <label class="text-success">
            <em class="fa icon-bulb mr-1 text-success"></em>
            {{ 'profiles.distributions.'+distributionMode+'.tipLink' | translate }}
          </label>
          <s1-button class="ml-3" (onClick)="goToPage()" [type]="s1ButtonType.Link" [label]="'profiles.distributions.'+distributionMode+'.link'" *ngIf="editMode"></s1-button>
        </div>
      </div>

      <div class="row text-left pl-2 mb-2" *ngIf="distributionMode == 'all'">
        <mdm-input-profile-type-select class="col-3" [control]="profileForm.controls.type" [bindAllObject]="false" *ngIf="editMode && profileForm.controls.type" [readonly]="settings.isOperatoreRO() || !editMode" (onLoadList)="typeLoad($event)" (onSelectChange)="typeChange($event)"></mdm-input-profile-type-select>
        <mdm-input-profile-list-select class="col-6" [filterDisabled]="true" [idCompany]="idCompany" [basedOnType]="(editMode && profileForm.controls.type) ? true : false" [idType]="idType" [control]="profileForm.controls.profile" (onLoadList)="selectCurrentProfile($event)" [bindAllObject]="false" [readonly]="settings.isOperatoreRO() || !editMode"></mdm-input-profile-list-select>
      </div>
      <div class="row text-left pl-2 mb-2" *ngIf="distributionMode == 'os'">
        <mdm-input-os-list-select class="col-6" [idCompany]="idCompany" [control]="profileForm.controls.profile" (onSelectChange)="typeChange()" [bindAllObject]="false" [readonly]="!editMode"></mdm-input-os-list-select>
        <mdm-input-os-action-type-select class="col-6" [control]="profileForm.controls.action" [bindAllObject]="false" [readonly]="!editMode"></mdm-input-os-action-type-select>
      </div>
      <div class="row text-left pl-2 mb-2" *ngIf="distributionMode == 'agent'">
        <mdm-input-agent-list-select *ngIf="editMode; else agentInfo" class="col-6" [idCompany]="idCompany" [control]="profileForm.controls.profile" (onSelectChange)="typeChange()" [bindAllObject]="false" [readonly]="!editMode"></mdm-input-agent-list-select>
        <ng-template #agentInfo>
          <s1-input-text class="col-6" [control]="profileForm.controls.profile" [label]="'fields.input.agent'" [labelGrouped]="false" [readonly]="true"></s1-input-text>
        </ng-template>
      </div>
      <div class="row text-left pl-2 mb-2" *ngIf="distributionMode == 'ae'">
        <mdm-input-profile-list-select class="col-6" [idCompany]="idCompany" [isAe]="true" [label]="'fields.input.policy'" [control]="profileForm.controls.profile" (onLoadList)="selectCurrentProfile($event)" [bindAllObject]="false" [readonly]="settings.isOperatoreRO() || !editMode"></mdm-input-profile-list-select>
      </div>
      
      <div class="row text-left pl-2 mb-2">
        <s1-input-text class="col-6" [readonly]="settings.isOperatoreRO() || !editMode" [control]="profileForm.controls.description" [label]="'profiles.list.table.columns.description'" [labelGrouped]="false"></s1-input-text>
      </div>

      <div class="row text-left pl-2">
        <!-- edit mode -->
        <ng-container *ngIf="editMode">
          <div class="col-auto d-flex">
            <s1-input-datepicker class="mt-1" [control]="profileForm.controls.startdate" [label]="'profiles.list.table.columns.start'" [labelGrouped]="false" [minDate]="minDate" [readonly]="settings.isOperatoreRO() || !editMode"></s1-input-datepicker>
            <div class="pl-1" *ngIf="!settings.isOperatoreRO()">
              <timepicker [formControl]="profileForm.controls.starttime" [showMeridian]="false" [mousewheel]="false" [minuteStep]="1"></timepicker>
            </div>
          </div>
          <div *ngIf="!settings.isOperatoreRO()" class="col-auto mt-4 ml-1">
            <s1-button [label]="'base.now'" [icon]="'far fa-clock'" [outline]="false" (onClick)="setNow()"></s1-button>
          </div>
          <div class="col-auto mt-4 text-danger" *ngIf="editMode && !IsValidStart()">
            {{ 'error.future_date_required' | translate}}
          </div>
        </ng-container>
        <!-- read only -->
        <ng-container *ngIf="!editMode">
          <div class="col-auto">
            <label>{{ 'profiles.list.table.columns.start' | translate }}</label>
            <input class="form-control" type="text" [readonly]="true" value="{{ profileForm.controls.startdate?.value | date: 'dd/MM/yyyy HH:mm:ss'}}"/>
          </div>
        </ng-container>

        <s1-input-switch *ngIf="distributionMode == 'all'" class="col-auto" [control]="profileForm.controls.reboot" [label]="'profiles.list.table.columns.rebootPostDistribution'" [readonly]="settings.isOperatoreRO() || !editMode"></s1-input-switch>
      </div>

      
      
      <div class="row text-center my-1">
        <div *ngIf="!settings.isOperatoreRO() && editMode" class="col-12">
          <s1-button [type]="s1ButtonType.Save" (onClick)="save()" [disabled]="editMode && !IsValidStart()"></s1-button>
        </div>
      </div>

      <mdm-asset-modal *ngIf="distributionMode == 'os'" #addAssetModal (assetAdded)="addAsset($event)" [operativeSystemMode]="true"></mdm-asset-modal>
    </ng-container>
    <!------------------- END -------------------->
    <!-------  profile distribution layout ------->
    <!-------------------------------------------->


  </form>
</s1-card>