import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'battery-icon',
  templateUrl: './battery-icon.component.html',
  styleUrls: ['./battery-icon.component.scss']
})

export class BatteryIconComponent implements OnChanges {

  @Input() percentage: number;
  style: string;
  available: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.available = !isNaN(this.percentage) && this.percentage>=0 && this.percentage<=100 && this.percentage!=null;
    this.style = this.getBatteryStyle();
  }

  getBatteryStyle() {
    if (!isNaN(this.percentage) && this.percentage>=0 && this.percentage<=100 && this.percentage!=null) {
      if (this.percentage > 70) {
        return 'fas fa-battery-full fa-1x text-success';
      } else if (this.percentage <= 70 && this.percentage > 30) {
        return 'fas fa-battery-half fa-1x text-warning';
      } else if (this.percentage <= 30) {
        return 'fas fa-battery-empty fa-1x text-danger';
      }
    } else {
      return 'fas fa-battery-empty fa-1x text-muted';
    }
  }

}
