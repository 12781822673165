import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IProfileActivationStatistic } from '@app/shared/models/profile';

@Component({
  selector: 'mdm-profile-activation-statistic-card',
  templateUrl: './profile-activation-statistic-card.component.html',
  styleUrls: ['./profile-activation-statistic-card.component.scss']
})
export class ProfileActivationStatisticCardComponent implements OnInit {

  @Input() statistic: IProfileActivationStatistic;
  @Output() clicked = new EventEmitter<IProfileActivationStatistic>()

  constructor() { }

  ngOnInit(): void { }

  onClick() {
    this.clicked.emit(this.statistic)
  }

}
