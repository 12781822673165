import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';
import { AeService, IPoliciesSearchParams } from '@app/shared/services/ae.service';

@Component({
  selector: 'mdm-input-ae-policy-select',
  templateUrl: './input-ae-policy-select.component.html',
  styleUrls: ['./input-ae-policy-select.component.scss']
})
export class InputAePolicySelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Input() placeholder: string = '';
  policyList: IS1InputSelectItem[];
  @Output() onSelectChange = new EventEmitter();

  constructor(private settings: SettingsService, private aeService: AeService) { }

  ngOnInit(): void {
    this.getPolicies()
  }

  private getPolicies() {
    const params: IPoliciesSearchParams = {
      idCompany: this.settings.getCompany().code
    };
    this.aeService.getPolicies(params, false).subscribe(policies => {
      this.policyList = policies.map(policy => { 
        return { code: policy.id, label: policy.name } 
      })
    })

  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}