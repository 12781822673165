import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mdm-input-interval-refresh-select',
  templateUrl: './input-interval-refresh-select.component.html',
  styleUrls: ['./input-interval-refresh-select.component.scss']
})
export class InputIntervalRefreshSelectComponent implements OnInit {

  @Output() onSelectInterval = new EventEmitter<number>();
  
  intervals: Array<any>;
  selectedInterval: any;

  constructor() {
  }

  ngOnInit(): void {
    this.intervals = this.getIntervals();
  }

  getIntervals() {
    return [
      { time: 30000, label: '30s' },
      { time: 60000, label: '1m' },
      { time: 300000, label: '5m' },
      { time: 600000, label: '10m' },
      { time: 1800000, label: '30m' }
    ];
  }

  selectInterval(interval) {
    this.selectedInterval = interval;
    this.onSelectInterval.emit(interval.time);
  }
  
  selectManual() {
    this.selectedInterval = null;
    this.onSelectInterval.emit(0);
  }
}