import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IModel } from '../models/models';

export interface IModelsSearchParams {
  code: string,
  name: string,
  idBrand: number
}

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  private path = "/models"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getModels(parameters?: IModelsSearchParams, ui: boolean = true): Observable<IModel[]> {
    return this.s1HttpClient.get(this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createModel(model: IModel): Observable<IModel> {
    return this.s1HttpClient.post(this.path, model, false).pipe(
      map(response => response.data)
    )
  }

  updateModel(model: IModel): Observable<IModel> {
    return this.s1HttpClient.put(this.elementPath(model), model, false).pipe(
      map(response => response.data)
    )
  }

  deleteModel(model: IModel): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(model), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(model: IModel): string {
    return this.path + "/" + model.id
  }

}
