import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1UIService } from '@app/s1';
import { IDashboardInfo } from '@app/shared/models/dashboard';
import { CartsService, IDashboardSearchParams } from '@app/shared/services/carts.service';
import { CompaniesService } from '@app/shared/services/companies.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dashboard-shop',
  templateUrl: './dashboard-shop.component.html',
  styleUrls: ['./dashboard-shop.component.scss']
})
export class DashboardShopComponent implements OnInit, OnDestroy {

  sidebarEventSubscription: Subscription;
  s1ButtonType = S1ButtonType;
  searchForm: UntypedFormGroup;
  optionsGraphsTypesBlocco1: any[];
  optionsGraphsTypesBlocco2: any[];
  selectedOptionsGraphsTypesBlocco1: string;
  showSelectorCartsBlocco1: string = null;
  showSelectorCartsBlocco2: string = null;
  hasErrorGraphsTypeBlocco1: boolean = false;
  hasErrorGraphsTypeBlocco2: boolean = false;

  refreshTimerStore = [];
  dashboardInfo: IDashboardInfo;
  loading = false;
  maxSites = 10;
  isHomeVisible = true;
  msInDay = 1000 * 60 * 60 * 24;

  dates: number[];
  mondays: Date[];
  sundays: Date[];
  now: any;

  idCompany: number;
  dashboardSearchParams: IDashboardSearchParams;
  piePlotType: any;

  cartsConfig: any;
  partialRescansConfig: any;
  totalRescansConfig: any;

  sitesRescansConfig: any;
  sitesRescansPlotType: any;
  sitesCartsStatusConfig: any;
  sitesCartsStatusPlotType: any;
  sitesCartsRevenueConfig: any;
  sitesCartsRevenuePlotType: any;

  daysCartsCounterConfig: any;
  daysCartsCounterPlotType: any;
  daysCartsRevenueConfig: any;
  daysCartsRevenuePlotType: any;

  daysRescansConfig: any;
  daysRescansPlotType: any;

  constructor(private settings: SettingsService, private formBuilder: UntypedFormBuilder, private cartsService: CartsService, public companiesService: CompaniesService, public http: HttpClient, private router: Router, private ui: S1UIService) {
    let monday = this.getMondayOfWeek(new Date());
    let sunday = this.getSundayOfWeek(new Date());
    let now = monday.getTime()
    this.mondays = [monday, monday, monday];
    this.sundays = [sunday, sunday, sunday];
    this.dates = [now, now, now];
    this.now = now;
    this.idCompany = this.settings.getCompany()?.code;
    this.searchForm = this.formBuilder.group({
      region: null,
      site: null,
      graphsTypeBlocco1: null,
      graphsTypeBlocco2: null
    });

    this.sidebarEventSubscription = this.settings.sidebarChanged.subscribe(() => {
      this.ui.showSpinner();
      setTimeout(()=> {
        this.ui.closeSpinner();
      }, 500);
      this.getDashboardInfo();
    });

    this.optionsGraphsTypesBlocco1 = [
      { code: 'SITES_CARTS_STATUS', label: 'Numero Spese per negozio'},
      { code: 'SITES_CARTS_REVENUE', label: 'Fatturato per negozio'},
      { code: 'SITES_RESCANS', label: 'Riletture per negozio'}
    ];

    this.optionsGraphsTypesBlocco2 = [
      { code: 'SPESE_GIORNALIERE_NEGOZIO', label: 'Numero spese giornaliero per negozio'},
      { code: 'FATTURATO_NEGOZIO', label: 'Fatturato giornaliero per negozio'},
      { code: 'RILETTURE_NEGOZIO', label: 'Numero riletture giornaliero per negozio'}
    ];
  }

  ngOnInit() {
    // Qui potresti caricare dati asincroni o fare altre operazioni
    // Dopo che i dati sono caricati, aggiorna il form control come segue:
    /*if (this.optionsGraphsTypesBlocco1.length) {
      this.searchForm.patchValue({graphsTypeBlocco1: this.optionsGraphsTypesBlocco1[0].code});
      this.getGraficoBlocco1();
    }
    if (this.optionsGraphsTypesBlocco2.length) {
      this.searchForm.patchValue({graphsTypeBlocco2: this.optionsGraphsTypesBlocco2[0].code});
    }*/
  }

  /*setRefresh(milliseconds = 0) {
    this.getDashboardInfo();
    this.clearRefreshInterval();
    if(milliseconds > 0) {
      let refreshTimerId = setInterval(() => this.getDashboardInfo(), milliseconds);
      this.refreshTimerStore.push(refreshTimerId);
    }
  }*/

  refresh() {
    this.ui.showSpinner();
    setTimeout(()=> {
      this.ui.closeSpinner();
    }, 500);
    this.getDashboardInfo();
  }

  clearRefreshInterval() {
    this.refreshTimerStore.forEach(intervalId => {
      clearInterval(intervalId);
    });
    this.refreshTimerStore = [];
  }

  getDashboardInfo() {
    this.loading = true;
    setTimeout(()=> {
      this.loading = false;
    }, 1000);

    console.log('refreshTimerStore - dashboardInfo', this.refreshTimerStore);

    this.dashboardSearchParams = {
      idCompany: this.idCompany?.toString(),
      idRegion: null,
      idSite: null,
      tsStart: null,
      tsEnd: null
    }

    if(this.idCompany) {

      this.companiesService.getCompany(this.idCompany, false).subscribe(company => {
        if(company.reseller) {
          this.isHomeVisible = false;
          this.router.navigate(['/user']);
        } else {
          this.isHomeVisible = true;
          this.getCartsInfo();
          this.getPartialRescansInfo();
          this.getTotalRescansInfo();

          this.getGraficoBlocco1();
          this.getGraficoBlocco2();
        }
      });

    }
  }

  getGraficoBlocco1(){
    let tipoGrafico = this.searchForm?.controls.graphsTypeBlocco1.value;
    this.showSelectorCartsBlocco1 = '';
    this.hasErrorGraphsTypeBlocco1 = false;

    switch(tipoGrafico) {
      case 'SITES_CARTS_STATUS': {
        this.showSelectorCartsBlocco1 = 'SITES_CARTS_STATUS';
        this.getSitesCartsStatusInfo();
        break;
      }
      case 'SITES_CARTS_REVENUE': {
        this.showSelectorCartsBlocco1 = 'SITES_CARTS_REVENUE';
        this.getSitesCartsRevenueInfo();
        break;
      }
      case 'SITES_RESCANS': {
        this.showSelectorCartsBlocco1 = 'SITES_RESCANS';
        this.getSitesRescansInfo();
        break;
      }
      default: {
        this.hasErrorGraphsTypeBlocco1 = true;
        break;
      }
    }
  }

  getGraficoBlocco2(){
    let tipoGrafico = this.searchForm?.controls.graphsTypeBlocco2.value;
    let idRegion = this.searchForm.controls.region.value;
    let idSite = this.searchForm.controls.site.value;

    this.showSelectorCartsBlocco2 = '';
    this.hasErrorGraphsTypeBlocco2 = false;


    if(!idRegion || !idSite){
      this.hasErrorGraphsTypeBlocco2 = true;
      return;
    }

    switch(tipoGrafico) {
      case 'SPESE_GIORNALIERE_NEGOZIO': {
        this.showSelectorCartsBlocco2 = 'SPESE_GIORNALIERE_NEGOZIO';
        this.getDaysCartsCounterInfo();
        break;
      }
      case 'FATTURATO_NEGOZIO': {
        this.showSelectorCartsBlocco2 = 'FATTURATO_NEGOZIO';
        this.getDaysCartsRevenueInfo();
        break;
      }
      case 'RILETTURE_NEGOZIO': {
        this.showSelectorCartsBlocco2 = 'RILETTURE_NEGOZIO';
        this.getDaysRescansInfo();
        break;
      }
      default: {
        this.hasErrorGraphsTypeBlocco2 = true;
        break;
      }
    }
  }

  getCartsInfo() {
    this.dashboardSearchParams.tsStart = this.mondays[0].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[0].getTime();
    this.cartsService.getCartsInfo(this.dashboardSearchParams).subscribe(info => {
      let total = info.ok + info.waiting + info.ko;
      let percOk = this.percentage(info.ok, total);
      let percWaiting = this.percentage(info.waiting, total);
      let percKo = this.percentage(info.ko, total);
      this.piePlotType = {
        plotType: 'pie'
      };
      this.cartsConfig = {
        "data": [
          { 'color': '#39C558', 'data': percOk },
          { 'color': '#ff902b', 'data': percWaiting },
          { 'color': '#ff3e43', 'data': percKo },
        ],
        "info": {
          "title": "Spese",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Terminate", "count": info.ok, "style": "success" },
            { "type": "Interrotte", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getPartialRescansInfo() {
    this.dashboardSearchParams.tsStart = this.mondays[0].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[0].getTime();
    this.cartsService.getPartialRescansInfo(this.dashboardSearchParams).subscribe(info => {
      let total = info.ok + info.waiting + info.ko;
      let percOk = this.percentage(info.ok, total);
      let percWaiting = this.percentage(info.waiting, total);
      let percKo = this.percentage(info.ko, total);
      this.piePlotType = {
        plotType: 'pie'
      };
      this.partialRescansConfig = {
        "data": [
          { 'color': '#39C558', 'data': percOk },
          { 'color': '#ff902b', 'data': percWaiting },
          { 'color': '#ff3e43', 'data': percKo },
        ],
        "info": {
          "title": "Riletture Parziali",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getTotalRescansInfo() {
    this.dashboardSearchParams.tsStart = this.mondays[0].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[0].getTime();
    this.cartsService.getTotalRescansInfo(this.dashboardSearchParams).subscribe(info => {
      let total = info.ok + info.waiting + info.ko;
      let percOk = this.percentage(info.ok, total);
      let percWaiting = this.percentage(info.waiting, total);
      let percKo = this.percentage(info.ko, total);
      this.piePlotType = {
        plotType: 'pie'
      };
      this.totalRescansConfig = {
        "data": [
          { 'color': '#39C558', 'data': percOk },
          { 'color': '#ff902b', 'data': percWaiting },
          { 'color': '#ff3e43', 'data': percKo },
        ],
        "info": {
          "title": "Riletture Totali",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getSitesCartsStatusInfo() {
    this.dashboardSearchParams.tsStart = this.mondays[1].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[1].getTime();
    this.cartsService.getSitesCartsStatusInfo(this.dashboardSearchParams).subscribe(info => {
      let dataOk = [];
      let dataWaiting = [];
      let dataKo = [];
      let sites = [];

      info?.results.forEach(data => {
        //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
        let ok = [data.nameSite, data.ok];
        let waiting = [data.nameSite, data.waiting];
        let ko = [data.nameSite, data.ko];
        let site = { 'idSite': data.idSite, 'nameSite': data.nameSite, 'idRegion': data.idRegion};
        if(sites.length<this.maxSites) {
          dataOk.push(ok);
          dataWaiting.push(waiting);
          dataKo.push(ko);
          sites.push(site);
        }
      });

      //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
      for (let i = sites.length+1 ; i<=this.maxSites; i++) {
        dataKo.push(['#'+i, 0]);
        dataWaiting.push(['#'+i, 0]);
        dataOk.push(['#'+i, -1]);
        sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
      }

      let barStackeData = [
        { "color": "#f05050", "sites": sites, "data": dataKo },
        { "color": "#ff902b", "sites": sites, "data": dataWaiting },
        { "color": "#39C558", "sites": sites, "data": dataOk }
      ];
      this.sitesCartsStatusPlotType = {
        plotType: 'bar',
        numCategories: sites.length
      };
      this.sitesCartsStatusConfig = {
        "data": barStackeData,
        "info": {
          "title": "Numero Spese per negozio",
          "link": "Vedi Fatturato",
          "filterLabel": "",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getSitesCartsRevenueInfo() {
    this.dashboardSearchParams.tsStart = this.mondays[1].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[1].getTime();
    this.cartsService.getSitesCartsRevenueInfo(this.dashboardSearchParams).subscribe(info => {
      let dataRevenue = [];
      let sites = [];

      info?.results.forEach(data => {
        //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
        let revenue = [data.nameSite, data.revenue];
        let site = { 'idSite': data.idSite, 'nameSite': data.nameSite, 'idRegion': data.idRegion};
        if(sites.length<this.maxSites) {
          dataRevenue.push(revenue);
          sites.push(site);
        }
      });

      //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
      for (let i = sites.length+1 ; i<=this.maxSites; i++) {
        dataRevenue.push(['#'+i, 0]);
        sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
      }

      let barStackeData = [
        { "color": "#39C558", "sites": sites, "data": dataRevenue },
      ];
      this.sitesCartsRevenuePlotType = {
        plotType: 'bar',
        numCategories: sites.length
      };
      this.sitesCartsRevenueConfig = {
        "data": barStackeData,
        "info": {
          "title": "Fatturato per negozio",
          "link": "Vedi numero spese",
          "filterLabel": "",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getSitesRescansInfo() {
    this.dashboardSearchParams.tsStart = this.mondays[1].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[1].getTime();
    this.cartsService.getSitesRescansInfo(this.dashboardSearchParams).subscribe(info => {
      let dataOk = [];
      let dataWaiting = [];
      let dataKo = [];
      let sites = [];

      info?.results.forEach(data => {
        //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
        let ok = [data.nameSite, data.ok];
        let waiting = [data.nameSite, data.waiting];
        let ko = [data.nameSite, data.ko];
        let site = { 'idSite': data.idSite, 'nameSite': data.nameSite, 'idRegion': data.idRegion};
        if(sites.length<this.maxSites) {
          dataOk.push(ok);
          dataWaiting.push(waiting);
          dataKo.push(ko);
          sites.push(site);
        }
      });

      //nel caso in cui ci siano meno sites di maxSites, riempi fino a maxSites
      for (let i = sites.length+1 ; i<=this.maxSites; i++) {
        dataKo.push(['#'+i, 0]);
        dataWaiting.push(['#'+i, 0]);
        dataOk.push(['#'+i, -1]);
        sites.push({ 'idSite': '#'+i, 'idRegion': null}); //empty
      }

      let barStackeData = [
        { "color": "#f05050", "sites": sites, "data": dataKo },
        { "color": "#ff902b", "sites": sites, "data": dataWaiting },
        { "color": "#39C558", "sites": sites, "data": dataOk }
      ];
      this.sitesRescansPlotType = {
        plotType: 'bar',
        numCategories: sites.length
      };
      this.sitesRescansConfig = {
        "data": barStackeData,
        "info": {
          "title": "Riletture per negozio",
          "filterLabel": "",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getDaysCartsCounterInfo() {
    this.dashboardSearchParams.idRegion = this.searchForm.controls.region.value;
    this.dashboardSearchParams.idSite = this.searchForm.controls.site.value;
    this.dashboardSearchParams.tsStart = this.mondays[2].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[2].getTime();
    this.cartsService.getDaysCartsCounterInfo(this.dashboardSearchParams).subscribe(info => {
      let dataCounter = [];
      let days = [];

      info?.results.forEach(data => {
        //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
        let counter = [data.labelDay, data.counter];
        let day = { 'labelDay': data.labelDay};
        dataCounter.push(counter);
        days.push(day);
      });

      let barStackeData = [
        { "color": "#39C558", "days": days, "data": dataCounter },
      ];
      this.daysCartsCounterPlotType = {
        plotType: 'bar',
        numCategories: days.length
      };
      this.daysCartsCounterConfig = {
        "data": barStackeData,
        "info": {
          "title": "Numero spese giornaliero per negozio",
          "link": "Vedi fatturato giornaliero",
          "filterLabel": "",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getDaysCartsRevenueInfo() {
    this.dashboardSearchParams.idRegion = this.searchForm.controls.region.value;
    this.dashboardSearchParams.idSite = this.searchForm.controls.site.value;
    this.dashboardSearchParams.tsStart = this.mondays[2].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[2].getTime();
    this.cartsService.getDaysCartsRevenueInfo(this.dashboardSearchParams).subscribe(info => {
      let dataRevenue = [];
      let days = [];

      info?.results.forEach(data => {
        //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
        let revenue = [data.labelDay, data.revenue]; //cambio con nameDay
        let day = { 'labelDay': data.labelDay};
        dataRevenue.push(revenue);
        days.push(day);
      });

      let barStackeData = [
        { "color": "#39C558", "days": days, "data": dataRevenue },
      ];
      this.daysCartsRevenuePlotType = {
        plotType: 'bar',
        numCategories: days.length
      };
      this.daysCartsRevenueConfig = {
        "data": barStackeData,
        "info": {
          "title": "Fatturato giornaliero per negozio",
          "link": "Vedi numero spese giornaliero",
          "filterLabel": "",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getDaysRescansInfo() {
    this.dashboardSearchParams.idRegion = this.searchForm.controls.region.value;
    this.dashboardSearchParams.idSite = this.searchForm.controls.site.value;
    this.dashboardSearchParams.tsStart = this.mondays[2].getTime();
    this.dashboardSearchParams.tsEnd = this.sundays[2].getTime();
    this.cartsService.getDaysRescansInfo(this.dashboardSearchParams).subscribe(info => {
      let dataCounter = [];
      let days = [];

      info?.results.forEach(data => {
        //nel caso in cui ci siano più sites di maxSites, non li considerare per la dashboard
        let counter = [data.labelDay, data.counter];
        let day = { 'labelDay': data.labelDay};
        dataCounter.push(counter);
        days.push(day);
      });

      let barStackeData = [
        { "color": "#39C558", "days": days, "data": dataCounter },
      ];
      this.daysRescansPlotType = {
        plotType: 'bar',
        numCategories: days.length
      };
      this.daysRescansConfig = {
        "data": barStackeData,
        "info": {
          "title": "Numero riletture giornaliero per negozio",
          "filterLabel": "",
          "filters": [
            { "type": "In corso", "count": info.waiting, "style": "warning" },
            { "type": "Concluse OK", "count": info.ok, "style": "success" },
            { "type": "Concluse KO", "count": info.ko, "style": "danger" }
          ]
        }
      }
    });
  }

  getRegion(list) {
    this.searchForm.patchValue({
      region: list[0].code
    });
  }
  getSite(list) {
    if(this.searchForm.controls.region.value) {
      this.searchForm.patchValue({
        site: list[0].code
      });
      this.getGraficoBlocco2();
    }
  }

  getGraphsTypeBlocco1(list) {
    // Qui potresti caricare dati asincroni o fare altre operazioni
    // Dopo che i dati sono caricati, aggiorna il form control come segue:
    this.searchForm.patchValue({graphsTypeBlocco1: list[0].code});
    this.getGraficoBlocco1();
  }

  getGraphsTypeBlocco2(list) {
    this.searchForm.patchValue({graphsTypeBlocco2: list[0].code});
  }

  prevWeek(chartIndex: number) {
    this.dates[chartIndex] -= this.msInDay * 7;
    this.mondays[chartIndex] = this.getMondayOfWeek(new Date(this.dates[chartIndex]));
    this.sundays[chartIndex] = this.getSundayOfWeek(new Date(this.dates[chartIndex]));
    this.dates[chartIndex] = this.mondays[chartIndex].getTime();
    this.loadChart(chartIndex);
  }

  nextWeek(chartIndex: number) {
    if(this.sundays[chartIndex].getTime() < this.now) {
      this.dates[chartIndex] += this.msInDay * 7;
      this.mondays[chartIndex] = this.getMondayOfWeek(new Date(this.dates[chartIndex]));
      this.sundays[chartIndex] = this.getSundayOfWeek(new Date(this.dates[chartIndex]));
      this.dates[chartIndex] = this.mondays[chartIndex].getTime();
      this.loadChart(chartIndex);
    }
  }

  loadChart(chartIndex: number) {
    switch (chartIndex) {
      case 0:
        this.getCartsInfo();
        this.getPartialRescansInfo();
        this.getTotalRescansInfo();
        break;
      case 1:
        this.getGraficoBlocco1();
        break;
      case 2:
        this.getGraficoBlocco2();
        break;
    }
  }

  private getMondayOfWeek(from: Date) {
    const first = from.getDate() - from.getDay() + 1;
    const monday = new Date(from.setDate(first));
    monday.setHours(0,0,0);
    return monday;
  }
  private getSundayOfWeek(from: Date) {
    const first = from.getDate() - from.getDay() + 1;
    const last = first + 6;
    const sunday = new Date(from.setDate(last));
    sunday.setHours(23,59,59);
    return sunday;
  }

  ngOnDestroy() {
    this.clearRefreshInterval();
    this.sidebarEventSubscription?.unsubscribe();
  }

  percentage(value, total) {
    if(total>0) {
      return (100 * value / total).toFixed(2);
    }
    return null;
  }
}
