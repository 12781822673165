import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType } from '@app/s1';
import { ITimeline } from '@app/shared/models/models';
import { UnitsService } from '@app/shared/services/units.service';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'unit-timeline',
  templateUrl: './unit-timeline.component.html',
  styleUrls: ['./unit-timeline.component.scss']
})
export class UnitTimelineComponent implements OnInit, OnChanges {

  @Input() codeUnit;
  private autoRefresh: boolean = false;
  private updateSubscription: Subscription;
  timeline: ITimeline[] = [];
  delta: number = 1;
  hasNext: false;
  
  s1ButtonType = S1ButtonType;
  searchForm: UntypedFormGroup;
  moduleTypeOptions: { code: any; label: string; }[];
  filterTypeOptions: { code: any; label: string; type: string}[];
  filterOptions: { code: any; label: string; }[];
  minDate: Date = new Date();

  constructor(private datePipe: DatePipe, private unitsService: UnitsService, private settings: SettingsService,
              private formBuilder: UntypedFormBuilder, private translate: TranslateService) {

    let modules = this.settings.getCompanyModules(this.settings.getCompany().code);
    this.moduleTypeOptions = modules.map(module => { return {code: module, label: module} });
    
    this.filterTypeOptions = [];
    this.filterOptions = [];

    this.searchForm = this.formBuilder.group({
      moduleType: null,
      filterType: null,
      filter: null,
      startdate: null,
      starttime: null,
      enddate: null,
      endtime: null
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.codeUnit.currentValue) {
      this.loadElements();
    }
  }

  changeModuleType() {
    this.searchForm.patchValue({
      filterType: null,
      filter: null
    });
    if(this.searchForm.controls.moduleType.value?.code == 'MDM') {
      this.filterTypeOptions = [
        { code: 'EVENT', label: 'EVENT', type: "options"},
        { code: 'STATUS', label: 'STATUS', type: "text"},
        { code: 'COMMAND', label: 'COMMAND', type: "options"},
        { code: 'FEEDBACK', label: 'FEEDBACK', type: "options"}
      ];
    } else if(this.searchForm.controls.moduleType.value?.code == 'DISPENSING') {
      this.filterTypeOptions = [
        { code: 'EVENT', label: 'EVENT', type: "options"},
        { code: 'STATUS', label: 'STATUS', type: "text"},
      ];
    } 
  }

  changeFilterType() {
    this.searchForm.patchValue({
      filter: null
    });
    if(this.searchForm.controls.moduleType.value?.code == 'MDM' && this.searchForm.controls.filterType.value?.code == 'EVENT') {
      this.filterOptions = [
        { code: 'UNIT_DEAD', label: 'UNIT_DEAD'},
        { code: 'UNIT_DISABLED', label: 'UNIT_DISABLED'},
        { code: 'UNIT_REBOOT', label: 'UNIT_REBOOT'},
        { code: 'UNIT_WAKEUP', label: 'UNIT_WAKEUP'},
        { code: 'UNIT_FALL', label: 'UNIT_FALL'},
      ];
    } else if(this.searchForm.controls.moduleType.value?.code == 'DISPENSING' && this.searchForm.controls.filterType.value?.code == 'EVENT') {
      this.filterOptions = [
        { code: 'UNIT_UNLOCK', label: 'UNIT_UNLOCK'},
        { code: 'UNIT_UNLOCK_ERROR', label: 'UNIT_UNLOCK_ERROR'},
        { code: 'UNIT_PICK_UP', label: 'UNIT_PICK_UP'},
        { code: 'APP_START', label: 'APP_START'},
        { code: 'UNIT_RETURN', label: 'UNIT_RETURN'},
        { code: 'APP_CLOSE', label: 'APP_CLOSE'},
        { code: 'UNIT_MISSING_PICK_UP', label: 'UNIT_MISSING_PICK_UP'},
        { code: 'UNIT_DEAD', label: 'UNIT_DEAD'},
        { code: 'UNIT_REBOOT', label: 'UNIT_REBOOT'},
        { code: 'UNIT_WAKEUP', label: 'UNIT_WAKEUP'},
        { code: 'UNIT_DISCONNECTED', label: 'UNIT_DISCONNECTED'},
        { code: 'UNIT_LOCK', label: 'UNIT_LOCK'}
      ];
    } else if(this.searchForm.controls.filterType.value?.code == 'COMMAND' || this.searchForm.controls.filterType.value?.code == 'FEEDBACK') {
      this.filterOptions = [
        { code: 'GET_PROFILES', label: 'GET_PROFILES'},
        { code: 'SET_REBOOT_TIME', label: 'SET_REBOOT_TIME'},
        { code: 'SET_LOCK_SCREEN', label: 'SET_LOCK_SCREEN'},
        { code: 'PROFILE_ACTIVATION', label: 'PROFILE_ACTIVATION'},
        { code: 'DOWNLOAD_ASSET', label: 'DOWNLOAD_ASSET'},
        { code: 'WIPE', label: 'WIPE'},
        { code: 'MODIFY_KIOSK', label: 'MODIFY_KIOSK'},
        { code: 'SET_LOCALE', label: 'SET_LOCALE'},
        { code: 'SET_BRIGHTNESS', label: 'SET_BRIGHTNESS'},
        { code: 'SET_BARS_VISIBILITY', label: 'SET_BARS_VISIBILITY'},
        { code: 'INSTALL_APP', label: 'INSTALL_APP'},
        { code: 'EXEC_TOOL', label: 'EXEC_TOOL'},
        { code: 'MOVE_FILE_FROM_DOWNLOAD', label: 'MOVE_FILE_FROM_DOWNLOAD'},
        { code: 'GET_FILESYSTEM', label: 'GET_FILESYSTEM'},
        { code: 'GET_FILE', label: 'GET_FILE'},
        { code: 'SET_TIME_ZONE', label: 'SET_TIME_ZONE'},
        { code: 'UPDATE_OS', label: 'UPDATE_OS'},
        { code: 'SET_WIFI', label: 'SET_WIFI'},
        { code: 'VIBRATE', label: 'VIBRATE'},
        { code: 'REBOOT', label: 'REBOOT'},
        { code: 'SET_ADB', label: 'SET_ADB'},
        { code: 'PLAY_SOUND', label: 'PLAY_SOUND'},
        { code: 'FLASH_LED', label: 'FLASH_LED'},
        { code: 'EXECUTE_TOOL', label: 'EXECUTE_TOOL'},
        { code: 'INSTALL_AGENT', label: 'INSTALL_AGENT'}
      ];
    } else if(this.searchForm.controls.filterType.value?.code == 'STATUS') {
      this.filterOptions = [
        { code: 'IP', label: 'IP'},
      ];
    }
  }

  ngOnInit(): void {
    if (this.autoRefresh) {
      this.updateSubscription = interval(60000).subscribe(
        (val) => { this.refresh(); }
      );
    }
  }

  ngOnDestroy() {
    if (this.autoRefresh) {
      this.updateSubscription.unsubscribe();
    }
  }

  loadElements(newElements: boolean = true, showUI: boolean = true) {
    let startDate = this.searchForm?.controls.startdate.value;
    let startTime = this.searchForm?.controls.starttime.value;
    if(startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
    }
    if(startDate && !startTime) {
      startDate.setHours(0,0,0);
    }
    let endDate = this.searchForm?.controls.enddate.value;
    let endTime = this.searchForm?.controls.endtime.value;
    if(endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
    }
    if(endDate && !endTime) {
      endDate.setHours(0,0,0);
    }

    let parameters = {
      codUnit: this.codeUnit,
      moduleType: this.searchForm.controls.moduleType.value?.code,
      filterType: this.searchForm.controls.filterType.value?.code,
      filter: this.searchForm.controls.filter.value,
      startDate: startDate?.getTime(),
      endDate: endDate?.getTime(),
      idCompany: this.settings.getCompany().code,
      timeZoneOffset: new Date().getTimezoneOffset(),
      page: this.delta,
      rows: 50
    };

    this.unitsService.getUnitTimeline(parameters, showUI)
      .subscribe((response) => {

        let list = response.list;
        this.hasNext = response.hasNext;

        let currentDate = null;
        list.forEach(timeline => {
          if(timeline.timelineEventType == "EVENT") {
            timeline.timeEvent = this.datePipe.transform(timeline.tsEvent, 'HH:mm:ss');
          } else if(timeline.timelineEventType == "STATUS") {
            timeline.timeEvent = this.datePipe.transform(timeline.tsStatus, 'HH:mm:ss');
          } else if(timeline.timelineEventType == "FEEDBACK") {
            timeline.timeEvent = this.datePipe.transform(timeline.tsEndExecution, 'HH:mm:ss');
          } else if(timeline.timelineEventType == "COMMAND") {
            timeline.timeEvent = this.datePipe.transform(timeline.tsCreated, 'HH:mm:ss');
          }
          timeline.dateEvent = this.datePipe.transform(timeline.time, 'dd-MM-yyyy');
          if(currentDate && timeline.dateEvent==currentDate) {
            timeline.dateEvent = null;
          } else {
            currentDate = timeline.dateEvent;
          }
        });

        if(newElements) {
          this.timeline = list;
        } else {
          this.timeline = this.timeline.concat(list);
        }

      });
  }

  arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
      for(var j=i+1; j<a.length; ++j) {
        if(a[i].id === a[j].id)
          a.splice(j--, 1);
      }
    }
    return a;
  }

  loadAnotherElements() {
    this.delta += 1;
    this.loadElements(false);
  }
  
  refresh(showUI: boolean = true) {
    this.delta = 1;
    this.loadElements(true, showUI);
  }

  getIcon(type: string) {
    if (type === 'EVENT') {
      return "fa-bell";
    } else if (type === 'COMMAND') {
      return "fa-bolt";
    } else if (type === 'FEEDBACK') {
      return "fa-share";
    } else { //STATUS
      return "icon-feed";
    } 
  }

  getColorIcon(element: any) {
    if (element.timelineEventType === 'EVENT') {
      if (element.codEvent === 'UNIT_DEAD' || element.codEvent === 'DISCONNECTED') {
        return "danger";
      }
      if (element.codEvent === 'UNIT_DISCONNECTED' || element.codEvent === 'UNIT_MISSING_PICKUP') {
        return "warning";
      }
      return "success";
    } else if (element.timelineEventType === 'FEEDBACK') {
      return element.success ? "success" : "danger";
    }
    return "primary";
  }

}