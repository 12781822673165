import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRegion } from '../models/models';

export interface IRegionsSearchParams {
  code: string;
  name: string;
  idCompany: number;
}

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  private path = "/regions"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getRegion(regionId: number, ui: boolean = false): Observable<IRegion> {
    return this.s1HttpClient.get(this.path + "/" + regionId, ui).pipe(
      map(response => response.data)
    )
  }

  getRegions(parameters?: IRegionsSearchParams, minimal: boolean = false, ui: boolean = true): Observable<IRegion[]> {
    return this.s1HttpClient.get(minimal ? this.path + '/minimal' : this.path, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createRegion(region: IRegion): Observable<IRegion> {
    return this.s1HttpClient.post(this.path, region, false).pipe(
      map(response => response.data)
    )
  }

  updateRegion(region: IRegion): Observable<IRegion> {
    return this.s1HttpClient.put(this.elementPath(region), region, false).pipe(
      map(response => response.data)
    )
  }

  deleteRegion(region: IRegion): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(region), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(region: IRegion): string {
    return this.path + "/" + region.id
  }

}
