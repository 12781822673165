<s1-modal #modal [title]="'groups.list.title'" (onClose)="close()">

  <form [formGroup]="searchForm">
    <s1-card [bottomPadding]="false" class="text-left">
      <div class="row mb-3">
        <s1-input-text class="col-3" [control]="searchForm.controls.code" [label]="'groups.list.fields.code'" [labelGrouped]="false"></s1-input-text>
        <s1-input-text class="col-6" [control]="searchForm.controls.description" [label]="'groups.list.fields.description'" [labelGrouped]="false"></s1-input-text>
        <s1-button class="col-auto text-center mt-search" [type]="s1ButtonType.Search" (onClick)="getGroups()"></s1-button>
      </div>
    </s1-card>
  </form>

  <s1-card>
    <s1-table #table [onlinePagination]="false">
      <tbody body>
        <tr *ngFor="let group of table.config.rows; let i = index">
          <td>
            <div class="row text-left">
              <div class="col-3">
                <p>
                  <strong class="mr-1">{{ group.code }}</strong>
                </p>
              </div>
              <div class="col-8">
                <p>
                  <span>{{ group.name }}</span>
                </p>
              </div>
              <div class="col-1 text-right">
                <s1-button *ngIf="group.id == unit.idGroup" [type]="s1ButtonType.Unlink" (onClick)="updateGroup(group, true)"></s1-button>
                <s1-button *ngIf="group.id != unit.idGroup" [type]="s1ButtonType.Link" (onClick)="updateGroup(group, false)"></s1-button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </s1-table>

  </s1-card>
</s1-modal>