import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ChartBarConfig, ChartColors } from '../chart-bar/chart-bar.component';
import { IS1Modal, S1ButtonType, S1Modal, S1ModalSizes, S1UIService } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { DispensingService } from '@app/shared/services/dispensing.service';
import { IDispensingUnit } from '@app/shared/models/models';
import {UnitStatus} from '@app/core/classes/status';

@Component({
  selector: 'unit-detail-modal',
  templateUrl: './unit-detail-modal.component.html',
  styleUrls: ['./unit-detail-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UnitDetailModal implements OnInit, IS1Modal {

  @ViewChild('modal') public modal: S1Modal;
  s1ButtonType = S1ButtonType;
  units: IDispensingUnit[];
  date: any;
  chartConfig: ChartBarConfig;
  private msInDay = 1000 * 60 * 60 * 24;
  isAdminOrSuper: boolean;
  isCommandEnabled: boolean;

  constructor(
    private datePipe: DatePipe,
    private dispensingService: DispensingService,
    private settings: SettingsService,
    private ui: S1UIService
  ) {
    this.isAdminOrSuper = this.settings.isSuperAdmin() || this.settings.isReseller() || this.settings.isAdmin();
    this.isCommandEnabled = this.settings.isCommandEnabled();
    this.date = 0;
    this.chartConfig = { data: [], dataLabels: [], colors: [], axisLabels: { x: '', y: ' '} };
    this.setupGraph([]);
  }

  ngOnInit() {}

  setupGraph(data: any[]) {
    const labelXAxis: string[] = [];
    const pickedList: number[] = [];

    data.forEach(singleHour => {
      labelXAxis.push(singleHour.hour);
      pickedList.push(singleHour.countPickedUp);
    });
    this.chartConfig.dataLabels = labelXAxis;
    this.chartConfig.data = [
      {
        data: pickedList,
        label: 'Picked',
        borderColor: ChartColors.info,
        backgroundColor: ChartColors.info,
        hoverBackgroundColor: ChartColors.darkerInfo
      }
    ];
    this.chartConfig.colors = [{ backgroundColor: ChartColors.info  }];
    this.chartConfig.axisLabels.x = 'Hours';
    this.chartConfig.axisLabels.y = 'Times';
  }

  load(units: IDispensingUnit[]) {
    this.units = units;
    this.loadGraph(units[0]);
    this.modal.open(S1ModalSizes.XL);
  }

  loadGraph(unit: IDispensingUnit) {
    let params = {};
    if (this.date > 0) {
      params = {
        dateFrom: this.datePipe.transform(this.date, 'yyyy-MM-dd')
      };
    }

    this.dispensingService.getGraph(unit.id, params, false).subscribe(graphs => {
      if (graphs.length > 0){
        this.date = graphs[0].date;
        this.setupGraph(graphs[0].details);
      }
    });
  }

  askToExclude(unit: IDispensingUnit) {
    this.ui.showDialogPopup('dispensing.units.popup.askExcludeUnit').then(result => {
      if (result.value) {
        this.exclude(unit);
      }
    });
  }
  askToInclude(unit: IDispensingUnit) {
    this.ui.showDialogPopup('dispensing.units.popup.askIncludeUnit').then(result => {
      if (result.value) {
        this.include(unit);
      }
    });
  }
  exclude(unit: IDispensingUnit) {
    this.dispensingService.excludeUnit(unit.id, true).subscribe(excluded => {
      if (excluded) {
        this.ui.showSuccessToast('dispensing.units.popup.excludedUnit');
        unit.isExcluded = true;
      }
    });
  }

  include(unit: IDispensingUnit) {
    this.dispensingService.excludeUnit(unit.id, false).subscribe(included => {
      if (included) {
        this.ui.showSuccessToast('dispensing.units.popup.includedUnit');
        unit.isExcluded = false;
      }
    });
  }


  prevDay(unit: IDispensingUnit) {
    this.date -= this.msInDay;
    this.loadGraph(unit);
  }

  nextDay(unit: IDispensingUnit) {
    this.date += this.msInDay;
    this.loadGraph(unit);
  }

  close() {
    this.modal.close();
  }


  getStatusStyleClass(status: string) {
    if (status === UnitStatus.DEAD) {
      return 'badge badge-danger';
    } else if (status === UnitStatus.DISCONNECTED) {
      return 'badge badge-warning';
    } else if (status === UnitStatus.DISABLED) {
      return 'badge badge-dark';
    } else {
      return 'badge badge-success';
    }
  }

}
