<div class="row">
  <label class="col-auto" >{{'fields.input.action' | translate}}</label>
</div>
<div class="form-group row mt-1">
  <div class="col-md-10">
      <label class="c-radio">
          <input type="radio" [formControl]="control" value="UPGRADE" checked="checked" [attr.disabled]="readonly ? '' : null"/>
          <span class="mr-2 fa fa-circle"></span>
          <em class="mr-2 fa-2x fas fa-mobile-alt"></em>
          <em class="fa-2x fas fa-long-arrow-alt-up"></em>UPGRADE</label>

      <label class="c-radio ml-4">
          <input type="radio" [formControl]="control" value="DOWNGRADE"  [attr.disabled]="readonly ? '' : null"/>
          <span class="mr-2 fa fa-circle"></span>
          <em class="mr-2 fa-2x fas fa-mobile-alt"></em>
          <em class="fa-2x fas fa-long-arrow-alt-down"></em>DOWNGRADE</label>
  </div>
</div>