import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mdm-input-timezone-type-select',
  templateUrl: './input-timezone-type-select.component.html',
  styleUrls: ['./input-timezone-type-select.component.scss']
})
export class InputTimezoneTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  types: Array<any> = new Array();

  constructor() { }

  ngOnInit(): void {
    this.types = this.getTypes();
  }

  private getTypes() {
    return [
      { code: 'Europe/Rome', label: 'Europe/Rome' },
      { code: 'Europe/Madrid', label: 'Europe/Madrid' },
      { code: 'Europe/Paris', label: 'Europe/Paris' },
      { code: 'Europe/Berlin', label: 'Europe/Berlin' },
      { code: 'Europe/London', label: 'Europe/London' },
      { code: 'America/New_York', label: 'America/New York' },
      { code: 'Asia/Shanghai', label: 'Asia/Shanghai' },
      { code: 'Asia/Calcutta', label: 'Asia/Calcutta' }
    ];
  }
  
  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
