import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ModelsService, IModelsSearchParams } from '@app/shared/services/models.service';
import { IS1InputSelectItem } from '@app/s1';

@Component({
  selector: 'mdm-input-model-select',
  templateUrl: './input-model-select.component.html',
  styleUrls: ['./input-model-select.component.scss']
})
export class InputModelSelectComponent implements OnInit {

  @Output() onLoadList = new EventEmitter<any>();
  @Input() bindAllObject: boolean = true;
  @Input() control: UntypedFormControl;
  @Input() set idBrand(newValue: number) {
    this.brandSelected = newValue != null
    this.control.patchValue(null)
    this.getModels(newValue)  
  }

  @Input() showAll: boolean = false;
  @Input() placeholder: string = '';
  
  brandSelected: boolean = false;

  modelsList: IS1InputSelectItem[];

  constructor(private modelsService: ModelsService) { }

  ngOnInit(): void {}

  /** Models must be loaded only when idBrand is available, therefore a brand was selected and models are selectable */
  private getModels(idBrand?: number, ui: boolean = true) {
    if (idBrand) {
      const params: IModelsSearchParams = {
        code: null,
        name: null,
        idBrand: idBrand
      }
  
      this.modelsService.getModels(params, false).subscribe(models => {
        this.modelsList = models.map(model => { 
          return { code: model.id, label: model.name } 
        });
        this.onLoadList.emit();
      });
    } else {
      this.onLoadList.emit();
    }
  }

}
