<s1-card [title]="" [alignTitleCenter]="false">
  <ng-container header-right>
    <s1-button (onClick)="openSelection()" [type]="s1ButtonType.Add"></s1-button>
  </ng-container>

  <div class="my-4">
    <ng-container *ngIf="profile.tsStart">
      <span class="h4 ml-2">Validity start date <strong>{{profile.tsStart | date: 'dd/MM/yyyy HH:mm:ss' }}</strong></span>
      <span class="ml-2">-</span>
      <ng-container *ngIf="profile.tsEnd"><span class="h4 ml-2">Validity end date <strong>{{ profile?.tsEnd | date: 'dd/MM/yyyy HH:mm:ss'}}</strong></span></ng-container>
      <ng-container *ngIf="!profile.tsEnd"><span class="h4 ml-2">Validity end date <strong>∞</strong></span></ng-container>
    </ng-container>  
    <ng-container *ngIf="!profile.tsStart"><span class="h4 ml-2">Validity start date -</span><span class="h4 ml-2">Validity end date -</span></ng-container>  
  </div>




  <s1-table class="pt-1" #table [onlinePagination]="false">
    <thead header>
      <tr>
        <th>{{'profiles.assignment.sections.hierarchical.table.columns.region' | translate}}</th>
        <th>{{'profiles.assignment.sections.hierarchical.table.columns.site' | translate}}</th>
        <th>{{'profiles.assignment.sections.hierarchical.table.columns.group' | translate}}</th>
        <th>{{'profiles.assignment.sections.hierarchical.table.columns.brand' | translate}}</th>
        <th>{{'profiles.assignment.sections.hierarchical.table.columns.model' | translate}}</th>
        <th>{{'Unit' | translate}}</th>
        <th></th>
      </tr>
    </thead>
    <tbody body>
      <ng-container *ngFor="let assignment of table.config.rows; let i = index">
        <tr>
          <td>{{ assignment.nameRegion || '-' }}</td>
          <td>{{ assignment.nameSite || '-' }}</td>
          <td>{{ assignment.nameGroup || '-' }}</td>
          <td>{{ assignment.nameBrand || '-' }}</td>
          <td>{{ assignment.nameModel || '-' }}</td>
          <td>{{ assignment.codUnit || '-'}}</td>
          <td class="text-left fit">
            <s1-button class="pr-1" (onClick)="showDetail(assignment)" [type]="s1ButtonType.View"></s1-button>
            <s1-button (onClick)="askDeleteRule(assignment)" [type]="s1ButtonType.Delete"></s1-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </s1-table>
</s1-card>

<mdm-hierarchical-selection-modal #hierarchicalSelectionModal (hierarchicalSelected)="addRule($event)"></mdm-hierarchical-selection-modal>