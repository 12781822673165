<div class="refreshIntervals btn-group" dropdown>
  <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle">
    <ng-container *ngIf="selectedInterval">
      {{ 'base.refresh_selected' | translate }} {{ selectedInterval.label }} <span class="caret"></span>
    </ng-container>
    <ng-container *ngIf="!selectedInterval">
      {{ 'base.refresh_manual' | translate }} <span class="caret"></span>
    </ng-container>
  </button>
  <ul *dropdownMenu class="dropdown-menu">
    <li class="text-right" *ngFor="let interval of intervals">
      <a class="dropdown-item" href="javascript:void(0)" (click)="selectInterval(interval)">
        {{ interval.label }}
      </a>
    </li>
    <li class="text-right">
      <a class="dropdown-item" href="javascript:void(0)" (click)="selectManual()">
        {{ 'base.refresh_manual' | translate }}
      </a>
    </li>
  </ul>
</div>