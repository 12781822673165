import { Component, OnInit, Input } from '@angular/core';

export enum SummaryBadgeType {
  NORMAL = "NORMAL",
  INFO = "INFO",
  OK = "OK",
  ERROR = "ERROR",
  STATUS_OK = "STATUS_OK",
  STATUS_WARNING = "STATUS_WARNING",
  STATUS_ERROR = "STATUS_ERROR",
  BATTERY_OK = "BATTERY_OK",
  BATTERY_WARNING = "BATTERY_WARNING",
  BATTERY_DANGER = "BATTERY_DANGER",
  TIME_OK = "TIME_OK",
  TIME_WARNING = "TIME_WARNING",
  TIME_DANGER = "TIME_DANGER"
}

export interface SummaryBadgeConfig {
  type: SummaryBadgeType,
  value: number,
  label: string
}

interface SummaryBadgeStyle {
  icon: string,
  backgroundColor: string,
  iconBackgroundColor: string,
  textColor: string
}

@Component({
    selector: 'summary-badge',
    templateUrl: './summary-badge.component.html',
    styleUrls: ['./summary-badge.component.scss']
})

export class SummaryBadgeComponent implements OnInit {

  @Input() type: SummaryBadgeType = SummaryBadgeType.NORMAL;
  @Input() value: number;
  @Input() label: string;
  @Input() doubleSize: boolean = true;

  style: { [id: string] : SummaryBadgeStyle };
  
  constructor() {

    this.style = {
      "NORMAL": { icon: 'fa fa-tablet-alt', backgroundColor: 'bg-light', iconBackgroundColor: 'bg-light-dark', textColor: 'light' },
      "INFO": { icon: 'fa fa-info', backgroundColor: 'bg-info', iconBackgroundColor: 'bg-info-dark', textColor: 'light' },
      "OK": { icon: 'fa fa-check', backgroundColor: 'bg-success', iconBackgroundColor: 'bg-success-dark', textColor: 'success'  },
      "ERROR": { icon: 'fa fa-times', backgroundColor: 'bg-danger', iconBackgroundColor: 'bg-danger-dark', textColor: 'danger'  },
      "STATUS_OK": { icon: 'fa fa-check', backgroundColor: 'bg-success', iconBackgroundColor: 'bg-success-dark', textColor: 'success'  },
      "STATUS_WARNING": { icon: 'fas fa-exclamation-triangle', backgroundColor: 'bg-warning', iconBackgroundColor: 'bg-warning-dark', textColor: 'warning'  },
      "STATUS_ERROR": { icon: 'fa fa-times', backgroundColor: 'bg-danger', iconBackgroundColor: 'bg-danger-dark', textColor: 'danger'  },
      "BATTERY_OK": { icon: 'fa fa-battery-full', backgroundColor: 'bg-success', iconBackgroundColor: 'bg-success-dark', textColor: 'success'  },
      "BATTERY_WARNING": { icon: 'fa fa-battery-half', backgroundColor: 'bg-warning', iconBackgroundColor: 'bg-warning-dark', textColor: 'warning'  },
      "BATTERY_DANGER": { icon: 'fa fa-battery-empty', backgroundColor: 'bg-danger', iconBackgroundColor: 'bg-danger-dark', textColor: 'danger'  },
      "TIME_OK": { icon: 'fas fa-hourglass-start', backgroundColor: 'bg-success', iconBackgroundColor: 'bg-success-dark', textColor: 'success'  },
      "TIME_WARNING": { icon: 'fas fa-hourglass-half', backgroundColor: 'bg-warning', iconBackgroundColor: 'bg-warning-dark', textColor: 'warning'  },
      "TIME_DANGER": { icon: 'fas fa-hourglass-end', backgroundColor: 'bg-danger', iconBackgroundColor: 'bg-danger-dark', textColor: 'danger'  }
    }

  }

  ngOnInit():void {}
  
}
