<s1-card class="reseller-selection">
  <h4>{{ 'companies.company.fields.link_reseller' | translate }}</h4>
  <s1-table #table [onlinePagination]="false" [withPagination]="false">
    <tbody body>
      <tr *ngFor="let reseller of table.config.rows; let i = index">
        <td>
          <div class="row text-left">
            <div class="col-3">
              <p>
                <strong class="mr-1">{{ reseller.code }}</strong>
              </p>
            </div>
            <div class="col-7">
              <p>
                <span>{{ reseller.name }}</span>
              </p>
            </div>
            <div class="col-2 text-right">
              <s1-button *ngIf="reseller.id == company.idResellerCompany" [type]="s1ButtonType.Unlink" (onClick)="updateCompany(reseller, true)"></s1-button>
              <s1-button *ngIf="reseller.id != company.idResellerCompany" [type]="s1ButtonType.Link" (onClick)="updateCompany(reseller, false)"></s1-button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </s1-table>

</s1-card>