<ng-container>

  <div class="row">

    <div class="col-2 pointer" (click)="goToUnits(unit)">
      <p class="mb-2 font-weight-bold">{{ 'unitsfailures.list.table.columns.code' | translate }}</p>
      <p class="mb-0 pointer text-primary">
        <u>
          <strong class="mr-1">{{ unit?.code }}</strong>
        </u>
      </p>
      <p class="mt-1 mb-0" *ngIf="unit?.nameRegion">
        <em class="fa mr-1 fa fa-map"></em>
        <span class="mr-1">{{ unit?.nameRegion }}</span>
      </p>
      <p class="mt-1 mb-0" *ngIf="unit?.nameSite">
        <em class="fa mr-1 fa fa-building"></em>
        <span class="mr-1">{{ unit?.nameSite }}</span>
      </p>
      <p class="mt-1 mb-0" *ngIf="unit?.nameGroup">
        <em class="fa mr-1 fa fa-coins"></em>
        <span class="mr-1">{{ unit?.nameGroup }}</span>
      </p>
    </div>

    <div class="col-6 disabling-info">
      <p class="mb-2 font-weight-bold">{{ 'unitsfailures.list.table.columns.lastDisabling' | translate }}</p>
      <div>
        <p class="mb-1">
          <span><b>{{ 'unitsfailures.list.table.columns.lastTsDisabling' | translate }}</b>:</span>
          {{ unit?.lastTsDisabling ? (unit?.lastTsDisabling | s1ReadableDate: true) : 'N/D' }}
        </p>
        <p class="mb-1">
          <span><b>{{ 'unitsfailures.list.table.columns.lastDisablingReason' | translate }}</b>:</span>
          {{ unit?.lastDisablingReason ? unit?.lastDisablingReason : 'N/D' }}
        </p>
        <p class="mb-1">
          <span><b>{{ 'unitsfailures.list.table.columns.lastTsEnabling' | translate }}</b>:</span>
          {{ unit?.tsEnabling ? (unit?.tsEnabling | s1ReadableDate: true) : 'N/D'  }}
        </p>
        <p class="mb-1">
          <span><b>{{ 'unitsfailures.list.table.columns.failure' | translate }}</b>:</span>
          {{ unit?.failure ? ('base.yes' | translate) : ('base.no' | translate) }}
        </p>
      </div>
    </div>

    <div class="col-2 mt-2 section-content-centered">
      <div class="mb-1 text-center">
        <b [ngClass]="stylizeStatus(unit?.status)">{{unit?.status}}</b>
      </div>
      <div class="mb-1 text-center" *ngIf="!isDisabled">
        <battery-icon class="ml-2" [percentage]="unit?.batteryPerc"></battery-icon>
      </div>
    </div>
    
    <div class="col-2 mt-2 section-content-centered">
      <div class="mb-2 pointer text-primary" (click)="openUnitFailureDetails(unit)">
        <em class="mr-1 fas fa-info-circle"></em>
        <span>{{'unitsfailures.list.table.columns.archive' | translate}}</span>
      </div>
    </div>

  </div>

</ng-container>


<mdm-unit-failure-modal #unitFailureModal></mdm-unit-failure-modal>