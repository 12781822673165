<s1-modal #modal [title]="'assignments.list.title' | translate: { profile: nameProfile || ''}">
  
  <s1-card>
    <form [formGroup]="profileAssignmentForm">

      <ng-container *ngIf="!bulkRebootFlagsUpdate">
        <div class="row text-left">
          <div class="offset-3 col-auto d-flex align-items-center">
            <s1-input-datepicker [control]="profileAssignmentForm.controls.startdate" [label]="'assignments.list.fields.startdate'" [labelGrouped]="false"></s1-input-datepicker>
            <div class="pl-1">
              <label></label>
              <timepicker [formControl]="profileAssignmentForm.controls.starttime" [showMeridian]="false" [mousewheel]="false" [minuteStep]="1"></timepicker>
            </div>        
          </div>
          <div class="col-auto d-flex align-items-center">
            <s1-button class="mt-3" [label]="'base.now'" [icon]="'far fa-clock'" [outline]="false" (onClick)="setNow()"></s1-button>
          </div>
        </div>
        <div class="row text-left">
          <div class="offset-3 col-auto d-flex align-items-center">
            <s1-input-datepicker [control]="profileAssignmentForm.controls.enddate" [label]="'assignments.list.fields.enddate'" [labelGrouped]="false"></s1-input-datepicker>
            <div class="pl-1">
              <label></label>
              <timepicker [formControl]="profileAssignmentForm.controls.endtime" [showMeridian]="false" [mousewheel]="false" [minuteStep]="1"></timepicker>
            </div>        
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="!bulkDatesUpdate">
        <div class="row mt-3 mb-2">
          <div class="col-3" [ngClass]="bulkRebootFlagsUpdate ? 'offset-4' : 'offset-3'">
            <label>{{'profiles.list.table.columns.rebootPostEnrollment' | translate }}</label>
          </div>
          <s1-input-switch class="col-auto" [control]="profileAssignmentForm.controls.reboot"></s1-input-switch>
        </div>
      </ng-container>

      <div class="row text-left" *ngIf="!isBulkUpdateModal && isCreateModal && !isHierarchicalAssignment">
        <s1-input-text class="offset-1 col-10" [control]="profileAssignmentForm.controls.unit" [label]="'assignments.list.fields.codeunit'" [labelGrouped]="false"></s1-input-text>
      </div>
      <div class="row text-left" *ngIf="!isBulkUpdateModal && isCreateModal && isHierarchicalAssignment">
        <mdm-input-region-select class="offset-1 col-10" placeholder="{{ 'base.all' | translate }}" [control]="profileAssignmentForm.controls.region" [idCompany]="settings.getCompany()?.code"
          [siteControl]="profileAssignmentForm.controls.site"></mdm-input-region-select>
        <mdm-input-site-select class="offset-1 col-10" placeholder="{{ 'base.all' | translate }}" [control]="profileAssignmentForm.controls.site" [idRegion]="profileAssignmentForm.controls.region.value?.code"></mdm-input-site-select>
        <mdm-input-group-select class="offset-1 col-10" placeholder="{{ 'base.all' | translate }}" [control]="profileAssignmentForm.controls.group" [idCompany]="settings.getCompany()?.code"></mdm-input-group-select>
        <mdm-input-brand-select class="offset-1 col-10" placeholder="{{ 'base.all' | translate }}" [control]="profileAssignmentForm.controls.brand"></mdm-input-brand-select>
        <mdm-input-model-select class="offset-1 col-10" placeholder="{{ 'base.all' | translate }}"  [control]="profileAssignmentForm.controls.model" [idBrand]="profileAssignmentForm.controls.brand.value?.code"></mdm-input-model-select>
      </div>
      <div class="row text-left">
        <div class="col-12 text-center my-2">
          <s1-button [disabled]="!profileAssignmentForm.valid" [type]="s1ButtonType.Save" *ngIf="isCreateModal && !isBulkUpdateModal" (onClick)="createProfileAssignment()"></s1-button>
          <s1-button [disabled]="!profileAssignmentForm.valid" [type]="s1ButtonType.Save" *ngIf="!isCreateModal && !isBulkUpdateModal" (onClick)="updateProfileAssignments()"></s1-button>
          <s1-button [disabled]="!profileAssignmentForm.valid" [type]="s1ButtonType.Save" *ngIf="isBulkUpdateModal" (onClick)="updateProfileAssignments()" [icon]="'fas fa-calendar-alt'" [label]="'assignments.assignment.title_massive_update'"></s1-button>
        </div>
        <div class="offset-1 col-10 text-danger">
          <ul>
            <li *ngIf="hasError && !profileAssignmentForm?.controls.startdate?.value">
            {{ 'assignments.list.fields.startdate' | translate}}{{ 'base.required' | translate}}
            </li>
          </ul>
        </div>
      </div>
    </form>
  </s1-card>

</s1-modal>