export interface IFileSystem {
  idUnit: number,
  codUnit: string,
  idCompany: number,
  codCompany: string,
  tsUpload: number,
  fsTree: IFileSystemItem[],
  fileList: IFileItem[]
}

export interface IFileSystemItem {
  directory: boolean,
  name: string,
  path: string,
  size: number,
  subDirectory: IFileSystemItem[],
  tsLastModified: number
}

export interface IFilesList {
  idUnit: number,
  codUnit: string,
  idCompany: number,
  codCompany: string,
  fileList: IFileItem[]
}

export interface IFileItem {
  id: number,
  path: string,
  name: string,
  tsLastModified: number,
  tsUpload: number,
  status?: FileItemStatus
}

export enum FileItemStatus {
  "NONE" = "NONE",
  "REQUESTED" = "REQUESTED",
  "UPLOADED" = "UPLOADED",
  "ERROR" = "ERROR"
}