import { Injectable } from '@angular/core';
import { TranslatorService } from '@app/core/translator/translator.service';
import { IS1SearchParams, S1HttpClientService, SelectItem } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from '../models/models';

export interface IUsersSearchParams {
  name?: string,
  surname?: string,
  email?: string,
  company: number,
  deleted?: boolean
}
export interface IUsersPaginated {
  users: IUser[],
  total: number
}
export interface IUserRole {
  codeRole: string;
  descRole: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private path = '/admin/user/users';
  private pathForce = '/admin/user/users/force';
  private pathRoles = '/admin/role/roles';

  constructor(private s1HttpClient: S1HttpClientService, private translatorService: TranslatorService) { }

  getUsers(searchParams?: IS1SearchParams, usersSearchParams?: IUsersSearchParams, ui: boolean = true): Observable<IUsersPaginated> {
    return this.s1HttpClient.get(this.path, {...searchParams, ...usersSearchParams}, ui).pipe(
      map(response => { return { users: response.data.results, total: response.data.total } })
    )
  }

  getUserRoles(): Observable<SelectItem[]> {
    return this.s1HttpClient.get(this.pathRoles).pipe(
      map(response => { return response.data.results.map((r: IUserRole) => { return { code: r.codeRole, label: r.descRole } }) })
    );
  }

  createUser(user: IUser, force: boolean): Observable<IUser> {
    user['language'] = this.translatorService.browserLanguage();
    const pathCreateUser = !force ? this.path : this.pathForce;
    return this.s1HttpClient.post(pathCreateUser, user).pipe(
      map(response => response.data)
    )
  }

  updateUser(user: IUser): Observable<IUser> {
    return this.s1HttpClient.put(this.elementPath(user), user, true).pipe(
      map(response => response.data)
    )
  }

  deleteUser(user: IUser): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(user), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(user: IUser): string {
    return this.path + '/' + user.idUser
  }

}
