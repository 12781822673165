import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { S1ButtonType } from '@app/s1';

@Component({
  selector: 'mdm-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss']
})
export class FileUploadButtonComponent implements OnInit {
  
  @Input() acceptedType: string;
  @Input() uploadText: string;

  @Output() fileUpload = new EventEmitter<File>();

  S1ButtonType = S1ButtonType;

  constructor() { }

  ngOnInit(): void {
  }

  onFileUpload(target: HTMLInputElement): void {
    this.fileUpload.emit(target.files[0]);
    target.value = null;
  }

}
