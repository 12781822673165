<div class="commands btn-group" dropdown>
  <button dropdownToggle type="button" class="btn btn-secondary dropdown-toggle" [ngClass]="selectedCommand ? styles[selectedCommand.command].color : ''">
    <ng-container *ngIf="selectedCommand">
      <i [ngClass]="styles[selectedCommand.command].icon"></i> {{ selectedCommand.description }} <span class="caret"></span>
    </ng-container>
    <ng-container *ngIf="!selectedCommand">
      {{ 'unitCommands.actions' | translate }} <span class="caret"></span>
    </ng-container>
  </button>
  <ul *dropdownMenu class="dropdown-menu">
    <li *ngFor="let command of unitCommands">
      <a [ngClass]="styles[command.command].color" class="dropdown-item" href="javascript:void(0)" (click)="selectCommand(command)">
        <i [ngClass]="styles[command.command].icon"></i> {{ command.description }}
      </a>
    </li>
  </ul>
</div>
<button type="button" class="btn btn-info ml-1" [disabled]="!selectedCommand || (!unit && unitList?.length==0)" (click)="launchCommand(selectedCommand)">{{ 'unitCommands.sendAction' | translate }}</button>