<s1-card cardStyle="h-100">
  <div class="row mt-2">
    <div class="col-12 text-center">
      <h3 class="text-primary">{{ title | translate }}</h3>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-10 col-md-8 col-xl-6 p-{{ padding }} d-flex flex-column mx-auto">
      <chart-pie class="align-self-stretch" [pieConfig]="chart" (sliceClicked)="elementSelected($event)"></chart-pie>
    </div>
    <div class="col-12 col-md-10 col-xl-8 p-{{ padding }} d-flex flex-column mx-auto">
      <summary-badge *ngFor="let badge of badges" [type]="badge.type" [value]="badge.value" [label]="badge.label"></summary-badge>
    </div>
  </div>

</s1-card>