import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { LoggingService } from '@app/core/services/log.service';
import { S1UIService } from './s1-ui.service';
import { Router } from '@angular/router';
import { TranslatorService } from '@app/core/translator/translator.service';

// Call interfaces
/*export interface IS1SearchParams {
  sortBy?: string,
  isSortAscending?: boolean,
  page: number,
  pageSize: number
  
}*/

export interface IS1SearchParams {
  paging?: boolean,
  page?: number,
  rows?: number,
  pageSize?: number // only for compatibility with standard component 
}

// Response interfaces
export interface IS1PaginationInfo {
  actualPage: number
  next: boolean
  pageSize: number
  previous: boolean
  totalItems: number
  totalPages: number
}

export interface IS1PaginatedResult {
  paginationInfo: IS1PaginationInfo
}

export interface IS1SimplePaginatedResult {
  total: number
}

interface IS1Outcome {
  success: boolean,
  errorMessage: string
  message: string
  code?: string
}

interface IS1Response extends HttpResponse<any> {
  outcome: IS1Outcome
  item?: any
  results?: any[]
  data?: any
  paginationInfo?: IS1PaginationInfo
  total?: number
}

export interface IS1FailureData {
  code: string;
  lineNumber: number;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class S1HttpClientService {

  constructor(private http: HttpClient, private logger: LoggingService, private ui: S1UIService, private router: Router, private translator: TranslatorService) { }

  /**
   * Wrapper of `HTTPClient` post function that centralize the managment of base url, errors and UI feedback
   * 
   * @param path relative path of the API call.
   * @param body JSON body of the API call.
   * @param showUI determine to show or not UI feedback
   * @returns `Observable` of type `IS1Response`
   */

  post(path: string, body: any, showUI: boolean = false): Observable<IS1Response> {

    this.logger.log("POST: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.post<IS1Response>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      )
  }

  get(path: string, urlParameters: any = {}, showUI: boolean = true, skipErrorStatus = null): Observable<IS1Response> {

    const parameters = this.mapParameters(urlParameters)

    this.logger.log("GET: ", path, 200)
    this.logger.log("Par: ", parameters, 200)

    if (showUI) {
      this.ui.showSpinner();
    }

    return this.http.get<any>(environment.restBaseUrl + path + parameters, this.getRequestOptionArgs(false))
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => {

          if(skipErrorStatus && skipErrorStatus == error.status) {
            this.ui.closeSpinner()
            return of(null);
          }
          return this.handleError(error)
        })
      )

  }

  put(path: string, body: any, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log("PUT: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.put<IS1Response>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      )
  }

  delete(path: string, showUI: boolean = true): Observable<IS1Response> { 

    this.logger.log("DELETE: ", path, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.delete<any>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      )

  }

  private handleResponse(response: IS1Response, showUI: boolean = true) {

    const s1Response = response

    this.logger.log("Response: ", s1Response, 200)

    if (s1Response.outcome?.success) {

      if (showUI) {
        this.ui.closeSpinner();
      }

      return s1Response
      
    } else {

      let status = s1Response.outcome.code ?? '499'
      status = (status == '0005') ? '403' : status

      if (showUI) {
        this.ui.closeSpinner();
      }

      if (status === '0009') { // Error on file line, response is false but an error has to be displayed through handleerror 
        const failure: IS1FailureData = s1Response.data.failures[0];
        this.handleError({ status: +failure.code, statusText: failure.message, name: null, message: failure.message, error: failure.lineNumber, headers: null, ok: null, type: HttpEventType.Response, url: null })
      } else {
        throw new HttpErrorResponse({
          status: +status,
          statusText: s1Response.outcome.errorMessage ?? s1Response.outcome.message,
          headers: s1Response.headers,
          url: s1Response.url
        })
      }

    }

  }

  private handleError(error: HttpErrorResponse, showUI: boolean = true) {
    this.logger.log("HTTP Error: ", error, 200)

    switch(error.status) {
      case 401: // Unauthorized
      case 403: // Forbidden
        localStorage.clear();
        this.router.navigate(["/login/1"]);
      break;
    }
    
    // Default popup is displayed when ui is required or when an error was not fired by the request body document itself (0009)
    if (showUI) {
      this.ui.showHTTPErrorPopup(error)
    }

    return throwError(error)
  }

  private getRequestOptionArgs(donwloadFile: boolean = false): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
        'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? "")
      }),
      ...(donwloadFile) && { responseType: 'blob' }
    };
    return httpOptions;
  }

  public mapParameters(parametersObj: any): string {

    var parameterString = "?";

    for (var key in parametersObj) {

      const value = parametersObj[key]

      if (value != null) {

        if (parameterString != "?") {
          parameterString += "&";
        }

        if(Array.isArray(value)) {
          parameterString += value.map(item => key + "=" + encodeURIComponent(item)).join('&');
        } else {
          parameterString += key + "=" + encodeURIComponent(value);
        }
      }
    }

    if (parameterString == "?") {
      return ""
    }

    return parameterString

  }

  downloadPost(path: string, body: any = {}, showUI: boolean = true): Observable<Blob> {

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.post(environment.restBaseUrl + path, body, { headers: { 'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? "") }, responseType: 'blob' })
      .pipe(
        map((response: Blob) => {

          if (response.type == 'application/json') {

            throw new HttpErrorResponse({
              status: +status,
              statusText: 's1.downloadError',
              headers: null,
              url: null
            })

          }

          if (showUI) {
            this.ui.closeSpinner()
          }

          return response;

        }),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )
  }

  download(path: string, urlParameters: any = {}, showUI: boolean = true): Observable<Blob> {

    this.logger.log("path:", path, 200);

    const parameters = this.mapParameters(urlParameters)

    this.logger.log("DOWNLOAD: ", path, 200)
    this.logger.log("Par: ", parameters, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.get(environment.restBaseUrl + path + parameters, { headers: { 'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? "") }, responseType: 'blob' })
      .pipe(
        map((response: Blob) => {

          if (response.type == 'application/json') {

            throw new HttpErrorResponse({
              status: +status,
              statusText: 's1.downloadError',
              headers: null,
              url: null
            })

          }

          if (showUI) {
            this.ui.closeSpinner()
          }

          return response;

        }),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )
  }

  postFormData(path: string, body: any): Observable<any> {

    let requestOptionArgs = {
      reportProgress: true,
      headers: new HttpHeaders({
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
        'Authorization': 'Bearer ' + (localStorage.getItem("token") ?? "")
      })
    };
    const req = new HttpRequest('POST', environment.restBaseUrl + path, body, requestOptionArgs);
    return this.http.request(req)
      .pipe(
        map(event => this.getEventMessage(event)),
        catchError((error: HttpErrorResponse) => this.handleError(error))
      )
  }

  /** Return distinct message for sent, upload progress, & response events */
  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 0;
      case HttpEventType.UploadProgress:
        return Math.round(100 * event.loaded / event.total);
      case HttpEventType.Response:
        return this.handleResponse(event.body, false);
      default:
        return null;
    }
  }
}

