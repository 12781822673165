import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {

    if(!isNaN(value)){

      let startValue = value

      let fromNow = args.length > 0 ? args[0] : false

      if (fromNow) {
        
        let now = new Date().getTime() / 1000;

        startValue = Math.floor(now - value);

      }

      const hours = Math.floor(startValue / 3600)

      if (hours >= 100) {
        return "> 100h"
      }

      const minutes = Math.floor((startValue - (hours * 3600)) / 60)
      const seconds = startValue - (hours * 3600) - (minutes * 60)

      const hoursStr = hours > 0 ? hours + 'h ' : ''
      const minutesStr = minutes > 0 ? ('0' + minutes).substr(-2) + 'm ' : ''
      const secondsStr = ('0' + seconds).substr(-2) + 's '
      
      return hoursStr + minutesStr + secondsStr;
    }
    return;
  }

}
