import { Component, OnInit, ViewChild } from '@angular/core';
import { IS1Modal, S1Modal, S1ButtonType, S1ModalSizes, S1ReadableDatePipe } from '@app/s1';
import { IUnit } from '@app/shared/models/models';
import { IProfileNetwork } from '@app/shared/models/profile';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UnitsService } from '@app/shared/services/units.service';
import { ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-profile-network-modal',
  templateUrl: './profile-network-modal.component.html',
  styleUrls: ['./profile-network-modal.component.scss']
})
export class ProfileNetworkModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  
  s1ButtonType = S1ButtonType;
  unit: IUnit;
  networkProfile: IProfileNetwork;
  activeProfile: IProfileNetwork;
  profileForm: UntypedFormGroup;
  nameType = 'Network';

  constructor(private readableDatePipe: S1ReadableDatePipe, private formBuilder: UntypedFormBuilder, private unitsService: UnitsService, private profilesService: ProfilesService) {
    this.profileForm = this.formBuilder.group({
      region: null,
      site: null,
      group: null,
      type: [null, Validators.required],
      code: null,
      description: null,
      startdate: null,
      starttime: null,
      enddate: null,
      endtime: null,
      company: null
    });
  }

  ngOnInit(): void {}

  getSite(sites) {
    if(this.profileForm.controls.region.value) {
      let site = sites.find(site => site.code == this.networkProfile?.idSite,);
      if(site) {
         this.profileForm.patchValue({
          site: this.networkProfile?.idSite,
        });
      }
    }
  }

  refresh(viewPendingProfile) {
    if(this.unit.idNetworkProfile || this.unit.idNetworkProfilePending) {
      this.unitsService.getUnitProfileNetwork(this.unit).subscribe(network => {
        this.networkProfile = network;
        this.activeProfile = network;
        this.networkProfile.viewPendingProfile = viewPendingProfile;
        this.updateForm();
      });
    }
  }  

  open(networkProfile?: IProfileNetwork, unit?: IUnit) {
    this.networkProfile = networkProfile;
    this.networkProfile.nameType = 'Network'
    this.activeProfile = networkProfile;
    this.unit = unit;
    this.updateForm();
    this.modal.open(S1ModalSizes.XL);
  }

  detail(networkProfile?: IProfileNetwork) {
    setTimeout(() => {
      if(networkProfile) {
        let history = this.networkProfile.history;
        let assigned = this.networkProfile.assigned;
        let canceled = this.networkProfile.canceled;
        this.networkProfile = networkProfile;
        this.networkProfile.history = history;
        this.networkProfile.assigned = assigned;
        this.networkProfile.canceled = canceled;
      } else {
        this.networkProfile = this.activeProfile;
      }
      this.updateForm();
    }, 0);
  }

  changeProfileStatusInError(idUnitProfile: number) {
    this.profilesService.forceProfileStatusError(idUnitProfile).subscribe(result => {
      this.refresh(this.networkProfile.viewPendingProfile);
    });
  }

  private updateForm() {
    this.profileForm.patchValue({
      region: this.networkProfile?.idRegion,
      site: this.networkProfile?.idSite,
      group: this.networkProfile?.idGroup,
      type: this.nameType,
      code: this.networkProfile?.codeUnit,
      company: this.networkProfile?.idCompany,
      description: this.networkProfile?.description,
      startdate: this.networkProfile?.tsStart && this.readableDatePipe.transform(new Date(this.networkProfile?.tsStart), true),
      starttime: null,
      enddate: this.networkProfile?.tsEnd && this.readableDatePipe.transform(new Date(this.networkProfile?.tsEnd), true),
      endtime: null
    });
  }

  close() {
    this.networkProfile = null;
    this.modal.close();
  }

}