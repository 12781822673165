<div>
  <div>
    <div style=" position: relative;">
      <canvas baseChart [style.height]="chartHeight"
        [datasets]="chartConfig.data"
        [labels]="chartConfig.dataLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [type]="barChartType"
        (chartClick)="chartClicked($event)">
      </canvas>
    </div>
    <div class="row justify-content-center mb-2">
      <div class="col-4 col-lg-3 col-xl-2"  *ngFor="let cat of chartConfig.data, let i =index">
        <div class="row justify-content-center">
          <span class="pl-4">
            <em class="mr-2 fas fa-square status" style.color="{{ chartConfig.colors[i].backgroundColor }}"></em>
            {{ cat.label | translate }}
          </span>
        </div>
      </div>
    </div>

  </div>
</div>