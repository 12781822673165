import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { BrandsService, IBrandsSearchParams } from "@app/shared/services/brands.service";

@Component({
  selector: 'mdm-input-brand-multiselect',
  templateUrl: './input-brand-multiselect.component.html',
  styleUrls: ['./input-brand-multiselect.component.scss']
})
export class InputBrandMultiselectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean = false;
  @Input() basedOnAgent: boolean = false;
  @Input() set idAgent(newValue: number) {
    this.agentSelected = newValue != null;
    if (this.basedOnAgent && this.agentSelected) {
      this.getBrands(newValue);
    }
  }
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Output() onSelectChange = new EventEmitter();
  @Output() onSelectAllChange = new EventEmitter();

  agentSelected = false;
  brandsList: IS1InputSelectItem[];

  constructor(private brandsService: BrandsService) { }

  ngOnInit(): void {
    if (!this.basedOnAgent) {
      this.getBrands();
    }
  }

  private getBrands(idAgent?: number) {

    const params: IBrandsSearchParams = {
      code: null,
      name: null,
      ...(idAgent) && { idAgent }
    };

    this.brandsService.getBrands(params, false).subscribe(brands => {

      this.brandsList = brands.map(brand => {
        return { code: brand.id, label: brand.name };
      });

      this.onLoadList.emit(this.brandsList);
    });

  }

  select(event) {
    this.onSelectChange.emit(event);
  }

  selectAll(event) {
    this.onSelectAllChange.emit(event);
  }

}
