import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1Modal, IS1Modal, S1ModalSizes } from '@app/s1';
import { IProfileAssignment } from '@app/shared/models/profile';

@Component({
  selector: 'profile-assignment-modal',
  templateUrl: './profile-assignment-modal.component.html',
  styleUrls: ['./profile-assignment-modal.component.scss']
})
export class ProfileAssignmentModalComponent implements OnInit, IS1Modal {

  @Output() profileAssignmentSaved = new EventEmitter<IProfileAssignment>();
  @Output() profileAssignmentUpdated = new EventEmitter<IProfileAssignment>();
  @Output() profileDatesAssignmentsUpdated = new EventEmitter<IProfileAssignment>();
  @Output() profileRebootFlagsAssignmentsUpdated = new EventEmitter<IProfileAssignment>();
  @ViewChild('modal') modal: S1Modal;
  s1ButtonType = S1ButtonType;
  profileAssignmentForm: UntypedFormGroup;
  idProfile: number;
  nameProfile: string;
  idProfileAssignment: number;
  hasError: boolean = false;
  isHierarchicalAssignment: boolean = true;
  isCreateModal: boolean = false;
  isBulkUpdateModal: boolean = false;
  bulkDatesUpdate: boolean = false;
  bulkRebootFlagsUpdate: boolean = false;
  
  constructor(private formBuilder: UntypedFormBuilder, public settings: SettingsService) {
    this.profileAssignmentForm = this.formBuilder.group({
      region: null,
      site: null,
      group: null,
      brand: null,
      model: null,
      unit: null,
      startdate: [null, Validators.required],
      starttime: null,
      enddate: null,
      endtime: null,
      reboot: false
    });
  }

  ngOnInit(): void { }

  isCompanyUser() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0;
  }

  isRegionUser() {
    return !this.settings.isOperatore() || this.settings.getSites()?.length==0;
  }

  isGroupUser() {
    return !this.settings.isOperatore() || this.settings.getGroups()?.length==0;
  }

  open(nameProfile, idProfile, assignment: IProfileAssignment, isHierarchicalAssignment, bulkDatesUpdate: boolean = false, bulkRebootFlagsUpdate: boolean = false) {
    this.hasError = false;
    this.idProfile = idProfile;
    this.nameProfile = nameProfile;
    this.idProfileAssignment = assignment?.id;
    this.isHierarchicalAssignment = isHierarchicalAssignment;
    this.isCreateModal = assignment == null;
    this.isBulkUpdateModal = bulkDatesUpdate || bulkRebootFlagsUpdate;
    this.bulkDatesUpdate = bulkDatesUpdate;
    this.bulkRebootFlagsUpdate = bulkRebootFlagsUpdate;

    this.profileAssignmentForm.patchValue({
      region: this.settings.setPreselectedRegion(this.profileAssignmentForm.controls.region, this.settings.getCompany()?.code, true),
      site: null,
      group: null,
      brand: null,
      model: null,
      unit: null,
      startdate: null,
      starttime: assignment?.tsStart ? new Date(assignment?.tsStart) : null,
      enddate: assignment?.tsEnd ? new Date(assignment?.tsEnd) : null,
      endtime: assignment?.tsEnd ? new Date(assignment?.tsEnd) : null,
      reboot: assignment?.flgReboot,
    });

    this.profileAssignmentForm.clearValidators();

    if(this.isCreateModal && this.isHierarchicalAssignment && !this.isCompanyUser()) {
      this.profileAssignmentForm.controls.region.setValidators(Validators.required);
    }

    if(this.isCreateModal && this.isHierarchicalAssignment && !this.isRegionUser()) {
      this.profileAssignmentForm.controls.site.setValidators(Validators.required);
    }

    if(this.isCreateModal && this.isHierarchicalAssignment && !this.isGroupUser()) {
      this.profileAssignmentForm.controls.group.setValidators(Validators.required);
    }

    if(this.isCreateModal && !this.isHierarchicalAssignment) {
      this.profileAssignmentForm.controls.unit.setValidators(Validators.required);
    }

    this.profileAssignmentForm.controls.startdate.setValidators(Validators.required);
    if(assignment?.tsStart) {
      this.profileAssignmentForm.controls.startdate.setValue(new Date(assignment?.tsStart));
    }

    this.profileAssignmentForm.updateValueAndValidity();


/*    this.profileAssignmentForm = this.formBuilder.group({
      region: this.isCreateModal && this.isHierarchicalAssignment && !this.isCompanyUser() ? [this.settings.setPreselectedRegion(this.profileAssignmentForm.controls.region, this.settings.getCompany()?.code, true), Validators.required] : null,
      site: this.isCreateModal && this.isHierarchicalAssignment && !this.isRegionUser() ? [null, Validators.required] : null,
      group: this.isCreateModal && this.isHierarchicalAssignment && !this.isGroupUser() ? [null, Validators.required] : null,
      brand: null,
      model: null,
      unit: this.isCreateModal && !this.isHierarchicalAssignment ? [null, Validators.required] : null,
      startdate: assignment?.tsStart ? [new Date(assignment?.tsStart), Validators.required] : [null, Validators.required],
      starttime: assignment?.tsStart ? new Date(assignment?.tsStart) : null,
      enddate: assignment?.tsEnd ? new Date(assignment?.tsEnd) : null,
      endtime: assignment?.tsEnd ? new Date(assignment?.tsEnd) : null,
      reboot: assignment?.flgReboot,
    });
*/
    this.profileAssignmentForm.controls.startdate.markAsTouched();
    this.profileAssignmentForm.controls.unit.markAsTouched(); 
    this.modal.open(S1ModalSizes.LG);
  }

  setNow() {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 5);
    now.setSeconds(0);
    this.profileAssignmentForm.patchValue({
      startdate: now,
      starttime: now,
    });
  }

  validateForm() {
    this.hasError = false;

    if(!this.isBulkUpdateModal || !this.bulkRebootFlagsUpdate) {

      if(!this.profileAssignmentForm.controls.startdate.value){
        this.hasError = true;
      }
    }

    return !this.hasError;
  }

  createProfileAssignment() {
    if(!this.validateForm()){
      return;
    }

    let startDate = this.profileAssignmentForm?.controls.startdate.value;
    let startTime = this.profileAssignmentForm?.controls.starttime.value;
    if(startDate && startTime) {
      startDate.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
    }
    if(startDate && !startTime) {
      startDate.setHours(0,0,0);
    }
    let endDate = this.profileAssignmentForm?.controls.enddate.value;
    let endTime = this.profileAssignmentForm?.controls.endtime.value;
    if(endDate && endTime) {
      endDate.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
    }
    if(endDate && !endTime) {
      endDate.setHours(0,0,0);
    }
    startDate = startDate && startDate.getTime();
    endDate = endDate && endDate.getTime();

    const region = this.profileAssignmentForm.controls.region.value?.code;
    const site = this.profileAssignmentForm.controls.site.value?.code;
    const group = this.profileAssignmentForm.controls.group.value?.code;
    const brand = this.profileAssignmentForm.controls.brand.value?.code;
    const model = this.profileAssignmentForm.controls.model.value?.code;
    const unit = this.profileAssignmentForm.controls.unit.value;
    const flgReboot = this.profileAssignmentForm?.controls.reboot.value ? true: false;
    const company = this.settings.getCompany().code;
    const profile = this.idProfile;

    const profileAssignment: IProfileAssignment = {
      idProfile: profile,
      idCompany: company,
      idRegion: region,
      idSite: site,
      idGroup: group,
      idBrand: brand,
      idModel: model,
      codUnit: unit,
      tsStart: startDate,
      tsEnd: endDate,
      flgReboot: flgReboot
    };

    this.profileAssignmentSaved.emit(profileAssignment);
    this.close();
  }

  updateProfileAssignments() {
    if(!this.validateForm()){
      return;
    }

    let startDate = null;
    let startTime = null;
    let endDate = null;
    let endTime = null;

    if(!this.isBulkUpdateModal || !this.bulkRebootFlagsUpdate) {
      startDate = this.profileAssignmentForm?.controls.startdate.value;
      startTime = this.profileAssignmentForm?.controls.starttime.value;
      if(startDate && startTime) {
        startDate.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
      }
      if(startDate && !startTime) {
        startDate.setHours(0,0,0);
      }
      endDate = this.profileAssignmentForm?.controls.enddate.value;
      endTime = this.profileAssignmentForm?.controls.endtime.value;
      if(endDate && endTime) {
        endDate.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
      }
      if(endDate && !endTime) {
        endDate.setHours(0,0,0);
      }
      startDate = startDate && startDate.getTime();
      endDate = endDate && endDate.getTime();
    }

    let flgReboot = null;

    if(!this.isBulkUpdateModal || !this.bulkDatesUpdate) {
      flgReboot = this.profileAssignmentForm?.controls.reboot.value ? true: false;
    }

    const profileAssignment: IProfileAssignment = {
      idProfile: this.idProfile,
      tsStart: startDate,
      tsEnd: endDate,
      flgReboot: flgReboot
    };

    if(!this.isBulkUpdateModal) {
      profileAssignment.id = this.idProfileAssignment;
      this.profileAssignmentUpdated.emit(profileAssignment);
    } else if(this.bulkDatesUpdate) {
      this.profileDatesAssignmentsUpdated.emit(profileAssignment);
    } else if(this.bulkRebootFlagsUpdate) {
      this.profileRebootFlagsAssignmentsUpdated.emit(profileAssignment);
    }

    this.close();
  }

  close() {
    this.modal.close();
  }

}
