<s1-modal #modal [title]="'dispensing.unit_detail_modal.title'">

  <ng-container *ngIf="units?.length>1; else noTab">
    <tabset>
      <tab *ngFor="let unit of units; let first = first;" [active]="first" (selectTab)="loadGraph(unit)">
        <ng-template tabHeading>
          <h4 class="mb-2"><em class="fa fa-tablet-alt mr-1"></em><b>{{unit.code}}</b></h4>
        </ng-template>
        <ng-template
          [ngTemplateOutlet]="unitTemplate"
          [ngTemplateOutletContext]="{ $implicit: unit }">
        </ng-template>
      </tab>
      <tab [disabled]="true" [active]="false">
        <ng-template tabHeading>
          <em class="fa-2x fas fa-exclamation-triangle text-danger"></em>
        </ng-template>
      </tab>
    </tabset>
</ng-container>
  
  <ng-template #noTab>
    <ng-container *ngIf="units?.length==1">
      <ng-template 
        [ngTemplateOutlet]="unitTemplate" 
        [ngTemplateOutletContext]="{ $implicit: units[0] }">
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #unitTemplate let-unit>
    <ng-container *ngIf="unit">
      <div class="row p-0">
        <div class="col-12 text-right rounded-right">
          <s1-button *ngIf="!unit?.isExcluded" class="mr-2" [label]="'dispensing.units.exclude'" [icon]="'far fa-times-circle'" [type]="s1ButtonType.Undo" [onlyIcon]="false" (onClick)="askToExclude(unit)"></s1-button>
          <s1-button *ngIf="unit?.isExcluded" class="mr-2" [label]="'dispensing.units.include'" [icon]="'far fa-check-circle'" [type]="s1ButtonType.Link" [onlyIcon]="false" (onClick)="askToInclude(unit)"></s1-button>
        </div>
      </div>

      <div class="row p-0">
        <div class="col-6 col-md-3 py-3 rounded-right">
          <div class=""><em class="fa fa-tablet-alt mr-1"></em><b>{{unit.code}}</b></div>
          <div class="mb-0"><b [ngClass]="getStatusStyleClass(unit?.unitStatus)">{{unit?.unitStatus}}</b> | <battery-icon [percentage]="unit.batteryPerc"></battery-icon></div>
        </div>

        <div class="col-6 col-md-3 py-3 rounded-right">
          <div class=""><b>{{unit.nameSite}}</b></div>
          <div class="mb-0"><em class="fa-1x fas fa-th mr-1"></em> {{'dispensing.units.wall' | translate}}: <b>{{unit.descriptionWall}}</b> - {{'dispensing.units.table_row' | translate}}: <b>{{unit.row}}</b> - {{'dispensing.units.table_coll' | translate}}: <b>{{unit.col}}</b></div>
        </div>

        <div class="col-6 col-md-3 py-3">
          <div class="">{{'dispensing.units.table_ipAddress' | translate}}: <b>{{unit.ipaddress}}</b></div>
          <div class="mb-0"><em class="fa-1x fas fa-code-branch mr-1"></em>Ver: <b>{{unit.appVersion}}</b> - <em class="fa-1x fab fa-android mr-1"></em>OS: <b>{{unit.osrelease}}</b></div>
        </div> 
        <div class="col-6 col-md-3 py-3">
          <div class="">
            <em title="{{'dispensing.units.table_lastEvent' | translate}}" class="icon-bell mr-2"></em>
            <span class=" font-weight-light">
              {{unit.tsLastEvent | date:'dd/MM/yyyy HH:mm:ss'}} ({{ (unit.tsLastEvent / 1000) | secondsToTime: true }} {{'dispensing.units.secondsAgo' | translate}})
            </span>
          </div>
          <div class="">
            <em title="{{'dispensing.units.table_lastStatusTime' | translate}}" class="icon-feed mr-2"></em>
            <span class=" font-weight-light" >
              {{ unit?.tsLastStatus | s1ReadableDate: true}} ({{ unit?.tsLastStatus / 1000 | secondsToTime: '48' }} {{'base.ago' | translate}})
            </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 offset-0 col-lg-4 offset-lg-4 order-2 order-lg-1 mb-3">
          <button class="btn btn-lg btn-light" type="button" (click)="prevDay(unit)">
            <i class="fas fa-caret-left fa-2x"></i>
          </button>
          <button class="btn btn-lg btn-light" type="button" (click)="prevDay(unit)" disabled>
            <h4>{{ date | date:'dd/MM/yyyy' }}</h4>
          </button>
          <button class="btn btn-lg btn-light" type="button" (click)="nextDay(unit)">
            <i class="fas fa-caret-right fa-2x"></i>
          </button>
        </div>
        <div class="col-12 col-lg-4 order-1 order-lg-1 mb-3">
          <unit-commands *ngIf="isAdminOrSuper || isCommandEnabled" [dispensingCommands]="true" [unit]="unit"></unit-commands>
        </div>
        <div class="col-12 order-3">
          <chart-bar class="mt-3" [config]="chartConfig"></chart-bar>  
        </div>
      </div>
    </ng-container>
  </ng-template>
  
  </s1-modal>