<div class="my-2 p-0 rounded unit shadow-sm text-center" [ngClass]="background" (click)="goToDetails()" *ngIf="fullMode else miniMode;">
  <div class="mt-1">
    <ng-container *ngIf="((unit.row != null && unit.row != -1) || (unit.col != null && unit.col != -1)) else onlySN;">
      {{ unit.row }} - {{ unit.col }}
    </ng-container>
    <ng-template #onlySN>
      - <!-- <small>{{ unit.serialNumber }}</small> -->
    </ng-template>
  </div>
  <ng-container *ngIf="fullMode">
    <div class="bg-light text-dark d-flex mt-1">
      <div class="mx-auto">
        <battery-icon class="mr-1" [percentage]="unit.batteryPerc"></battery-icon>
      </div>
      <div class="mx-auto">
        <time-icon [timestamp]="unit.tsLastStatus"></time-icon>
      </div>
    </div>
    <!-- {{ unit.status }} -->
  </ng-container>
  <div class="mt-1">
    <em *ngIf="isOverlappedUnit" class="fa-2x fas fa-exclamation-triangle text-danger"></em>
    <em *ngIf="unit.isExcluded" class="fa-2x fas fa-ban text-gray-lighter"></em>
  </div>
</div>

<ng-template #miniMode>
  <div class="my-2 p-0 rounded unit shadow-sm shadow-sm d-flex align-items-center justify-content-center h5" [ngClass]="background">
    {{ unit.row }} - {{ unit.col }}
  </div>
</ng-template>

<unit-detail-modal #detailModal></unit-detail-modal>
