import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFromInterval'
})
export class DateFromIntervalPipe implements PipeTransform {

  transform(interval: number, ...args: any[]): any {

    let now = new Date().getTime()

    let difference = 0

    if(!isNaN(interval)) {

      difference = interval * 1000

    }

    return new Date(now - difference)

  }

}
