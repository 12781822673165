import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ProfilesService } from '@app/shared/services/profiles.service';
import { IUnit } from '@app/shared/models/models';
import { IProfileAsset } from '@app/shared/models/profile';
import { IAsset } from '@app/shared/models/models';
import { S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { ProfileStatus } from '@app/core/classes/status';
import { SettingsService } from '@app/core/settings/settings.service';
import { AssetsService } from '@app/shared/services/assets.service';
import { AssetSelectionModalComponent } from '../asset-selection-modal/asset-selection-modal.component';
import { AssetModalComponent } from '../asset-modal/asset-modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'mdm-profile-file-manager-content',
  templateUrl: './profile-file-manager-content.component.html',
  styleUrls: ['./profile-file-manager-content.component.scss']
})
export class ProfileFileManagerContentComponent implements OnInit {

  @Input() set profileAsset(newValue: IProfileAsset) {
    if (newValue) {
      this.assetProfile = newValue;

      if(!this.assetProfile.id) {
        this.showOnlyTableOthers = true;
        this.assetProfile.viewCanceledProfile = true;
      }

      if(this.assetProfile.viewCanceledProfile) {
        this.showTableHistory = false;
        this.showTableAssigned = false;
        this.showTableOthers = true;
        //detail not viewable
      }
      if(this.assetProfile.viewPendingProfile) {
        this.showTableHistory = false;
        this.showTableAssigned = true;
        this.showTableOthers = false;
        //update detail if not already selected
        if(this.hasProfileHistory && !this.selected && this.assetProfile.assigned?.length > 0) {
          this.viewAssigned(this.assetProfile.assigned[0]);
        } 
      }
      
      if(!this.assetProfile.viewCanceledProfile && !this.assetProfile.viewPendingProfile) {
        this.showTableHistory = true;
        this.showTableAssigned = false;
        this.showTableOthers = false;
        //update detail if not already selected
        if(this.hasProfileHistory && !this.selected && this.assetProfile.history.length > 0) {
          this.selected =  this.assetProfile.history[0];
        }
      }

      this.assets = this.assetProfile.assets
      this.tableHistory.updateData(this.assetProfile.history);
      this.tableAssigned.updateData(this.assetProfile.assigned);
      this.tableOthers.updateData(this.assetProfile.canceled);
    } else {
      this.selected = null;
    }

  }
  @Input() profileForm: UntypedFormGroup;
  @Input() hasProfileHistory: boolean = false;
  @Input() editMode: boolean = true;
  @Input() newMode: boolean = false;
  @Input() refreshButton: boolean = true;
  @Input() unit: IUnit;
  @Input() set profile(newValue: IProfileAsset) {
    if (newValue) {
      this.assetProfile = newValue;
      this.assets = this.assetProfile?.assets ?? []
    }
  }

  @Output() onDetail = new EventEmitter<IProfileAsset>();
  @Output() onRefresh = new EventEmitter<any>();
  @Output() onChangeUnitProfileStatusInError = new EventEmitter<number>();

  @ViewChild('tableHistory') tableHistory: S1Table;
  @ViewChild('tableAssigned') tableAssigned: S1Table;
  @ViewChild('tableOthers') tableOthers: S1Table;
  @ViewChild('addAssetModal') addAssetModal: AssetModalComponent;
  @ViewChild('selectAssetModal') selectAssetModal: AssetSelectionModalComponent;
  assetProfile: IProfileAsset;
  selected: IProfileAsset;
  showTableHistory: boolean = true;
  showTableAssigned: boolean = false;
  showTableOthers: boolean = false;
  showOnlyTableOthers: boolean = false;
  s1ButtonType = S1ButtonType;
  profileStatus = ProfileStatus;
  assets: IAsset[] = [];
  parentPath: string;

  constructor(private route: ActivatedRoute, private router: Router, private profilesService: ProfilesService, private ui: S1UIService, private settings: SettingsService, private assetsService: AssetsService) {

    this.route.parent.url.subscribe((urlPath) => {
      this.parentPath = "/" + urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit(): void { }

  refresh() {
    this.selected = null;
    this.onRefresh.emit(this.assetProfile.viewPendingProfile);
  }

  save() {
    if (this.assetProfile) {
      this.assetProfile.description = this.profileForm.controls.description.value;
      this.assetProfile.idRegion = this.profileForm?.controls.region.value;
      this.assetProfile.idSite = this.profileForm?.controls.site.value;
      this.assetProfile.idGroup = this.profileForm?.controls.group.value;
      this.assetProfile.assets = this.assets;
      this.updateAssetProfile(this.assetProfile);
    } else {
      const newAssetProfile: IProfileAsset = {
        idCompany: this.settings.getCompany().code,
        idRegion: this.profileForm?.controls.region.value,
        idSite: this.profileForm?.controls.site.value,
        idGroup: this.profileForm?.controls.group.value,
        description: this.profileForm?.controls.description.value,
        assets: this.assets
      };
      this.createAssetProfile(newAssetProfile);
    }
  }

  createAssetProfile(profile: IProfileAsset ) {
    this.profilesService.createProfileAsset(profile).subscribe(newProfile => {
      if(newProfile) {
        this.ui.showSuccessToast('msg.operation_ok');
        this.router.navigate(['/profiles', newProfile.idProfile, newProfile.nameType]);
      }
    })
  }
  
  updateAssetProfile(profile: IProfileAsset) {
    this.profilesService.updateProfileAsset(profile).subscribe(assetProfileUpdated => {
      if (assetProfileUpdated) {
        this.profilesService.getProfileAsset(this.assetProfile.idProfile, false).subscribe(assetProfile => {
          this.assetProfile = assetProfile;
          this.assets = this.assetProfile?.assets ?? []
        });
        this.ui.showSuccessToast('msg.operation_ok');
      }
    })
  }

  openModalAddAsset(asset?: IAsset) {
    let fromAsset: IAsset = {
      description: null,
      source: null,
      installationOrder: asset?.installationOrder
    }
    this.addAssetModal.open(fromAsset);
  }
  
  openModalSearchAsset(asset?: IAsset) {
    this.selectAssetModal.open(asset?.installationOrder);
  }

  addAssets(assetsSelection: IAsset[]) {

    assetsSelection = assetsSelection.filter(selected => {
      return !this.assets.find(asset => asset.id === selected.id);
    });
    this.assets = this.assets.concat(assetsSelection);
    this.assetProfile.assets = this.assets;
    this.updateAssetProfile(this.assetProfile);
  }

  addAsset(asset: IAsset) {
    this.ui.showBlockingSpinner();
    let saved = false;
    this.saveAsset(asset).subscribe(
      response => {
        if(response && typeof response === 'number') {
          this.ui.updateFooter(response+'%');
          saved = response == 100;
        }
        if(response && typeof response !== 'number') {
          if(saved) {
            asset.id = response.id;
            asset.source = response.source;
            delete asset.file;
            this.assets.push(asset);
            this.assetProfile.assets = this.assets;
            this.updateAssetProfile(this.assetProfile);
          }
        }
      },
      error => {console.log(error);},
      () => {this.ui.closeSpinner();}
    );
  }
  
  updateAsset(assetUpdated: IAsset) {
    this.ui.showBlockingSpinner();
    let saved = false;
    this.saveAsset(assetUpdated).subscribe(
      response => {
        if(response && typeof response === 'number') {
          this.ui.updateFooter(response+'%');
          saved = response == 100;
        }
        if(response && typeof response !== 'number') {
          console.log(response);
          if(saved) {
            assetUpdated.source = response.source;
            delete assetUpdated.file;
            this.assets[this.assets.findIndex(asset => asset.id === assetUpdated.id)] = assetUpdated;
            this.assetProfile.assets = this.assets;
            this.updateAssetProfile(this.assetProfile);
          }
        }
      },
      error => {console.log(error);},
      () => {this.ui.closeSpinner();}
    );
  }

  saveAsset(asset: IAsset): Observable<any> {
    var formData: any = new FormData();
    if(asset.id) {
      formData.append("id", asset.id);
    }
    formData.append("description", asset.description);
    formData.append("name", asset.name);
    formData.append("file", asset.file);
    formData.append("localFile", asset.localFile);
		formData.append("source", asset.source);
    if(asset.target) {
      formData.append("target", asset.target);
    }
    if(asset.executeWith) {
      formData.append("executeWith", asset.executeWith);
    }
    if(asset.packageName) {
      formData.append("packageName", asset.packageName);
    }
    if(asset.flgExtract != null) {
      formData.append("flgExtract", asset.flgExtract);
    }
		formData.append("idAssetType", asset.idAssetType);
		formData.append("idCompany", asset.idCompany);
    return !asset.id ? this.profilesService.createAsset(formData) :  this.profilesService.updateAsset(formData);
  }

  moveAsset(assetUpdated: IAsset, direction: boolean) {
    this.profilesService.moveAssetOfProfileAsset(this.assetProfile,assetUpdated,direction).subscribe(assetProfile => {
      this.assetProfile = assetProfile;
      this.assets = this.assetProfile?.assets ?? []
    });
  }

  deleteAsset(assetDeleted: IAsset) {
    this.profilesService.deleteAssetOfProfileAsset(this.assetProfile, assetDeleted).subscribe(profile => {
      if (profile) {
        this.profilesService.getProfileAsset(this.assetProfile.idProfile, true).subscribe(assetProfile => {
          this.assetProfile = assetProfile;
          this.assets = this.assetProfile?.assets ?? []
        });
        this.ui.showSuccessToast('msg.operation_ok');
      }
    })
  }

  stylizeStatus(status: string) {
    if (status === ProfileStatus.APPLIED) {
      return 'text-success';
    } else if (status === ProfileStatus.DOWNLOAD || status === ProfileStatus.ASSIGNED || status === ProfileStatus.SENT) {
      return 'text-warning';
    } else if (status === ProfileStatus.ERROR) {
      return 'text-danger';
    } else {
      return 'text-muted';
    }
  }
   
  askChangeProfileStatus(profile: IProfileAsset) {
    if(profile?.status ===  ProfileStatus.ASSIGNED || profile?.status ===  ProfileStatus.DOWNLOAD || profile?.status ===  ProfileStatus.SENT) {
      let text = 'profiles.distributions.popup.askChangeProfileStatusToCanceled'; //ProfileStatus.ASSIGNED
      if(profile?.status ===  ProfileStatus.DOWNLOAD) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToInterrupted';
      } else if(profile?.status ===  ProfileStatus.SENT) {
        text = 'profiles.distributions.popup.askChangeProfileStatusToAborted';
      }
      this.ui.showDialogPopup(text).then(result => {
        if (result.value) {
          this.onChangeUnitProfileStatusInError.emit(profile.idUnitProfile);
        }
      });
    }
  }
  
  viewHistory(assetProfile?: IProfileAsset) {
    if(assetProfile) {
      this.selected = assetProfile;
    } else if(this.assetProfile.history.length > 0) {
      this.selected =  this.assetProfile.history[0];
    }
    this.selected.viewPendingProfile = this.assetProfile.viewPendingProfile;
    this.selected.viewCanceledProfile = false;
    this.onDetail.emit(this.selected);
  }

  viewAssigned(assetProfile?: IProfileAsset) {
    if(assetProfile) {
      this.selected = assetProfile;
    } else if(this.assetProfile.assigned.length > 0) {
      this.selected =  this.assetProfile.assigned[0];
    }
    this.selected.viewPendingProfile = this.assetProfile.viewPendingProfile;
    this.selected.viewCanceledProfile = false;
    this.onDetail.emit(this.selected);
  }

  viewOthers(assetProfile?: IProfileAsset) {
    if(assetProfile) {
      this.selected = assetProfile;
    } else if(this.assetProfile.canceled.length > 0) {
      this.selected =  this.assetProfile.canceled[0];
    }
    this.selected.viewPendingProfile = false;
    this.selected.viewCanceledProfile = this.assetProfile.viewCanceledProfile;
    this.onDetail.emit(this.selected);
  }

  viewLastApplied() {
    if(this.assetProfile.history?.length > 0) {
      this.selected =  this.assetProfile.history[0];
      this.selected.viewPendingProfile = this.assetProfile.viewPendingProfile;
      this.selected.viewCanceledProfile = false;
    }
    this.tableHistory.config.pagination.page.page=1;
    this.tableHistory.pagination.changePage();
    this.onDetail.emit(this.selected);
  }

  openHistoryTable() {
    this.showTableOthers = false;
    this.showTableHistory = true;
    this.showTableAssigned = false;
    this.assetProfile.viewPendingProfile = false;
    this.viewHistory();
  }

  openAssignedTable() {
    if(this.assetProfile.assigned?.length > 0) {
      this.showTableOthers=false;
      this.showTableHistory=false;
      this.showTableAssigned=true;
      this.assetProfile.viewPendingProfile = true;
      this.selected.viewCanceledProfile = false;
      this.viewAssigned();
    }
  }

  openOthersTable() {
    if(this.tableOthers.config.rows.length > 0) {
      this.showTableOthers = true;
      this.showTableHistory = false;
      this.showTableAssigned = false;
      this.assetProfile.viewPendingProfile = false;
      this.assetProfile.viewCanceledProfile = true;
      this.viewOthers();
    }
  }
}
