<s1-card>
  <div class="w-100 text-center"><h5>{{ ('dashboard.os.title' | translate) | uppercase }}</h5></div>
  <div class="row">
    <div class="col-6 h2 text-center">
      <i class="fab fa-android text-primary"></i> {{ item?.ok }}
    </div>
    <div class="col-6 h2 text-center">
      <i class="fab fa-android text-danger"></i> {{ item?.ko }}
    </div>
  </div>
</s1-card>