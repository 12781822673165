<div class="sidebar-nav">
    <ng-container *ngIf="settings.getFilterOfSidebarByName('company')">
        <p class="h5 group-title">{{'rightsidebar.current_company' | translate}}</p>
        <ul class="group-block mb-0">
            <li class="d-flex">
                <span class="flex-grow-1">{{settings.getFilterOfSidebarByName('company')?.label}}</span>
            </li>
        </ul>
    </ng-container>
    <p class="h5 group-title">{{'rightsidebar.select_company' | translate}}</p>
    <ul class="group-block">
        <ng-container *ngFor="let company of companiesList; let i = index;">
           <li (click)="selectedFilter('company' , company)"
            [ngClass]="{'active' : settings.getFilterOfSidebarByName('company')?.code == company?.code}">
            <a>{{company?.label || '--'}} <ng-container *ngIf="company?.reseller"><br>{{'companies.list.fields.reseller_info' | translate}}</ng-container></a>
            </li>
        </ng-container>
    </ul>
</div>