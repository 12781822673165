import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { AssetsService, IAssetsSearchParams } from '@app/shared/services/assets.service';
import { ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-input-os-list-select',
  templateUrl: './input-os-list-select.component.html',
  styleUrls: ['./input-os-list-select.component.scss']
})
export class InputOsListSelectComponent implements OnInit {

  @Input() idCompany: number;
  @Input() control: UntypedFormControl;
  @Input() readonly: boolean = false;
  @Input() bindAllObject: boolean = true;
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  osList: IS1InputSelectItem[];

  constructor(private assetsService: AssetsService, private profilesService: ProfilesService) { }

  ngOnInit(): void {
    this.getAssets();
  }

  getAssets() {
    const parameters: IAssetsSearchParams = {
      idCompany: this.idCompany
    };
    this.profilesService.getOsAssets(parameters).subscribe(assets => {
      this.osList = assets.map(asset => { 
        return { code: asset.id, label: asset.description } 
      });
    });
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
