import { Component, OnInit, ViewChild } from '@angular/core';
import { IS1Modal, S1Modal, S1ModalSizes, S1ButtonType, S1Table } from '@app/s1';
import { SettingsService } from '@app/core/settings/settings.service';
import { UnitsService } from '@app/shared/services/units.service';

@Component({
  selector: 'mdm-asset-units-modal',
  templateUrl: './asset-units-modal.component.html',
  styleUrls: ['./asset-units-modal.component.scss']
})
export class AssetUnitsModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  s1ButtonType = S1ButtonType;

  constructor(public settings: SettingsService, private unitsService: UnitsService) {
  }

  ngOnInit(): void { }
  
  open(filters: any) {
    this.modal.open(S1ModalSizes.XL);
    this.getAssetUnits(filters);
  }

  close() {
    this.modal.close();
  }

  getAssetUnits(filters: any) {
    this.unitsService.getAssetUnits(filters).subscribe(units => {
      this.table.updateData(units);
    });
  }

}
