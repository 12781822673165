<s1-card>
  <ng-container *ngIf="showOnlyTableOthers && !selected">
    <div class="text-center my-4">
        <em class="fa-2x mr-2 fas fa-exclamation-circle"></em>
        <span class="h3">{{ 'base.no_profiles' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!showOnlyTableOthers || selected">
    <div class="col fit text-right text-primary" *ngIf="!showTableOthers && refreshButton">
      <s1-button right class="mr-2" [type]="s1ButtonType.Refresh" [onlyIcon]="false" [outline]="true" (onClick)="refresh()"></s1-button>
    </div>
    <form [formGroup]="settingsForm">
  
      <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="offset-md-3 col-2" [control]="settingsForm.controls.barsVisibility" [readonly]="!editMode"  [label]="'profiles.settings.fields.barsVisibility'"></s1-input-switch>
        <s1-input-switch class="col-auto" [control]="settingsForm.controls.barVisibilityTop" [readonly]="!editMode || !settingsForm.controls.barsVisibility.value"  [label]="'profiles.settings.fields.barVisibilityTop'"></s1-input-switch>
        <s1-input-switch class="col-auto" [control]="settingsForm.controls.barVisibilityBottom" [readonly]="!editMode || !settingsForm.controls.barsVisibility.value"  [label]="'profiles.settings.fields.barVisibilityBottom'"></s1-input-switch>
      </div>
      <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="offset-md-3 col-2" [control]="settingsForm.controls.brightnessRadio" [readonly]="!editMode" [label]="'profiles.settings.fields.brightness'"></s1-input-switch>
        <s1-input-range class="col-3 pointer" [control]="settingsForm.controls.brightness" [readonly]="!editMode || !settingsForm.controls.brightnessRadio.value" [label]="'profiles.settings.fields.brightness'"></s1-input-range>
        <label class="mt-3">{{settingsForm.controls.brightness.value}}</label>
      </div>
      <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="offset-md-3 col-2" [control]="settingsForm.controls.screentimeoutRadio" [readonly]="!editMode" [label]="'profiles.settings.fields.screentimeout'"></s1-input-switch>
        <s1-input-text class="col-3"[control]="settingsForm.controls.screentimeout" [readonly]="!editMode || !settingsForm.controls.screentimeoutRadio.value" [labelGrouped]="false" [label]="'profiles.settings.fields.screentimeoutTime'"></s1-input-text>
      </div>
      <div class="row text-left pl-2 mt-2">
        <s1-input-switch class="offset-md-3 col-2" [control]="settingsForm.controls.dailyReboot" [readonly]="!editMode" [label]="'profiles.settings.fields.reboot'"></s1-input-switch>
        <s1-input-text *ngIf="!editMode" class="col-3"[control]="settingsForm.controls.dailyRebootTime" [readonly]="!editMode" [labelGrouped]="false" [label]="'profiles.settings.fields.rebootTime'"></s1-input-text>
        <div class="col-auto" *ngIf="editMode">
          <label>{{ 'profiles.settings.fields.rebootTime' | translate }}</label>
          <timepicker [formControl]="settingsForm.controls.dailyRebootTime" [showMeridian]="false" [mousewheel]="false" [disabled]="!settingsForm.controls.dailyReboot.value"></timepicker>
        </div>
        <s1-input-switch class="col-auto" [control]="settingsForm.controls.dailyRebootWhenCharging" [readonly]="!editMode || !settingsForm.controls.dailyReboot.value" [label]="'profiles.settings.fields.rebootWhenCharging'"></s1-input-switch>
      </div>
      <div class="row text-left pl-2 mt-2">
        <s1-input-text class="offset-md-3 col-2" [control]="settingsForm.controls.locale" *ngIf="!editMode" [readonly]="true" [labelGrouped]="false" [label]="'profiles.settings.fields.locale'"></s1-input-text>
        <mdm-input-locale-type-select class="offset-md-3 col-2" [control]="settingsForm.controls.locale" *ngIf="editMode" [bindAllObject]="false" (onSelectClear)="localeChange($event)" (onSelectChange)="localeChange($event)"></mdm-input-locale-type-select>
        <s1-input-text class="col-3"[control]="settingsForm.controls.timezone" *ngIf="!editMode" [readonly]="true" [labelGrouped]="false" [label]="'profiles.settings.fields.timezone'"></s1-input-text>
        <mdm-input-timezone-type-select class="col-3" [control]="settingsForm.controls.timezone" *ngIf="editMode" [bindAllObject]="false" (onSelectClear)="timezoneChange($event)" (onSelectChange)="timezoneChange($event)"></mdm-input-timezone-type-select>
      </div>
  
      <div class="row text-center my-1">
        <div class="col-12" *ngIf="editMode">
          <s1-button [disabled]="!profileForm.valid" [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
        </div>
      </div>
    </form>
  </ng-container>

  <s1-card class="bg-light" [ngClass]="hasProfileHistory ? '' : 'd-none'">
    <div class="row justify-content-end mt-2 mb-0" *ngIf="!showOnlyTableOthers && (showTableAssigned || showTableOthers)">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="showTableAssigned || (showTableHistory && tableAssigned.config.rows.length > 0) || showTableOthers ? 'text-primary' : 'text-muted'" (click)="openHistoryTable()">
        <span>
          {{ 'base.history' | translate }}
        </span>
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row justify-content-end mt-2 mb-0" *ngIf="!showTableAssigned">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="tableAssigned.config.rows.length > 0 ? 'text-primary' : 'text-muted'" (click)="openAssignedTable()">
        <span>
          {{ 'base.waiting_activation' | translate }} ({{tableAssigned.config.rows.length}})
        </span>
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row justify-content-end mt-0 mb-2" *ngIf="!showTableOthers">
      <div class="col-auto d-flex align-items-center font-weight-bold pointer" [ngClass]="tableOthers.config.rows.length > 0 ? 'text-primary' : 'text-muted'" (click)="openOthersTable()">
        {{ 'base.canceled_interrupted' | translate }} ({{tableOthers.config.rows.length}})
        <em class="ml-2 fa-2x fas fa-angle-right"></em>
      </div>
    </div>
    <div class="row text-left mx-2">
      <div class="col-auto text-primary">
          <h4 *ngIf="showTableHistory">
            {{ 'base.history' | translate }}
            <button type="button" class="col-auto mx-4 my-2 btn-xs btn btn-outline-primary" (click)="viewLastApplied()">
            {{ 'base.show_current' | translate }}
            </button>
          </h4>
          <h4 *ngIf="showTableAssigned">
            {{ 'base.waiting_activation' | translate }}
          </h4>
          <h4 *ngIf="showTableOthers">
            {{ 'base.canceled_interrupted' | translate }}
          </h4>
      </div>
    </div>
    <s1-table #tableHistory [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableHistory ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.start' | translate }}</th>     
          <th class="">{{ 'profiles.list.table.columns.end' | translate }}</th>                                      
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let setting of tableHistory.config.rows; let i = index" class="pointer" [ngClass]="setting == selected ? 'row-selected' : ''" (click)="viewHistory(setting)">
          <td>
            <b [ngClass]="stylizeStatus(setting?.status)">{{setting?.status}}</b>

            <ng-container *ngIf="setting?.status ===  profileStatus.ASSIGNED">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(setting)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="setting?.status ===  profileStatus.DOWNLOAD">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(setting)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="setting?.status ===  profileStatus.SENT">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(setting)"></s1-button>  
            </ng-container>

          </td>
          <td>{{ setting.description }}</td>
          <td>{{ setting.tsStart | s1ReadableDate: true }}</td>
          <td>{{ setting.tsEnd | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
    <s1-table #tableAssigned [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableAssigned ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.activation' | translate }}</th>     
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let setting of tableAssigned.config.rows; let i = index;" class="pointer" [ngClass]="setting == selected ? 'row-selected' : ''" (click)="viewAssigned(setting)">
          <td>
            <b [ngClass]="stylizeStatus(setting?.status)">{{setting?.status}}</b>
            
            <ng-container *ngIf="setting?.status ===  profileStatus.ASSIGNED">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(setting)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="setting?.status ===  profileStatus.DOWNLOAD">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(setting)"></s1-button>  
            </ng-container>
            <ng-container *ngIf="setting?.status ===  profileStatus.SENT">
              <s1-button class="ml-2" [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(setting)"></s1-button>  
            </ng-container>

          </td>
          <td>{{ setting.description }}</td>
          <td>{{ setting.tsActivation | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
    <s1-table #tableOthers [onlinePagination]="false" [withPagination]="true" [hoverStyle]="false" [style.display]="showTableOthers ? 'block' : 'none'">
      <thead header>
        <tr>
          <th class="">{{ 'profiles.list.table.columns.status' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.description' | translate }}</th>      
          <th class="">{{ 'profiles.list.table.columns.start' | translate }}</th>     
          <th class="">{{ 'profiles.list.table.columns.end' | translate }}</th>                                      
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let setting of tableOthers.config.rows; let i = index;" class="pointer" [ngClass]="setting == selected ? 'row-selected' : ''" (click)="viewOthers(setting)">
          <td>
            <b [ngClass]="stylizeStatus(setting?.status)">{{setting?.status}}</b>
          </td>
          <td>{{ setting.description }}</td>
          <td>{{ setting.tsStart | s1ReadableDate: true }}</td>
          <td>{{ setting.tsEnd | s1ReadableDate: true }}</td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>

</s1-card>