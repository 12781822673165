import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { S1ButtonType, S1Modal, IS1Modal, S1ModalSizes } from '@app/s1';

@Component({
  selector: 'mdm-managed-configuration-modal',
  templateUrl: './application-managed-configuration-modal.component.html',
  styleUrls: ['./application-managed-configuration-modal.component.scss']
})
export class AeManagedConfigurationModalComponent implements OnInit, IS1Modal {

  @Output() onCreated = new EventEmitter<any>();
  @ViewChild('modal') modal: S1Modal;
  s1ButtonType = S1ButtonType;
  configurationForm: UntypedFormGroup;
  editMode = false;
  
  constructor(private formBuilder: UntypedFormBuilder, ) {
    this.configurationForm = this.formBuilder.group({
      index: null,
      key: [null, Validators.required]
    });
    this.configurationForm.get('key').markAsTouched();
  }

  ngOnInit(): void { }

  open(config: any, index: number) {
    this.editMode = !config ? false : true;
    this.configurationForm.patchValue({
      index: index,
      key: config?.key
    });
    this.modal.open(S1ModalSizes.LG);
  }

  save() {
    const configuration: any = {
      index: this.configurationForm?.controls.index.value,
      key: this.configurationForm?.controls.key.value
    };
    this.onCreated.emit(configuration);
    this.close();
  }

  close() {
    this.modal.close();
  }
}
