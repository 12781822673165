
<div class="card shadow-sm summary">
  <div class="card-header bg-light pb-0 pt-1">
    <div class="row justify-content-between text-center">
      <div class="col-auto text-left">
        <h4 class="mt-1">
          {{ wall.description }}
        </h4>
      </div>
      <div class="col text-right">
        <button class="mr-1 btn btn-labeled btn-light mb-2 border rounded fake-disabled">
          <span class="btn-label rounded-left"><i class="fa fa-tablet-alt"></i></span>
          <h4 class="d-inline"> {{ statistics.total }}</h4> 
        </button>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="statistics.total > 0">
    <div class="row justify-content-between">

      <div class="col-12 col-md-6 col-xl-4 text-center" *ngFor="let card of cardsConfig">
        <statistic-card [title]="card.title" [badges]="card.badges" [chart]="card.chart"></statistic-card>
      </div>

    </div>
  </div>
</div>