<s1-card [title]="'profiles.assignment.sections.single.title'" [alignTitleCenter]="false">
  <ng-container header-right >
    <s1-button (onClick)="add()" [type]="s1ButtonType.Add"></s1-button>
  </ng-container>
  <s1-table class="pt-1" #table [onlinePagination]="false">
    <tbody body>
      <ng-container *ngFor="let assignment of table.config.rows; let i = index">
        <tr>
          <td>{{ assignment.codUnit }}</td>
          <td>{{ assignment.nameRegion + " - " + assignment.nameSite }}</td>
          <td>{{ assignment.nameBrand + " - " + assignment.nameModel }}</td>
          <td>{{ assignment.appRelease }}</td>
          <td>{{ assignment.batteryPerc }}</td>
          <td><!-- assigned --></td>
          <td>{{ assignment.tsLastStatus | s1ReadableDate: true }}</td>
          <td class="text-left fit">
            <s1-button (onClick)="askRemoveDevice(assignment)" [type]="s1ButtonType.Delete"></s1-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </s1-table>
</s1-card>

<mdm-unit-selection-modal #unitSelectionModal (unitSelected)="addDevice($event)"></mdm-unit-selection-modal>