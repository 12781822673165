import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
   ;
    const userRole = localStorage.getItem('role');
    // decode the token to get its payload

    if (
      !this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    } else if( route.data!= null && route.data.roles!=null &&
        !route.data.roles.some( role => role === userRole)
        ){
        this.router.navigate(['/home']);
        return false;
    }

    return true;
  }
}