<ng-container *ngIf="template == 'units'">
    <div class="row">
        <div class="col-2">
            <p class="mb-1">
                <em *ngIf="!ae" class="fa mr-1 fa-tablet-alt"></em>
                <em *ngIf="ae" class="mr-1 fa-google fab"></em>
                <strong class="mr-1">{{ unit?.code }}</strong>
            </p>
       
            <p class="mb-0" *ngIf="unit?.nameRegion">
                <em class="fa mr-1 fa fa-map"></em>
                <span class="mr-1">{{ unit?.nameRegion }}</span>
          
                <ng-container *ngIf="unit?.nameSite">
                    <em class="fa ml-2 mr-1 fa fa-building"></em>
                    <span class="mr-1">{{ unit?.nameSite }}</span>
                </ng-container>
            </p>
            <p class="mt-0" *ngIf="unit?.nameGroup">
                <em class="fa mt-1 mr-1 fa fa-coins"></em>
                <span class="mr-1">{{ unit?.nameGroup }}</span>
            </p>
            
        </div>
        <div class="col-9">
            <p class="mb-1">
                <strong>{{ unit?.nameBrand }} - {{ unit?.nameModel }}</strong>
            </p>
            <p>
                <span>{{ unit?.fingerprint }}</span>
            </p>
        </div>
        <div class="col-1">
            <div class="mb-1" *ngIf="!ae && unit?.appRelease">
                <i [ngClass]="unit?.deprecated ? 'ml-3 fas fa-exclamation-triangle text-warning' : 'app-release-icon'"></i>
                <span class="ml-1" [ngClass]="{'text-warning font-weight-bold': unit?.deprecated}">{{ unit?.appRelease}}</span>
            </div>
            <div class="mb-1 ml-1" *ngIf="!isDisabled">
                <i class="fab fa-android fa-lg"></i>
                <span class="ml-1">{{ unit?.osRelease || "base.notassigned" | translate }}</span>
            </div>
        </div>
        
    </div>
</ng-container>
