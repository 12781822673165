<form [formGroup]="searchForm">
    <s1-card [bottomPadding]="false">
        <div class="row">
            <mdm-input-options-select class="col-6" (onSelectChange)="changeModuleType()" [control]="searchForm.controls.moduleType" [label]="'fields.input.moduleType'" [options]="moduleTypeOptions" [bindAllObject]="true"></mdm-input-options-select>
            <div class="col-6 d-flex">
                <s1-input-datepicker class="mt-1" [control]="searchForm.controls.startdate" [label]="'fields.input.start'" [labelGrouped]="false"></s1-input-datepicker>
                <div class="pl-1">
                    <timepicker [formControl]="searchForm.controls.starttime" [showMeridian]="false" [mousewheel]="false" [minuteStep]="1"></timepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <mdm-input-options-select *ngIf="searchForm.controls.moduleType.value && searchForm.controls.moduleType.value?.code != 'AE'" class="col-6" (onSelectChange)="changeFilterType()" [control]="searchForm.controls.filterType" [label]="'fields.input.filterType'" [options]="filterTypeOptions" [bindAllObject]="true"></mdm-input-options-select>
            <s1-input-text *ngIf="!searchForm.controls.moduleType.value || searchForm.controls.moduleType.value?.code == 'AE'" class="col-6" [readonly]="true" [control]="searchForm.controls.filterType" [label]="'fields.input.filterType'" [labelGrouped]="false"></s1-input-text>
            <div class="col-6 d-flex">
                <s1-input-datepicker class="mt-1" [control]="searchForm.controls.enddate" [label]="'fields.input.end'" [labelGrouped]="false"></s1-input-datepicker>
                <div class="pl-1">
                    <timepicker [formControl]="searchForm.controls.endtime" [showMeridian]="false" [mousewheel]="false" [minuteStep]="1"></timepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <mdm-input-options-select *ngIf="searchForm.controls.filterType.value?.type == 'options'" class="col-6" [control]="searchForm.controls.filter" [label]="searchForm.controls.filterType.value?.label" [options]="filterOptions" [bindAllObject]="false"></mdm-input-options-select>
            <s1-input-text *ngIf="searchForm.controls.filterType.value?.type == 'text'" class="col-6 mb-4" [control]="searchForm.controls.filter" [label]="filterOptions[0]?.label" [labelGrouped]="false"></s1-input-text>
            <s1-input-text *ngIf="!searchForm.controls.filterType.value" class="col-6 mb-4" [readonly]="true" [control]="searchForm.controls.filter" [label]="'fields.input.type'" [labelGrouped]="false"></s1-input-text>
            <div class="col-6 mt-4 text-center">
                <s1-button class="mr-2" [type]="s1ButtonType.Search" [disabled]="!searchForm.valid" (onClick)="refresh()"></s1-button>
            </div>
        </div>
    </s1-card>
</form>

<ul class="timeline-alt mt-4">

    <ng-container *ngFor="let detail of timeline">
        <li *ngIf="detail.dateEvent" class="timeline-separator" [attr.data-datetime]="detail.dateEvent"></li>
    
        <li class="timeline-inverted">
            <div class="timeline-badge text-center" [ngClass]='getColorIcon(detail)'>
                <em class="fa" [ngClass]="getIcon(detail.timelineEventType)"></em>
                <div class="text-dark">
                    <strong>{{detail.timelineEventType | lowercase}}</strong>
                </div>
            </div>
            <div class="timeline-card">
                <div class="popover left">
                    <h4 class="popover-header pb-3">
                        <span class="float-left">
                            <em class="fa-1x icon-clock"></em> {{ detail.time | date: 'HH:mm:ss' }} <ng-container *ngIf="detail.timelineEventType != 'COMMAND'"><em class="ml-2 fas fa-mobile-alt"></em> {{detail.timeEvent }}</ng-container>
                        </span>
                        <div class="text-info text-right small">{{detail.timelineEventType}} {{detail.moduleType || '' | uppercase}}</div>
                        <span class="float-right text-right small">
                            <ng-container *ngIf="detail.codEvent">
                                {{ detail.codEvent }}
                            </ng-container>
                            <ng-container *ngIf="detail.command">
                                {{ detail.command }}
                            </ng-container>
                        </span>
                    </h4>
                    <div class="popover-body">
                        <span>
                            <!-- event -->
                            <ng-container *ngIf="detail.codWall">
                                <em class="fa-1x fas fa-th ml-2"></em> {{ detail.codWall }} - {{'dispensing.units.table_row' | translate}}: {{detail.row}}  - {{'dispensing.units.table_coll' | translate}}: {{detail.col}}
                            </ng-container>
                            <ng-container *ngIf="detail.appRelease">
                                <i [ngClass]="detail?.deprecated ? 'ml-3 fas fa-exclamation-triangle text-warning' : 'app-release-icon'"></i> <span class="ml-1" [ngClass]="{'text-warning font-weight-bold': detail?.deprecated}">{{ detail?.appRelease}}</span>
                            </ng-container>
                            <ng-container *ngIf="detail.sdkVersion">
                                <em class="fa-1x fas fa-code-branch ml-2"></em> Ver: {{ detail.sdkVersion }}
                            </ng-container>
                            <ng-container *ngIf="detail.osRelease">
                                <em class="fa-1x fab fa-android ml-2"></em> OS: {{ detail.osRelease }}
                            </ng-container>
                            <ng-container *ngIf="detail.wifimac">
                                <em class="fa-1x fas fa-wifi ml-2"></em> MAC: {{ detail.wifimac }}
                            </ng-container>
                            <!-- status -->
                            <ng-container *ngIf="detail.ipaddress">
                                <em class="fa-1x fas fa-globe ml-2"></em> IP: {{ detail.ipaddress }}
                            </ng-container>
                            <ng-container *ngIf="detail.wifiSignal">
                                <em class="fa-1x fas fa-signal ml-2"></em> Signal: {{ detail.wifiSignal }}
                            </ng-container>
                            <ng-container *ngIf="detail.batteryperc">
                                <battery-icon class="ml-2" [percentage]="detail.batteryperc"></battery-icon>
                            </ng-container>
                            <ng-container *ngIf="detail.batteryHealth">
                                <battery-icon class="ml-2" [percentage]="detail.batteryHealth"></battery-icon>
                            </ng-container>
                            <ng-container *ngIf="detail.batteryCycles">
                                <em class="fa-1x fas fa-car-battery ml-2"></em> {{ detail.batteryCycles }} Battery Cycles
                            </ng-container>
                            <!-- ae -->
                            <ng-container *ngIf="detail.messageId">
                                <em class="fa-1x fas fa-chevron-right ml-2"></em> Message Id: {{ detail.messageId }}
                            </ng-container>
                            <!-- feedback -->
                            <div>
                                <ng-container *ngIf="detail.idCommand">
                                    <span class="mx-1"><strong>Command ID:</strong> {{ detail.idCommand }}</span>
                                </ng-container>
                            </div>
                            <div>
                                <ng-container *ngIf="detail.success !=null ">
                                    <span class="mx-1">
                                        <strong>Success:</strong>
                                        <em *ngIf="detail.success" class="fa-1x icon-check ml-2 text-success"></em>
                                        <em *ngIf="!detail.success" class="fa-1x icon-close ml-2 text-danger"></em>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="detail.executeNow">
                                    <span class="mx-1"><strong>ExecuteNow:</strong><em *ngIf="detail.executeNow" class="fa-1x icon-check ml-2"></em><em *ngIf="!detail.executeNow" class="fa-1x icon-close ml-2"></em></span>
                                </ng-container>
                            </div>
                            <div>
                                <ng-container *ngIf="detail.tsCmdReceived">
                                    <span class="mx-1"><strong>Received:</strong> {{ detail.tsCmdReceived | s1ReadableDate: true }}</span>
                                </ng-container>
                                <ng-container *ngIf="detail.tsStartExecution">
                                    <span class="mx-1"><strong>Start:</strong> {{ detail.tsStartExecution | s1ReadableDate: true }}</span>
                                </ng-container>
                                <ng-container *ngIf="detail.tsEndExecution">
                                    <span class="mx-1"><strong>End:</strong> {{ detail.tsEndExecution | s1ReadableDate: true }}</span>
                                </ng-container>
                            </div>
                            <div>
                                <ng-container *ngIf="detail.message">
                                    <span class="mx-1"><strong>Message:</strong> {{ detail.message }}</span>
                                </ng-container>
                                <br />
                                <ng-container *ngIf="detail.stacktrace">
                                    <span class="mx-1"><strong>Stacktrace:</strong> {{ detail.stacktrace }}</span>
                                </ng-container>
                            </div>
                            <!-- command -->
                            <div>
                                <ng-container *ngIf="detail.mode">
                                    <span class="mx-1"><strong>Mode:</strong> {{ detail.mode }}</span>
                                </ng-container>
                                <ng-container *ngIf="detail.source">
                                    <span class="mx-1"><strong>Source:</strong> {{ detail.source }}</span>
                                </ng-container>
                            </div>
                            <div *ngIf="detail.body">
                                {{ detail.body }}
                            </div>
                        </span>
                        
                    </div>
                </div>
            </div>
        </li>
    </ng-container>

  <li class="timeline-end" *ngIf="hasNext">
      <a class="timeline-badge pointer" (click)="loadAnotherElements()">
          <em class="fa fa-plus"></em>
      </a>
  </li>
</ul>

<br>