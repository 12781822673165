import { Component, OnInit, Input } from '@angular/core';
import { IWall } from '@app/shared/models/models';

@Component({
    selector: 'walls-tabs',
    templateUrl: './walls-tabs.component.html',
    styleUrls: ['./walls-tabs.component.scss']
})

export class WallsTabsComponent implements OnInit {

  @Input() set wallsList(newValue: IWall[]) {
    this.walls = newValue;
    this.updateSummary();
  }

  walls: IWall[];
  summaryWall: IWall;
  
  constructor() {
    this.walls = [];
    this.summaryWall = {
      id: -99,
      description: "Summary",
      code: "SUMMARY",
      cols: 0,
      rows: 0,
      show: true
    };
  }

  public ngOnInit(): void {
    this.updateSummary();
  }

  updateSummary() {
    this.walls?.forEach(wall => wall.show = false);
    if (this.walls?.length > 0) {
      this.summaryWall = {
        id: -99,
        description: "Summary",
        code: "SUMMARY",
        cols: 0,
        rows: 0,
        show: true,
        nameCompany: this.walls[0].nameCompany,
        nameRegion: this.walls[0].nameRegion,
        nameSite: this.walls[0].nameSite,
      };
    }
  }

  showTab(wallToShow: any) {
    this.summaryWall.show = false;
    this.walls.forEach(wall => wall.show = false);
    wallToShow.show = true;
  }

}
