import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';
import { IWallsSearchParams, DispensingService } from '@app/shared/services/dispensing.service';

@Component({
  selector: 'mdm-input-wall-select',
  templateUrl: './input-wall-select.component.html',
  styleUrls: ['./input-wall-select.component.scss']
})
export class InputWallSelectComponent implements OnInit {

  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();
  @Input() control: UntypedFormControl;
  @Input() idRegion: number;
  @Input() set idSite(newValue: number) {
    this.siteSelected = newValue != null
    this.control.patchValue(null);
    this.getWalls(newValue)
  }
  @Input() bindAllObject: boolean = true;
  @Input() showAll: boolean = false
  @Input() placeholder: string = '';

  siteSelected: boolean = false
  wallsList: IS1InputSelectItem[];

  constructor(private dispensingService: DispensingService, public settingsService: SettingsService) { 
  }
  
  ngOnInit(): void {}

  private getWalls(idSite?: number, ui: boolean = true) {

    if(idSite>0 && this.idRegion>0) {
      const params: IWallsSearchParams = {
        idCompany: this.settingsService.getCompany().code,
        idRegion: this.idRegion,
        idSite: idSite
      }
  
      this.dispensingService.getWalls(params, false).subscribe(walls => {
        this.wallsList = walls.map(wall => { 
          return { code: wall.code, label: wall.description } 
        });
        this.onLoadList.emit(this.wallsList);
      })

    }

  }

}
