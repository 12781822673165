import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { ProfilesService } from '@app/shared/services/profiles.service';

@Component({
  selector: 'mdm-input-os-action-type-select',
  templateUrl: './input-os-action-type-select.component.html',
  styleUrls: ['./input-os-action-type-select.component.scss']
})
export class InputOsActionTypeSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean = false;
  @Input() bindAllObject: boolean = true;

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  typesList: IS1InputSelectItem[];

  constructor(private profilesService: ProfilesService) { }

  ngOnInit(): void {
    this.typesList = this.getTypes();
  }

  private getTypes() {
    return [
      { code: 'UPGRADE', label: 'UPGRADE' },
      { code: 'DOWNGRADE', label: 'DOWNGRADE' }
    ];
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
