import { Component, OnInit, Input } from '@angular/core';
import { ChartType, ChartOptions, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';

export interface ChartGaugeConfig {
  total: number,
  value: number,
  color: string,
  label: string
}

export enum ChartColors {
  primary = "#5d9cec",
  success = "#27c24c",
  info = "#0371a1", //"#23b7e5",
  warning = "#ff902b",
  danger = "#f05050",
  dark = "#3a3f51"
}

@Component({
  selector: 'chart-gauge',
  templateUrl: './chart-gauge.component.html',
  styleUrls: ['./chart-gauge.component.scss']
})
export class ChartGaugeComponent implements OnInit {

  @Input() set gaugeConfig(newValue: ChartGaugeConfig) {

    this.chartConfig = newValue;

    this.gaugeChartData = [this.chartConfig.value, this.chartConfig.total - this.chartConfig.value]

    this.percentage = this.chartConfig.total > 0 ? (this.chartConfig.value / this.chartConfig.total * 100) : 0 

    this.gaugeChartColors[0].backgroundColor[0] = this.chartConfig.color
    this.gaugeChartLabels[0] = this.chartConfig.label

    this.setLabelText()

  }

  percentage: number = 0

  chartConfig: ChartGaugeConfig;

  previousChartWidth: number = 0;

  private chartCanvas: Chart;

  // Doughnut
  public gaugeChartLabels = ['', ''];
  public gaugeChartData = [40, 60];
  public gaugeChartType: ChartType = 'doughnut'; // Gauge is a doughnut with only 2 values
  public gaugeChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(255,255,255,0.3)'],
    },
  ];
  public gaugeChartOptions: BaseChartDirective['getChartOptions'] = {
    responsive: true,
    legend: {
      display: false,
    },
    cutoutPercentage: 90
  };

  public gaugeChartPlugins: BaseChartDirective['getChartConfiguration'];

  pieOptions
  
  constructor() {

    //this.pieChartData = this.chartConfig.data

    this.pieOptions = {
      animate: {
        duration: 800,
        enabled: true
    },
    barColor: ChartColors.info,
    trackColor: 'rgba(93, 192, 236,0.3)',
    scaleColor: false,
    lineWidth: 20,
    lineCap: 'round',
    size: 300
};
    
    
   }

  ngOnInit() {

    let me = this;

    this.gaugeChartPlugins = [{

      afterRender(chart) {

        me.chartCanvas = chart

        if (me.previousChartWidth != chart.width) {
          me.setLabelText()
        }

       
      },

      /*resize() {
        me.chartCanvas = chart
        me.setLabelText()
      }*/
  
    }]
    
  }

  setLabelText() {

    let chart = this.chartCanvas

    if (!chart) {
      return
    }

    const ctx = chart.ctx;
      

    /*if (this.previousChartWidth == chart.width) {
      return
    }*/

    this.previousChartWidth = chart.width

    const txt = this.percentage.toFixed(0)  + " %" ;
    const innerRadius = chart.width * 0.1 * 2

    //Get options from the center object in options
    const sidePadding = 20;
    const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

    //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = (innerRadius * 2) * 0.9; //- sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const elementHeight = (innerRadius * 2);

    // Pick a new font size so it will not be larger than the height of label.
    const fontSizeToUse = Math.min(newFontSize, elementHeight);

    ctx.font = fontSizeToUse + 'px Source Sans Pro';
    ctx.fillStyle = ChartColors.dark;

    // Draw text in center
    ctx.fillText(txt, centerX, centerY);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}