import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICommandList } from '@app/shared/models/models';

export type ComponentChange<T, P extends keyof T> = {
  previousValue: T[P];
  currentValue: T[P];
  firstChange: boolean;
};

export type ComponentChanges<T> = {
  [P in keyof T]?: ComponentChange<T, P>;
};


@Component({
  selector: 'app-command-list',
  templateUrl: './command-list.component.html',
  styleUrls: ['./command-list.component.scss']
})
export class CommandListComponent implements OnChanges  {
  @Input() commands: ICommandList;
  constructor(private _router: Router) { }

  ngOnChanges(changes: ComponentChanges<CommandListComponent>): void {
    this.commands = changes.commands?.currentValue;
    console.log("COM:" + JSON.stringify(this.commands));
  }

  getColorStatus(status:number) : string{
    let stato:string="";
    switch (status)
    {
      case 1:
        stato="color_1";
        break;
      case 2:
        stato="color_2";
        break;
      case 3: 
        stato="color_3";
        break;
      case 4:
        stato="color_4";
        break;
      default: 
        stato="color_1";
        break;
    }
    return stato;
  }

  getColorBorderStatus(status:number) : string{
    let stato:string="";
    switch (status)
    {
      case 1:
        stato="border_color_1";
        break;
      case 2:
        stato="border_color_2";
        break;
      case 3: 
        stato="border_color_3";
        break;
      case 4:
        stato="border_color_4";
        break;
      default: 
        stato="border_color_1";
        break;
    }
    return stato;
  }

  getTitleStatus(status:number) : string{
    let stato:string="";
    switch (status)
    {
      case 1:
        stato="Running";
        break;
      case 2:
        stato="Success";
        break;
      case 3: 
        stato="Warning";
        break;
      case 4:
        stato="Error";
        break;
      default: 
        stato="Unknow";
        break;
    }
    return stato;
  }

  apriDettaglio(id:number){
    this._router.navigate(["/units/command_datail/" + id]);
  }

  apriDuple(tipo:string,id:number){
    
    if(tipo.toLocaleLowerCase()=="diretto"){
      this._router.navigate(["/units/direct_duple_command/" + id]);
    }else{
      this._router.navigate(["/units/broadcast_duple_command/" + id]);
    }

  }

}
