import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LoggingService } from '@app/core/services/log.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem, S1UIService, SelectItem } from '@app/s1';
import { UsersService } from '@app/shared/services/users.service';

@Component({
  selector: 'mdm-input-user-role-select',
  templateUrl: './input-user-role-select.component.html',
  styleUrls: ['./input-user-role-select.component.scss']
})
export class InputUserRoleSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;

  rolesList: IS1InputSelectItem[];

  constructor(
    private settingsService: SettingsService,
    private userService: UsersService,
    private logger: LoggingService,
    private ui: S1UIService
  ) { 
    this.rolesList = [];
  }

  ngOnInit(): void {
    this.loadElements();
  }

  // ---------- PRIVATE METHODS ---------- //

  private loadElements(): void {
    this.userService.getUserRoles().subscribe(
      (response) => {
        this.rolesList = this.manageUserRoles(response);
      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.ui.showErrorToast(error.message);
      }
    )
  }

  /** Method that manages user roles removing specific types when not required (since the service automatically returns the entire list) */
  private manageUserRoles(response: SelectItem[]): SelectItem[] {
    if (!this.settingsService.isSuperAdmin()) {
      response.splice(response.findIndex(i => i.code === 'SUPERADMIN'), 1);
    } else if (this.settingsService.getCompany().reseller) {
      response.splice(response.findIndex(i => i.code === 'OPERATOR'), 1);
      response.splice(response.findIndex(i => i.code === 'OPERATOR_RO'), 1);
    }

    return response;
  }

}
