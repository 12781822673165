<s1-modal #modal [title]="'assets.list.title'" (onClose)="close()">

  <form [formGroup]="searchForm">
    <s1-card [bottomPadding]="false" class="text-left">
      <div class="row">
        <mdm-input-asset-type-select class="col-4" [includeOs]="false" [control]="searchForm.controls.assetType"></mdm-input-asset-type-select>
        <s1-input-text class="col-4" [control]="searchForm.controls.name" [label]="'assets.list.fields.name'" [labelGrouped]="false"></s1-input-text>
        <s1-input-text class="col-4" [control]="searchForm.controls.description" [label]="'assets.list.fields.description'" [labelGrouped]="false"></s1-input-text>
      </div>
      <div class="row text-center">
        <div class="col-12 my-2">
          <s1-button [type]="s1ButtonType.Search" (onClick)="getAssets()"></s1-button>
        </div>
      </div>
    </s1-card>
  </form>

  <s1-card class="asset-selection-table">
    <s1-table #table [onlinePagination]="false">
      <tbody body>
        <tr *ngFor="let asset of table.config.rows; let i = index">
          <td *ngIf="!singleSelection">
            <s1-button-check (checkChange)="updateSelection($event, asset)"></s1-button-check>
          </td>
          <td>
            <mdm-asset-card [asset]="asset" [editMode]="false"></mdm-asset-card>
          </td>
          <td *ngIf="singleSelection">
            <s1-button [type]="s1ButtonType.Link" [label]="'s1.button.add'" [icon]="'fa fa-plus'" (onClick)="saveSingleSelection(asset)"></s1-button>
          </td>
        </tr>
      </tbody>
    </s1-table>
    
    <div class="row text-left m-2" *ngIf="!singleSelection">
      <div class="col fit text-center">
          <button type="button" class="btn px-2 shadow-sm btn-outline-primary" (click)="save()">
              <em class="mr-2 fa-2x icon-asset icon-smartilio"></em>
              <strong class="btn-select-label"> {{ 'base.add_selection' | translate }} </strong>
          </button>
      </div>
    </div>

  </s1-card>
</s1-modal>