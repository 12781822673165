import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {IS1InputSelectItem} from "@app/s1";

@Component({
  selector: 'mdm-input-options-select',
  templateUrl: './input-options-select.component.html',
  styleUrls: ['./input-options-select.component.scss']
})
export class InputOptionsSelectComponent implements OnInit {

  @Input() options: Array<any>;
  @Input() label: string;
  @Input() control: UntypedFormControl;
  @Input() bindAllObject: boolean = true;
  @Input() readonly: boolean = false;
  @Input() clearable: boolean = true;
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();

  constructor() { }

  ngOnInit(): void {
    this.onLoadList.emit(this.options);
  }
  
  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
