<!-- <div>
  <div class="p-2">
    <canvas baseChart width="100%" height="100%"
      [data]="gaugeChartData"
      [labels]="gaugeChartLabels"
      [colors]="gaugeChartColors"
      [options]="gaugeChartOptions"
      [type]="gaugeChartType"
      [plugins]="gaugeChartPlugins">
    </canvas>
    <div class="percentage text-center">
      {{ percentage | number : '1.0-2' }} %
    </div>
  </div>
</div> -->

<div class="text-center py-4 position-relative">
  <div class="easypie-chart" easypiechart [options]="pieOptions" [percent]="percentage">
    <div class="percentage text-center position-absolute">
      {{ percentage | number : '1.0-1' }} %
    </div>
  </div>
</div>