import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartType } from 'chart.js';

export interface ChartPieConfig {
  // data: number[];
  // colors: string[];
  datasets: {
    data: number[],
    backgroundColor: string[],
    hoverBackgroundColor: string[],
    hoverBorderColor: string[]
  }[];
  labels: string | string[];
}

export enum ChartColors {
  primary = '#5d9cec',
  secondary = '#fff',
  success = '#27c24c',
  info = '#0371a1',
  warning = '#ff902b',
  danger = '#f05050',
  dark = '#3a3f51'
}

@Component({
  selector: 'chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.scss']
})
export class ChartPieComponent implements OnInit {

  @Input() set pieConfig(newValue: ChartPieConfig) {
    this.chartConfig = newValue;
  }

  @Output() sliceClicked = new EventEmitter<number>();

  chartConfig: ChartPieConfig;

  // Pie
  public pieChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    }
  };
  public pieChartLabels = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [1, 2, 3];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [];

  constructor() {}

  ngOnInit() {}

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: any[] }): void {
    if (active && active.length) {
      const indexClicked = active[0].index;
      this.sliceClicked.emit(indexClicked);
    }
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {}

}
