import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { CompaniesService } from '@app/shared/services/companies.service';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../core/settings/settings.service';

declare var $: any;

@Component({
    selector: 'app-subheader',
    templateUrl: './subheader.component.html',
    styleUrls: ['./subheader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubHeaderComponent implements OnInit {

    isSuperAdminOrReseller : boolean = false;
    activeCompany: any;
    sidebarEventSubscription : Subscription;

    constructor(
        public settings: SettingsService,
        private companiesService: CompaniesService,
        public router: Router
    ) {
        this.isSuperAdminOrReseller = this.settings.isSuperAdmin() || this.settings.isReseller();
    }

    ngOnInit() {
        if(this.isSuperAdminOrReseller) {
            this.sidebarEventSubscription = this.settings.sidebarChanged.subscribe(() => {
                console.log('sidebarChanged');
                this.activeCompany = this.settings.getCompany();
                if(this.activeCompany) {
                    this.companiesService.getCompany(this.settings.getCompany().code, false).subscribe(company => {
                        //refreshed company
                        this.activeCompany = {'code': company.id, 'label': company.name, 'reseller': company.reseller};
                        this.settings.setFilterFromSidebar( 'company' , this.activeCompany );
                    })
                }
            });
        } else {
            this.activeCompany = this.settings.getCompany();
        }
    }

    menuToggle() {
        const offsideValue = this.settings.getLayoutSetting('offsidebarOpen');
        this.settings.setLayoutSetting('offsidebarOpen', !offsideValue);
    }

    ngOnDestroy() {
        if(this.isSuperAdminOrReseller) {
            this.sidebarEventSubscription.unsubscribe();
        }
    } 
}
