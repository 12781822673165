<s1-input-label [label]="label" [grouped]="false">
  <ng-multiselect-dropdown
    placeholder="{{ placeholder | translate}}"
    [disabled]="readonly"
    [ngClass]="{'is-invalid' : !checkIsValid() }"
    [formControl]="control"
    [settings]="dropdownSettings"
    [data]="options"
    (onSelect)="onItemSelect($event)"
    (onSelectAll)="onAllItemsSelect($event)"
    (onDeSelectAll)="onAllItemsDeselect()"
    >
  </ng-multiselect-dropdown>
</s1-input-label>