import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem } from '@app/s1';
import { RegionsService, IRegionsSearchParams } from '@app/shared/services/regions.service';

@Component({
  selector: 'mdm-input-region-select',
  templateUrl: './input-region-select.component.html',
  styleUrls: ['./input-region-select.component.scss']
})
export class InputRegionSelectComponent implements OnChanges {

  @Input() control: UntypedFormControl;
  @Input() siteControl: AbstractControl;
  @Input() set idCompany(newValue: number) {
    this.companySelected = newValue != null;
    this.getRegions(newValue);
  }
  @Input() readonly = false;
  @Input() showAll = false;
  @Input() bindAllObject = true;
  @Input() placeholder = '';
  @Input() disableSelected = false;
  @Input() clearable: boolean = true;
  @Input() selectedRegions: number[] = [];
  @Output() onSelectChange = new EventEmitter();
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();

  companySelected = false;
  regionsList: IS1InputSelectItem[];
  isAdminOrSuper: boolean;
  selRegions: number[] = [];

  constructor(private regionsService: RegionsService, public settingsService: SettingsService) {
    this.isAdminOrSuper = this.settingsService.isSuperAdmin() || this.settingsService.isReseller() || this.settingsService.isAdmin();
  }

  ngOnChanges(): void {
    if (this.regionsList) {
      const list = this.regionsList.map(r => { r.disabled = this.selectedRegions.includes(r.code as number); return r });
      this.regionsList = [...list];
    }
  }

  private getRegions(idCompany?: number) {

    const params: IRegionsSearchParams = {
      code: null,
      name: null,
      idCompany
    };

    this.regionsService.getRegions(params, true, false).subscribe(regions => {

      const regionsList = regions.map(region => {
        return { code: region.id, label: region.name, disabled: (this.disableSelected && this.selectedRegions.includes(region.id)) };
      });

      const userRegionIds = this.settingsService.getRegions()?.map(region => region.code);
      if (userRegionIds?.length > 0) {
        this.regionsList = regionsList.filter(region => userRegionIds.includes(region.code as number));
      } else {
        this.regionsList = regionsList;
      }

      this.onLoadList.emit(this.regionsList);

      // autoselection when items == 1
      this.settingsService.setPreselectedRegion(this.control, idCompany, this.bindAllObject, regionsList);

    });

  }

  changed(event) {
    /** If a siteControl is available, then it must be cleared on each region change, in order to avoid wrong input values */
    if (this.siteControl) {
      this.siteControl.setValue(null);
    }

    this.onSelectChange.emit(event);
  }

}
