<div class="card" *ngIf="currentWall">
  <div class="card-header bg-light pb-0 pt-1">
    <div class="row justify-content-between">
      <div class="col"></div>
      <div class="col text-center">
        <h4 class="mt-1">
          {{ currentWall.description }}
        </h4>
      </div>
      <div class="col text-right">
        <button class="mr-1 btn btn-light mb-1" (click)="showTab(currentWall)">
          <i class="icon-refresh fa-2x" aria-hidden="true"></i>
        </button>
        <button class="mr-1 btn btn-labeled btn-light mb-1 border rounded fake-disabled">
          <span class="btn-label rounded-left"><i class="fa fa-tablet-alt"></i></span>
          <h4 class="d-inline"> {{ unitCounter }}</h4>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="currentWall.code != 'NO_WALL'; else noWalls" class="card-body" [collapse]="isCollapsed">

    <div *ngIf=" currentWall.rows <= 0 && currentWall.cols <= 0" class="text-center w-100 h4">
      {{ 'dispensing.no_row_col_card' | translate }}
    </div>

    <div class="row justify-content-center" *ngFor="let row of (currentWall.rows | numericList)">
      
      <ng-container *ngIf="currentWall.cols <= 12; else moreThan12">
        <ng-template *ngFor="let col of (currentWall.cols | numericList);"
          [ngTemplateOutlet]="cellTemplate" 
          [ngTemplateOutletContext]="{ $implicit: row, col: col, width:1 }"> <!-- (currentWall.cols <= 6) ? 2 : 1 -->
        </ng-template>
      </ng-container>

      <ng-template #moreThan12>

        <ng-container *ngFor="let level of gridLevels;">
          <ng-container *ngIf="currentWall.cols > level.lowerBound && currentWall.cols <= level.upperBound">
  
            <ng-template
              [ngTemplateOutlet]="maxiCellTemplate"
              [ngTemplateOutletContext]="{ $implicit: row, elem: level.elements, width: level.width}">
            </ng-template>

          </ng-container>
        </ng-container>

      </ng-template>

    </div>

  </div>

  <ng-template #noWalls>

    <div class="card-body">
      <div class="row justify-content-center">

        <ng-container *ngFor="let unit of flattenedList; let i = index">
          
          <div *ngIf="(i % 12) == 0" class="w-100"></div>
  
          <div class="col-1 m-0 p-1" >
            <unit-card [units]="[unit]"></unit-card>
          </div>
        </ng-container>
  
      </div>
    </div>

  </ng-template>

</div>

<!-- MaxiCell 
  row: riga
  nElem: numero elementi all'interno della singola maxiCella
  cellW: larghezza in col- (1 - 12) della cella. Si poteva fare 12 (colonne bootstrap) / nElem, per sicurezza è come parametro
-->
<ng-template #maxiCellTemplate let-row let-nElem="elem" let-cellW="width">
  <ng-container *ngFor="let maxiColl of ((currentWall.cols/nElem) | numericList: 0);">
    <div class="col m-0 p-0" *ngIf="(maxiColl * nElem + 1) <= currentWall.cols">
      <div class="row m-0 p-0">

        <ng-template *ngFor="let miniColl of (nElem | numericList);"
          [ngTemplateOutlet]="cellTemplate"
          [ngTemplateOutletContext]="{ $implicit: row, col: (maxiColl * nElem + miniColl), width:cellW }">
        </ng-template>

      </div> 
    </div>
  </ng-container>
</ng-template>

<!-- Cell 
  row: riga
  coll: colonna
  cellW: larghezza cella (1 - 12)
-->
<ng-template #cellTemplate let-row let-coll="col" let-cellW="width">
  <div class="col-{{cellW}} m-0 p-1" *ngIf="coll <= currentWall.cols">
    <ng-template
      [ngTemplateOutlet]="unitTemplate"
      [ngTemplateOutletContext]="{ $implicit: row, col: coll }">
    </ng-template>
  </div>
</ng-template>

<!-- Unit -->
<ng-template #unitTemplate let-row let-coll="col">
  <ng-container *ngIf="getUnits(currentWall.units, row, coll) as units; else emptyCard">
    <unit-card [units]="units" [fullMode]="currentWall.cols <= limitFullMode"></unit-card>
  </ng-container>
  <ng-template #emptyCard>
    <div class="my-2 p-0 rounded unit shadow-sm shadow-sm d-flex align-items-center justify-content-center bg-light h5">
      {{ row }} - {{ coll }}
    </div>
  </ng-template>
</ng-template>