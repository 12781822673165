import { Injectable } from '@angular/core';
import { S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IpAssignment, ISite } from '../models/models';

export interface IpAssignmentsSearchParams {
  idCompany: number,
  codUnit?: string,
  ipAddress?: string,
  subnetMask?: string,
  idRegion?: number,
  idRegions?: number[],
  idSite?: number,
  idSites?: number[],
  hasIpAssignments?: boolean,
  description?: string
}

@Injectable({
  providedIn: 'root'
})
export class IpAssignmentsService {

  private path = "/ipassignment"
  private pathIpAssignmentsSite = "/ipassignment/sites"
  private pathIpAssignmentsCodUnit = "/ipassignment/codunit-ip-assignments"
  private pathIpAssignmentStrategies = "/ipassignment/strategies"

  constructor(private s1HttpClient: S1HttpClientService) { }

  getProfileNetworkIpAssignmentStrategies(): Observable<any[]> {
    return this.s1HttpClient.get(this.pathIpAssignmentStrategies, {}, false).pipe(
      map(response => response.data.results)
    )
  }

  getIpAssignmentsSite(parameters: IpAssignmentsSearchParams, ui: boolean = true): Observable<ISite[]> {
    return this.s1HttpClient.get(this.pathIpAssignmentsSite, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  getIpAssignmentsCodUnit(parameters: IpAssignmentsSearchParams, ui: boolean = true): Observable<ISite[]> {
    return this.s1HttpClient.get(this.pathIpAssignmentsCodUnit, parameters, ui).pipe(
      map(response => response.data.results)
    )
  }

  createIpAssignment(ipAssignment: IpAssignment): Observable<IpAssignment> {
    return this.s1HttpClient.post(this.path, ipAssignment, false).pipe(
      map(response => response.data)
    )
  }

  deleteIpAssignment(ipAssignment: IpAssignment): Observable<boolean> {
    return this.s1HttpClient.delete(this.elementPath(ipAssignment), false).pipe(
      map(response => response.outcome.success)
    )
  }

  private elementPath(ipAssignment: IpAssignment): string {
    return this.path + "/" + ipAssignment.id
  }

}
