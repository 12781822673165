<s1-modal #modal [title]="'units.list.title'" (onClose)="close()">

  <form [formGroup]="searchForm">
    <s1-card [bottomPadding]="false" class="text-left">
      <div class="row mb-2">
        <s1-input-text class="col-3" [control]="searchForm.controls.code" [label]="'units.list.fields.code'" [labelGrouped]="false"></s1-input-text>
        <s1-input-text class="col-7" [control]="searchForm.controls.fingerprint" [label]="'units.list.fields.fingerprint'" [labelGrouped]="false"></s1-input-text>
        <s1-button class="col-auto mt-search" [type]="s1ButtonType.Search" (onClick)="getAvaiableUnitsDistribution()" *ngIf="advancedSearchCollapsed"></s1-button>
      </div>
      
      <div id="advancedSearch" [collapse]="advancedSearchCollapsed" [isAnimated]="true">
        <div class="row mt-1">
          <mdm-input-region-select class="col-3" [control]="searchForm.controls.region" [idCompany]="settings.getCompany()?.code"
            [siteControl]="searchForm.controls.site"></mdm-input-region-select>
          <mdm-input-site-select class="col-3" [control]="searchForm.controls.site" [idRegion]="searchForm.controls.region.value?.code"></mdm-input-site-select>
          <mdm-input-group-select class="col-3" [control]="searchForm.controls.group" [idCompany]="settings.getCompany()?.code"></mdm-input-group-select>
        </div>
        <div class="row">
          <mdm-input-brand-select class="col-3" [control]="searchForm.controls.brand" [basedOnAgent]="editMode" [idAgent]="idAgent"></mdm-input-brand-select>
          <mdm-input-model-select class="col-3" [control]="searchForm.controls.model" [idBrand]="searchForm.controls.brand.value?.code"></mdm-input-model-select>
        </div>
      </div>
  
      <div class="row">
        <button type="button" class="col-auto mx-4 my-1 btn-xs btn btn-outline-primary" (click)="advancedSearchCollapsed = !advancedSearchCollapsed">{{ 'base.advanced_search_'+(advancedSearchCollapsed ? 'on' : 'off') | translate }}
        </button>
      </div>
  
      <div class="row text-center" *ngIf="!advancedSearchCollapsed">
        <div class="col-12 my-2">
          <s1-button [type]="s1ButtonType.Search" (onClick)="getAvaiableUnitsDistribution()"></s1-button>
        </div>
      </div>
    </s1-card>
  </form>

  <s1-card class="units-list-table">
    <div class="row text-left">
      <div class="col fit text-left" *ngIf="table.config.totalData>0">
        <s1-button *ngIf="getPageSelectionFlag()" class="ml-2" [type]="s1ButtonType.Cancel" [icon]="'fa-2x far fa-check-square'" [outline]="true" [onlyIcon]="true" (onClick)="toggleSelection()"></s1-button>
        <s1-button *ngIf="!getPageSelectionFlag()" class="ml-2" [type]="s1ButtonType.Cancel" [icon]="'fa-2x far fa-square'" [outline]="false" [onlyIcon]="true" (onClick)="toggleSelection()"></s1-button>
      </div>
      <div class="col-7 fit text-right" *ngIf="table.config.totalData>0">
       <button type="button" class="btn px-2 shadow-sm btn-outline-primary mr-2" (click)="askSaveAll()" *ngIf="true">
          <em class="mr-2 fa-2x fas fa-th"></em>
          <strong class="btn-select-label"> {{ 'base.add_all_selection' | translate: { total: table.config.totalData }  }} </strong>
        </button>
        <button type="button" class="btn px-2 shadow-sm btn-outline-success mr-2" (click)="askSaveSelection()">
            <em class="mr-2 fa-2x fas fa-mobile-alt"></em>
            <strong class="btn-select-label"> {{ 'base.add_selection' | translate }} </strong>
        </button>
      </div>
    </div>
    <div class="row text-left my-1" *ngIf="table.config.totalData>0">
      <div class="col ml-2">
          {{ 'base.counter_selection' | translate: { counter: getTotalSelections(), total: table.config.totalData } }}
          <ng-container *ngIf="existsSelection()">
            <strong class="ml-2 text-primary pointer underline" (click)="deselectSelection()">{{ 'base.empty_selection' | translate }}</strong>
          </ng-container>
      </div>
    </div>

    <s1-table #table [onlinePagination]="true" (pageChanged)="getAvaiableUnitsDistribution()">
      <tbody body>
        <tr *ngFor="let unit of table.config.rows; let i = index">
          <td>
            <div class="row">
              <div class="col-auto py-2">
                <s1-button-check [check]="isSelected(unit.id)" (checkChange)="updateSelection($event, unit.id)"></s1-button-check>
              </div>
              <div class="col py-2">
                <mdm-unit-cell [modal]="true" [unit]="unit" template="profiles" [showButtons]="false"></mdm-unit-cell>
                <div class="row mt-2" *ngIf="unit.nextPendingDistributionId">
                  <div class="col text-left text-primary">
                      <em class="fa fas fa-exclamation-circle mr-2"></em>
                      {{'profiles.distributions.all.unit_schedulate_in_other_distribution' | translate}}<span class="font-weight-bold">{{unit.nextPendingDistributionStartDate | date: 'dd/MM/yyyy HH:mm'}}</span>
                      <span *ngIf="unit?.distributionPendingNumber>2"> {{'profiles.distributions.all.unit_pending_number_distributions' | translate: { counter: unit.distributionPendingNumber - 1 } }}</span>
                      <span *ngIf="unit?.distributionPendingNumber==2"> {{'profiles.distributions.all.unit_pending_number_distribution' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </s1-table>

  </s1-card>
</s1-modal>