import { Component, OnInit, Input, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { S1ButtonType, S1Table, S1UIService, IS1InputSelectItem } from '@app/s1';
import { IProfileAssignment, IProfile } from '@app/shared/models/profile';
import { SettingsService } from '@app/core/settings/settings.service';
import { IHierarcicalSelection, HierarchicalSelectionModalComponent } from '../hierarchical-selection-modal/hierarchical-selection-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'mdm-profile-assignment-hierarchical',
  templateUrl: './profile-assignment-hierarchical.component.html',
  styleUrls: ['./profile-assignment-hierarchical.component.scss']
})
export class ProfileAssignmentHierarchicalComponent implements OnInit, AfterViewInit {

  s1ButtonType = S1ButtonType;

  searchForm: UntypedFormGroup;

  @Input() profile: IProfile;
  @Input() set profileAssignments(newValue: IProfileAssignment[]) {
    this._profileAssignments = newValue
    this.updateTable()
  }

  @Output() profileAssignmentsChange = new EventEmitter<IProfileAssignment[]>();

  @ViewChild('table') table: S1Table;
  @ViewChild('hierarchicalSelectionModal') hierarchicalSelectionModal: HierarchicalSelectionModalComponent;

  private _profileAssignments: IProfileAssignment[] = []

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private ui: S1UIService, public settings: SettingsService) { 

    this.searchForm = this.formBuilder.group({
      code: null,
      company: null,
      region: null,
      site: null,
      brand: null,
      model: null,
      group: null
    });

  }

  ngAfterViewInit(): void {
    this.updateTable()
  }

  ngOnInit(): void {
  }

  private removeSingle(assignments: IProfileAssignment[]): IProfileAssignment[] {

    return assignments.filter(assignment => (assignment.idUnit == null))

  }

  private updateTable() {
    this.table?.updateData(this.removeSingle(this._profileAssignments))
  }

  openSelection() {
    this.hierarchicalSelectionModal.open()
  }

  addRule(selection: IHierarcicalSelection) {

    if (this._profileAssignments == null) {
      this._profileAssignments = []
    }

    const company: IS1InputSelectItem = selection.company
    const region: IS1InputSelectItem = selection.region
    const site: IS1InputSelectItem = selection.site
    const group: IS1InputSelectItem = selection.group
    const brand: IS1InputSelectItem = selection.brand
    const model: IS1InputSelectItem = selection.model

    const profileAssignment: IProfileAssignment  = {
      idProfile: this.profile?.id,
      idCompany: +company?.code,
      nameCompany: company?.label,
      idRegion: +region?.code,
      nameRegion: region?.label,
      idSite: +site?.code,
      nameSite: site?.label,
      idGroup: +group?.code,
      nameGroup: group?.label,
      idBrand: +brand?.code,
      nameBrand: brand?.label,
      idModel: +model?.code,
      nameModel: model?.label
    }

    this._profileAssignments.push(profileAssignment)
    this.updateTable()
    this.ui.showSuccessToast("profiles.assignment.sections.hierarchical.popup.created")
    this.profileAssignmentsChange.emit(this._profileAssignments)

    this.searchForm.patchValue({
      code: null,
      region: null,
      site: null,
      brand: null,
      model: null,
      group: null
    })

  }

  askDeleteRule(assignment: IProfileAssignment) {

    this.ui.showDialogPopup("profiles.assignment.sections.hierarchical.popup.askDelete").then(result => {

      if (result.value) {
        this.deleteRule(assignment)
      }

    })

  }

  deleteRule(assignmentToDelete: IProfileAssignment) {

    this._profileAssignments = this._profileAssignments.filter(assignment => assignment.id != assignmentToDelete.id)
    this.updateTable()
    this.ui.showSuccessToast("profiles.assignment.sections.hierarchical.popup.deleted")
    this.profileAssignmentsChange.emit(this._profileAssignments)

  }

  showDetail(assignmentProfile: IProfileAssignment) {
    this.router.navigate(["/profiles/" + this.profile?.id + "/assignment/" + assignmentProfile?.id + "/detail"]);
  }

}
